/* eslint-disable */
export const getParameterByName = (name, url) => {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
};

export const mapGameEvents = (gameEvents, gamePeriods) => {
  const mappedEvents = gameEvents.map(event => {
    if (event.commentType.includes("Period")) {
      const periodNumber =
        gamePeriods.findIndex(period => period.seekTime === event.seekTime) + 1;

      return {
        ...event,
        commentType: `Period ${periodNumber}`
      };
    }

    return event;
  });
  return mappedEvents;
};

export const filterEvents = (filters, events, periodsTimes) => {
  const filteredEvents = events.filter(event => {
    let positionFilter = true;
    let eventFilter = true;
    let periodFilter = true;
    let playerFilter = true;
    if (filters.event && filters.event.length > 0) {
      eventFilter = filters.event.includes(event.commentType);
    }
    if (filters.position && filters.position.length > 0) {
      if (event.position && event.position.area) {
        positionFilter = filters.position.includes(event.position.area);
      } else {
        positionFilter = false;
      }
    }
    if (
      filters.period &&
      filters.period.length > 0 &&
      periodsTimes.length > 0
    ) {
      periodFilter =
        periodsTimes.filter(
          (periodTime, index) =>
            filters.period.includes(index + 1) &&
            event.seekTime >= periodTime.seekTime &&
            event.seekTime <= periodTime.endSeekTime
        ).length > 0;
    }
    if (filters.player && filters.player.length > 0) {
      if (
        event.tagedUsers &&
        event.tagedUsers.length > 0 &&
        event.tagedUsers[0].playerNumber !== null
      ) {
        playerFilter = filters.player.includes(
          event.tagedUsers[0].playerNumber
        );
      } else {
        playerFilter = false;
      }
    }
    return positionFilter && eventFilter && periodFilter && playerFilter;
  });

  return filteredEvents;
};

export const sortEvents = (sortBy, events, isDesc) => {
  switch (sortBy) {
    case "time":
      return sortByCategory("seekTime", events, isDesc);
    case "events":
      return sortByCategory("commentType", events, isDesc);
    case "player":
      const eventsWithNumber = extractPlayerNumber(events);
      return sortByCategory("playerNumber", eventsWithNumber, isDesc);
    case "position":
      const eventsWithArea = extractPositionArea(events);
      return sortByCategory("positionArea", eventsWithArea, isDesc);
  }
};

const sortByCategory = (category, events, sortDesc) => {
  events.sort((a, b) => {
    if (a[category] === null || a[category] < b[category]) {
      return 1;
    }
    if (b[category] === null || a[category] > b[category]) {
      return -1;
    }
    return 0;
  });

  return sortDesc ? events : events.reverse();
};

const extractPlayerNumber = events => {
  // extract player number from tagedUser object to the event object in order to sort by player number
  const eventsWithPlayerNumbers = [];
  events.map(event => {
    if (event.tagedUsers && event.tagedUsers.length > 0) {
      const playerNumber = event.tagedUsers[0].playerNumber;
      event.playerNumber = playerNumber;
      eventsWithPlayerNumbers.push(event);
    } else {
      event.playerNumber = -1;
      eventsWithPlayerNumbers.push(event);
    }
  });
  return eventsWithPlayerNumbers;
};

const extractPositionArea = events => {
  //extract position area from position object to the event object in order to sort by area
  const eventsWithArea = [];
  events &&
    events.map(event => {
      event.positionArea = event.position.area;
      eventsWithArea.push(event);
    });
  return eventsWithArea;
};

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const HOST = "http://localhost:3001"


const updateUploadRequest = (requestId)=> {
  const request = new XMLHttpRequest();
  request.open(
    "PATCH",
    `${HOST}/api/v1/unsecure/updateUploadRequest/${requestId}`,
    false
  );
  request.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
  request.send(JSON.stringify({ status: "CANCELED_BY_USER" }));
}

export default updateUploadRequest;

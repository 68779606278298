import React from 'react';
import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';

import './VideoPreview.scss';


function VideoPreviewMenu({ isOpen, children }) {
  const styles = {
    entered: { opacity: 1, visibility: 'visible' },
  };

  return (
    <Transition in={isOpen} timeout={60}>
      {state => (
        <div className="video-preview-menu" style={{ ...styles[state] }}>
          {children}
        </div>
      )}
    </Transition>
  );
}

VideoPreviewMenu.propTypes = {
  children: PropTypes.node,
  isOpen: PropTypes.bool
}

VideoPreviewMenu.defaultProps = {
  children: null,
  isOpen: false
}

export default VideoPreviewMenu;

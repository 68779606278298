import React, { Component } from "react";
import PropTypes from "prop-types";
import { utils } from "@myplay/all";

import WithCountriesContext from "./CountriesContext";

class WithCountries extends Component {
  static propTypes = {
    children: PropTypes.node
  };

  static defaultProps = {
    children: null
  };

  state = {
    countries: [],
    currentCountry: 0
  };

  componentDidMount() {
    this.setCountries();
  }

  shouldComponentUpdate() {
    return true;
  }

  setCountries = async () => {
    try {
      const { list, userLocation } = await utils.getCountriesList();
      let currentCountry;
      if (!list) {
        this.setState({
          countries: 0,
          currentCountry: null
        });
        return;
      }

      if (userLocation) {
        currentCountry = list.findIndex(
          ({ code }) => code === userLocation.country
        );
      }
      else {
        currentCountry = list.findIndex(
          ({ name }) => name === 'Israel'
        );
      }

      const countries = list.map(({ name, dial_code }) => ({ // eslint-disable-line
        value: name,
        dialCode: dial_code
      })); // eslint-disable-line

      this.setState({ countries, currentCountry });
    } catch (error) {
      console.log(error); // eslint-disable-line
      /** @TODO: Toast */
    }
  };

  render() {
    const { children } = this.props;
    const { countries, currentCountry } = this.state;
    return (
      <WithCountriesContext.Provider
        value={{
          countries,
          currentCountry,
          setCountries: () => this.setCountries()
        }}
      >
        {children}
      </WithCountriesContext.Provider>
    );
  }
}

export default WithCountries;

const copyToClipboard = content => {
  const textArea = document.createElement('textarea');

  textArea.style.maxHeight = '0px';
  textArea.style.height = '0px';
  textArea.style.opacity = 0;
  textArea.value = content;
  document.body.appendChild(textArea);
  textArea.select();
  window.document.execCommand('copy');
  document.body.removeChild(textArea);
}

export default copyToClipboard;

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from "react";
import "./Header.scss";
import { inject, observer } from "mobx-react";
import { PlayerBadge } from "@myplay/ui";
import Logo from "../../assets/images/logo_small.svg";
import { Settings, RecordingStatusDisplay, UserTypeGuard } from "../"; // eslint-disable-line
import HeaderButtonPanel from "../HeaderButtonsPanel";
import settingsIcon from "../../assets/icons/Icon-DropDown.svg";
import { USER_OPERATOR, LS_USER_DATA } from "../../utils/constants"; // eslint-disable-line
import { getItem } from "../../utils/storage";
import NotificationsIcon from "../Notifications/NotificationsIcon";
import I18N from "../../I18N"; // eslint-disable-line

class Header extends Component {
  static getDerivedStateFromProps(props, state) {
    const {
      UserStore: {
        data: { firstName, lastName, fullName, avatar, avatarBgColor }
      }
    } = props;

    if (props.data !== state.data) {
      return {
        data: {
          firstName,
          lastName,
          fullName,
          avatar,
          avatarBgColor
        }
      };
    }

    return {};
  }

  state = {
    data: {
      firstName: "",
      lastName: "",
      fullName: "",
      avatar: "",
      avatarBgColor: ""
    },
    shouldRenderSettings: false,
    hasImageError: false
  };

  async componentDidMount() {
    const { SiteStore, UserStore, NotificationsStore } = this.props;
    const { data } = UserStore;
    if (data.accountType !== "player") {
      SiteStore.checkRecordStatus();
    }
    NotificationsStore.getUnreadCount();
    NotificationsStore.getNotifications();
    NotificationsStore.getActiveTasksRecursive();

    const { isInvisibleUser } = getItem(LS_USER_DATA); // remove after we release ad panel to all users
    this.isInvisibleUser = isInvisibleUser;
  }

  componentDidUpdate(prevProps) {
    const { SiteStore, UserStore } = this.props;
    const { data } = UserStore;
    if (data.accountType !== "player") {
      if (prevProps.currentTeamId !== this.props.currentTeamId) {
        SiteStore.checkRecordStatus();
      }
    }
  }

  handleToggleSettings = () => {
    const { shouldRenderSettings } = this.state;
    if (!shouldRenderSettings) {
      this.setState(prevState => ({
        shouldRenderSettings: !prevState.shouldRenderSettings
      }));
    }
  };

  handleClickOutside = e => {
    const { shouldRenderSettings } = this.state;
    if (shouldRenderSettings) {
      e.stopPropagation();
    }
    this.setState({ shouldRenderSettings: false });
  };

  handleNotificationsClick = () => {
    const { ModalStore, NotificationsStore } = this.props;
    if (ModalStore.isOpen) {
      ModalStore.closeModal();
    } else {
      ModalStore.openModal({
        modalType: "NOTIFICATIONS",
        modalPosition: "top-right"
      });
      NotificationsStore.markAllAsRead();
    }
  };

  handleImageError = () => {
    this.setState({
      hasImageError: true
    });
  };

  render() {
    const { data, shouldRenderSettings, hasImageError } = this.state;
    const { SiteStore, NotificationsStore, UserStore } = this.props;
    const { recordState, isRemoteCheckDone } = SiteStore;
    const { timeToEnd } = recordState || {};
    const { unreadCount, activeTasks, clientUploadTasks } = NotificationsStore;
    const activeTasksCount = activeTasks ? activeTasks.length : 0;
    const clientUploadTasksCount = clientUploadTasks
      ? clientUploadTasks.length
      : 0;
    const totalCount = activeTasksCount + clientUploadTasksCount;
    const { accessToken, refreshToken } = UserStore;
    return (
      <header className="header">
        <div className="header__logo">
          <img src={Logo} className="header__logo__image" />
        </div>
        {isRemoteCheckDone ? <HeaderButtonPanel /> : <div />}
        <div className="header__controls">
          <RecordingStatusDisplay timeToEnd={timeToEnd} />
        </div>
        <div className="header__user">
          {this.isInvisibleUser && (
            <UserTypeGuard allowed={[USER_OPERATOR]}>
              {/* eslint-disable */}
              <a
                className="header__user__ad-panel-link"
                href={`https://manage.myplay.com/?accessToken=${accessToken}&refreshToken=${refreshToken}&userId=${
                  UserStore.data._id
                }`}
              >
                {/* eslint-enable */}
                {I18N.t("GO_TO_AD_PANEL")}
              </a>
            </UserTypeGuard>
          )}
          <NotificationsIcon
            unreadCount={unreadCount}
            activeTasksCount={totalCount}
            onClick={this.handleNotificationsClick}
          />
          <button
            type="button"
            style={{ all: "unset", display: "flex", alignItems: "center" }}
            onClick={this.handleToggleSettings}
          >
            <div className="header__user__profile">
              {data.avatar && !hasImageError ? (
                <img
                  className="header__user__profile__avatar"
                  src={data.avatar}
                  onError={this.handleImageError}
                />
              ) : (
                <div className="header__user__profile__avatar header__user__profile__avatar--big">
                  <PlayerBadge {...data} color={data.avatarBgColor} />
                </div>
              )}
            </div>
            <img src={settingsIcon} className="header__user__settings__icon" />
          </button>
          <div>
            <Settings
              isOpen={shouldRenderSettings}
              onClickOutside={this.handleClickOutside}
            />
          </div>
        </div>
      </header>
    );
  }
}

export default inject(
  "UserStore",
  "SiteStore",
  "ModalStore",
  "NotificationsStore"
)(observer(Header));

import React, { Component } from "react";
import PropTypes from "prop-types";
import { Field } from "react-final-form";
import { WithCountries, StepHeader } from "../../../components/";
import CountriesContext from "../../../components/WithCountries/CountriesContext";
import Countries from "../Countries";
import I18N from "../../../I18N";

class StepCountry extends Component {
  static propTypes = {
    countryFromInvitation: PropTypes.any,
    isActive: PropTypes.bool,
    isNextStepAllowed: PropTypes.func
  };

  static defaultProps = {
    countryFromInvitation: null,
    isActive: false,
    isNextStepAllowed: () => {}
  };

  componentDidUpdate(prevProps) {
    const { isActive, isNextStepAllowed } = this.props;

    if (isActive && prevProps.isActive !== isActive) {
      isNextStepAllowed(true);
    }
  }

  render() {
    const { countryFromInvitation } = this.props;
    return (
      <WithCountries>
        <CountriesContext.Consumer>
          {({ countries, currentCountry }) => (
            <div className="register__form__step">
              <StepHeader lines={[I18N.t("WHERE_ARE"),I18N.t("YOU_FROM")]}/>
              <div className="register__form__step__fields">
                <Field className="register__form__step__fields__field" name="country">
                  {({ input }) => (
                    <div
                      className="register__form__step__fields__field__dropdown"
                      data-testid="country"
                    >
                      {countries ? (
                        <Countries
                          currentCountry={currentCountry}
                          countries={countries}
                          input={input}
                          tabIndex={-1}
                          countryFromInvitation={countryFromInvitation}
                        />
                      ) : null}
                    </div>
                  )}
                </Field>
              </div>
            </div>
          )}
        </CountriesContext.Consumer>
      </WithCountries>
    );
  }
}

export default StepCountry;

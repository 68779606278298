import React, {Fragment} from "react";
import "./BookingScheduler.scss";
import {Input, SuccessButton, ConfirmModal, Switch, Radio, ScoreboardTeamEdit} from "@myplay/ui";
import I18N from "../../../I18N";
import {inject, observer} from "mobx-react";
import BookingCard from "./BookingCard";
import dayjs from "dayjs";
import {liveStreamDashboard, utils} from "@myplay/all";
import isValidUrl from "../../../utils/isValidUrl";


const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

const BookingScheduler = ({BookingsStore}) => {
  const handleSave = async () => {
    BookingsStore.setLoadingState(true);
    if(BookingsStore.currentBooking.scoreboardData && BookingsStore.currentBooking.scoreboardData.homeLogo && !isValidUrl(BookingsStore.currentBooking.scoreboardData.homeLogo)){
      const homeLogoUrl = await uploadImage(BookingsStore.currentBooking.scoreboardData.homeLogo, BookingsStore.getCurrentTeam()._id);
      BookingsStore.onTeamLogoChange(homeLogoUrl,true);
    }
    if(BookingsStore.currentBooking.scoreboardData &&  BookingsStore.currentBooking.scoreboardData.awayLogo && !isValidUrl(BookingsStore.currentBooking.scoreboardData.awayLogo)){
      const awayLogoUrl = await uploadImage(BookingsStore.currentBooking.scoreboardData.awayLogo, BookingsStore.getCurrentTeam()._id);
      BookingsStore.onTeamLogoChange(awayLogoUrl,false);
    }

    BookingsStore.setLoadingState(false);

    await BookingsStore.saveBooking();
  };

  const uploadImage = async (base64file, teamId) => {
    try {
      const uniqueId = utils.generateNanoId('booking', 8);
      const { uploadUrl, assetUrl } = await liveStreamDashboard.getSignedUrl({ teamId, uniqueId })
      const file = utils.base64ImgToFile(base64file, uniqueId);
      const upload = new utils.Upload({
        id: uniqueId,
        url: uploadUrl,
        file,
        contentType: 'image/png',
      })
      await upload.start()
      return assetUrl;
    } catch (error) {
      console.log(error)
    }
  }
  const handleThirdPartyKeySet = event => {
    BookingsStore.setCurrentBookingProp("thirdPartyProxyUri", event.target.value);
  };

  const toggleListMode = event => {
    BookingsStore.toggleListMode();
    BookingsStore.resetCurrentBooking();

  };

  const handleThirdPartySelect = event => {
    BookingsStore.setCurrentBookingProp("thirdPartyType", event.target.id.toUpperCase());
  };


  const handleTimeChange = event => {
    BookingsStore.setCurrentBookingProp("bookingTime", event.target.value);
    mergeDateAndTime();
  }
  const handleDateChange = event => {
    BookingsStore.setCurrentBookingProp("bookingDateTemp", event.target.value);
    mergeDateAndTime();
  };
  const mergeDateAndTime = () => {
    BookingsStore.clearError();
    const {bookingDateTemp, bookingTime} = BookingsStore.currentBooking;
    if(!bookingDateTemp || !bookingTime) {
      return null;
    }

    const newBookingDate =  new Date(bookingDateTemp + ' ' + bookingTime);
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);


    if(newBookingDate < tomorrow) {
      BookingsStore.setError('Game can only be booked 24 hours before staring time')
      return ;
    }

    // the date is valid set to current booking store
    BookingsStore.setCurrentBookingProp("bookingDate", newBookingDate);
  }

  const handleEditBooking = (bookingData) => {
    BookingsStore.toggleListMode();
    BookingsStore.setEditBookingMode(true);
    BookingsStore.setCurrentBooking(bookingData);
  }

  const handleSwitch = () => {
      BookingsStore.setHasThirdParty();
  }



  const {currentBooking} = BookingsStore;
  const { thirdPartyProxyUri, bookingDate, bookingDateTemp, bookingTime } = currentBooking;

  return (
    <div className="booking-scheduler">
      {/*<div className="booking-scheduler__credits">*/}
      {/*  <p>Credits</p>*/}
      {/*  <p>{BookingsStore.credits}</p>*/}
      {/*</div>*/}
      <div className="booking-scheduler__header">
        <h3 className="booking-scheduler__header__credit-status">Credits: {BookingsStore.credits}</h3>
      </div>
      {        !BookingsStore.isListMode && (
          <Fragment>
            <div className="booking-scheduler__bookings-editor">
              <p className="booking-scheduler__title">{I18N.t("BOOKING")} new event</p>
              <div className="booking-scheduler__form">

                <div className="booking-scheduler__form__time-panel">
                  <p className="booking-scheduler__form__input-text">Event date:</p>
                  {
                    BookingsStore.isOnEdit && <div className="booking-scheduler__notice-text">
                      <p>Event date and Kickoff time can't be edit after booking creation</p>
                    </div>
                  }

                  <div className="booking-scheduler__event-date">
                    <input
                      className="booking-scheduler__date-input"
                      type={'date'}
                      onChange={handleDateChange}
                      value={ bookingDateTemp }
                      disabled={BookingsStore.hasNoCredits() || BookingsStore.isOnEdit}
                    />
                  </div>

                  <p className="booking-scheduler__form__input-text">Kickoff time:</p>

                  <div className="booking-scheduler__event-time">
                    <input
                      className="booking-scheduler__date-input"
                      type={'time'}
                      onChange={handleTimeChange}
                      value={ bookingTime }
                      disabled={BookingsStore.hasNoCredits() || BookingsStore.isOnEdit}
                    />
                  </div>
                  {
                    bookingTime && (
                      <div className="booking-scheduler__broadcast-notice">
                        <p>*The broadcast will start <span className="booking-scheduler__broadcast-notice__time">{dayjs('2023-01-01 ' +bookingTime).subtract(20,'minutes').format('HH:mm')}</span>&nbsp;
                          And will end at start <span className="booking-scheduler__broadcast-notice__time">{dayjs('2023-01-01 ' +bookingTime).subtract(20,'minutes').add(3,'hours').format('HH:mm')}</span>
                        </p>

                      </div>
                    )
                  }

                </div>
                <div className="booking-scheduler__broadcast-time__notice">
                  {/*<p>*/}
                  {/*  The broadcast will start at*/}
                  {/*  ${bookingDateLocal.subtract(20,'minutes').format("HH:mm")} -*/}
                  {/*  ${bookingDateLocal.subtract(3,'hours').format("HH:mm")}*/}
                  {/*</p>*/}
                </div>
                <div className="booking-scheduler__scoreboard-team-edit">
                  <div className="booking-scheduler__scoreboard-team-edit__team">
                    <ScoreboardTeamEdit
                      placeholder={"Home team"}
                      name={currentBooking && currentBooking.scoreboardData && currentBooking.scoreboardData.homeName}
                      handleNameChange={(event) => BookingsStore.onTeamNameChange(event.currentTarget.value, true)}
                      handleImageSave={(image) => BookingsStore.onTeamLogoChange(image, true)}
                      logo={currentBooking && currentBooking.scoreboardData && currentBooking.scoreboardData.homeLogo}
                      color={currentBooking && currentBooking.scoreboardData && currentBooking.scoreboardData.homeColor}
                      handleColorChange={(color) => BookingsStore.onTeamColorChange(color, true)}
                      isHomeTeam={true}
                    />
                  </div>
                  <p className="booking-scheduler__scoreboard-team-edit__vs">
                    Vs
                  </p>
                  <div className="booking-scheduler__scoreboard-team-edit__team">
                    <ScoreboardTeamEdit
                      placeholder={"Away team"}
                      name={currentBooking && currentBooking.scoreboardData && currentBooking.scoreboardData.awayName}
                      handleNameChange={(event) => BookingsStore.onTeamNameChange(event.currentTarget.value, false)}
                      handleImageSave={(image) => BookingsStore.onTeamLogoChange(image, false)}
                      handleColorChange={(color) => BookingsStore.onTeamColorChange(color, false)}
                      logo={currentBooking && currentBooking.scoreboardData && currentBooking.scoreboardData.awayLogo}
                      color={currentBooking && currentBooking.scoreboardData && currentBooking.scoreboardData.awayColor}
                      isHomeTeam={false}
                    />
                  </div>

                </div>

                <div className="booking-scheduler__third-party-switcher">
                  <p>Cast to third party</p>
                  <Switch onChange={handleSwitch} isChecked={BookingsStore.hasThirdParty} isDisabled={!currentBooking.bookingDate}></Switch>
                </div>
                {(BookingsStore.hasThirdParty) &&
                  <Fragment>
                    <Input
                      placeholder={"Stream url"}
                      className={`booking-scheduler__form__input`}
                      value={thirdPartyProxyUri}
                      onChange={handleThirdPartyKeySet}
                    />
                  </Fragment>}
                <SuccessButton onClick={handleSave} disabled={!bookingDate || BookingsStore.isLoading || BookingsStore.hasNoCredits()}>
                  {" "}
                  {BookingsStore.isLoading ? I18N.t("SAVING") : I18N.t("SAVE")}
                </SuccessButton>
                <span className="booking-scheduler__form__error-message">
                  {BookingsStore.error.message}
                </span>
                {BookingsStore.saveError && (
                  <div className="booking-scheduler__save-error">
                  <span
                  className="booking-scheduler__save-error__title">{I18N.t("BOOKING_ERROR")}</span>
                    <span>{BookingsStore.saveError}</span>
                  </div>)}
              </div>
              <div className="booking-scheduler__swap-list-mode" onClick={toggleListMode}><span>{BookingsStore.isListMode ? 'Back to form' : 'Swap to event list'}</span></div>
            </div>
          </Fragment>
        )
      }

      {
        BookingsStore.isListMode && (
          <Fragment>
              <div className="booking-scheduler__bookings-list">
                <p className="booking-scheduler__title">{BookingsStore.bookings.length > 0 ? "Booked Games" : "No future events"}</p>
                {
                  BookingsStore.bookings.length > 0 && (
                    <div className="booking-scheduler__bookings-list__header">
                      <div className="booking-scheduler__bookings-list__header__item"><p>Date</p></div>
                      <div className="booking-scheduler__bookings-list__header__item"><p>Kickoff</p></div>
                      <div className="booking-scheduler__bookings-list__header__item"><p>Actions</p></div>
                    </div>
                  )
                }

                {BookingsStore.bookings.length > 0 &&
                  BookingsStore.bookings.map(booking => <BookingCard
                    handleEditBooking={handleEditBooking}
                    bookingData={booking}/>)}
              </div>
              <div className="booking-scheduler__swap-list-mode" onClick={toggleListMode}><span>Back to form</span></div>
            </Fragment>
        )
      }

    {BookingsStore.hasConfirmModal && <ConfirmModal onDismiss={() => {
      BookingsStore.dismissCancelRequest();
    }} onApprove={() => {
        BookingsStore.cancelBooking();
    }} title="Are sure you want to cancel this booking?">

    </ConfirmModal>}


  </div>);
};

BookingScheduler.propTypes = {};
BookingScheduler.defaultProps = {};

export default inject("TeamsStore", "BookingsStore")(observer(BookingScheduler));

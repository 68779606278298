import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { Input, Loader } from "@myplay/ui";
import { user as userModule } from "@myplay/all";
import CheckCircle from "../../assets/images/check-circle.svg";
import CancelCircle from "../../assets/images/close-circle.svg";
import RightArrowCircle from "../../assets/images/right-arrow-circle.svg";
import PlusButton from "../../assets/images/plus-button.svg";
import I18N from "../../I18N/";
import { sendAnalyticsEvent } from "../../utils/analytics";
import { ANALYTICS_SWITCH_TEAM } from "../../utils/constants";

import "./ChangeTeam.scss";
import scrollTop from "../../utils/scroll-top";

const { teams } = userModule;

class ChangeTeam extends Component {
  static propTypes = {
    ModalStore: PropTypes.shape(),
    TeamsStore: PropTypes.shape(),
    UserStore: PropTypes.shape()
  };

  static defaultProps = {
    ModalStore: {},
    TeamsStore: {},
    UserStore: {}
  };

  state = {
    teamCode: null,
    newTeam: null,
    error: null,
    isProcessingNewTeamRequest: false
  };

  handleChangeTeam = team => {
    const { TeamsStore, ModalStore, UserStore } = this.props;

    scrollTop();
    sendAnalyticsEvent(ANALYTICS_SWITCH_TEAM, {
      teamId: TeamsStore.currentTeam._id,
      teamName: TeamsStore.currentTeam.name,
      email: UserStore.data.email
    });

    TeamsStore.setCurrentTeam(team._id);
    ModalStore.closeModal();
  };

  handleEnterCode = e => {
    const teamCode = e.target.value.length > 5 ? e.target.value : null;
    this.setState({ teamCode });
  };

  handleCheckTeamCode = async () => {
    const { TeamsStore, ModalStore, isProcessingNewTeamRequest } = this.props;
    const { teamCode } = this.state;

    if (isProcessingNewTeamRequest) {
      return;
    }

    if (teamCode) {
      const existsTeam = TeamsStore.teams.find(team => team.code === teamCode);
      if (existsTeam) {
        TeamsStore.setCurrentTeam(existsTeam._id);
        this.setState({ error: null, newTeam: null, teamCode: null });
        ModalStore.closeModal();
      } else {
        this.setState({ isProcessingNewTeamRequest: true })
        const res = await teams.getTeamByCode(teamCode);
        if (res.length > 0) {
          this.setState({ newTeam: res[0], teamCode: null, isProcessingNewTeamRequest: false });
        } else {
          this.handleError(I18N.t("ERROR_INVALID_TEAMCODE"));
        }
      }
    }
  };

  handleCancel = () => {
    this.setState({ newTeam: null, teamCode: null });
  };

  handleAddNewTeam = async () => {
    const { newTeam, isProcessingNewTeamRequest } = this.state;
    const { UserStore, TeamsStore, ModalStore } = this.props;
    const user = UserStore.data;
    if(isProcessingNewTeamRequest) {
      return ;
    }
    try {
      scrollTop();
      this.setState({ isProcessingNewTeamRequest: true });
      const selectedTeam = await teams.addTeamToUser(user._id, newTeam._id);
      TeamsStore.addNewTeam(selectedTeam);
      this.setState({ error: null, newTeam: null, teamCode: null, isProcessingNewTeamRequest: false });
      ModalStore.closeModal();
    } catch (e) {
      console.log(e.message);
      this.handleError(e.message);
    }
  };

  handleError = error => {
    this.setState({ error });
    setTimeout(() => {
      this.setState({
        error: null,
        newTeam: null,
        teamCode: null,
        isProcessingNewTeamRequest: false
      });
    }, 2000);
  };

  renderAddNewTeamSteps = () => {
    const { teamCode, newTeam, isProcessingNewTeamRequest } = this.state;
    return (
      <Fragment>
        {!newTeam ? (
          <Input
            className="change-team__add-team-container__team-code-input"
            placeholder="Enter new team code"
            onChange={this.handleEnterCode}
          />
        ) : (
            <Fragment>
              <p
                className="change-team__add-team-container__new-team-name"
                data-testid="new-team-name"
              >
                {newTeam.name}
              </p>
              {!isProcessingNewTeamRequest ?
                <Fragment>
                  <button
                    type="button"
                    data-testid="add-team-btn"
                    onClick={this.handleAddNewTeam}
                  >
                    <img src={CheckCircle} />
                  </button>
                  <button type="button" onClick={this.handleCancel}>
                    <img src={CancelCircle} />
                  </button>
                </Fragment> :
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", cursor: 'default' }}>
                  <Loader
                    type="spin"
                    color="grey"
                    height={30}
                    width={30}
                  />
                </div>

              }
            </Fragment>
          )}
        {teamCode && (
          !isProcessingNewTeamRequest ?
            <button
              type="button"
              data-testid="check-teamcode-btn"
              onClick={this.handleCheckTeamCode}
            >
              <img src={RightArrowCircle} />
            </button> :
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", cursor: 'default' }}>
              <Loader
                type="spin"
                color="grey"
                height={30}
                width={30}
              />
            </div>
        )}
      </Fragment>
    );
  };

  render() {
    const { TeamsStore } = this.props;
    const { error } = this.state;
    return (
      <div className="change-team">
        <ul data-testid="teams-list" className="change-team__list fadeInDown">
          {TeamsStore.teams &&
            TeamsStore.teams.map(team => (
              <li
                key={team._id}
                data-testid="list-item"
                className="change-team__list__item"
              >
                <button
                  type="button"
                  className="change-team__list__item__text"
                  onClick={() => {
                    this.handleChangeTeam(team);
                  }}
                >
                  <img
                    className="change-team__button__team-logo"
                    src={team.teamImage}
                  />
                  <span className="change-team__list__item__text__name">
                    {team.name}
                  </span>
                  {TeamsStore.teams.length > 1 && (
                    <button
                      type="button"
                      className="change-team__list__item__delete"
                      onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        TeamsStore.removeTeamWithConfirm(team._id);
                      }}
                    >
                      {I18N.t("REMOVE")}
                    </button>
                  )}
                </button>
              </li>
            ))}
        </ul>
        <div className="change-team__add-team-container">
          <img
            className="change-team__add-team-container__plus-icon"
            src={PlusButton}
          />
          {!error ? (
            this.renderAddNewTeamSteps()
          ) : (
              <p
                className="change-team__add-team-container__error"
                data-testid="change-team-error"
              >
                {error}
              </p>
            )}
        </div>
      </div>
    );
  }
}

export default inject("TeamsStore", "UserStore", "ModalStore")(
  observer(ChangeTeam)
);

/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { WithLoader, Loader, StatsPreview } from "@myplay/ui";
import "intersection-observer";

import "./VideoGrid.scss";
import { VideoPreview } from "../../components";


import {
  USER_PLAYER,
  TEAM_CHANNEL_GAMES_BULK_SIZE
} from "../../utils/constants";

/* eslint-disable react/no-unused-state */
class VideoGrid extends Component {
  static propTypes = {
    GamesStore: PropTypes.shape().isRequired,
    UserStore: PropTypes.shape().isRequired,
    // eslint-disable-next-line react/boolean-prop-naming
    withStats: PropTypes.bool
  };

  static defaultProps = {
    withStats: true
  };

  state = {
    prevY: 0
  };

  loadingRef = null;

  ref = null;

  VIDEOS_AT_EACH_BULK = 12;

  MAIN_VIDEOS_NUMBER = 3;

  componentDidMount() {
    this.observer = new IntersectionObserver(this.handleObserver, {});

    this.observer.observe(this.loadingRef);
  }

  shouldComponentUpdate() {
    return true;
  }

  /* eslint-disable */
  handleObserver = entities => {
    const { GamesStore } = this.props;

    // eslint-disable-next-line prefer-destructuring
    const y = entities[0].boundingClientRect.y;

    if (this.state.prevY > y) {
      if (GamesStore.hasMoreVideos) {
        this.increaseCursor();
      }
    }
    this.setState({ prevY: y });
  };

  handleRef = ref => {
    if (!this.ref) {
      this.ref = ref;
    }
  };

  handleLoadingRef = ref => {
    if (!this.loadingRef) {
      this.loadingRef = ref;
    }
  };

  increaseCursor() {
    const { GamesStore } = this.props;

    GamesStore.fetchCurrentTeamGames(
      TEAM_CHANNEL_GAMES_BULK_SIZE,
      GamesStore.currentTeamGamesCursor
    );
  }

  render() {
    const { GamesStore, UserStore, withStats } = this.props;
    const { stats } = this.state;
    const {
      mainCurrentTeamGames,
      restCurrentTeamGames,
      currentTeamGames,
      hasMoreVideos,
      lastGameStats
    } = GamesStore;

    const isStatsExists = lastGameStats && lastGameStats.isStats;

    /* eslint-disable react/jsx-no-literals */
    return (
      <div ref={this.handleRef} className="video-grid">
        <div className="video-grid__main">
          {mainCurrentTeamGames
            ? mainCurrentTeamGames.map((game, index) => (
                <VideoPreview
                  key={game._id}
                  item={game}
                  index={index}
                  allowEditTitle={UserStore.data.accountType !== USER_PLAYER && game.canEdit.title}
                  allowEditScore={UserStore.data.accountType !== USER_PLAYER && game.canEdit.score}
                  onDelete={gameId => GamesStore.deleteGame(gameId)}
                  withMarker={isStatsExists}
                />
              ))
            : null}
        </div>
        {withStats ? (
          <div
            className="video-grid__stats"
            style={{ minHeight: isStatsExists ? "300px" : "0px" }}
          >
            <WithLoader
              type="bubbles"
              className="video-grid__stats__loader"
              isLoading={!lastGameStats}
            >
              {isStatsExists ? (
                <StatsPreview
                  bars={lastGameStats.bars}
                  donuts={lastGameStats.donuts}
                  isStats
                  isLastGameStats
                />
              ) : null}
            </WithLoader>
          </div>
        ) : null}
        <div className="video-grid__more-videos">
          {restCurrentTeamGames ? (
            restCurrentTeamGames.map((game, index) => (
              <VideoPreview
                key={game._id}
                item={game}
                index={index}
                allowEditTitle={UserStore.data.accountType !== USER_PLAYER}
                onDelete={gameId => GamesStore.deleteGame(gameId)}
              />
            ))
          ) : (
            <div />
          )}
        </div>
        <div
          className="video-grid__more-videos__observer"
          ref={loadingRef => this.handleLoadingRef(loadingRef)}
        />
        <div className="video-grid__more-videos__loader">
          {hasMoreVideos ? <Loader height={100} width={100} /> : null}
        </div>
      </div>
    );
  }
}

export default observer(VideoGrid);

import React, { Fragment } from "react";
import "./Record.scss";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import dayjs from "dayjs";
import {
  SwitchButtonWithLabels,
  Input,
  Counter,
  Loader,
  PrimaryButton
} from "@myplay/ui";
import calendarIcon from "../../../assets/images/calendar.svg";

import I18N from "../../../I18N/";
import RoundRecordButton from "../../RoundRecordButton/";
import {
  ORANGE,
  GREEN_LIGHT,
  GREY_LIGHT,
  WHITE
} from "../../../styles/shared/colors";

const Record = ({ SiteStore, ModalStore, UserStore }) => {
  const { recordState, isOnline } = SiteStore;
  const {
    isLoading,
    fetchComplete,
    isRecording,
    isCamerasReady,
    isTraining,
    timeToEnd,
    shouldRenderCounter,
    recordName,
    recordError
  } =
    recordState || {};

  const date = dayjs().format("dddd, MMMM D, YYYY");

  const renderActiveRecord = () => {
    const DEFAULT_DURATION = 1000 * 60 * 90;

    return shouldRenderCounter ? (
      <div data-testid="counter" className="record__active">
        <p className="record__active__name" style={{ textAlign: "center" }}>
          {recordName}
        </p>
        {timeToEnd !== null && <Counter
          className="record__counter"
          time={timeToEnd}
          hasHours
        />}
      </div>
    ) : null;
  };

  const renderNoRecord = () => {
    return isCamerasReady || recordError ? (
      <div data-testid="switch-button">
        <SwitchButtonWithLabels
          leftLabel={I18N.t("GAME")}
          rightLabel={I18N.t("TRAINING")}
          isChecked={isTraining}
          handleChange={SiteStore.toggleRecordMode}
          style={{ marginBottom: "40px" }}
        />
        <Input
          placeholder={
            isTraining ? I18N.t("ADD_TRAINING_TITLE") : I18N.t("ADD_GAME_TITLE")
          }
          style={{ borderBottomColor: GREY_LIGHT, width: "264px" }}
          autoFocus
          onBlur={e => {
            SiteStore.setRecordName(e.target.value);
          }}
        />
      </div>
    ) : (
      <p className="record__error">{I18N.t("RECORD_SITE_ERROR")}</p>
    );
  };

  const handleStopRecord = () => {
    const recordingCamera =
      SiteStore.cameras &&
      SiteStore.cameras.find(
        camera => camera.status === "RECORDING" && camera.task !== null
      );

    if (
      recordingCamera &&
      recordingCamera.task &&
      recordingCamera.task.record &&
      !recordingCamera.task.record.senderId
    ) {
      SiteStore.stopRecord();
      return;
    }

    const { firstName, lastName, _id } =
      recordingCamera &&
      recordingCamera.task &&
      recordingCamera.task.record &&
      recordingCamera.task.record.senderId;
    const isUserSameAsSender = _id === UserStore.data._id;

    ModalStore.openModal({
      modalType: "CONFIRM_MODAL",
      modalPosition: "center",
      modalProps: {
        text: `${I18N.t("STOP_RECORD")} ${
          !isUserSameAsSender
            ? `${I18N.t("STARTED_BY")} ${firstName} ${lastName}`
            : ""
        }`,
        handleSuccess: () => SiteStore.stopRecord()
      }
    });
  };

  const openRecordSchedule = () => {
    ModalStore.openModal({
      modalType: "RECORD_SCHEDULER",
      modalPosition: "top"
    });
  };

  return (
    <div className="record__content">
      <div className="record__title-wrapper">
        <p>{(SiteStore && SiteStore.name) || ""}</p>
        <p className="record__date">{date}</p>
      </div>
      <div className="record__form-wrapper">
        {fetchComplete ? (
          <Fragment>
            <div className="record__button-wrapper">
              {isRecording ? renderActiveRecord() : renderNoRecord()}
              <RoundRecordButton
                text={
                  isRecording ? I18N.t("STOP") : I18N.t("RECORD_BUTTON_TEXT")
                }
                isLoading={isLoading || !fetchComplete}
                background={isRecording ? GREEN_LIGHT : ORANGE}
                disabled={(!isRecording && !isCamerasReady) || !isOnline}
                data-testid="round-record-button"
                onClick={isRecording ? handleStopRecord : SiteStore.startRecord}
              />
            </div>
            <PrimaryButton
              color={WHITE}
              className="record__schedule"
              style={{ border: "1px solid white" }}
              onClick={openRecordSchedule}
            >
              <img src={calendarIcon} />
              {I18N.t("SCHEDULE_RECORDING")}
            </PrimaryButton>
          </Fragment>
        ) : (
          <Loader color={ORANGE} type="spin" height="80px" width="80px" />
        )}
      </div>
    </div>
  );
};

Record.propTypes = {
  ModalStore: PropTypes.object.isRequired,
  SiteStore: PropTypes.shape().isRequired,
  UserStore: PropTypes.object.isRequired
};

export default inject("SiteStore", "ModalStore", "UserStore")(observer(Record));

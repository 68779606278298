import React, { Component } from "react";
import PropTypes from 'prop-types';
import { Field } from "react-final-form";
import { Input } from "@myplay/ui";
import { utils }  from "@myplay/all";
import { WithCountries, StepHeader } from "../../../components/";
import CountriesContext from "../../../components/WithCountries/CountriesContext";
import CountriesCode from "../CountriesCode";
import I18N from "../../../I18N";

class StepPhone extends Component {
  static propTypes = {
    isActive: PropTypes.bool,
    isNextStepAllowed: PropTypes.func,
    onHandleCurrentStep: PropTypes.func,
    toggleIsServerValidationNeeded: PropTypes.func,
  }

  static defaultProps = {
    isActive: false,
    isNextStepAllowed: ()=>{},
    onHandleCurrentStep: ()=>{},
    toggleIsServerValidationNeeded: ()=>{}
  }

  state= {
    isStepValid: false,
  }

  componentDidMount() {
    const { onHandleCurrentStep, toggleIsServerValidationNeeded, isActive } = this.props;
    if (isActive) {
      onHandleCurrentStep('phone');
      toggleIsServerValidationNeeded(true);
    }
  };

  componentDidUpdate(prevProps) {
    const { isActive, isNextStepAllowed, onHandleCurrentStep, toggleIsServerValidationNeeded } = this.props;
    const { isStepValid } = this.state;
    if (isActive && prevProps.isActive !== isActive) {
      onHandleCurrentStep('phone');
      toggleIsServerValidationNeeded(true);
      isNextStepAllowed(isStepValid);
    };
  };

  validatePhone = phone => {
    const { isActive, isNextStepAllowed } = this.props;
    if (phone && typeof phone !== "undefined") {
      const isPhoneValid = utils.isValidPhone(phone || "")

      if (isActive) {
        this.setState({
          isStepValid: isPhoneValid
        },()=> isNextStepAllowed(this.state.isStepValid))

      };
      return isPhoneValid ? undefined : I18N.t("ERROR_INVALID_PHONE");
    };
    return undefined;
  };

  getSelectedCountry = (countries, currentCountry) => {
    const { selectedCountry } = this.props;
    const isSelectedCountryString  = typeof selectedCountry === 'string' ;
    const selectedCountryIndex =
      selectedCountry ? countries.map(c => c.value).indexOf(isSelectedCountryString ? selectedCountry : selectedCountry.value)
      : null ;
    return selectedCountry ? selectedCountryIndex : currentCountry;
  }

  render() {
    return (
      <div className="register__form__step">
        <StepHeader lines={[I18N.t("WHATS_YOUR"), I18N.t("NUMBER")]}/>
        <WithCountries>
          <CountriesContext.Consumer>
            {({ countries, currentCountry }) => (
              <Field className="register__form__step__field" name="countryCode">
                {({ input }) => (
                  <div className="register__form__step__field__dropdown">
                    {countries ? (
                      <div className="register__form__step__field__dropdown__phone" >
                        <CountriesCode
                          currentCountry={this.getSelectedCountry(countries, currentCountry)}
                          countries={countries}
                          input={input}
                        />
                        <Field
                          className="register__form__step__field"
                          name="regularPhone"
                          validate={this.validatePhone}

                        >
                          {({ input ,meta }) => (// eslint-disable-line
                            <Input
                              {...input}
                              type="tel"
                              error={
                                (!meta.pristine && meta.error) ||
                                meta.submitFailed
                                  ? meta.error
                                  : ""
                              }
                              placeholder="050-1234567"
                              style={{paddingLeft: '74px', borderBottomColor:'grey', lineHeight: '23px'}}
                              data-testid="phone"
                              tabIndex={-1}
                              required
                            />
                          )}
                        </Field>
                      </div>
                    ) : null}
                  </div>
                )}
              </Field>
            )}
          </CountriesContext.Consumer>
        </WithCountries>
      </div>
    );
  }
}

export default StepPhone;

import React from "react";
import PropTypes from "prop-types";

import "./RecordPreviewButton.scss";
import I18N from "../../I18N";

const RecordPreviewButton = ({ onClick }) => {
  return (
    <button type="button" className="record-preview-button" onClick={onClick}>
      <span className="record-preview-button__circle" />
      <span className="record-preview-button__text">
        {I18N.t("RECORD_BUTTON_TEXT")}
      </span>
    </button>
  );
};

RecordPreviewButton.propTypes = {
  onClick: PropTypes.func
};

RecordPreviewButton.defaultProps = {
  onClick: () => {}
};

export default RecordPreviewButton;

import React, { Component } from "react";
import PropTypes from "prop-types";

import "./AnimatedScene.scss";
import history from "../../utils/history";

class AnimatedScene extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.func])
  };

  static defaultProps = {
    children: null
  };

  state = {
    className: "animated-scene fade-in"
  };

  handleBack = () => {
    this.setState(
      {
        className: "animated-scene slide-out"
      },
      () => {
        setTimeout(() => {
          history.goBack();
        }, 180);
      }
    );
  };

  render() {
    const { children } = this.props;
    const { className } = this.state;

    return (
      <div className={className}>
        {children({ handleBack: this.handleBack })}
      </div>
    );
  }
}

export default AnimatedScene;

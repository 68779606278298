/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { inject } from "mobx-react";
import "./PlayerHighlightModal.scss";
import {
  TextInput,
  SocialSharePanel,
  ConfirmModal,
  HighlightPlayer
} from "@myplay/ui";
import Rodal from "rodal";
import { DefaultPlayer as Video } from "react-html5video";
// import "../../assets/styles/react-video.css";

import arrow from "../../../assets/images/leftArrow.svg";
import closeIcon from "../../../assets/images/close-11.svg";
import GarbageIcon from "../../../assets/images/inline-svgs/garbage.svg";

import { sendAnalyticsEvent } from "../../../utils/analytics";
import { ANALYTICS_SHARED_HIGHLIGHT } from "../../../utils/constants";
import getHighlightLink from "../../../utils/getHighlightLink";

const HIGHLIGHT_PAGE_PATH = "public-highlights/";
const SHARE_LINK = "app.myplay.com/" + HIGHLIGHT_PAGE_PATH;

class PlayerHighlightModal extends Component {
  editModalRef = React.createRef();

  static propTypes = {
    highlights: PropTypes.array.isRequired,
    selectedHighlight: PropTypes.object.isRequired
  };

  static defaultProps = {};

  state = {
    currentHighlightIndex: 0,
    showModal: true,
    showConfirmModal: false,
    hover: false
  };

  componentDidMount() {
    const { highlights, selectedHighlight } = this.props;
    const index = highlights.findIndex(
      highlight => highlight.id === selectedHighlight.id
    );
    if (highlights.length > 0) {
      this.setState({ currentHighlightIndex: index });
    }
    if (this.editModalRef.current) {
      this.editModalRef.current.focus();
    }
  }

  onSave = () => {
    const { highlights } = this.props;
    const { currentHighlightIndex } = this.state;
    const clip = {
      id: highlights[currentHighlightIndex].id,
      tags: [],
      tagedUsers: [],
      title: this.inputElement.value
    };
    this.props.onSave(clip);
  };

  onDismiss = () => {
    this.setState({ showModal: false });
    if (this.props.onDismiss) {
      this.props.onDismiss();
    }
  };

  handleNextHighlight = () => {
    if (this.state.currentHighlightIndex < this.props.highlights.length - 1) {
      this.setState(prevState => ({
        currentHighlightIndex: prevState.currentHighlightIndex + 1
      }));
    }
  };

  handlePrevHighlight = () => {
    if (this.state.currentHighlightIndex > 0) {
      this.setState(prevState => ({
        currentHighlightIndex: prevState.currentHighlightIndex - 1
      }));
    }
  };

  handleKeyboardNavigate = e => {
    if (e.key === "ArrowRight") {
      return this.handleNextHighlight();
    }
    if (e.key === "ArrowLeft") {
      return this.handlePrevHighlight();
    }
  };

  getSharedPlatform = platform => {
    const {
      UserStore: { data }
    } = this.props;

    sendAnalyticsEvent(ANALYTICS_SHARED_HIGHLIGHT, {
      email: data.email,
      id: data._id,
      fullName: data.fullName,
      platform
    });
  };

  render() {
    const { highlights, isPanoramic } = this.props;
    const { currentHighlightIndex, showModal, hover } = this.state;

    return (
      <Rodal
        visible={showModal}
        animation="fade"
        duration={400}
        onClose={this.onDismiss}
        closeOnEsc={true}
        customMaskStyles={{ background: "#000000cf" }}
        data-testid="player-highlight-modal"
        customStyles={{
          display: "flex",
          background: "black",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          height: "550px",
          width: "711px"
        }}
      >
        <img
          className="player-highlight-modal__close-button"
          src={closeIcon}
          onClick={this.onDismiss}
          alt="img"
        />
        <div
          className="player-highlight-modal__video-container"
          onMouseOver={() => {
            this.setState({ hover: true });
          }}
          onMouseLeave={() => {
            this.setState({ hover: false });
          }}
          tabIndex={0}
          onKeyDown={e => {
            this.handleKeyboardNavigate(e);
          }}
          onKeyPress={e => {
            this.handleKeyboardNavigate(e);
          }}
          ref={this.editModalRef}
        >
          <img
            src={arrow}
            onClick={this.handlePrevHighlight}
            style={{
              visibility:
                hover && currentHighlightIndex > 0 ? "visible" : "hidden"
            }}
            className="player-highlight-modal__navigate-arrow player-highlight-modal__navigate-arrow--left"
          />

          <div style={{ width: "711px", background: "black" }}>
            {!isPanoramic ? (
              <Video
                controls={["PlayPause", "Seek"]}
                style={{ maxHeight: "400px", maxWidth: "700px" }}
                autoPlay
                loop
                src={highlights[currentHighlightIndex].streamUri}
                poster={highlights[currentHighlightIndex].thumbnailUri}
              />
            ) : (
              <HighlightPlayer
                style={{ maxHeight: "400px", maxWidth: "700px" }}
                height="400px"
                width="700px"
                autoPlay
                loop
                videoId={highlights[currentHighlightIndex].id}
              />
            )}
          </div>
          <img
            src={arrow}
            onClick={this.handleNextHighlight}
            style={{
              visibility:
                hover && currentHighlightIndex < highlights.length - 1
                  ? "visible"
                  : "hidden"
            }}
            className="player-highlight-modal__navigate-arrow player-highlight-modal__navigate-arrow--right"
          />
        </div>
        <TextInput
          style={{
            width: "302px",
            fontSize: "20px",
            marginTop: "30px",
            textAlign: "center"
          }}
          placeholder="What is your feedback?"
          inputRef={el => (this.inputElement = el)}
          value={
            highlights.length > 0 && highlights[currentHighlightIndex].title
          }
          autoFocus={false}
          onSave={this.onSave}
        />
        <div className="player-highlight-modal__bottom-panel">
          <div style={{ flex: "1" }}>
            <SocialSharePanel
              shareLink={getHighlightLink(highlights[currentHighlightIndex])}
              getSharedPlatform={this.getSharedPlatform}
            />
          </div>
          <GarbageIcon
            className="player-highlight-modal__delete-button"
            onClick={() => {
              this.setState({ showConfirmModal: true });
            }}
          />
        </div>
        <Rodal
          visible={this.state.showConfirmModal}
          animation="door"
          duration={200}
          showCloseButton={false}
          customStyles={{ background: "none" }}
        >
          <ConfirmModal
            onDismiss={() => {
              this.setState({ showConfirmModal: false });
            }}
            onApprove={() => {
              this.props.onDelete(highlights[currentHighlightIndex].id);
            }}
            title="Are sure you want to delete this highlight?"
          />
        </Rodal>
      </Rodal>
    );
  }
}

export default inject("UserStore")(PlayerHighlightModal);

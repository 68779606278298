import { types, flow, getRoot } from "mobx-state-tree";
import { cameraControl } from "@myplay/all";
import { toast } from "@myplay/ui";
import { sendAnalyticsEvent } from "../../utils/analytics";

import {
  VISIBLE_ERROR,
  ANALYTICS_RECORD_SCHEDULED,
  ANALYTICS_RECORD_SCHEDULED_DELETE
} from "../../utils/constants";
import I18N from "../../I18N";

const { scheduler } = cameraControl;

const SchedulerStore = types
  .model(`Site`, {
    tasks: types.array(types.frozen())
  })
  .actions(self => ({
    fetchAndSetTasks: flow(function* fetchAndSetTasks() {
      try {
        const { TeamsStore } = getRoot(self);
        const teamId = TeamsStore.currentTeam ? TeamsStore.currentTeam._id : "";

        const tasks = yield scheduler.getTasks(teamId);
        self.tasks = tasks;
      } catch (error) {
        console.log(error);
      }
    }),
    deleteScheduledTask: flow(function* deleteScheduledTask(taskId) {
      try {
        const { TeamsStore, UserStore } = getRoot(self);

        yield scheduler.cancelTask(taskId, UserStore.data._id);
        yield self.fetchAndSetTasks();
        toast.success(I18N.t("RECORD_DELETED_SUCCESSFULLY"));
        sendAnalyticsEvent(ANALYTICS_RECORD_SCHEDULED_DELETE, {
          email: UserStore.data.email,
          team: TeamsStore.currentTeam.name,
          teamId: TeamsStore.currentTeam._id,
          userName: UserStore.data.fullName,
          userId: UserStore.data._id,
          accountType: UserStore.data.accountType
        });
      } catch (error) {
        console.log(error);
        toast.error(I18N.t("ERROR_DELETE_RECORD"));
      }
    }),
    scheduleNewTask: flow(function* scheduleNewTask(name, date, duration) {
      const { TeamsStore, UserStore } = getRoot(self);
      try {
        const task = yield cameraControl.scheduler.startScheduledRecord({
          senderID: UserStore.data._id,
          teamID: TeamsStore.currentTeam._id,
          title: name,
          recordType: "game",
          startTime: date,
          duration
        });

        if (task) {
          self.tasks.unshift(task);
          toast.success(I18N.t("RECORD_CREATED_SUCCESSFULLY"));
          sendAnalyticsEvent(ANALYTICS_RECORD_SCHEDULED, {
            email: UserStore.data.email,
            team: TeamsStore.currentTeam.name,
            teamId: TeamsStore.currentTeam._id,
            userName: UserStore.data.fullName,
            userId: UserStore.data._id,
            duration,
            accountType: UserStore.data.accountType
          });
        }
      } catch (error) {
        console.log(error);

        if (error.name === VISIBLE_ERROR) {
          toast.error(error.message);
        } else {
          toast.error(I18N.t("SOMETHING_WENT_WRONG"));
        }

        throw new Error(error);
      }
    })
  }));

export default SchedulerStore;

import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactCodeInput from "react-code-input";
import { Field } from "react-final-form";
import isMobile from "is-mobile";

import I18N from "../../../I18N";
import { StepHeader } from "../../../components";

class StepVerificationCode extends Component {
  static propTypes = {
    handleSendVerificationCode: PropTypes.func,
    isActive: PropTypes.bool,
    isNextStepAllowed: PropTypes.func,
    onHandleCurrentStep: PropTypes.func,
    phone: PropTypes.string,
    toggleIsServerValidationNeeded: PropTypes.func
  };

  static defaultProps = {
    handleSendVerificationCode: () => {},
    isActive: false,
    isNextStepAllowed: () => {},
    onHandleCurrentStep: () => {},
    phone: "",
    toggleIsServerValidationNeeded: () => {}
  };

  state = {
    countDown: null
  };

  componentDidMount() {
    const codeInputs = document.querySelector(".code-input").childNodes;
    codeInputs.forEach(input => (input.tabIndex = -1));
    this.timer = {};
    clearInterval(this.timer);
  }

  componentDidUpdate(prevProps) {
    const {
      onHandleCurrentStep,
      toggleIsServerValidationNeeded,
      isActive,
      handleSendVerificationCode,
      phone
    } = this.props; // eslint-disable-line
    const { countDown } = this.state;

    if (isActive && prevProps.isActive !== isActive) {
      if (!countDown) {
        handleSendVerificationCode();
        this.startCountDown();
      }

      if (this.state.phone && this.state.phone !== phone) {
        handleSendVerificationCode();
        this.startCountDown();
      }
      this.setState({// eslint-disable-line
        phone
      });

      onHandleCurrentStep("verificationCode");
      toggleIsServerValidationNeeded(true);
    }
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  startCountDown = () => {
    clearInterval(this.timer);
    this.setState(
      {
        countDown: 5 * 60 * 1000
      },
      () => {
        this.timer = setInterval(() => {
          const { countDown } = this.state;

          if (countDown > 0) {
            this.setState(prevState => ({
              countDown: prevState.countDown - 1000
            }));
          } else {
            clearInterval(this.timer);
          }
        }, 1000);
      }
    );
  };

  millisToMinutesAndSeconds = millis => {
    if (millis) {
      const minutes = Math.floor(millis / 60000);
      const seconds = ((millis % 60000) / 1000).toFixed(0);
      return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
    }
  };

  validateVerificationCode = value => {
    const { isNextStepAllowed, isActive } = this.props;
    const isCodeValid = value && value.length === 4;

    if (isActive) {
      isNextStepAllowed(isCodeValid);
    }

    return isCodeValid ? undefined : "check";
  };

  handleResendCode = () => {
    const { handleSendVerificationCode } = this.props;
    const { countDown } = this.state;
    //if (countDown <= 0) {
      //this.startCountDown();
      handleSendVerificationCode();
    //}
  };

  render() {
    const ismobile = isMobile();
    const { countDown } = this.state;
    const { isActive, phone ,email} = this.props;
    return (
      <div className="register__form__step">
        <StepHeader lines={[I18N.t("INSERT_CODE")]} />
        <div className="register__form__step__verification-text">
          <span>{I18N.t("VERIFICATION_CODE_TEXT_FIRST_PART")}</span>
          <span>
            {I18N.t("VERIFICATION_CODE_TEXT_SECOND_PART")}
            {phone || email}
          </span>
        </div>
        <div
          className="register__form__step__fields"
          data-testid="verificationCode"
        >
          <Field
            name="verificationCode"
            validate={this.validateVerificationCode}
          >
            {(
              { input } // eslint-disable-line
            ) => (
              <ReactCodeInput
                type="number"
                autoFocus={isActive}
                inputStyle={{
                  background: "none",
                  border: "none",
                  borderBottom: "1px solid white",
                  width: "45px",
                  margin: "0px 16px",
                  color: "white",
                  fontSize: ismobile ? "15px" : "35px",
                  textAlign: "center"
                }}
                className="code-input"
                onChange={value => {
                  input.onChange(value);
                }}
              />
            )}
          </Field>
          {/* eslint-disable */}
          <div
            className="register__form__step__fields__resend-code"

          >
            <span>Code is valid for {this.millisToMinutesAndSeconds(countDown) || "0:00"}</span>
            {countDown === 0 && <span onClick={this.handleResendCode}> {I18N.t("RESEND_CODE")}</span>}
          </div>
          {/* eslint-enable */}
        </div>
      </div>
    );
  }
}

export default StepVerificationCode;

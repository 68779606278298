import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { user } from "@myplay/all";
import { SwipeableViews } from "@myplay/ui";
import { Form } from "react-final-form";
import createDecorator from "final-form-calculate";
import dayjs from "dayjs";
import isMobile from "is-mobile";

import "./Register.scss";
import { RegisterStandartButtons, RegisterSubmitPanel } from "../../components";
import StepName from "./steps/StepName";
import StepCountry from "./steps/StepCountry";
import StepBirthdate from "./steps/StepBirthdate";
import StepRegistrationMethod from "./steps/StepRegistrationMethod";
import StepTeamCode from "./steps/StepTeamCode";
import StepPhone from "./steps/StepPhone";
import StepEmail from "./steps/StepEmail";
import I18N from "../../I18N";
import StepVerificationCode from "./steps/StepVerificationCode";
import StepTermsOfService from "./steps/StepTermsOfService";
import StepPassword from "./steps/StepPassword";
import {
  backgrounds,
  mobileBackgrounds
} from "../../styles/shared/backgrounds";
import { sendAnalyticsEvent } from "../../utils/analytics";
import {
  NAME,
  COUNTRY,
  BIRTHDATE,
  REGISTRATION_METHOD,
  PHONE,
  EMAIL,
  VERIFICATION_CODE,
  TEAM_CODE,
  PASSWORD,
  TERMS_OF_SERVICE,
  FORGOT_PASSWORD_ROUTE_NAME,
  ANALYTICS_REGISTER,
  MYPLAY_LOGIN_URL
} from "../../utils/constants";

const authModule = user.auth;

class Register extends Component {
  static propTypes = {
    RegisterStore: PropTypes.object,
    UserStore: PropTypes.object
  };

  static defaultProps = {
    RegisterStore: {},
    UserStore: {}
  };

  state = {
    currentPage: 0,
    isNextStepAllowed: false,
    isServerValidationNeeded: false,
    currentStep: "",
    requestId: "",
    termsUrl: "",
    teamLogo: "",
    stepsNames: [],
    error: "",
    initialValues: {},
    isInitialValuesSet: false,
    recoverPasswordText: "",
    ismobile: false
  };

  phoneNumber = createDecorator({
    field: [/regularPhone/, /countryCode/],
    updates: {
      phone: (ignoredValue, allValues) => {
        if (
          allValues.regularPhone &&
          (allValues.country || allValues.countryCode)
        ) {
          return (allValues.country.dialCode || allValues.countryCode).concat(
            allValues.regularPhone
          );
        }
      }
    }
  });

  async componentDidMount() {
    const { UserStore, RegisterStore, TeamsStore } = this.props;
    const params = new URLSearchParams(location.search); // eslint-disable-line no-restricted-globals
    const token = params.get("token");

    if (
      UserStore &&
      UserStore.data &&
      UserStore.data.status !== "registerIncomplete"
    ) {
      UserStore.clearUserData();
    }

    document.addEventListener("touchmove", e => this.disableZoom(e), {
      passive: false
    });

    if (token) {
      try {
        await UserStore.invitationRegistration(token);
        this.setState({
          teamLogo: TeamsStore.currentTeam.teamImage
        });
      } catch (error) {
        console.log(error);
      }
    }

    if (
      !token &&
      !RegisterStore.fields.length &&
      !RegisterStore.registerWithFacebook &&
      !RegisterStore.registerWithAppleId
    ) {
      RegisterStore.setFullRegistrationFlow();
    }

    this.setState({
      stepsNames: this.props.RegisterStore.stepsArray,
      ismobile: isMobile()
    });
  }

  componentDidUpdate() {
    const {
      RegisterStore: { fields }
    } = this.props;

    if (!this.state.isInitialValuesSet && Object.values(fields)[0]) {
      this.setInitialValues();
    }
  }

  componentWillUnmount() {
    document.removeEventListener("touchmove", e => this.disableZoom(e));
  }

  disableZoom = event => {
    event.preventDefault();
  };

  setInitialValues = () => {
    const {
      RegisterStore: { fields, stepsArray }
    } = this.props;
    const values = {};
    Object.values(fields).map(field => {
      if (field && field.value) {
        if (field.stepName === NAME) {
          values.firstName = field.value.firstName;
          values.lastName = field.value.lastName;
        }
        if (field.stepName === COUNTRY) {
          values.country = field.value.country;
        }
        if (field.stepName !== NAME) {
          values[field.stepName] = field.value;
        }
      }
      this.setState({
        isInitialValuesSet: true
      });
    });
    this.setState({
      initialValues: values,
      stepsNames: stepsArray
    });
  };

  handleSubmit = async values => {
    const { UserStore } = this.props;

    const cloneValues = { ...values };

    if (values.registrationMethod === PHONE) {
      delete cloneValues.email;
    }

    if (values.registrationMethod === EMAIL) {
      delete cloneValues.phone;
    }
    cloneValues.country =
      typeof cloneValues.country === "string"
        ? cloneValues.country
        : cloneValues.country.value;
    cloneValues.birthdate =
      cloneValues.birthdate !== null
        ? dayjs(cloneValues.birthdate)
            .add(1, "day")
            .format("DD/MM/YYYY")
        : null;

    if (UserStore.data && UserStore.data._id) {
      delete cloneValues.email;
      UserStore.completeExternalRegistration(cloneValues);
      return;
    }
    await UserStore.register(cloneValues);
    if (window.isRNWebView) {
      // timout is a hack to fix android postMessage not working.
      setTimeout(() => {
        window.postMessage(
          JSON.stringify({
            identity: cloneValues.phone || cloneValues.email,
            password: cloneValues.password
          }),
          "*"
        );
      }, 1000);
    }
  };

  handleCurrentStep = step => {
    this.setState({
      currentStep: step
    });
  };

  isNextStepAllowed = value => {
    this.setState({
      isNextStepAllowed: value
    });
  };

  handlePrevious = () => {
    this.toggleBackground();
    this.setState(prevProps => ({
      currentPage: prevProps.currentPage - 1,
      isNextStepAllowed: true,
      isServerValidationNeeded: false,
      shouldDisplayButtons: true,
      error: "",
      recoverPasswordText: ""
    }));
  };

  handleNextClick = values => {
    const { isServerValidationNeeded } = this.state;

    if (isServerValidationNeeded) {
      this.handleServerValidation(values);
      return;
    }

    this.handleNext(values);
  };

  handleNext = values => {
    this.toggleBackground();
    this.handleAnalyticsEvent(values);

    this.setState(prevState => ({
      currentPage: prevState.currentPage + 1,
      isNextStepAllowed: false,
      isServerValidationNeeded: false,
      error: "",
      recoverPasswordText: ""
    }));
  };

  handleAnalyticsEvent = values => {
    const { stepsNames, currentPage } = this.state;
    const stepName = stepsNames[currentPage];
    let value;
    switch (stepsNames[currentPage]) {
      case NAME:
        value = {
          firstName: values.firstName,
          lastName: values.lastName
        };
        break;
      case COUNTRY:
        value = { ...values.country.value };
        break;
      case REGISTRATION_METHOD:
        value = values;
        break;
      default:
        value = values[stepName];
    }

    sendAnalyticsEvent(ANALYTICS_REGISTER, {
      stepName,
      value,
      email: values.email || ""
    });
  };

  handlePhoneValidation = async values => {
    const country =
      typeof values.country === "string"
        ? values.country
        : values.country.value;
    const { isPhoneExist } = await authModule.isPhoneOrEmailExists(
      values.phone,
      country,
      ""
    );

    if (!isPhoneExist) {
      this.handleNext(values);
      return;
    }

    this.setState({
      error: I18N.t("SIGNUP_PHONE_EXIST"),
      recoverPasswordText: I18N.t("CLICK_HERE_TO_RECOVER_PASSWORD")
    });
  };

  handleSendVerificationCode = async values => {
    const country =
      typeof values.country === "string"
        ? values.country
        : values.country.value;

    try {
      const request = await authModule.verifyPhone(values.phone, country);
      this.setState({
        requestId: request.request_id
      });
    } catch (error) {
      this.setState({ error: error });
      setTimeout(() => {
        this.setState({ error: "" });
      }, 2000);
    }
  };

  handleEmailValidation = async values => {
    const { isEmailExist } = await authModule.isEmailExist(values.email);

    if (!isEmailExist) {
      this.handleNext(values);
      return;
    }

    this.setState({
      error: I18N.t("SIGNUP_EMAIL_EXIST"),
      recoverPasswordText: I18N.t("CLICK_HERE_TO_RECOVER_PASSWORD")
    });
  };

  handleVerificationCodeValidation = async values => {
    const { verificationCode } = values;
    const { requestId } = this.state;

    try {
      const isValid = await authModule.checkVerifyPhone({
        code: verificationCode,
        requestID: requestId
      });

      if (isValid) {
        this.handleNext(values);
      }
    } catch (error) {
      console.log(error);
      this.setState({
        error: I18N.t("WRONG_VERIFICATION_CODE")
      });
    }
  };

  handleTeamCode = async values => {
    const { teamCode } = values;
    try {
      await authModule.isValidTeamCode(teamCode);
      const teamLogo = await authModule.getTeamLogoByCode(teamCode);
      this.setState(
        {
          teamLogo
        },
        () => this.handleNext(values)
      );
    } catch (error) {
      console.log(error);

      this.setState({
        error: I18N.t("INVALID_TEAM_CODE")
      });
    }
  };

  handleGetTermsOfService = async values => {
    const yearOfBirth = dayjs(values.birthdate).year();
    const currentYear = dayjs().year();
    const age = currentYear - yearOfBirth;
    const termsUrl = await authModule.getTermsAndCondition(age || 1);

    this.setState({
      termsUrl
    });
  };

  handleServerValidation = values => {
    const { currentStep } = this.state;
    if (currentStep === PHONE) {
      this.handlePhoneValidation(values);
    }

    if (currentStep === EMAIL) {
      this.handleEmailValidation(values);
    }

    if (currentStep === VERIFICATION_CODE) {
      this.handleVerificationCodeValidation(values);
    }

    if (currentStep === TEAM_CODE) {
      this.handleTeamCode(values);
    }
  };

  toggleIsServerValidationNeeded = value => {
    this.setState({
      isServerValidationNeeded: value
    });
  };

  toggleBackground = () => {
    this.setState(prevState => ({
      backgroundImage: backgrounds[prevState.currentPage + 1]
    }));
  };

  updateStepsNames = method => {
    // remove and add the correct steps name after changing between email and phone registration
    const { stepsNames, currentPage } = this.state;
    const newStepsNames = stepsNames;

    if (method === EMAIL) {
      let index = newStepsNames.indexOf(VERIFICATION_CODE);
      if (index > -1) {
        newStepsNames.splice(index, 1);
      }
      index = newStepsNames.indexOf(PHONE);
      if (index > -1) {
        newStepsNames.splice(index, 1);
      }
      if (!newStepsNames.includes(EMAIL)) {
        newStepsNames.splice(currentPage + 1, 0, EMAIL);
      }
      this.setState({
        stepsNames: newStepsNames
      });
    }

    if (method === PHONE) {
      const index = newStepsNames.indexOf(EMAIL);
      if (index > -1) {
        if (!newStepsNames.includes(VERIFICATION_CODE)) {
          newStepsNames.splice(currentPage + 1, 1, VERIFICATION_CODE);
        } else {
          newStepsNames.splice(index, 1);
        }
      }
      if (!newStepsNames.includes(PHONE)) {
        newStepsNames.splice(currentPage + 1, 0, PHONE);
      }
      this.setState({
        stepsNames: newStepsNames
      });
    }
  };

  handleEnterPress = (event, values) => {
    const { isNextStepAllowed } = this.state;
    if (event.key === "Enter") {
      event.preventDefault();
      if (isNextStepAllowed) {
        this.handleNextClick(values);
      }
    }
  };

  handleBackToLogin = () => {
    if (window.isRNWebView) {
      // timout is a hack to fix android postMessage not working.
      setTimeout(() => {
        window.postMessage("goBack", "*");
      }, 1000);
    } else {
      window.location.href = `${MYPLAY_LOGIN_URL}/logout`;
    }
  };

  /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

  render() {
    const {
      currentPage,
      isNextStepAllowed,
      termsUrl,
      teamLogo,
      stepsNames,
      initialValues,
      recoverPasswordText,
      ismobile
    } = this.state;
    const {
      RegisterStore: { fields }
    } = this.props;
    return (
      <div
        className="register"
        style={{
          backgroundImage: `url(${
            ismobile
              ? mobileBackgrounds[stepsNames[currentPage]]
              : backgrounds[stepsNames[currentPage]]
          })`
        }}
      >
        <Form
          decorators={[this.phoneNumber]}
          initialValues={initialValues}
          onSubmit={this.handleSubmit}
        >
          {({ handleSubmit, values }) => {
            return stepsNames && stepsNames.length > 0 ? (
              <form
                style={{ width: "100%" }}
                className="form-lock"
                onSubmit={handleSubmit}
                onKeyPress={event => this.handleEnterPress(event, values)}
              >
                <SwipeableViews
                  index={currentPage}
                  isSwipeable={false}
                  hasArrows={false}
                  hasDots={false}
                  arrows={false}
                  handleSwipe={() => null}
                >
                  {fields.name &&
                    fields.name.isVisible && (
                      <StepName
                        isNextStepAllowed={this.isNextStepAllowed}
                        isActive={stepsNames[currentPage] === NAME}
                        toggleTab={this.toggleTab}
                      />
                    )}
                  {fields.country &&
                    fields.country.isVisible && (
                      <StepCountry
                        isNextStepAllowed={this.isNextStepAllowed}
                        isActive={stepsNames[currentPage] === COUNTRY}
                        countryFromInvitation={initialValues.country}
                      />
                    )}
                  {fields.birthdate &&
                    fields.birthdate.isVisible && (
                      <StepBirthdate
                        isNextStepAllowed={this.isNextStepAllowed}
                        isActive={stepsNames[currentPage] === BIRTHDATE}
                      />
                    )}
                  {fields.registrationMethod &&
                    fields.registrationMethod.isVisible && (
                      <StepRegistrationMethod
                        isNextStepAllowed={this.isNextStepAllowed}
                        toggleButtonPanel={this.toggleButtonPanel}
                        isActive={
                          stepsNames[currentPage] === REGISTRATION_METHOD
                        }
                        updateStepsNames={this.updateStepsNames}
                        onHandleNext={this.handleNext}
                      />
                    )}
                  {((fields.phone && fields.phone.isVisible) ||
                    (fields.email && fields.email.isVisible)) && (
                    <div>
                      {values.registrationMethod === PHONE
                        ? fields.phone &&
                          fields.phone.isVisible && (
                            <StepPhone
                              isNextStepAllowed={this.isNextStepAllowed}
                              isActive={stepsNames[currentPage] === PHONE}
                              toggleIsServerValidationNeeded={
                                this.toggleIsServerValidationNeeded
                              }
                              selectedCountry={values.country}
                              onHandleCurrentStep={this.handleCurrentStep}
                            />
                          )
                        : fields.email &&
                          fields.email.isVisible && (
                            <StepEmail
                              isActive={stepsNames[currentPage] === EMAIL}
                              isNextStepAllowed={this.isNextStepAllowed}
                              toggleIsServerValidationNeeded={
                                this.toggleIsServerValidationNeeded
                              }
                              onHandleCurrentStep={this.handleCurrentStep}
                            />
                          )}
                    </div>
                  )}
                  {values.registrationMethod !== EMAIL &&
                    (fields.phone &&
                      fields.phone.isVisible && (
                        <StepVerificationCode
                          isNextStepAllowed={this.isNextStepAllowed}
                          toggleIsServerValidationNeeded={
                            this.toggleIsServerValidationNeeded
                          }
                          isActive={
                            stepsNames[currentPage] === VERIFICATION_CODE
                          }
                          handleSendVerificationCode={() =>
                            this.handleSendVerificationCode(values)
                          }
                          phone={values.regularPhone}
                          onHandleCurrentStep={this.handleCurrentStep}
                        />
                      ))}
                  {fields.teamCode &&
                    fields.teamCode.isVisible && (
                      <StepTeamCode
                        isActive={stepsNames[currentPage] === TEAM_CODE}
                        isNextStepAllowed={this.isNextStepAllowed}
                        toggleIsServerValidationNeeded={
                          this.toggleIsServerValidationNeeded
                        }
                        onHandleCurrentStep={this.handleCurrentStep}
                      />
                    )}
                  {fields.password &&
                    fields.password.isVisible && (
                      <StepPassword
                        isActive={stepsNames[currentPage] === PASSWORD}
                        isNextStepAllowed={this.isNextStepAllowed}
                      />
                    )}
                  {fields.termsOfService &&
                    fields.termsOfService.isVisible && (
                      <StepTermsOfService
                        isActive={stepsNames[currentPage] === TERMS_OF_SERVICE}
                        getTermsOfService={() =>
                          this.handleGetTermsOfService(values)
                        }
                        termsUrl={termsUrl}
                        teamLogo={teamLogo}
                      >
                        <RegisterSubmitPanel
                          isSuccessAllowed={values.termsAndConditions}
                          onSuccess={handleSubmit}
                          onDismiss={this.handlePrevious}
                        />
                      </StepTermsOfService>
                    )}
                </SwipeableViews>
                <p className="register__error">{this.state.error}</p>
                {recoverPasswordText ? (
                  <Link to={FORGOT_PASSWORD_ROUTE_NAME}>
                    <p className="register__recover-password-link">
                      {recoverPasswordText}
                    </p>
                  </Link>
                ) : (
                  <div className="register__recover-password-link--empty" />
                )}
                <div
                  className="register__buttons"
                  style={{
                    display:
                      stepsNames[currentPage] !== TERMS_OF_SERVICE
                        ? "flex"
                        : "none"
                  }}
                >
                  <RegisterStandartButtons
                    isSuccessAllowed={isNextStepAllowed}
                    isSuccessVisible={
                      stepsNames[currentPage] !== REGISTRATION_METHOD
                    }
                    isDismissVisible
                    onDismiss={
                      currentPage ? this.handlePrevious : this.handleBackToLogin
                    }
                    onSuccess={() => this.handleNextClick(values)}
                  />
                </div>
              </form>
            ) : null;
          }}
        </Form>
      </div>
    );
  }
}

export default inject("UserStore", "RegisterStore", "TeamsStore")(
  observer(Register)
);

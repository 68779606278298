import React, { Component } from "react";
import PropTypes from "prop-types";
import "./Settings.scss";
import { inject, observer } from "mobx-react";
import { HorizontalSeparator, PrimaryButton, toast } from "@myplay/ui";
import ClickOutside from "react-click-outside";
/* eslint-disable */
import I18N from "../../I18N";
import UserSettings from "./UserSettings";
import TeamSettings from "./TeamSettings";
import ImageTools from "../../utils/imageTools";
import { WHITE } from "../../styles/shared/colors";

class Settings extends Component {
  static propTypes = {
    isOpen: PropTypes.bool,
    ModalStore: PropTypes.object,
    onClickOutside: PropTypes.func,
    TeamsStore: PropTypes.object.isRequired,
    UserStore: PropTypes.object
  };

  static defaultProps = {
    isOpen: false,
    ModalStore: {},
    onClickOutside: () => {},
    UserStore: {}
  };

  state = {
    shouldRenderPicker: false,
    profilePicture: "",
    teamImage: "",
    settingsCssClass: "settings",
    isClickOutsideActive: true
  };

  componentDidMount() {
    const { UserStore, TeamsStore } = this.props;

    this.setState({
      profilePicture: (UserStore.data && UserStore.data.avatar) || "",
      teamImage:
        (TeamsStore.currentTeam && TeamsStore.currentTeam.teamImage) || ""
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isOpen !== this.props.isOpen) {
      // eslint-disable-next-line
      this.setState({
        settingsCssClass: `settings ${
          this.props.isOpen ? "slide-down" : "slide-up"
        }`
      });
    }
  }

  handleToggleImagePicker = () => {
    this.setState(prevState => ({
      shouldRenderPicker: !prevState.shouldRenderPicker
    }));
  };

  onSaveCroppedImage = (newSrc, type) => {
    const { UserStore, TeamsStore } = this.props;

    if (type === "user") {
      this.setState({
        profilePicture: newSrc
      });
      this.toggleIsClickOutsideActive();
      UserStore.updateUserImage(newSrc);
      return;
    }
    this.setState({
      teamImage: newSrc
    });
    this.toggleIsClickOutsideActive();
    TeamsStore.updateTeamImage(newSrc);
  };

  onUploadImage = (image, type) => {
    const { ModalStore } = this.props;

    this.setState({
      isClickOutsideActive: false
    });

    ModalStore.openModal({
      modalType: "PICKER_MODAL",
      modalPosition: "top",
      modalProps: {
        img: image,
        handleSave: croppedImage => {
          ModalStore.closeModal();
          this.onSaveCroppedImage(croppedImage, type);
        },
        handleCancel: () => {
          ModalStore.closeModal();
          this.onCancel();
        }
      },
      containerProps: {
        onRequestClose: () => {
          ModalStore.closeModal();
          this.onCancel();
        }
      }
    });
  };

  onCancel = () => {
    const { UserStore } = this.props;
    this.toggleIsClickOutsideActive();

    this.setState({
      profilePicture: UserStore && UserStore.data && UserStore.data.avatar
    });
  };

  handleProfileImageChange = async (event, type) => {
    if (!event.target.files.length) {
      return;
    }
    this.setState({
      isClickOutsideActive: false
    });
    const eventCopy = { ...event };
    const reader = new FileReader();
    reader.onloadend = onLoadEvent => {
      this.onUploadImage(onLoadEvent.target.result, type);
    };

    ImageTools.resize(
      eventCopy.target.files[0],
      {
        width: 540,
        height: 360
      },
      compressed => {
        reader.readAsDataURL(compressed);
      }
      );
  };

  handleShowLogoutModal = () => {
    const { ModalStore, UserStore } = this.props;

    this.setState({
      isClickOutsideActive: false
    });

    ModalStore.openModal({
      modalType: "CONFIRM_MODAL",
      modalPosition: "center",
      modalProps: {
        text: `${I18N.t("LOGOUT_CONFIRMATION")}`,
        handleSuccess: () => {
          UserStore.logout();
          this.toggleIsClickOutsideActive();
        },
        onCancel: () => {
          this.toggleIsClickOutsideActive();
          ModalStore.closeModal();
        }
      }
    });
  };

  handleRefreshTeamCode = async () => {
    const { TeamsStore, ModalStore } = this.props;

    this.setState({ isClickOutsideActive: false });

    ModalStore.openModal({
      modalType: "CONFIRM_MODAL",
      modalPosition: "center",
      modalProps: {
        text: I18N.t("PRE_REFRESH_TEAM_CODE_MESSAGE"),
        handleSuccess: () => {
          TeamsStore.refreshTeamCode();
          this.setState({ isClickOutsideActive: true });
        },
        onCancel: () => {
          this.setState({ isClickOutsideActive: true });
        }
      }
    });
  };

  toggleIsClickOutsideActive = () => {
    this.setState(prevState => ({
      isClickOutsideActive: !prevState.isClickOutsideActive
    }));
  };

  onHandleUpdateUser = async (values, form) => {
    const { UserStore, ModalStore } = this.props;
    const { data } = UserStore;
    if (data.phone !== values.phone) {
      this.setState({ isClickOutsideActive: false });
      ModalStore.openModal({
        modalType: "VERIFY_PHONE",
        modalPosition: "center",
        modalProps: {
          phone: values.phone,
          country: values.country,
          onSendValidCode: async () => {
            try {
              await UserStore.updateUser(values);
              ModalStore.closeModal();
              toast.success(I18N.t("UPDATED_SUCCESSFULLY"));
            } catch (e) {
              if (e.message === "Phone already exists") {
                ModalStore.openModal({
                  modalType: "CONFIRM_MODAL",
                  modalPosition: "center",
                  modalProps: {
                    text: `${I18N.t("PHONE_ALREADY_EXIST")}, ${I18N.t(
                      "PLEASE_CONTACT_SUPPORT"
                    )}`,
                    hasButtons: false
                  }
                });
              } else {
                toast.error(I18N.t("UPDATED_FAILED"));
                ModalStore.closeModal();
              }
            } finally {
              form.reset();
              this.setState({ isClickOutsideActive: true });
            }
          }
        }
      });
    } else {
      try {
        await UserStore.updateUser(values);
        toast.success(I18N.t("UPDATED_SUCCESSFULLY"));
      } catch (e) {
        toast.error(I18N.t("UPDATED_FAILED"));
      }
    }
  };

  handleUpdateTeamName = name => {
    const { TeamsStore } = this.props;
    TeamsStore.updateTeamName(name);
  };

  render() {
    const {
      profilePicture,
      settingsCssClass,
      teamImage,
      isClickOutsideActive
    } = this.state;
    const { UserStore, TeamsStore, onClickOutside } = this.props;
    const { data } = UserStore;
    const { accountType } = data;

    return (
      <ClickOutside
        onClickOutside={isClickOutsideActive ? onClickOutside : () => {}}
      >
        <div className={settingsCssClass}>
          <UserSettings
            user={UserStore}
            img={profilePicture}
            updateUser={this.onHandleUpdateUser}
            onUpdatePress={this.handleProfileImageChange}
          />
          <HorizontalSeparator />
          <TeamSettings
            team={TeamsStore.currentTeam}
            img={teamImage}
            onUpdateTeamImage={this.handleProfileImageChange}
            onUpdateTeamName={this.handleUpdateTeamName}
            onRefreshTeamClick={this.handleRefreshTeamCode}
            isAllowToEdit={accountType !== "player"}
          />
          <HorizontalSeparator />
          <PrimaryButton color={WHITE} onClick={this.handleShowLogoutModal}>
            {I18N.t("LOGOUT")}
          </PrimaryButton>
        </div>
      </ClickOutside>
    );
  }
}

export default inject("TeamsStore", "UserStore", "ModalStore")(
  observer(Settings)
);

import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import ClickOutside from "react-click-outside";
import Notifications from "./Notifications";

/* eslint-disable react/require-optimization */
/* eslint-disable react/prop-types */

class NotificationsContainer extends Component {
  state = {
    skip: 0
  };

  async componentDidMount() {
    const { NotificationsStore } = this.props; // eslint-disable-line
    const {
      getNotifications,
      getUnreadCount,
      getActiveTasks
    } = NotificationsStore;

    if (!getNotifications || !getUnreadCount || !getActiveTasks) {
      return;
    }

    getNotifications();
    getUnreadCount();
    getActiveTasks();
  }

  getMoreNotifications = () => {
    try {
      const { NotificationsStore } = this.props; // eslint-disable-line
      const { getNotifications } = NotificationsStore;

      this.setState(
        state => ({
          skip: state.skip + 10
        }),
        () => {
          getNotifications(null, this.state.skip);
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  handleAfterOpen = () => {
    if (!this.props.NotificationsStore) {
      return;
    }

    this.props.NotificationsStore.markAllAsRead();
  };

  updateSelectedVideoId = (id, teamId) => {
    const { NotificationsStore } = this.props;
    NotificationsStore.setCurrentVideoId(id);
    this.switchTeam(teamId);
  }

  updateSelectedClipId = (id, teamId) => {
    const { NotificationsStore } = this.props;
    NotificationsStore.setCurrentClipId(id);
    this.switchTeam(teamId);
  }

  switchTeam = async teamId => {
    const { TeamsStore } = this.props;
    if (TeamsStore.currentTeam._id !== teamId) {
      TeamsStore.setCurrentTeam(teamId);
    }
  }

  closeModal = e => {
    const { ModalStore } = this.props;
    if (e) {
      e.stopPropagation();
    }
    ModalStore.closeModal();
  }

  render() {
    const { NotificationsStore } = this.props;
    const tasks = NotificationsStore.activeTasks || [];
    const notifications = NotificationsStore.notifications || [];
    const clientUploadTasks = NotificationsStore.clientUploadTasks || [];
    /* eslint-disable react/jsx-handler-names */
    return (
      <ClickOutside
        onClickOutside={e => this.closeModal(e)}
      >
        <div className="notifications">
          <Notifications
            unreadCount={NotificationsStore.unreadCount}
            notifications={[...clientUploadTasks,...tasks, ...notifications]}
            currentVideoId={NotificationsStore.selectedVideoId}
            getMoreNotifications={this.getMoreNotifications}
            updateSelectedVideoId={this.updateSelectedVideoId}
            updateSelectedClipId={this.updateSelectedClipId}
            closeModal={this.closeModal}
            onNotificationClick={NotificationsStore.handleNotificationClick}
            onOpen={this.handleAfterOpen}
          />
        </div>
      </ClickOutside>
    );
  }
}

export default inject("NotificationsStore", "ModalStore", "TeamsStore")(observer(NotificationsContainer));

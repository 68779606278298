/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from "react";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import { user, stats as statsModule, game } from "@myplay/all";
import { PlayerBadge, Loader, toast, EmptyPlayerProfile, StatsPreview } from "@myplay/ui";
import { inject, observer } from "mobx-react";

import "./PlayerProfile.scss";
import I18N from "../../I18N/";
import {
  PlayerInfo,
  HighlightPreview,
  SecondaryHeader,
  AnimatedScene
} from "../../components";

function getHeightRange(country) {
  const range = [];

  if (country === "United States") {
    for (let i = 4; i < 8; i++) {
      for (let j = 0; j < 10; j++) {
        range.push(`${i}'${j}"`);
      }
    }
  } else {
    for (let i = 120; i < 250; i++) {
      range.push(i);
    }
  }

  return range;
}

/* eslint-disable */
// eslint-disable-next-line react/prop-types
const PlayerProfileHighlightsReel = ({ numberOfHighlights, children }) => {
  return (
    <div className="player-profile__highlights__reel">
      <div className="player-profile__highlights__reel__title">
        <h4 className="player-profile__highlights__reel__title__text">
          {I18N.t("SEASONAL")}
          <br />
          {I18N.t("HIGHLIGHTS")}
        </h4>
        <div className="player-profile__highlights__reel__title__saved">
          <span className="player-profile__highlights__reel__title__saved__number">
            {numberOfHighlights}
          </span>
          <span className="player-profile__highlights__reel__title__saved__explainer">
            {I18N.t("TOTAL_SAVED")}
          </span>
        </div>
      </div>
      {children}
    </div>
  );
};
class PlayerProfile extends Component {
  state = {
    info: null,
    stats: null,
    highlights: [],
    isEditable: false,
    isStats: false
  };

  componentDidMount() {
    const { MetaStore, UserStore, playerId, TeamsStore } = this.props;
    const { currentTeam } = TeamsStore;
    const { data } = UserStore;
    const { accountType } = data;
    this.isAllowedToChangeNumber = accountType !== "player";

    MetaStore.toggleHeaderOff();
    this.setState({
      isEditable: UserStore.data._id === playerId
    });

    this.setInfo();
    this.setStats();
    this.setHiglights();
  }

  setInfo = async () => {
    const { playerId, TeamsStore } = this.props;
    const { currentTeam } = TeamsStore;

    try {
      const data = await user.auth.getUserData(playerId);
      data.playerNumber = await user.settings.getPlayerNumberByTeam(
        currentTeam._id,
        playerId
      );

      this.setState({
        info: data
      });
    } catch (error) {
      console.log(error);
    }
  };

  setStats = async () => {
    const { playerId, TeamsStore } = this.props;

    try {
      const stats = await statsModule.stats.getPlayerProfileStats(
        playerId,
        TeamsStore.currentTeam._id
      );

      this.setState({
        stats,
        isStats: stats.isStats
      });
    } catch (error) {
      console.log(error);
    }
  };

  setHiglights = async () => {
    const { playerId, TeamsStore } = this.props;

    try {
      const highlights = await game.highlights.getUserHighlights(
        playerId,
        TeamsStore.currentTeam._id
      );

      this.setState({
        highlights
      });
    } catch (error) {
      console.log(error);
    }
  };

  handleDelete = highlightId => {
    const { ModalStore } = this.props;

    const handleSuccess = async () => {
      ModalStore.closeModal();
      this.setState(state => ({
        highlights: state.highlights.filter(({ _id }) => _id !== highlightId)
      }));

      await game.highlights.deleteHighlight(highlightId);
      toast.success("Highlight Deleted Successfully");
    };

    ModalStore.openModal({
      modalType: "CONFIRM_MODAL",
      modalPosition: "center",
      modalProps: {
        text: "Are you sure you want to delete this higlight?",
        handleSuccess
      }
    });
  };

  handleHighlightClick = highlight => {
    const { ModalStore } = this.props;
    const { isEditable } = this.state;

    ModalStore.openModal({
      modalType: "PREVIEW_HIGHLIGHT",
      modalPosition: "center",
      modalProps: {
        highlight,
        onDelete: () => this.handleDelete(highlight._id),
        isEditable
      },
      containerProps: {
        style: {
          content: {
            padding: 0
          }
        }
      }
    });
  };

  handleSaveInfo = async data => {
    const { TeamsStore, UserStore } = this.props;
    const { currentTeam } = TeamsStore;
    const { info } = this.state;
    if (data.playerNumber) {
      if (!info.playerNumbersPerTeam) {
        info.playerNumbersPerTeam = {};
      }
      info.playerNumbersPerTeam[currentTeam._id] = data.playerNumber;
      data.playerNumbersPerTeam = info.playerNumbersPerTeam;
    }
    await user.settings.updateUserData(info._id, data);
    await UserStore.RefreshTeamMembersList()
    await this.setInfo();
  };

  render() {
    const { TeamsStore } = this.props;
    const { stats, info, highlights, isEditable, isStats } = this.state;
    const {
      firstName,
      lastName,
      avatarBgColor,
      avatar,
      position,
      weight,
      height,
      playerNumber,
      birthdate,
      country,
      playerNumbersPerTeam
    } =
      info || {};
    const { currentTeam } = TeamsStore;
    const isAmerican = country === "United States";

    const positions =
      currentTeam &&
      currentTeam.category &&
      currentTeam.category.playerPositions
        ? currentTeam.category.playerPositions
        : [];
    const inputValues = [
      {
        name: "team",
        placeholder: "team",
        value: currentTeam.name,
        disabled: true,
        type: "text"
      },
      {
        name: "position",
        placeholder: "position",
        value: position,
        type: isEditable ? "select" : "string",
        options: positions,
        disabled: !isEditable
      },
      {
        name: "weight",
        placeholder: `weight ${isAmerican ? "(lbs)" : "(kg)"}`,
        value: weight,
        type: "number",
        disabled: !isEditable
      },
      {
        name: "playerNumber",
        placeholder: "jersy",
        value: playerNumber,
        type: "number",
        disabled: true && !this.isAllowedToChangeNumber
      },
      {
        name: "age",
        placeholder: "age",
        value: dayjs().diff(birthdate, "year"),
        type: "number",
        disabled: true
      },
      {
        name: "height",
        placeholder: `height (${isAmerican ? "ft" : "cm"})`,
        value: height,
        type: isEditable ? "select" : "number",
        options: getHeightRange(country),
        disabled: !isEditable
      }
    ];

    return (
      <AnimatedScene>
        {({ handleBack }) => (
          <div className="player-profile">
            <SecondaryHeader handleBack={handleBack} />
            <div className="player-profile__details">
              <div className="player-profile__details__brand">
                <h2 className="player-profile__details__brand__name">
                  {firstName}
                  <br />
                  {lastName}
                </h2>
                {avatar ? (
                  <img
                    className="player-profile__details__brand__avatar"
                    src={avatar}
                    alt=""
                  />
                ) : (
                  <div className="player-profile__details__brand__avatar">
                    <PlayerBadge
                      firstName={firstName}
                      lastName={lastName}
                      color={avatarBgColor}
                    />
                  </div>
                )}
              </div>
              <div className="player-profile__details__info">
                <PlayerInfo
                  inputs={inputValues}
                  isEditable={isEditable}
                  onChange={this.handleSaveInfo}
                />
              </div>
            </div>
            <div className="player-profile__stats">
              {stats ? (
                <StatsPreview
                  bars={stats.bars}
                  donuts={stats.donuts}
                  uniqueId={1}
                  isStats={isStats}
                  pointsPerGame={stats.avgTotalPoints}
                  sportsCategory={TeamsStore.currentTeam.category.name}
                  isPlayerProfile
                />
              ) : (
                <div className="player-profile__stats__loader">
                  <Loader />
                </div>
              )}
            </div>
            <div className="player-profile__highlights">
              {highlights && highlights.length > 0 ? (
                <PlayerProfileHighlightsReel
                  numberOfHighlights={highlights.length}
                >
                  {highlights.map(highlight => (
                    <HighlightPreview
                      key={highlight._id}
                      highlight={highlight}
                      isEditable={isEditable}
                      onClick={this.handleHighlightClick}
                      onDelete={this.handleHighlightDelete}
                    />
                  ))}
                </PlayerProfileHighlightsReel>
              ) : (
                <EmptyPlayerProfile />
              )}
            </div>
          </div>
        )}
      </AnimatedScene>
    );
  }
}

PlayerProfile.propTypes = {
  MetaStore: PropTypes.shape().isRequired,
  ModalStore: PropTypes.shape().isRequired,
  playerId: PropTypes.string,
  TeamsStore: PropTypes.shape().isRequired,
  UserStore: PropTypes.shape().isRequired
};

PlayerProfile.defaultProps = {
  playerId: ""
};

export default withRouter(
  inject("UserStore", "TeamsStore", "ModalStore", "MetaStore")(
    observer(PlayerProfile)
  )
);

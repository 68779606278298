import React from "react";
import PropTypes from "prop-types";
import { inject } from "mobx-react";
import { DefaultPlayer as Video } from "react-html5video";
import { HighlightPlayer, SocialSharePanel } from "@myplay/ui";

import "./PreviewHighlight.scss";
import getHighlightLink from "../../../utils/getHighlightLink";
import { sendAnalyticsEvent } from "../../../utils/analytics";
import {
  ANALYTICS_SHARED_HIGHLIGHT,
  ANALYTICS_SHARED_COACH_HIGHLIGHT
} from "../../../utils/constants";
import deleteIcon from "../../../assets/icons/Icon-Delete.svg";

const PreviewHighlight = ({ highlight, onDelete, UserStore, isEditable }) => {
  const shareLink = getHighlightLink(highlight);
  const hashtag = "#";

  const getSharedPlatform = platform => {
    const { data } = UserStore;

    sendAnalyticsEvent(
      data.accountType === "player"
        ? ANALYTICS_SHARED_HIGHLIGHT
        : ANALYTICS_SHARED_COACH_HIGHLIGHT,
      {
        email: data.email,
        id: data._id,
        fullName: data.fullName,
        platform
      }
    );
  };

  return (
    <div className="preview-highlight">
      <header>
        <span className="preview-highlight__title">{hashtag}</span>
        <span className="preview-highlight__title">{highlight.title}</span>
      </header>

      {!highlight.video.isPanoramic ? (
        <Video
          controls={["PlayPause", "Seek"]}
          src={highlight.streamUri}
          poster={highlight.thumbnailUri}
          autoPlay
          loop
        />
      ) : (
        <HighlightPlayer videoId={highlight.id} autoPlay loop />
      )}
      {isEditable && (
        <div className="preview-highlight__bottom-panel">
          <button type="button" style={{ all: "unset" }} onClick={onDelete}>
            <img
              className="preview-highlight__bottom-panel__delete"
              src={deleteIcon}
              alt="delete"
            />
          </button>
        </div>
      )}
      <SocialSharePanel
        shareLink={shareLink}
        getSharedPlatform={getSharedPlatform}
      />
    </div>
  );
};

PreviewHighlight.propTypes = {
  highlight: PropTypes.shape(),
  isEditable: PropTypes.bool,
  onDelete: PropTypes.func,
  UserStore: PropTypes.shape().isRequired
};

PreviewHighlight.defaultProps = {
  highlight: {},
  isEditable: false,
  onDelete: () => {}
};

export default inject("UserStore")(PreviewHighlight);

/* eslint-disable react/jsx-handler-names */
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Input, SuccessButton, DismissButton, PlayerBadge, Loader } from "@myplay/ui";
import { TimePicker, DatePicker } from "@material-ui/pickers";
import { inject, observer } from "mobx-react";
import dayjsPluginUTC from "dayjs-plugin-utc";
import dayjs from "dayjs";
import { SimpleDropdown } from "../../";
import I18N from "../../../I18N";
import convertMinutesToHoursAndMinutes from "../../../utils/convertMinutesToHoursAndMinutes";

import PlusButton from "../../../assets/images/plus-button-white.svg";
import hourglassIcon from "../../../assets/images/hourglass.svg";
import clockIcon from "../../../assets/images/clock.svg";
import calendarIcon from "../../../assets/images/calendar.svg";

dayjs.extend(dayjsPluginUTC);

const maxRecordDuration = 180;
const minRecordDuration = 15;

const getRecordDurations = () => {
  const durationDivision = maxRecordDuration / minRecordDuration;
  const durations = [];

  for (let i = 1; i <= durationDivision; i++) {
    // eslint-disable-line
    durations.push({
      label: minRecordDuration * i,
      value: convertMinutesToHoursAndMinutes(minRecordDuration * i)
    });
  }

  return durations;
};

// eslint-disable-next-line react/prop-types
function Avatar({ avatar, firstName, lastName, avatarBgColor }) {
  return avatar ? (
    <img src={avatar} />
  ) : (
    <PlayerBadge
      firstName={firstName}
      lastName={lastName}
      color={avatarBgColor}
    />
  );
}

// eslint-disable-next-line react/prop-types
function ScheduleFormBox({ children }) {
  return <div className="record-scheduler__form__box">{children}</div>;
}

class AddScheduledRecord extends Component {
  static propTypes = {
    creator: PropTypes.object
  };

  static defaultProps = {
    creator: {}
  };

  DEFAULT_DURATIONS = getRecordDurations();

  state = {
    date: null,
    hour: null,
    duration: this.DEFAULT_DURATIONS[7], // 2 hours,
    title: "",
    titleErrorMessage: "",
    displayDate: null,
    isProcessing: false
  };

  handleDateChange = date => {
    this.setState({
      date: dayjs.utc(date.format("YYYY-MM-DD HH:mm:ss")),
      displayDate: date
    });
  };

  handleHourChange = hour => {
    this.setState({
      hour: dayjs.utc(hour.format("YYYY-MM-DD HH:mm:ss"))
    });
  };

  handleDurationChange = duration => {
    this.setState({
      duration
    });
  };

  handleSetTitle = event => {
    event.persist();

    this.setState(state => ({
      title: event.target.value,
      titleErrorMessage:
        event && event.target && event.target.value
          ? ""
          : state.titleErrorMessage
    }));
  };

  onSave = async () => {
    const { SchedulerStore } = this.props;
    const { title, duration, hour, date } = this.state;

    try {
      const hoursToDayjs = dayjs.utc(hour);
      const mergedDate = dayjs
        .utc(date)
        .set("hour", hoursToDayjs.get("hour"))
        .set("minute", hoursToDayjs.get("minute"))
        .toISOString();

      if (!title) {
        this.setState({
          titleErrorMessage: I18N.t("NO_SCHEDULED_RECORD_TITLE")
        });
        return;
      }
      this.setState({ isProcessing: true });
      await SchedulerStore.scheduleNewTask(title, mergedDate, duration.label);
      this.clean();
    } catch (error) {
      this.setState({ isProcessing: false });
      console.log(error);
    }
  };

  clean() {
    const { onToggle } = this.props;

    this.setState({
      date: null,
      hour: null,
      duration: this.DEFAULT_DURATIONS[this.DEFAULT_DURATIONS.length / 2],
      title: "",
      isProcessing: false,
      displayDate: null
    });
    onToggle();
  }

  render() {
    const { creator, isOpen, onToggle } = this.props;
    const {
      date,
      hour,
      duration,
      titleErrorMessage,
      displayDate,
      isProcessing
    } = this.state;

    return (
      <Fragment>
        {isOpen ? (
          <div className="record-scheduler__form">
            {isProcessing && (
              <div className="record-scheduler__form__processing-loader">
                <Loader type="bars" height="50px" width="50px" />
                {I18N.t("PROCESSING_REQUEST")}
              </div>
            )}
            <div className="record-scheduler__form__record-name">
              <div className="record-scheduler__form__record-name__avatar">
                {creator ? (
                  <Avatar
                    avatar={creator.avatar}
                    firstName={creator.firstName}
                    lastName={creator.lastName}
                    avatarBgColor={creator.avatarBgColor}
                  />
                ) : null}
              </div>
              <Input
                className="record-scheduler__form__record-name__input"
                placeholder="Home Team vs Away Team"
                onChange={this.handleSetTitle}
                error={titleErrorMessage}
                autoFocus
              />
            </div>
            <div className="record-scheduler__form__boxes">
              <ScheduleFormBox>
                <img src={calendarIcon} />
                <div className="record-scheduler__form__box__picker">
                  <DatePicker
                    placeholder={I18N.t("PICK_DATE")}
                    value={displayDate}
                    format="dddd, D/MM"
                    autoOk
                    onChange={this.handleDateChange}
                    minDate={new Date()}
                  />
                </div>
              </ScheduleFormBox>
              <ScheduleFormBox>
                <img src={clockIcon} />
                <div className="record-scheduler__form__box__picker">
                  <TimePicker
                    placeholder={I18N.t("SET_TIME")}
                    value={this.state.hour}
                    onChange={this.handleHourChange}
                    minDate={new Date()}
                    ampm
                  />
                </div>
              </ScheduleFormBox>
              <ScheduleFormBox>
                <img src={hourglassIcon} />
                <div className="record-scheduler__form__box__picker">
                  <SimpleDropdown
                    items={this.DEFAULT_DURATIONS}
                    defaultSelectedItem={this.state.duration}
                    placeholder="Duration"
                    onChange={value => this.handleDurationChange(value)}
                  />
                </div>
              </ScheduleFormBox>
            </div>
            <div className="record-scheduler__form__actions">
              <DismissButton type="button" onClick={() => onToggle(false)}>
                {I18N.t("DISCARD")}
              </DismissButton>
              <SuccessButton
                type="button"
                disabled={!date || !hour || !duration || isProcessing}
                onClick={this.onSave}
              >
                {I18N.t("SAVE")}
              </SuccessButton>
            </div>
          </div>
        ) : (
          <button
            type="button"
            className="scheduled-record scheduled-record--new"
            onClick={onToggle}
          >
            <div className="scheduled-record__avatar__add-new">
              <img
                src={PlusButton}
                className="scheduled-record__avatar__add-new__img"
              />
            </div>
            <div className="scheduled-record__content">
              <h4 className="scheduled-record__content__title scheduled-record__content__title--grey">
                {I18N.t(isOpen ? "CLOSE" : "SCHEDULE_NEW_RECORDING")}
              </h4>
            </div>
          </button>
        )}
      </Fragment>
    );
  }
}

AddScheduledRecord.propTypes = {
  creator: PropTypes.object,
  onToggle: PropTypes.func,
  isOpen: PropTypes.bool
};

AddScheduledRecord.defaultProps = {
  creator: {},
  onToggle: () => {},
  isOpen: false
};

export default inject(["SchedulerStore"])(observer(AddScheduledRecord));

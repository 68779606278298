import { types, getParent } from "mobx-state-tree";
import { user, config, utils } from "@myplay/all";
import { toast } from "@myplay/ui";
import { flow } from "mobx";
import postRobot from "post-robot";

import history from "../../utils/history";
import { setItem, getItem } from "../../utils/storage";
import optionalAndMaybeNull from "../../utils/mobx-optional-and-maybe-null";
import I18N from "../../I18N";
import {
  VISIBLE_ERROR,
  ACCESS_TOKEN,
  REFRESH_TOKEN,
  USER_DATA,
  ANALYTICS_UPDATE_PROFILE,
  PHONE,
  EMAIL,
  MYPLAY_LOGIN_URL,
  MYPLAY_APP_URL,
  REGISTER_ROUTE_NAME,
  UNAUTH_ROUTES,
  TEAM_CHANNEL_ROUTE_NAME,
  LS_ACCESS_TOKEN,
  LS_CURRENT_TEAM,
  LS_REFRESH_TOKEN,
  LS_USER_DATA
} from "../../utils/constants";
import { sendAnalyticsEvent } from "../../utils/analytics";

const { auth } = user;
const UserData = types.model(`UserData`, {
  accountType: optionalAndMaybeNull(types.string),
  _id: optionalAndMaybeNull(types.string),
  firstName: optionalAndMaybeNull(types.string),
  lastName: optionalAndMaybeNull(types.string),
  fullName: optionalAndMaybeNull(types.string),
  avatar: optionalAndMaybeNull(types.string),
  avatarBgColor: optionalAndMaybeNull(types.string),
  position: optionalAndMaybeNull(types.string),
  playerNumber: optionalAndMaybeNull(types.number),
  height: optionalAndMaybeNull(types.union(types.number, types.string)),
  weight: optionalAndMaybeNull(types.number),
  birthdate: optionalAndMaybeNull(types.string),
  phone: optionalAndMaybeNull(types.string),
  email: optionalAndMaybeNull(types.string),
  country: optionalAndMaybeNull(types.string),
  playerNumbersPerTeam: optionalAndMaybeNull(types.frozen()),
  status: optionalAndMaybeNull(types.string),
  isAdminUser: false
});

const UserStore = types
  .model(`User`, {
    isLoggedIn: types.boolean,
    accessToken: optionalAndMaybeNull(types.string),
    refreshToken: optionalAndMaybeNull(types.string),
    data: optionalAndMaybeNull(UserData),
    isInitFinished: optionalAndMaybeNull(types.boolean)
  })
  .views(self => ({
    get isAuthenticated() {
      return Boolean(
        self.isLoggedIn &&
          getIsLoggedIn({
            accessToken: self.accessToken,
            refreshToken: self.refreshToken,
            userData: self.data
          })
      );
    }
  }))
  .actions(self => ({
    afterCreate: flow(function* afterCreate() {
      try {
        /**
         * This functions must be called first, or the requests to the server will be invalid
         * @TODO: Maybe we should consider moving them to their own functions (e.g init) and call them here
         */

        if (
          UNAUTH_ROUTES.includes(window.location.pathname) ||
          window.location.pathname.includes("/public-highlight")
        ) {
          self.setIsInitFinished(true);
          return;
        }

        config.addHeader("platform", "myplay-web");
        window.addEventListener("storage", self.handleLocalStorageChange);
        let accessToken, refreshToken, userData, currentTeam, userId;
        ({ refreshToken, accessToken, userId } = utils.extractTokensFromUrl());
        if (userId && accessToken && refreshToken) {
          self.setAccessToken(accessToken);
          self.setRefreshToken(refreshToken);
          userData = yield user.auth.getUserData(userId);
          self.setUserData(userData);
          const params = new URLSearchParams(window.location.search);
          const isApple = params.get("apple");
          const isFacebook = params.get("facebook");
          if (userData.status === "registerIncomplete") {
            isApple &&
              getParent(self).RegisterStore.setAppleIdConnectValues(userData);
            isFacebook &&
              getParent(self).RegisterStore.setFacebookConnetValues(userData);
            history.push(REGISTER_ROUTE_NAME);
            self.setIsInitFinished(true);
            return;
          }
        } else {
          ({
            accessToken,
            userData,
            refreshToken,
            currentTeam
          } = getUserFromLocalStorage());
        }
        self.setAccessToken(accessToken || "");
        self.setRefreshToken(refreshToken || "");
        self.setUserData(userData || {});

        if (getParent(self) && getParent(self).TeamsStore && userData) {
          getParent(self).TeamsStore.setUserTeams(userData.teams, currentTeam);
        }

        const isLoggedIn = getIsLoggedIn({
          accessToken,
          refreshToken,
          userData
        });
        if (isLoggedIn) {
          self.setIsLoggedIn(isLoggedIn);
          postRobot.on("getAccessToken", () => ({
            accessToken: auth.getAccessToken()
          }));
        } else {
          window.location.href = `${MYPLAY_LOGIN_URL}/?redirect=${MYPLAY_APP_URL}`;
        }
        self.setIsInitFinished(true);
      } catch (error) {
        console.log(error); // eslint-disable-line
      }
    }),
    beforeDestroy() {
      window.removeEventListener("storage", self.handleLocalStorageChange);
    },
    setIsInitFinished(isInitFinished) {
      self.isInitFinished = isInitFinished;
    },
    setIsLoggedIn(isLoggedIn) {
      self.isLoggedIn = isLoggedIn;
    },
    setAccessToken(token) {
      self.accessToken = token;
      auth.setAccessToken(token);
    },
    setRefreshToken(token) {
      self.refreshToken = token;
      auth.setRefreshToken(token);
    },
    setUserData(data) {
      setItem(LS_USER_DATA, data);
      if (data !== null) {
        const instance = UserData.create(data);
        self.data = instance;
        return;
      }

      self.data = data;
    },
    setUserId(id) {
      self.data._id = id;
    },
    setPlayerNumber(number) {
      self.data.playerNumber = number;
      const userData = getItem(USER_DATA);
      if (userData) {
        userData.playerNumber = number;
        setItem(USER_DATA, userData);
      }
    },
    setPlayerNumberByTeam: flow(function* setPlayerNumber(teamId) {
      if (self.data && self.data.playerNumbersPerTeam) {
        const playerNumber = yield user.settings.getPlayerNumberByTeam(
          teamId,
          self.data._id
        );
        self.setPlayerNumber(playerNumber);
      }
    }),
    updateUserImage: flow(function* updateUserImage(newImg) {
      try {
        yield user.settings.updateProfileImage(self.data._id, newImg);
        yield self.refreshUserData();
        self.RefreshTeamMembersList();
      } catch (error) {
        console.log(error);
      }
    }),
    refreshUserData: flow(function* refreshUserData() {
      try {
        const data = yield user.auth.getUserData(self.data._id);
        self.setUserData(data);
      } catch (error) {
        console.log(error);
      }
    }),
    logout: flow(function* logout() {
      try {
        yield self.clearUserData();
        window.location.href = `${MYPLAY_LOGIN_URL}/logout`;
      } catch (error) {
        console.log(error); // eslint-disable-line
      }
    }),
    register: flow(function* register(data) {
      try {
        const {
          userData,
          accessToken,
          refreshToken
        } = yield user.auth.register(data);

        self.setUserData(userData);
        if (getParent(self) && getParent(self).TeamsStore && userData) {
          getParent(self).TeamsStore.setUserTeams(userData.teams);
        }

        self.setAccessToken(accessToken);
        self.setRefreshToken(refreshToken);

        self.setIsLoggedIn(true);
        history.push(TEAM_CHANNEL_ROUTE_NAME);
      } catch (error) {
        console.log(error);
        toast.error(
          error.name === VISIBLE_ERROR
            ? error.message
            : I18N.t("SOMETHING_WENT_WRONG")
        );
      }
    }),
    updateUser: flow(function* updateUser(data) {
      sendAnalyticsEvent(ANALYTICS_UPDATE_PROFILE, {
        playerName: self.data.fullName,
        playerId: self.data._id,
        email: self.data.email
      });
      try {
        if (data.age) {
          // WE DONT SEND AGE AS IT'S CALCULATED FORM BIRTHDATE
          delete data.age;
        }

        delete data.team;
        yield user.settings.updateUserData(self.data._id, data);

        const newData = yield auth.getUserData(self.data._id);
        self.setUserData(newData);
        self.RefreshTeamMembersList();
      } catch (error) {
        throw new Error(error.message);
      }
    }),
    completeExternalRegistration: flow(function* completeExternalRegistration(
      data
    ) {
      try {
        const {
          accessToken,
          refreshToken,
          userData
        } = yield auth.completeExternalRegistration(self.data._id, data);

        if (getParent(self) && getParent(self).TeamsStore && userData) {
          getParent(self).TeamsStore.setUserTeams(userData.teams);
        }

        self.setAccessToken(accessToken);
        self.setRefreshToken(refreshToken);
        self.setUserData(userData);
        self.setIsLoggedIn(true);
        history.push(TEAM_CHANNEL_ROUTE_NAME);
      } catch (error) {
        console.log(error);
      }
    }),
    invitationRegistration: flow(function* invitationRegistration(token) {
      try {
        const {
          accessToken,
          registerSteps,
          userData
        } = yield auth.getPreRegisteredUser(token);
        getParent(self).RegisterStore.setInvitationFlow(registerSteps);

        self.setAccessToken(accessToken);
        self.setUserId(userData._id);

        if (getParent(self) && getParent(self).TeamsStore && userData) {
          getParent(self).TeamsStore.setUserTeams(userData.teams);
        }
      } catch (error) {
        toast.error(
          error.name === VISIBLE_ERROR
            ? error.message
            : I18N.t("INVITATION_EXPIRED"),
          { autoClose: 6000 }
        );
        window.location.href = `${MYPLAY_LOGIN_URL}/logout`;
        console.log(error);
      }
    }),
    resetPasswordByEmail: flow(function* resetPasswordByEmail(email) {
      try {
        yield auth.requestPasswordRecovery(email, EMAIL);
        return true;
      } catch (error) {
        console.log(error);
        return false;
      }
    }),
    resetPasswordByPhone: flow(function* resetPasswordByPhone(phone) {
      try {
        const res = yield auth.requestPasswordRecovery(phone, PHONE);
        return res;
      } catch (error) {
        console.log(error);
        return false;
      }
    }),
    setNewPassword: flow(function* setNewPassword(token, password) {
      try {
        const res = yield auth.resetPassword(token, password);
        return res;
      } catch (error) {
        console.log(error);
      }
    }),
    RefreshTeamMembersList() {
      const currentTeamId = getParent(self).TeamsStore.currentTeam._id;
      getParent(self).TeamsStore.setCurrentTeamMembers(currentTeamId);
    },
    clearUserData: flow(function* clearUserData() {
      const { GamesStore, TeamsStore, SiteStore } = getParent(self);

      self.setAccessToken("");
      self.setRefreshToken("");
      self.setUserData(null);
      self.clearLocalStorage();

      GamesStore.clear();
      TeamsStore.clear();
      SiteStore.clear();
    }),
    handleLocalStorageChange: flow(function* handleLocalStorageChange() {
      const { accessToken, refreshToken } = getUserFromLocalStorage();
      if (accessToken !== self.accessToken) {
        self.setTokenToStore(ACCESS_TOKEN, accessToken);
      }

      if (refreshToken !== self.refreshToken) {
        self.setTokenToStore(REFRESH_TOKEN, refreshToken);
      }
    }),
    setTokenToStore(type, token) {
      self[type] = token;
    },
    clearLocalStorage() {
      auth.setAccessToken("");
      auth.setRefreshToken("");
      setItem(LS_CURRENT_TEAM, "");
      setItem(LS_USER_DATA, "");
    }
  }));

const getIsLoggedIn = ({ accessToken, refreshToken, userData }) => {
  if (!accessToken || !refreshToken || !userData) {
    return false;
  }

  if (
    refreshToken.length > 0 &&
    accessToken.length > 0 &&
    Object.keys(userData).length
  ) {
    return true;
  }
};

const getUserFromLocalStorage = () => {
  const accessToken = getItem(LS_ACCESS_TOKEN);
  const refreshToken = getItem(LS_REFRESH_TOKEN);
  const userData = getItem(LS_USER_DATA);
  const currentTeam = getItem(LS_CURRENT_TEAM);

  return {
    accessToken,
    refreshToken,
    userData,
    currentTeam
  };
};

export { UserData };
export default UserStore;

/* eslint-disable react/jsx-no-literals */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { SocialSharePanel } from "@myplay/ui";

import getHighlightLink from "../../utils/getHighlightLink";
import { sendAnalyticsEvent } from "../../utils/analytics";
import {
  ANALYTICS_SHARED_HIGHLIGHT,
  ANALYTICS_SHARED_COACH_HIGHLIGHT
} from "../../utils/constants";

import "./HighlightPreview.scss";

class HighlightPreview extends Component {
  state = {
    isImageLoaded: false
  };

  handleToggleShare = (isOpen = null) => {
    this.setState(state => ({
      isShareOpen: isOpen !== null ? isOpen : !state.isShareOpen
    }));
  };

  setIsImageLoaded = isLoaded => {
    this.setState({
      isImageLoaded: isLoaded
    });
  };

  getSharedPlatform = platform => {
    const {
      UserStore: { data }
    } = this.props;

    sendAnalyticsEvent(
      data.accountType === "player"
        ? ANALYTICS_SHARED_HIGHLIGHT
        : ANALYTICS_SHARED_COACH_HIGHLIGHT,
      {
        email: data.email,
        id: data._id,
        fullName: data.fullName,
        platform
      }
    );
  };

  /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
  /* eslint-disable jsx-a11y/click-events-have-key-events */
  render() {
    const { highlight, onClick } = this.props;
    const { isImageLoaded } = this.state;

    return (
      <div
        key={highlight._id}
        className={`highlight-preview ${
          isImageLoaded ? "highlight-preview--animation" : ""
        }`}
      >
        <img
          src={highlight.thumbnailUri}
          alt={highlight.title}
          className="highlight-preview__thumbnail"
          onLoad={() => this.setIsImageLoaded(true)}
          onClick={() => onClick(highlight)}
        />
        <div className="highlight-preview__footer">
          <span className="highlight-preview__title">
            <span className="highlight-preview__title__hashbang">
              {highlight.title ? "#" : ""}
            </span>
            {highlight.title}
          </span>
          <div className="highlight-preview__footer__share-panel">
            <div className="highlight-preview__footer__share-panel__icons">
              <SocialSharePanel shareLink={getHighlightLink(highlight)} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

HighlightPreview.propTypes = {
  highlight: PropTypes.shape(),
  onClick: PropTypes.func,
  UserStore: PropTypes.shape().isRequired
};

HighlightPreview.defaultProps = {
  highlight: {},
  onClick: () => {}
};

export default HighlightPreview;


import React, { Component } from "react";
import PropTypes from 'prop-types';
import { Field } from "react-final-form";
import { Input } from "@myplay/ui";
import I18N from "../../../I18N";
import { StepHeader } from '../../../components';

class StepTeamCode extends Component {
  static propTypes = {
    isActive: PropTypes.bool,
    isNextStepAllowed: PropTypes.func,
    onHandleCurrentStep: PropTypes.func,
    toggleIsServerValidationNeeded: PropTypes.func
  }

  static defaultProps = {
    isActive: PropTypes.bool,
    isNextStepAllowed: ()=>{},
    onHandleCurrentStep: ()=>{},
    toggleIsServerValidationNeeded: ()=>{}
  }

  componentDidMount() {
    const { onHandleCurrentStep, toggleIsServerValidationNeeded, isActive } = this.props;
    if (isActive) {
      onHandleCurrentStep("teamCode");
      toggleIsServerValidationNeeded(true);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      isActive,
      onHandleCurrentStep,
      toggleIsServerValidationNeeded
    } = this.props;
    if (isActive && prevProps.isActive !== isActive) {
      onHandleCurrentStep("teamCode");
      toggleIsServerValidationNeeded(true);
    }
  }

  validateTeamCode = value => {
    const { isNextStepAllowed, isActive } = this.props;
    const isValid = value && value.length >= 6;
    if (isActive) {
      isNextStepAllowed(isValid);
      return value && value.length >= 6
        ? undefined
        : I18N.t("ERROR_SHORT_TEAM_CODE");
    }
  };

  render() {
    return (
      <div className="register__form__step">
        <StepHeader lines={[I18N.t("INSERT_YOUR"),I18N.t("TEAM_CODE")]}/>
        <div className="register__form__step__fields">
          <Field
            className="register__form__field"
            name="teamCode"
            validate={this.validateTeamCode}
          >
            {({ input, meta }) => (
              <Input
                {...input}
                type="text"
                label={I18N.t("TEAM_CODE")}
                error={
                  (!meta.pristine && meta.error) || meta.submitFailed
                    ? meta.error
                    : ""
                }
                data-testid="teamCode"
                tabIndex={-1}
                required
              />
            )}
          </Field>
        </div>
      </div>
    );
  }
}

export default StepTeamCode;


import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import I18N from '../../../I18N';
import emailIcon from '../../../assets/images/email-icon.svg';
import phoneIcon from '../../../assets/images/phone-icon.svg';
import { StepHeader } from '../../../components';

class StepRegistrationMethod extends Component {
  static propTypes = {
    isActive: PropTypes.bool,
    isNextStepAllowed: PropTypes.func,
    onHandleNext: PropTypes.func,
    toggleButtonPanel: PropTypes.func,
    updateStepsNames: PropTypes.func
  }

  static defaultProps = {
    isActive: false,
    isNextStepAllowed: ()=>{},
    onHandleNext: ()=>{},
    toggleButtonPanel: ()=>{},
    updateStepsNames: ()=>{}
  }

  componentDidUpdate(prevProps) {
    const { isActive, toggleButtonPanel, isNextStepAllowed } = this.props;
    if (isActive && prevProps.isActive !== isActive) {
      toggleButtonPanel(false);
      isNextStepAllowed(true);
    }
  }

  render() {
    const { toggleButtonPanel, onHandleNext, updateStepsNames } = this.props;
    return (
      <div className="register__form__step">
        <StepHeader lines={[I18N.t("REGISTER_BY"),I18N.t("PHONE_OR_EMAIL")]}/>
        <div className="register__form__step__fields">
          <Field
            className="register__form__step__fields__field"
            name="registrationMethod"
          >
          {({input}) => (
            <div className="register__form__step__fields__field__registration-method-buttons" data-testid="registrationMethod" >
              <button
                type='button'
                value='phone'
                data-testid="registrationMethod-phone"
                className="register__form__step__fields__field__registration-method-buttons__button"
                tabIndex={-1}
                onClick={event => {
                  onHandleNext(event.currentTarget.value)
                  input.onChange(event.currentTarget.value);
                  updateStepsNames(event.currentTarget.value)
                  toggleButtonPanel(true);
                }}
              >
                <img src={phoneIcon} />
              </button>
              <br/>
              <button
                type='button'
                value='email'
                data-testid="registrationMethod-email"
                className="register__form__step__fields__field__registration-method-buttons__button"
                tabIndex={-1}
                onClick={event => {
                  onHandleNext(event.currentTarget.value)
                  input.onChange(event.currentTarget.value)
                  updateStepsNames(event.currentTarget.value)
                  toggleButtonPanel(true);
                }}
              >
                <img src={emailIcon} />
              </button>
            </div>
          )}
          </Field>
        </div>
      </div>
    )
  }
}

export default StepRegistrationMethod;

import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import "./TeamMembers.scss";
import SingleTeamMember from "./SingleTeamMember";
import { UserTypeGuard } from "../../components";
import { PLAYER_PROFILE_ROUTE_NAME } from "../../utils/constants";
import sortByAccountType from "../../utils/sortByAccountType";
import I18N from "../../I18N";
import { inject, observer } from "mobx-react/index";

// eslint-disable-next-line react/prop-types
const ConditionalLink = ({ withLink, children, _id }) => {
  const getRoute = id => `${PLAYER_PROFILE_ROUTE_NAME}/${id}`;

  return withLink ? <Link to={getRoute(_id)}>{children}</Link> : children;
};

const TeamMembers = ({ members, handleDeleteMember, UserStore }) => {
  const { data } = UserStore;
  const myId = data._id;
  if (!members) {
    return <div />;
  }

  // convert this: { operators: [], players: [], coachs: [] } to this: [{ ... }, { ... }, { ... }]
  const flatMembers = Object.keys(members).reduce(
    (prev, current) => [...members[current], ...prev],
    []
  );

  flatMembers.sort((member1, member2) => sortByAccountType(member1, member2));

  /* eslint-disable react/jsx-no-literals */
  return (
    <div className="team-members">
      {flatMembers.map(
        (
          {
            firstName,
            accountType,
            lastName,
            fullName,
            playerNumber,
            avatar,
            avatarBgColor,
            _id
          },
          index
        ) => {
          return (
            <ConditionalLink
              key={_id}
              withLink={accountType === "player"}
              _id={_id}
            >
              <div
                key={_id}
                className={`team-members__member team-members__member--${accountType}`}
                style={{ animationDuration: `${index * 200}ms` }}
              >
                <div className="team-members__member__image">
                  <SingleTeamMember
                    firstName={firstName}
                    lastName={lastName}
                    avatarBgColor={avatarBgColor}
                    avatar={avatar}
                  />
                </div>
                <span className="team-members__member__detail">
                  {accountType === "player" ? playerNumber : accountType}
                </span>
                <span className="team-members__member__name">{fullName}</span>
                <UserTypeGuard allowed={["coach"]}>
                  {accountType === "player" && (
                    <button
                      type="button"
                      className="team-members__member__delete"
                      onClick={e => {
                        e.preventDefault();
                        handleDeleteMember(_id);
                      }}
                    >
                      {I18N.t("REMOVE")}
                    </button>
                  )}
                </UserTypeGuard>
                <UserTypeGuard allowed={["operator"]}>
                  {(myId !== _id) && <button
                    type="button"
                    className="team-members__member__delete"
                    onClick={e => {
                      e.preventDefault();
                      handleDeleteMember(_id, accountType);
                    }}
                  >
                    {I18N.t("REMOVE")}
                  </button>}
                </UserTypeGuard>
              </div>
            </ConditionalLink>
          );
        }
      )}
    </div>
  );
};

TeamMembers.propTypes = {
  handleDeleteMember: PropTypes.func,
  members: PropTypes.any
};

TeamMembers.defaultProps = {
  handleDeleteMember: () => {},
  members: {}
};

export default inject(["UserStore"])(observer(TeamMembers));

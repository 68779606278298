import { types } from "mobx-state-tree";

import user from "./user/user";
import notifications from "./notifications/notifications";
import teams from "./teams/teams";
import games from "./games/games";
import modal from "./modal/modal";
import site from "./site/site";
import meta from "./meta/meta";
import register from "./register/register";
import stats from "./stats/stats";
import scheduler from "./scheduler/scheduler";
import gameScreen from "./gameScreen/gameScreen";
import bookings from "./bookings/bookings";

const RootStore = types.model("RootStore", {
  UserStore: types.optional(user, {
    isLoggedIn: false,
    isInitFinished: false
  }),
  NotificationsStore: types.optional(notifications, {
    count: 0
  }),
  TeamsStore: types.optional(teams, {
    teams: []
  }),
  GamesStore: types.optional(games, {
    currentTeamGamesCursor: 50,
    currentTeamGames: []
  }),
  ModalStore: types.optional(modal, {}),
  SiteStore: types.optional(site, {}),
  MetaStore: types.optional(meta, {
    showHeader: true
  }),
  RegisterStore: types.optional(register, {
    fields: {}
  }),
  StatsStore: types.optional(stats, {}),
  SchedulerStore: types.optional(scheduler, {}),
  GameScreenStore: types.optional(gameScreen, {}),
  BookingsStore: types.optional(bookings, {
    bookings: [],
    currentBooking: {
      homeTeamTitle: null,
      awayTeamTitle: null,
      thirdPartyType: "YOUTUBE",
      thirdPartyKey: null,
      bookingDate: null,
      day: null,
      month: null,
      creator: null,
      gameTime: null,
      id: null,
      duration:120
    }
  })
});

export { RootStore };
export default {
  RootStore
};

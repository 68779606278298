/* eslint-disable */
import React, { Component, Fragment } from "react";
import { SocialSharePanel, LivePlayer, Loader } from "@myplay/ui";
import { cameraControl } from "@myplay/all";
import Helmet from "react-helmet";
import isMobile from "is-mobile";

import "./PublicLive.scss";
import I18N from "../../I18N";

import {
  PUBLIC_LIVE_SHARE_LINK,
  ANALYTICS_PUBLIC_LIVE_VIEWS
} from "../../utils/constants";
import Logo from "../../assets/images/logo-name.png";
import LiveCourtLeftSide from "../../assets/images/live_court_left.jpg";
import LiveCourtRightSide from "../../assets/images/live_court_right.jpg";
import { sendAnalyticsEvent } from "../../utils/analytics";
import PublicLiveMobile from "./PublicLiveMobile";

const { live } = cameraControl;

class PublicLive extends Component {
  state = {
    isLive: false,
    isCheckLiveDone: false,
    liveId: null,
    siteName: null
  };

  async componentDidMount() {
    const { location } = this.props;
    if (location.search) {
      const params = new URLSearchParams(location.search);
      const liveId = params.get("liveId");
      try {
        const cameras = await live.liveAuth(liveId);
        const mainCamera = cameras[0];
        const { site } = mainCamera;
        const { name } = site;

        this.setState({ isLive: true, liveId, siteName: name });
        // sendAnalyticsEvent(ANALYTICS_PUBLIC_LIVE_VIEWS, {
        //   court: name
        // });
      } catch (e) {
        console.log(e);
        this.setState({ isLive: false });
      } finally {
        this.setState({ isCheckLiveDone: true });
      }
    } else {
      this.setState({ isCheckLiveDone: true, isLive: false });
    }
  }

  renderLiveOnline = () => {
    const { liveId } = this.state;
    return <LivePlayer liveId={liveId} />;
  };

  renderLiveOffline = () => {
    return (
      <div className="public-live__offline-title">
        <p>{I18N.t("PUBLIC_LIVE_OFFLINE_PART1")}</p>
        <p className="public-live__secondary-title">
          {I18N.t("PUBLIC_LIVE_OFFLINE_PART2")}
        </p>
        <div className="public-live__logo-container">
          <p>{I18N.t("POWERED_BY")}</p>
          <img src={Logo} />
        </div>
      </div>
    );
  };

  renderReadySiteStatus = () => {
    const { isLive } = this.state;
    return isLive ? this.renderLiveOnline() : this.renderLiveOffline();
  };

  renderPendingSiteStatus = () => {
    return (
      <div className="public-live__loader">
        <p className="public-live__secondary-title">
          {I18N.t("GETTING_READY")}
        </p>
        <Loader />
      </div>
    );
  };

  renderLiveView = () => {
    const { isCheckLiveDone } = this.state;
    return isCheckLiveDone
      ? this.renderReadySiteStatus()
      : this.renderPendingSiteStatus();
  };

  render() {
    const { isCheckLiveDone, isLive, liveId, siteName } = this.state;

    const shareLink = `${PUBLIC_LIVE_SHARE_LINK}/${liveId}`;

    return isMobile({ tablet: true }) ? (
      <PublicLiveMobile isCheckLiveDone={isCheckLiveDone} isLive={isLive} liveId={liveId} siteName={siteName} shareLink={shareLink} />
    ) : (
      <div className="public-live">
        <Helmet>
          <title>{I18N.t("SHARE_LIVE_TITLE")}</title>
          <meta name="og:url" content={document.location.href} />
          {/* <meta
            name="og:title"
            content={`${I18N.t("HIGHLIGHT_OF")} ${(user && user.fullName) ||
              ""}`}
          />
          <meta name="og:description"  content={I18N.t("OG_DESCRIPTION_PLAYER")} /> */}
        </Helmet>
        <div className="public-live__header">
          {siteName ? (
            <Fragment>
              <p style={{ marginRight: "10px" }}>{I18N.t("LIVE_VIEW_FROM")}</p>
              <p className="live__secondary-title"> {siteName} </p>
            </Fragment>
          ) : null}
        </div>
        <div className="public-live__wrapper">
          <img src={LiveCourtLeftSide} />
          <div className="public-live__player-container">
            {this.renderLiveView()}
          </div>
          <img src={LiveCourtRightSide} />
        </div>
        <div className="public-live__footer">
          {isLive ? (
            <Fragment>
              <SocialSharePanel shareLink={shareLink} />
            </Fragment>
          ) : null}
        </div>
      </div>
    );
  }
}

export default PublicLive;

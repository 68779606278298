import React from "react"; //eslint-disable-line
import { Redirect } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { utils } from "@myplay/all";

const UserTypeGuard = ({ UserStore, children, allowed, withRedirect, to }) => {
  if (
    !UserStore ||
    !UserStore.data ||
    !allowed.includes(UserStore.data.accountType)
  ) {
    return withRedirect ? <Redirect to={to} /> : null;
  }
  
  return children || null;
};

export default inject(["UserStore"])(observer(UserTypeGuard));

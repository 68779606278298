/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-handler-names */
/* eslint-disable react/jsx-no-literals */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { PlayerBadge, ConfirmModal } from "@myplay/ui";
import dayjsPluginUTC from "dayjs-plugin-utc";
import dayjs from "dayjs";

import deleteIcon from "../../../assets/icons/Icon-Delete.svg";

import AddScheduledRecord from "./AddScheduledRecord";
import convertMinutesToHoursAndMinutes from "../../../utils/convertMinutesToHoursAndMinutes";
import { USER_COACH } from "../../../utils/constants";

dayjs.extend(dayjsPluginUTC);

function Avatar({ avatar, firstName, lastName, avatarBgColor }) {
  return avatar ? (
    <img src={avatar} />
  ) : (
    <PlayerBadge
      firstName={firstName}
      lastName={lastName}
      color={avatarBgColor}
    />
  );
}

function ScheduledRecord({ UserStore, record, onDelete }) {
  const { firstName, lastName, avatarBgColor, avatar } = record.creator || {};
  const teamName = (record.team && record.team.name) || "";
  const date = dayjs.utc(record.startTime).format("dddd, D MMMM");
  const hour = dayjs.utc(record.startTime).format("HH:mm");
  const { duration } = record;
  const shouldDisplayDelete =
    UserStore.data.accountType !== USER_COACH ||
    UserStore.data._id === record.creator._id;

  return (
    <li className="scheduled-record">
      <div className="scheduled-record__avatar">
        <Avatar
          avatar={avatar}
          firstName={firstName}
          lastName={lastName}
          avatarBgColor={avatarBgColor}
        />
      </div>
      <div className="scheduled-record__content">
        <h4 className="scheduled-record__content__title">{record.title}</h4>
        <div className="scheduled-record__content__timing">
          <span>{date}</span>
          <span>{hour}</span>
          <span>{teamName}</span>
          <span>{convertMinutesToHoursAndMinutes(duration)}</span>
          {shouldDisplayDelete ? (
            <button
              type="button"
              className="scheduled-record__content__delete"
              onClick={onDelete}
            >
              <img
                src={deleteIcon}
                className="scheduled-record__content__delete__image"
              />
            </button>
          ) : null}
        </div>
      </div>
    </li>
  );
}

class RecordScheduler extends Component {
  state = {
    isFormOpen: false,
    showConfirmModal: false,
    tempSelectedScheduledRecord: null
  };

  componentDidMount() {
    const { SchedulerStore } = this.props;
    SchedulerStore.fetchAndSetTasks();
  }

  shouldComponentUpdate() {
    return true;
  }

  toggleForm = (isOpen = null) => {
    this.setState(state => ({
      isFormOpen: isOpen !== null ? isOpen : !state.isFormOpen
    }));
  };

  attemptToDeleteRecord = task => {
    this.setState({
      showConfirmModal: true,
      tempSelectedScheduledRecord: task
    });
  };

  handleDelete = () => {
    const { SchedulerStore } = this.props;
    const { tempSelectedScheduledRecord } = this.state;

    SchedulerStore.deleteScheduledTask(tempSelectedScheduledRecord._id);
    this.setState({
      showConfirmModal: false
    });
  };

  render() {
    const { isFormOpen, showConfirmModal } = this.state;
    const { UserStore, SchedulerStore } = this.props;

    return (
      <div className="record-scheduler">
        <AddScheduledRecord
          creator={UserStore.data}
          isOpen={isFormOpen}
          onToggle={this.toggleForm}
        />
        {SchedulerStore.tasks && !isFormOpen ? (
          <ul className="record-scheduler__records-list">
            {SchedulerStore.tasks.map(schedule => (
              <ScheduledRecord
                key={schedule._id}
                record={schedule}
                onDelete={() => this.attemptToDeleteRecord(schedule)}
                UserStore={UserStore}
              />
            ))}
          </ul>
        ) : null}

        {showConfirmModal ? (
          <ConfirmModal
            onDismiss={() => {
              this.setState({ showConfirmModal: false });
            }}
            onApprove={this.handleDelete}
            title="Are sure you want to delete this record?"
          />
        ) : null}
      </div>
    );
  }
}

RecordScheduler.propTypes = {
  SchedulerStore: PropTypes.object.isRequired,
  UserStore: PropTypes.object.isRequired
};

ScheduledRecord.propTypes = {
  onDelete: PropTypes.func,
  record: PropTypes.object.isRequired
};

ScheduledRecord.defaultProps = {
  onDelete: () => {}
};

export default inject("SchedulerStore", "UserStore")(observer(RecordScheduler));

import React, { Component } from "react";
import PropTypes from 'prop-types';
import { Input } from "@myplay/ui";
import { Field } from "react-final-form";
import I18N from "../../../I18N";
import { StepHeader } from '../../../components';

class StepName extends Component {
  static propTypes = {
    isActive: PropTypes.bool,
    isNextStepAllowed: PropTypes.func,
  };

  static defaultProps = {
    isActive: false,
    isNextStepAllowed: ()=>{},
  };

  state = {
    isFirstNameValid: false,
    isLastNameValid: false,
    isStepValid: false
  };

  validateFirstName = value => {
    this.setState(
      {
        isFirstNameValid: value && value.length >= 2
      },
      () => this.checkStepValidation()
    );
    return value && value.length >= 2 ? undefined : I18N.t("ERROR_SHORT_NAME");
  };

  validateLastName = value => {
    this.setState(
      {
        isLastNameValid: value && value.length >= 2
      },
      () => this.checkStepValidation()
    );
    return value && value.length >= 2 ? undefined : I18N.t("ERROR_SHORT_NAME");
  };

  checkStepValidation = () => {
    const { isFirstNameValid, isLastNameValid } = this.state;
    const { isNextStepAllowed } = this.props;
    this.setState(
      {
        isStepValid: isFirstNameValid && isLastNameValid
      },
      () => isNextStepAllowed(this.state.isStepValid)
    );
  };

  render() {
    const { isActive } = this.props;
    return (
      <div className="register__form__step">
        <StepHeader lines={[I18N.t("WHAT_IS"),I18N.t("YOUR_NAME")]}/>
        <div className="register__form__step__fields">
          <Field
            className="register__form__step__fields__field"
            name="firstName"
            validate={isActive ? this.validateFirstName : null}
          >
            {({ input, meta }) => (
              <Input
                {...input}
                type="text"
                label={I18N.t("FIRST_NAME")}
                data-testid="firstName"
                error={
                  (!meta.pristine && meta.error) || meta.submitFailed
                    ? meta.error
                    : ""
                }
                tabIndex={0}
              />
            )}
          </Field>
          <Field
            className="register__form__fields__field"
            name="lastName"
            validate={isActive ? this.validateLastName : null}
          >
            {({ input, meta }) => (
              <Input
                {...input}
                type="text"
                label={I18N.t("LAST_NAME")}
                error={
                  (!meta.pristine && meta.error) || meta.submitFailed
                    ? meta.error
                    : ""
                }
                tabIndex={0}
                data-testid="lastName"
                required
              />
            )}
          </Field>
        </div>
      </div>
    );
  }
}

export default StepName;

const ROUTE_PREFIX = "/app";
const withPrefix = route => `${ROUTE_PREFIX}${route}`;

const HOST = location.origin; // eslint-disable-line

const IS_LOCAL_HOST = HOST.includes("localhost");

const TEAM_CHANNEL_GAMES_BULK_SIZE = 30;

const LOGIN_ROUTE_NAME = "/login";
const REGISTER_ROUTE_NAME = "/register";
const FORGOT_PASSWORD_ROUTE_NAME = "/reset-password";
const PUBLIC_LIVE_ROUTE_NAME = "/public-live";
const PUBLIC_HIGHLIGHT_ROUTE_NAME = "/public-highlight";
const CLEAR_ROUTE_NAME = '/clear';
const TEAM_CHANNEL_ROUTE_NAME = withPrefix("/team-channel");
const SETTINGS_ROUTE_NAME = withPrefix("/settings");
const PLAYER_PROFILE_ROUTE_NAME = withPrefix("/player-profile");
const LIVE_ROUTE_NAME = withPrefix("/live");
const GAME_ROUTE_NAME = withPrefix("/game");
// const MYPLAY_LOGIN_URL = IS_LOCAL_HOST ? "http://localhost:3002" : "https://id.myplay.com";
const MYPLAY_LOGIN_URL = "https://id.myplay.com";
const MYPLAY_APP_URL = IS_LOCAL_HOST ? "http://localhost:3000" : "https://app.myplay.com";

const PUBLIC_LIVE_SHARE_LINK = `${process.env.REACT_APP_SHARE_URL}${PUBLIC_LIVE_ROUTE_NAME}`;
const PUBLIC_HIGHLIGHT_SHARE_LINK = `${
  process.env.REACT_APP_SHARE_URL
}${PUBLIC_HIGHLIGHT_ROUTE_NAME}`;

const VISIBLE_ERROR = "VisibleError";
const UNAUTH_ERROR = "UnauthError";

const ACCESS_TOKEN = "accessToken";
const REFRESH_TOKEN = "refreshToken";
const USER_DATA = "userData";
const CURRENT_TEAM = "currentTeam";
const START_LIVE_VIEW = "startLiveView";
const CHECK_IS_LIVE_READY = "checkLiveIsReady";

const TOAST_SUCCESS_TYPE = "TOAST/SUCCESS";
const TOAST_ERROR_TYPE = "TOAST/ERROR";
const TOAST_WARNING_TYPE = "TOAST/WARNING";

const GREEN_LIGHT = "#16c4ab";

const DONUTS_COLORS = [
  { start: "#ffdf87", end: "#f7be0f" },
  { start: "#f77b65", end: "#cb2f28" },
  { start: "#97c2c4", end: "#00a7ad" }
];

// RECORD
const READY_TO_RECORD = "READY_TO_RECORD";
const RECORDING = "RECORDING";
const ERROR = "ERROR";

// user types
const USER_PLAYER = "player";
const USER_COACH = "coach";
const USER_OPERATOR = "operator";
const USER_ENABLER = "enabler";

// Register

const NAME = "name";
const COUNTRY = "country";
const BIRTHDATE = "birthdate";
const REGISTRATION_METHOD = "registrationMethod";
const PHONE = "phone";
const EMAIL = "email";
const VERIFICATION_CODE = "verificationCode";
const TEAM_CODE = "teamCode";
const PASSWORD = "password";
const TERMS_OF_SERVICE = "termsOfService";
const NEW_PASSWORD = "newPassword";

// FACEBOOK ANALYTICS

const ANALYTICS_GAME_RECORD = "Game-Record";
const ANALYTICS_TRAINING_RECORD = "Training-Record";
const ANALYTICS_LIVE_VIEW = "Live-View";
const ANALYTICS_SHARE_LIVE = "Shared-Live-View";
const ANALYTICS_SWITCH_TEAM = "Switch-Team";
const ANALYTICS_UPDATE_PROFILE = "Update-Profile";
const ANALYTICS_PUBLIC_LIVE_VIEWS = "Public-Live-Views";
const ANALYTICS_COACH_HIGHLIGHT_RECORD = "Coach-Highlight-Record";
const ANALYTICS_PLAYER_HIGHLIGHT_RECORD = "Player-Highlight-Record";
const ANALYTICS_DISCARD_HIGHLIGHT = "Discard-Highlight";
const ANALYTICS_NEW_HIGHLIGHT_ADD_COMMENT = "New-Highlight-Add-Comment";
const ANALYTICS_SAVED_HIGHLIGHT = "Save-Recorded-Highlight";
const ANALYTICS_DISCARD_RECORDED_HIGHLIGHT = "Discard-Recorded-Highlight";
const ANALYTICS_SHARED_COACH_HIGHLIGHT = "Coach-Shared-Highlight";
const ANALYTICS_SHARED_HIGHLIGHT = "Shared-highlight";
const ANALYTICS_LOGIN = "Login";
const ANALYTICS_SIGNUP = "Sign-Up";
const ANALYTICS_REGISTRATION_FIRST_STEP = "Registartion-Step-1";
const ANALYTICS_REGISTRATION_SECOND_STEP = "Registartion-Step-2";
const ANALYTICS_REGISTER = "Register";
const ANALYTICS_FORGOT_PASSWORD = "Forgot-Password";
const ANALYTICS_CLIP_CREATED = "Clip-Created";
const ANALYTICS_STATS_SWITCH_PLAYER = "Stats-Switch-Player";
const ANALYTICS_STATS_TABLE_ROW_CLICKED = "Stats-Table-Row-Clicked";
const ANALYTICS_STATS_MAP_EVENT_CLICKED = "Stats-Map-Event-Clicked";
const ANALYTICS_BREAKDOWNS_CLIP_CREATED = "Breakdown-Make-Clip";
const ANALYTICS_BREAKDOWNS_FILTER_EVENTS = "Breakdown-Filter-Events";
const ANALYTICS_BREAKDOWNS_VIEW_EVENT = "Breakdown-View-Event";
const ANALYTICS_HIGHLIGHTS_HIGHLIGHT_VIEW = "Highlight-View";
const ANALYTICS_HIGHLIGHTS_CLIP_VIEW = "Clip-View";
const ANALYTICS_EDIT_MODAL_COMMENT = "Highlight-View-Modal-Add-Comment";
const ANALYTICS_EDIT_MODAL_TAG = "Highlight-View-Modal-Add-Tag";
const ANALYTICS_EDIT_MODAL_TAG_PLAYER = "Highlight-View-Modal-Tag-Player";
const ANALYTICS_EDIT_MODAL_DOWNLOAD = "Highlight-View-Modal-Download";
const ANALYTICS_EDIT_MODAL_DELETE_HIGHLIGHT =
  "Highlight-View-Modal-Delete-Highlight";
const ANALYTICS_EDIT_MODAL_DELETE_CLIP = "Highlight-View-Modal-Delete-Clip";
export const ANALYTICS_RECORD_SCHEDULED = "Record-Scheduled";
export const ANALYTICS_RECORD_SCHEDULED_DELETE = "Record-Schedule-Deleted";

// CATEGORIES

const SOCCER = "soccer";
const BASKETBALL = "basketball";
const HANDBALL = "handball";
const TENNIS = "tennis";
const VOLLYBALL = "vollyball";

// CAMERA TYPES

const SCORE = "SCORE";

// STATUSES

const ON_EDIT = "ON_EDIT";

// LOCAL STORAGE

const localStoragePrefix = property => `MYPLAY/${property}`;
const LS_ACCESS_TOKEN = localStoragePrefix(ACCESS_TOKEN);
const LS_REFRESH_TOKEN = localStoragePrefix(REFRESH_TOKEN);
const LS_USER_DATA = localStoragePrefix(USER_DATA);
const LS_CURRENT_TEAM = localStoragePrefix(CURRENT_TEAM);

const UNAUTH_ROUTES = [
  CLEAR_ROUTE_NAME,
  REGISTER_ROUTE_NAME,
  FORGOT_PASSWORD_ROUTE_NAME,
  PUBLIC_HIGHLIGHT_ROUTE_NAME,
  PUBLIC_LIVE_ROUTE_NAME
];

export {
  LOGIN_ROUTE_NAME,
  REGISTER_ROUTE_NAME,
  TEAM_CHANNEL_ROUTE_NAME,
  FORGOT_PASSWORD_ROUTE_NAME,
  LIVE_ROUTE_NAME,
  SETTINGS_ROUTE_NAME,
  GAME_ROUTE_NAME,
  TOAST_SUCCESS_TYPE,
  TOAST_ERROR_TYPE,
  TOAST_WARNING_TYPE,
  VISIBLE_ERROR,
  UNAUTH_ERROR,
  ACCESS_TOKEN,
  REFRESH_TOKEN,
  USER_DATA,
  CURRENT_TEAM,
  GREEN_LIGHT,
  ANALYTICS_GAME_RECORD,
  DONUTS_COLORS,
  PLAYER_PROFILE_ROUTE_NAME,
  READY_TO_RECORD,
  RECORDING,
  ERROR,
  PUBLIC_LIVE_SHARE_LINK,
  PUBLIC_HIGHLIGHT_SHARE_LINK,
  USER_PLAYER,
  USER_COACH,
  ANALYTICS_TRAINING_RECORD,
  ANALYTICS_LIVE_VIEW,
  ANALYTICS_SHARE_LIVE,
  ANALYTICS_SWITCH_TEAM,
  ANALYTICS_UPDATE_PROFILE,
  ANALYTICS_PUBLIC_LIVE_VIEWS,
  ANALYTICS_COACH_HIGHLIGHT_RECORD,
  ANALYTICS_PLAYER_HIGHLIGHT_RECORD,
  ANALYTICS_DISCARD_HIGHLIGHT,
  ANALYTICS_SHARED_COACH_HIGHLIGHT,
  ANALYTICS_SHARED_HIGHLIGHT,
  ANALYTICS_LOGIN,
  ANALYTICS_SIGNUP,
  ANALYTICS_REGISTRATION_FIRST_STEP,
  ANALYTICS_REGISTRATION_SECOND_STEP,
  ANALYTICS_REGISTER,
  ANALYTICS_FORGOT_PASSWORD,
  ANALYTICS_CLIP_CREATED,
  NAME,
  COUNTRY,
  BIRTHDATE,
  REGISTRATION_METHOD,
  PHONE,
  EMAIL,
  VERIFICATION_CODE,
  TEAM_CODE,
  PASSWORD,
  TERMS_OF_SERVICE,
  NEW_PASSWORD,
  TEAM_CHANNEL_GAMES_BULK_SIZE,
  BASKETBALL,
  VOLLYBALL,
  SOCCER,
  HANDBALL,
  TENNIS,
  ANALYTICS_STATS_SWITCH_PLAYER,
  ANALYTICS_STATS_TABLE_ROW_CLICKED,
  ANALYTICS_STATS_MAP_EVENT_CLICKED,
  ANALYTICS_BREAKDOWNS_CLIP_CREATED,
  ANALYTICS_BREAKDOWNS_FILTER_EVENTS,
  ANALYTICS_BREAKDOWNS_VIEW_EVENT,
  ANALYTICS_HIGHLIGHTS_HIGHLIGHT_VIEW,
  ANALYTICS_HIGHLIGHTS_CLIP_VIEW,
  ANALYTICS_EDIT_MODAL_COMMENT,
  ANALYTICS_EDIT_MODAL_TAG,
  ANALYTICS_EDIT_MODAL_TAG_PLAYER,
  ANALYTICS_EDIT_MODAL_DOWNLOAD,
  ANALYTICS_EDIT_MODAL_DELETE_HIGHLIGHT,
  ANALYTICS_EDIT_MODAL_DELETE_CLIP,
  ANALYTICS_DISCARD_RECORDED_HIGHLIGHT,
  ANALYTICS_NEW_HIGHLIGHT_ADD_COMMENT,
  ANALYTICS_SAVED_HIGHLIGHT,
  SCORE,
  ON_EDIT,
  USER_OPERATOR,
  MYPLAY_APP_URL,
  MYPLAY_LOGIN_URL,
  UNAUTH_ROUTES,
  PUBLIC_LIVE_ROUTE_NAME,
  PUBLIC_HIGHLIGHT_ROUTE_NAME,
  CLEAR_ROUTE_NAME, 
  LS_ACCESS_TOKEN, 
  LS_CURRENT_TEAM,
  LS_REFRESH_TOKEN,
  LS_USER_DATA,
  START_LIVE_VIEW,
  CHECK_IS_LIVE_READY,
  USER_ENABLER
};

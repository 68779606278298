import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Router } from "react-router-dom";
import { Notifyer } from "@myplay/ui";
import { inject, observer } from "mobx-react";
import "react-day-picker/lib/style.css"; // eslint-disable-line
import { utils } from "@myplay/all";
import "./App.scss";
import history from "../../utils/history";
import { ScrollToTop } from "../../components";
import MakeMainRoutes from "../../makeRoutes";
import Maintenance from "../Maintenance";

import updateUploadRequest from "../../utils/updateUploadRequest";
import NoInternetConnection from "../NoInternetConnection";
import noInternet from "../../assets/images/no-internet.png";

const INTERNET_CONNECTION_INTERVAL = 2000;
const CHECK_INTERNET_COUNTER = 3;
class App extends React.Component {
  state = {
    isFinishLoading: false,
    isSiteOnline: true,
    hasInternetConnection: true //eslint-disable-line
  };

  checkInterval = null;

  componentDidMount() {
    const { NotificationsStore } = this.props;
    this.checkInternetConnection(true);
    setTimeout(() => {
      this.setState({
        isFinishLoading: true
      });
    }, 300);

    window.addEventListener("unload", async () => {
      const uploadTasks = NotificationsStore.getInUploadProcessTask();
      uploadTasks.map(task => updateUploadRequest(task.requestId));
    });

    window.addEventListener("online", async () => {
      await this.checkInternetConnection(true);
    });
    window.addEventListener("offline", async () => {
      await this.checkInternetConnection(false);
      console.log("offline");
    });
    this.confirmClosing();
  }

  checkInternetConnection = async hasConnectionByEvent => new Promise(res => {
    const self = this;
    self.hasInternetConnection = hasConnectionByEvent;
    let count = 0;
    const checkInterval = setInterval(async () => {
      if (count < CHECK_INTERNET_COUNTER) {
        self.hasInternetConnection = await utils.checkInternetConnection();
        count++;
      } else {
        clearInterval(checkInterval);
        if (self.hasInternetConnection !== self.state.hasInternetConnection) {
          self.setState({
            hasInternetConnection: self.hasInternetConnection
          });

          if (self.hasInternetConnection) {
            const isSiteOnline = await utils.healthCheck();
            this.setState({ isSiteOnline });
          }
        }
        res();
      }
    }, INTERNET_CONNECTION_INTERVAL);
  });

  componentWillUnmount() {
    if (
      window.location.protocol !== "https:" &&
      !window.location.hostname.includes("localhost")
    ) {
      window.location.replace(
        `https:${window.location.href.substring(
          window.location.protocol.length
        )}`
      );
    }
  }

  confirmClosing = () => {
    const { NotificationsStore } = this.props;
    window.addEventListener("beforeunload", ev => {
      if (
        NotificationsStore.getInUploadProcessTask() &&
        NotificationsStore.getInUploadProcessTask().length > 0
      ) {
        ev.preventDefault();
      }
    });
  };

  render() {
    const { UserStore } = this.props;
    const { isFinishLoading, isSiteOnline, hasInternetConnection } = this.state;

    return !hasInternetConnection ? (
      <NoInternetConnection noInternetLogo={noInternet} />
    ) : (
      <Fragment>
        <img src={noInternet} style={{ display: "none" }} />
        {!isSiteOnline ? (
          <Maintenance />
        ) : (
          <Router history={history}>
            <Notifyer>
              <ScrollToTop>
                {UserStore.isInitFinished ? (
                  <div
                    className="app"
                    style={{
                      visibility: isFinishLoading || true ? "visible" : "hidden"
                    }}
                  >
                    <MakeMainRoutes />
                  </div>
                ) : (
                  <div />
                )}
              </ScrollToTop>
            </Notifyer>
          </Router>
        )}
      </Fragment>
    );
  }
}

App.propTypes = {
  UserStore: PropTypes.shape().isRequired,
  NotificationsStore: PropTypes.shape().isRequired
};

export default inject("UserStore", "NotificationsStore")(observer(App));

import React, { lazy, Suspense } from "react";
import "./index.scss";
import { Route, Switch, Redirect } from "react-router-dom";
import {
  LOGIN_ROUTE_NAME,
  REGISTER_ROUTE_NAME,
  TEAM_CHANNEL_ROUTE_NAME,
  PLAYER_PROFILE_ROUTE_NAME,
  LIVE_ROUTE_NAME,
  GAME_ROUTE_NAME,
  FORGOT_PASSWORD_ROUTE_NAME, 
  MYPLAY_LOGIN_URL,
  MYPLAY_APP_URL,
  CLEAR_ROUTE_NAME,
  PUBLIC_HIGHLIGHT_ROUTE_NAME,
  PUBLIC_LIVE_ROUTE_NAME
} from "./utils/constants";
import {
  AppLayout,
  UnauthRoute,
  AuthenticationGuard,
  MobileBlock
} from "./components/";
import {
  Register,
  Live,
  Game,
  PublicLive,
  PlayerProfile,
  PublicHighlight,
  ForgotPassword,
} from "./scenes/";
import I18N from "./I18N";
import PageNotFound from "./components/PageNotFound/PageNotFound";

const TeamChannel = lazy(() => import("./scenes/TeamChannel"));
const Login = ({ route })=> {
  window.location.href = route;
  return null;
}

/* eslint-disable react/jsx-no-literals */
const MakeMainRoutes = () => {
  return (
    <Suspense fallback={<div>{I18N.t("LOADING")}</div>}>
      <Switch>
        <UnauthRoute path={REGISTER_ROUTE_NAME} Component={Register} />
        <UnauthRoute path={LOGIN_ROUTE_NAME} Component={()=> Login({ route: `${MYPLAY_LOGIN_URL}/login/?redirect=${MYPLAY_APP_URL}` })} />
        <UnauthRoute
          path={FORGOT_PASSWORD_ROUTE_NAME}
          Component={ForgotPassword}
        />
        <Route path={PUBLIC_LIVE_ROUTE_NAME} component={PublicLive} />
        <Route path={PUBLIC_HIGHLIGHT_ROUTE_NAME} component={PublicHighlight} />
        <Route path={CLEAR_ROUTE_NAME} component={()=> Login({ route: `${MYPLAY_LOGIN_URL}/logout` })} />

        <MobileBlock>
          <AppLayout>
            <AuthenticationGuard>
              <Switch>
                <Route
                  path={TEAM_CHANNEL_ROUTE_NAME}
                  component={TeamChannel}
                  exact
                />
                <Route
                  path={`${PLAYER_PROFILE_ROUTE_NAME}/:id`}
                  component={({ match }) => (
                    <PlayerProfile playerId={match.params.id} />
                  )}
                />
                <Route path={LIVE_ROUTE_NAME} component={Live} />
                <Route
                  path={`${GAME_ROUTE_NAME}/:id`}
                  component={({ match }) => <Game videoId={match.params.id} />}
                />
                <Route component={() => <PageNotFound />} />
              </Switch>
            </AuthenticationGuard>
          </AppLayout>
        </MobileBlock>
        <Redirect from="/" to={`${MYPLAY_LOGIN_URL}/login/?redirect=${MYPLAY_APP_URL}`} />
      </Switch>
    </Suspense>
  );
};

export default MakeMainRoutes;

import React from "react";
import PropTypes from "prop-types";

import "./KeyValueDetail.scss";

const KeyValueDetail = ({ label, value }) => {
  return (
    <span className="key-value-detail">
      <span className="key-value-detail__value">{label}</span>
      {value}
    </span>
  );
};

KeyValueDetail.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any
};

KeyValueDetail.defaultProps = {
  label: "",
  value: ""
};

export default KeyValueDetail;

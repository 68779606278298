
import React, { Component } from "react";
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { utils } from '@myplay/all';
import { Input } from '@myplay/ui';
import I18N from '../../../I18N';
import { StepHeader } from '../../../components';

class StepEmail extends Component  {
  static propTypes = {
    isActive: PropTypes.bool,
    isNextStepAllowed: PropTypes.func,
    onHandleCurrentStep: PropTypes.func,
    toggleIsServerValidationNeeded: PropTypes.func,
  }

  static defaultProps = {
    isActive: false,
    isNextStepAllowed: ()=>{},
    onHandleCurrentStep: ()=>{},
    toggleIsServerValidationNeeded: ()=>{},
  }

  componentDidMount() {
    const { isActive } = this.props;

    if (isActive) {
      const { onHandleCurrentStep, toggleIsServerValidationNeeded } = this.props;
      onHandleCurrentStep('email');
      toggleIsServerValidationNeeded(true);
    }
  };

  componentDidUpdate(prevProps) {
    const { isActive, toggleIsServerValidationNeeded, onHandleCurrentStep } = this.props;

    if (isActive && prevProps.isActive !== isActive) {
      toggleIsServerValidationNeeded(true);
      onHandleCurrentStep('email');
    }
  }

  validateEmail = email => {
    const { isNextStepAllowed, isActive } = this.props;
    const isValidMail = utils.isValidEmail(email || "");

    if(isActive) {
      isNextStepAllowed(isValidMail);
    }
    return isValidMail ? undefined : I18N.t("ERROR_INVALID_EMAIL");
  }

  render() {
    return (
      <div className="register__form__step">
        <StepHeader lines={[I18N.t("WHATS_YOUR"),I18N.t("EMAIL")]}/>
        <div className="register__form__step__fields">
          <Field
            className="register__form__step__fields__field"
            name="email"
            data-testid="email"
            validate={this.validateEmail}
          >
            {({ input, meta }) => (
              <Input
                {...input}
                type="text"
                label={I18N.t("EMAIL")}
                error={
                  (!meta.pristine && meta.error) || meta.submitFailed
                    ? meta.error
                    : ""
                }
                data-testid="email"
                tabIndex={-1}
                required
              />
            )}
          </Field>
        </div>
      </div>
    );
  }

};

export default StepEmail;

import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import { LivePlayer, LiveError, toast, Loader } from "@myplay/ui";
import dayjs from "dayjs";

import {
  LiveSideMenu,
  PreLiveMenu,
  LiveProgress,
  UserTypeGuard,
  AnimatedScene
} from "../../components";
import SetFacebookLive from "./SetFacebookLive";
import I18N from "../../I18N";
import { initClientAndGetStream } from "../../utils/googleClient";
import CameraSwitcherButton from "./CameraSwitcherButton";
import {
  TEAM_CHANNEL_ROUTE_NAME,
  USER_OPERATOR,
  USER_COACH,
  START_LIVE_VIEW,
  CHECK_IS_LIVE_READY,
  USER_ENABLER
} from "../../utils/constants";
import RecentBooking from "./RecentBooking";
import StreamingDetailsTable from "./StreamingDetailsTable";
import "./Live.scss";

const PRE_LIVE = "preLive";
const PROGRESS = "progress";
const LIVE_ACTIVE = "liveActive";
const FACEBOOK = "facebook";
const ERROR = "liveError";

// const mockData = [
//   { type: "mic", url: "http://demo.mic.com" },
//   { type: "Tracking", url: "http://demo.tracking.com" }
// ];

class Live extends Component {
  progressInterval = null;

  state = {
    progress: 0,
    currentDisplayComponent: PRE_LIVE,
    isFacebookLive: false,
    cameraPosition: null,
    isStreamingDataShowing: false
  };

  async componentDidMount() {
    const { MetaStore, SiteStore, BookingsStore } = this.props;
    MetaStore.toggleHeaderOff();
    SiteStore.setLiveError(null);
    this.getCameraPosition();
    await SiteStore.checkLiveStatus();
    await SiteStore.checkIsRemoteProducer();

    await BookingsStore.getRecentBooking();

    if (
      SiteStore.pendingRequests.includes(CHECK_IS_LIVE_READY) ||
      SiteStore.pendingRequests.includes(START_LIVE_VIEW)
    ) {
      this.startProgress();
    }
  }

  componentDidUpdate() {
    const { SiteStore } = this.props;
    const {
      currentDisplayComponent,
      progress,
      isFacebookLive,
      cameraPosition
    } = this.state;
    const isLive = SiteStore.getIsLive();

    if (
      SiteStore.isOnline &&
      progress === 0 &&
      isLive &&
      !isFacebookLive &&
      currentDisplayComponent !== LIVE_ACTIVE
    ) {
      this.startProgress();
    }

    if (!cameraPosition && isLive) {
      this.getCameraPosition();
    }

    if (
      ((SiteStore.isCheckDone && !SiteStore.isOnline) || SiteStore.liveError) &&
      currentDisplayComponent !== ERROR
    ) {
      this.setState({
        currentDisplayComponent: ERROR
      });
    }
  }

  componentWillUnmount() {
    const { SiteStore } = this.props;

    clearInterval(this.progressInterval);
    SiteStore.setLiveError(null);
  }

  startProgress = () => {
    clearInterval(this.progressInterval);
    this.setState({
      progress: 1,
      currentDisplayComponent: PROGRESS
    });
    this.progrssInterval = setInterval(() => {
      this.changeProgress();
    }, 1500);
  };

  changeProgress = async () => {
    const { SiteStore } = this.props;
    if (SiteStore.isLiveReady) {
      clearInterval(this.progrssInterval);
      this.progrssInterval = setInterval(() => {
        this.changeProgress();
      }, 10);
    }
    if (this.state.progress < 100) {
      this.setState(prevState => ({ progress: prevState.progress + 1 }));
    } else {
      SiteStore.setLiveReady(true);
      this.setState({
        currentDisplayComponent: LIVE_ACTIVE
      });

      clearInterval(this.progrssInterval);
    }
  };

  handleMyplayLive = () => {
    const { SiteStore } = this.props;
    this.startProgress();
    SiteStore.startLiveView();
  };

  handleCancelLive = () => {
    const { SiteStore } = this.props;

    SiteStore.handleCancelLive({
      handleCancelOnPage: () => {
        clearInterval(this.progrssInterval);
        this.setState({
          progress: 0,
          currentDisplayComponent: PRE_LIVE
        });
      }
    });
  };

  getLiveId = () => {
    const { SiteStore } = this.props;
    if (SiteStore && SiteStore.liveState && SiteStore.liveState.sessionId) {
      return SiteStore.liveState.sessionId;
    }
    return null;
  };

  handleFacebookLive = async () => {
    window.open("https://www.facebook.com/live/create", "_blank");
    this.setState({ currentDisplayComponent: FACEBOOK });
  };

  handleOpenFacebookLiveModal = async streamKey => {
    const { SiteStore } = this.props;
    this.setState({ progress: 0, isFacebookLive: true });
    SiteStore.setIsLive(true);
    this.startProgress();
    const facebookRtmp = `rtmps://live-api-s.facebook.com:443/rtmp/${streamKey}`;
    await SiteStore.startLiveView(null, facebookRtmp);
  };

  handleYoutubeLive = async () => {
    const { SiteStore } = this.props;
    try {
      const { SiteStore } = this.props;

      SiteStore.setIsLive(true);
      const { name } = SiteStore;
      const date = dayjs(new Date()).format("YYYY-MM-DD HH:mm");
      const title = `Myplay Live ${name} ${date}`;
      this.startProgress();
      const { streamName, liveBroadcastId } = await initClientAndGetStream({
        title: title
      });
      const youTubeShareLink = `https://youtu.be/${liveBroadcastId}`;
      const thirdPartyProxyUri = `rtmp://a.rtmp.youtube.com/live2/${streamName}`;
      await SiteStore.startLiveView(null, thirdPartyProxyUri, youTubeShareLink);
    } catch (e) {
      toast.error(`LIVE Error: ${e.message}`);
      await SiteStore.cancelLive();
    }
  };

  getYoutubeShareLink = () => {
    const { SiteStore } = this.props;
    if (SiteStore && SiteStore.liveState) {
      return SiteStore.liveState.youTubeShareLink;
    }

    return null;
  };

  handleSwitchCamera = async () => {
    const { SiteStore } = this.props;
    SiteStore.handleSwitchLiveCamera(this.startProgress);
  };

  getCameraPosition = () => {
    const { liveState, cameras } = this.props.SiteStore;
    const cameraIndex = (liveState && liveState.currentStreamingIndex) || 0;
    const cameraPosition = cameras ? cameras[cameraIndex].position : null;

    this.setState({
      cameraPosition
    });
  };

  handleErrorScreenRetry = () => {
    this.setState({
      currentDisplayComponent: PRE_LIVE
    });
  };

  render() {
    const {
      currentDisplayComponent,
      progress,
      cameraPosition,
      isStreamingDataShowing
    } = this.state;
    const {
      SiteStore: {
        name,
        isCheckDone,
        hasTrackingCamera,
        liveError,
        isRemoteProducer,
        getInternalStreams,
        internalStreams
      },
      BookingsStore: { recentBooking, daysForNextLive, hoursForNextLive },
      UserStore: { data: isAdminUser }
    } = this.props;
    const liveId = this.getLiveId();
    const youTubeShareLink = this.getYoutubeShareLink();

    return (
      <AnimatedScene>
        {({ handleBack }) => (
          <UserTypeGuard
            to={TEAM_CHANNEL_ROUTE_NAME}
            allowed={[USER_OPERATOR, USER_COACH, USER_ENABLER]}
            withRedirect
          >
            <div className="live">
              <div className="live__header">
                <button onClick={handleBack}>
                  <div className="live__header__arrow" />
                </button>
              </div>
              <div className="live__main-container">
                <div className="live__main-container__side-menu">
                  <LiveSideMenu
                    showTitle={!recentBooking}
                    youTubeShareLink={youTubeShareLink}
                    hasDarkOverlay={currentDisplayComponent !== LIVE_ACTIVE}
                    liveId={liveId}
                    isLiveActive={currentDisplayComponent === LIVE_ACTIVE}
                  />
                  {currentDisplayComponent === LIVE_ACTIVE &&
                    (isStreamingDataShowing ? (
                      <StreamingDetailsTable streamingData={internalStreams} />
                    ) : (
                      <span
                        className="live__main-container__side-menu__streaming-details-button"
                        onClick={async () => {
                          await getInternalStreams();
                          this.setState({ isStreamingDataShowing: true });
                        }}
                      >
                        Show Streaming Details
                      </span>
                    ))}
                </div>
                <div className="live__main-container__video-container">
                  <div className="live__main-container__video-container__video">
                    {currentDisplayComponent === PRE_LIVE ? (
                      (isRemoteProducer && !isAdminUser) ? (
                        recentBooking ? (
                          <RecentBooking
                            bookingData={recentBooking}
                            isEditable={false}
                          />
                        ) : (
                          <div className="live__counter__loader">
                            <p>{I18N.t("GETTING_READY")}</p>
                            <Loader />
                          </div>
                        )
                      ) : (
                        <PreLiveMenu
                          onMyplay={this.handleMyplayLive}
                          onFacebook={this.handleFacebookLive}
                          onYoutube={this.handleYoutubeLive}
                          isCheckDone={isCheckDone}
                        />
                      )
                    ) : null}
                    {currentDisplayComponent === PROGRESS && (
                      <LiveProgress progress={progress} name={name} />
                    )}
                    {currentDisplayComponent === LIVE_ACTIVE && (
                      <Fragment>
                        {hasTrackingCamera === false && (
                          <CameraSwitcherButton
                            onSwitch={this.handleSwitchCamera}
                            cameraSide={cameraPosition}
                          />
                        )}
                        <LivePlayer liveId={liveId} />
                      </Fragment>
                    )}
                    {currentDisplayComponent === FACEBOOK && (
                      <SetFacebookLive
                        onClick={this.handleOpenFacebookLiveModal}
                      />
                    )}
                    {currentDisplayComponent === ERROR && (
                      <LiveError
                        error={liveError}
                        hasRetryButtons={
                          LiveError === I18N.t("LIVE_SITE_ERROR")
                        }
                        onRetry={this.handleErrorScreenRetry}
                        onQuit={handleBack}
                      />
                    )}
                  </div>
                  {currentDisplayComponent !== PRE_LIVE && currentDisplayComponent !== ERROR && (
                    <div>
                      <button
                        className="live__main-container__video-container__cancel"
                        onClick={this.handleCancelLive}
                      >
                        {I18N.t("CANCEL_LIVE_VIEW")}
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </UserTypeGuard>
        )}
      </AnimatedScene>
    );
  }
}

export default inject("MetaStore", "SiteStore", "BookingsStore", "UserStore")(
  observer(Live)
);

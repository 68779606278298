import React from "react";
import PropTypes from "prop-types";

import I18N from "../../I18N";
import "./Maintenance.scss";
import maintenance from "../../assets/images/maintenence-01.png";
import logo from "../../assets/images/logo-name.png";

const Maintenance = () => {
  return (
    <div className="maintenance">
      <img src={logo} className="maintenance__logo" />
      <p>{I18N.t("TIME_OUT")}</p>
      <p className="maintenance__secondary-title">
        {I18N.t("WILL_BE_BACK_ON_COURT_SHORTLY")}
      </p>
      <img src={maintenance} />
    </div>
  );
};

export default Maintenance;

// eslint-disable-next-line no-unused-vars
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import scrollTop from "../../utils/scroll-top";

class ScrollToTop extends Component {
  shouldComponentUpdate() {
    return true;
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      scrollTop();
    }
  }

  render() {
    return this.props.children;
  }
}

ScrollToTop.propTypes = {
  children: PropTypes.node,
  location: PropTypes.shape().isRequired
};

ScrollToTop.defaultProps = {
  children: null
};

export default withRouter(ScrollToTop);

import React from "react";
import PropTypes from "prop-types";
import { SuccessButton, DismissButton, toast } from "@myplay/ui";
import { inject, observer } from "mobx-react";

import "./ShareGame.scss";
import copyToClipboard from "../../../utils/copyToClipboard";
import I18N from "../../../I18N";

const ShareGameModal = ({ link, thumbnail, ModalStore }) => {
  const handleSuccess = () => {
    copyToClipboard(link);
    ModalStore.closeModal();
    toast.success(I18N.t("COPY_SUCCESS"));
  };

  return (
    <div className="share-game-modal">
      <h2>{I18N.t("COPY_LINK_AND_SHARE")}</h2>
      <h4 className="share-game-modal__link">{link}</h4>
      <img src={thumbnail} className="share-game-modal__thumbnail" />
      <SuccessButton onClick={handleSuccess}>
        {I18N.t("COPY_LINK")}
      </SuccessButton>
      <DismissButton onClick={() => ModalStore.closeModal()}>
        {I18N.t("CLOSE")}
      </DismissButton>
    </div>
  );
};

ShareGameModal.propTypes = {
  link: PropTypes.string,
  ModalStore: PropTypes.shape(),
  thumbnail: PropTypes.string
};

ShareGameModal.defaultProps = {
  link: "",
  ModalStore: {},
  thumbnail: ""
};

export default inject(["ModalStore"])(observer(ShareGameModal));

import { types } from "mobx-state-tree";
import { stats as statsModule, game as gameModule } from "@myplay/all";

import optionalAndMaybeNull from "../../utils/mobx-optional-and-maybe-null";

const Position = types.model("Position", {
  x: optionalAndMaybeNull(types.number),
  y: optionalAndMaybeNull(types.number),
  area: optionalAndMaybeNull(types.string),
  side: optionalAndMaybeNull(types.string)
});

const Event = types.model("Event", {
  commentType: optionalAndMaybeNull(types.string),
  markerColor: optionalAndMaybeNull(types.string),
  seekTime: optionalAndMaybeNull(types.number),
  position: optionalAndMaybeNull(Position),
  taggedUsers: optionalAndMaybeNull(types.array(types.frozen({})))
});

const TableItem = types.model("TableItem", {
  name: optionalAndMaybeNull(types.string),
  playerNumber: optionalAndMaybeNull(types.number),
  stats: optionalAndMaybeNull(types.frozen()),
  avatar: optionalAndMaybeNull(types.string),
  avatarBgColor: optionalAndMaybeNull(types.string)
});

const Statistics = types.model("Statistics", {
  bars: optionalAndMaybeNull(types.frozen()),
  donuts: optionalAndMaybeNull(types.frozen())
});

const StatsStore = types
  .model("Stats", {
    courtSketch: optionalAndMaybeNull(types.string),
    teamMappedEvents: optionalAndMaybeNull(types.array(Event)),
    currentMappedEvents: optionalAndMaybeNull(types.array(Event)),
    mappedEvents: optionalAndMaybeNull(types.array(Event)),
    teamStats: optionalAndMaybeNull(Statistics),
    playersStats: types.map(Statistics),
    tableStats: types.map(TableItem),
    isStats: optionalAndMaybeNull(types.boolean),
    tableHeaders: optionalAndMaybeNull(types.array(types.string))
  })
  .actions(self => ({
    getAllGameMappedEvents(videoId) {
      return statsModule.stats
        .getMappedEvents(videoId)
        .then(res => {
          self.setMappedEvents(res);
        })
        .catch(error => console.log(error));
    },
    setMappedEvents(events) {
      self.mappedEvents = events;
    },
    getCourtSketch(videoId) {
      gameModule.videos
        .getVideoContainerByID(videoId)
        .then(res => {
          self.setCourtSketch(res.team.category.fullCourtSketch);
        })
        .catch(error => console.log(error));
    },
    setCourtSketch(sketch) {
      self.courtSketch = sketch;
    },
    getTeamStats(videoId) {
      return statsModule.stats
        .getTeamStats(videoId)
        .then(res => {
          self.setTeamStats(res);
        })
        .catch(error => console.log(error));
    },
    setTeamStats(stats) {
      self.teamStats = stats;
      self.isStats = stats.isStats;
    },
    getPlayersStats(videoId) {
      statsModule.stats
        .getAllPlayersStats(videoId)
        .then(res => {
          self.setPlayerStats(res);
        })
        .catch(error => console.log(error));
    },
    pushPlayerStats(stats, userId) {
      self.playersStats.set(userId, stats);
    },
    getTableStats(videoId) {
      self.tableStats = {};
      return statsModule.stats
        .getStatsTable(videoId)
        .then(res => {
          self.setTableHeaders(res);
          Object.keys(res).map((id, i) => {
            const values = Object.values(res)[i];
            const newItem = {
              name: values.name,
              playerNumber: values.playerNumber,
              avatar: values.avatar,
              avatarBgColor: values.avatarBgColor
            };
            const newStats = {};
            if (values.stats.length > 0) {
              values.stats.map(item => {
                newStats[Object.keys(item)] = Object.values(item)[0]; //eslint-disable-line
              });
            }
            newItem.stats = { ...newStats };
            self.setTableStats(newItem, id);
          });
        })
        .catch(error => console.log(error));
    },
    setTableStats(data, id) {
      self.tableStats.set(id, data);
    },
    setPlayerStats(data) {
      self.playersStats = data;
    },
    setTableHeaders(stats) {
      self.tableHeaders = stats[Object.keys(stats)[0]].stats.map(tag=>Object.keys(tag)[0]);
    }
  }));

export default StatsStore;

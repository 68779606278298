import React from 'react';
import PropTypes from 'prop-types';

import './VideoPreview.scss';

function VideoPreviewMenuItem({ onClick, children }) {
  return (
    <button type="button" className="video-preview-menu__item" onClick={onClick}>
      {children}
    </button>
  );
}

VideoPreviewMenuItem.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func
}

VideoPreviewMenuItem.defaultProps = {
  children: null,
  onClick: () => { }
}


export default VideoPreviewMenuItem;

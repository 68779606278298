/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { TEAM_CHANNEL_ROUTE_NAME } from "../../utils/constants";

import "./GameTitle.scss";
import leftArrow from "../../assets/images/left_arrow-06.svg";
import { closeWindow } from "../../utils/iframeConnector";

const GameTitle = ({ date, title }) => {
  let gameDate;

  try {
    gameDate = new Intl.DateTimeFormat("en-GB", {
      year: "numeric",
      month: "short",
      day: "2-digit"
    }).format(new Date(date));
  } catch (e) {
    gameDate = "";
  }

  return (
    <div className="game-title">
      <div className="game-title__close">
        <Link to={TEAM_CHANNEL_ROUTE_NAME}>
          <img src={leftArrow} />
        </Link>
      </div>
      <div className="game-title__container">
        <span className="game-title__date">{gameDate}</span>
        <span className="game-title__game-title">{title}</span>
      </div>
    </div>
  );
};

GameTitle.propTypes = {
  date: PropTypes.string,
  title: PropTypes.string
};

GameTitle.defaultProps = {
  date: "",
  title: ""
};

export default GameTitle;

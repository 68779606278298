import React from "react"; // eslint-disable-line
import PropTypes from "prop-types";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router-dom";

import { LOGIN_ROUTE_NAME, MYPLAY_LOGIN_URL, MYPLAY_APP_URL } from "../../utils/constants";

const AuthenticationGuard = ({ UserStore, location, children }) =>
  // eslint-disable-next-line no-nested-ternary
  UserStore.isAuthenticated ? (
    children
  ) : location.pathname === LOGIN_ROUTE_NAME ? null : (
    window.location.href = `${MYPLAY_LOGIN_URL}/?redirect=${MYPLAY_APP_URL}`
  );

AuthenticationGuard.propTypes = {
  children: PropTypes.node,
  UserStore: PropTypes.shape().isRequired
};

AuthenticationGuard.defaultProps = {
  children: null
};

export default withRouter(inject(["UserStore"])(observer(AuthenticationGuard)));

import React from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";

import "./AppLayout.scss";
import { Modal, Header } from "../../";

function AppLayout({ children, MetaStore, TeamsStore }) {
  const teamId =
    TeamsStore && TeamsStore.currentTeam && TeamsStore.currentTeam._id
      ? TeamsStore.currentTeam._id
      : "";
  return (
    <div className="app-layout">
      <Modal />
      {MetaStore.showHeader && (
        <div className="app-layout__header">
          <Header currentTeamId={teamId} />
        </div>
      )}
      <div className="app-layout__content">{children}</div>
    </div>
  );
}

AppLayout.propTypes = {
  children: PropTypes.node,
  MetaStore: PropTypes.object,
  TeamsStore: PropTypes.object
};

AppLayout.defaultProps = {
  children: null,
  MetaStore: {},
  TeamsStore: {}
};

export default inject("MetaStore", "TeamsStore")(observer(AppLayout));

/* eslint-disable */
import React, { Component } from "react";
import {
  SuccessButton,
  TitleWithIcon,
  SelectPlayerButton,
  TextInput,
  SelectTagButton,
  PlusButton,
  TagsContainer,
  SocialSharePanel,
  ConfirmModal,
  HighlightPlayer,
  EditHighlightHeader
} from "@myplay/ui";
import { inject } from "mobx-react";
import PropTypes from "prop-types";
import { user as userModule } from "@myplay/all";
import { VelocityComponent, VelocityTransitionGroup } from "velocity-react";
import Textarea from "react-autosize-textarea";
import Rodal from "rodal";
import tagIcon from "../../../assets/images/Tag_icon-11.svg";
import playerIcon from "../../../assets/images/player-12.svg";
import garbageIcon from "../../../assets/images/inline-svgs/garbage.svg";
import downloadIcon from "../../../assets/images/inline-svgs/download-icon.svg";
import closeIcon from "../../../assets/images/close-11.svg";
import arrow from "../../../assets/images/leftArrow.svg";
import { sendAnalyticsEvent } from "../../../utils/analytics";
import {
  ANALYTICS_SHARED_COACH_HIGHLIGHT,
  USER_PLAYER,
  ANALYTICS_EDIT_MODAL_COMMENT,
  ANALYTICS_EDIT_MODAL_TAG,
  ANALYTICS_EDIT_MODAL_TAG_PLAYER,
  ANALYTICS_EDIT_MODAL_DOWNLOAD
} from "../../../utils/constants";
import I18N from "../../../I18N";

// include styles
import "rodal/lib/rodal.css";
import getHighlightLink from "../../../utils/getHighlightLink";

require("velocity-animate");
require("velocity-animate/velocity.ui");

const teamsModule = userModule.teams;
const HIGHLIGHT_PAGE_PATH = "public-highlights/";
const SHARE_LINK = `app.myplay.com/${HIGHLIGHT_PAGE_PATH}`;

class EditModal extends Component {
  editModalRef = React.createRef();
  videoRef = React.createRef();

  state = {
    showTagsPicker: false,
    showPlayerPicker: false,
    filterTags: [],
    selectedTags: this.props.highlight.clipTags
      ? [...this.props.highlight.clipTags]
      : [...this.props.highlight.tags],
    filterPlayers: this.props.teamPlayers.filter(
      player =>
        this.props.highlight.tagedUsers.filter(
          selectedPlayer => selectedPlayer.id === player.id
        ).length === 0
    ),
    selectedPlayers: this.props.highlight.clipTaggedPlayers
      ? [...this.props.highlight.clipTaggedPlayers]
      : [...this.props.highlight.tagedUsers],
    showModal: true,
    showConfirmModal: false,
    isChanged: true,
    currentHighlightIndex: this.props.currentHighlightIndex,
    numberOfHighlights: this.props.numberOfHighlights,
    hover: false,
    textAreaInput: this.props.highlight.title
  };

  componentDidMount = async () => {
    const {
      UserStore: {
        data: { fullName, _id, email, accountType }
      },
      highlight: { tagedUsers, clipTaggedPlayers }
    } = this.props;

    this.teamTags = await this.getTeamHighlightsTags();
    const filterTags = this.teamTags.filter(
      tag =>
        this.props.highlight.tags.filter(
          selectedTag => selectedTag.title === tag.title
        ).length === 0
    );
    this.setState({
      filterTags,
      selectedPlayers: clipTaggedPlayers
        ? [...clipTaggedPlayers]
        : [...tagedUsers]
    });
    if (this.editModalRef.current) {
      this.editModalRef.current.focus();
    }

    this.defaultAnalyticsData = {
      fullName,
      id: _id,
      email,
      accountType
    };
  };

  componentDidUpdate(prevProps) {
    if (prevProps.highlight.id !== this.props.highlight.id) {
      this.setState({
        filterTags: this.teamTags.filter(
          tag =>
            this.props.highlight.tags.filter(
              selectedTag => selectedTag.title === tag.title
            ).length === 0
        ),
        selectedTags: this.props.highlight.clipTags
          ? [...this.props.highlight.clipTags]
          : [...this.props.highlight.tags],
        selectedPlayers: this.props.highlight.clipTaggedPlayers
          ? [...this.props.highlight.clipTaggedPlayers]
          : [...this.props.highlight.tagedUsers],
        currentHighlightIndex: this.props.currentHighlightIndex,
        numberOfHighlights: this.props.numberOfHighlights,
        textAreaInput: this.props.highlight.title
      });
    }
  }

  getTeamHighlightsTags = async () => {
    const tags = await teamsModule.getTeamTags(this.props.highlight.team);
    const highlightTags = tags.customs.filter(tag => tag && tag.color);
    return [...highlightTags, ...this.props.teamTags];
  };

  togglePlayerPicker = () => {
    this.setState(prevState => ({
      showPlayerPicker: !prevState.showPlayerPicker
    }));
    this.setState({ showTagsPicker: false });
  };

  toggleTagPicker = () => {
    this.setState(prevState => ({
      showTagsPicker: !prevState.showTagsPicker
    }));
    this.setState({ showPlayerPicker: false });
  };

  addSelectedTag = tag => {
    const {
      highlight: { id }
    } = this.props;
    const newSelectedTags = this.state.selectedTags;
    newSelectedTags.unshift(tag);
    const tagIndex = this.state.filterTags.indexOf(tag);
    if (tagIndex !== -1) {
      this.state.filterTags.splice(tagIndex, 1);
    }
    this.setState({ selectedTags: newSelectedTags });

    sendAnalyticsEvent(ANALYTICS_EDIT_MODAL_TAG, {
      ...this.defaultAnalyticsData,
      tag,
      videoId: id
    });
  };

  removeSelectedTag = tag => {
    const newFilterTags = this.state.filterTags;
    newFilterTags.push(tag);
    const tagIndex = this.state.selectedTags.indexOf(tag);
    if (tagIndex !== -1) {
      this.state.selectedTags.splice(tagIndex, 1);
    }
    this.setState({ filterTags: newFilterTags });
  };

  addSelectedPlayer = player => {
    const {
      highlight: { id }
    } = this.props;
    const newSelectedPlayers = [...this.state.selectedPlayers];
    newSelectedPlayers.unshift(player);
    const playerIndex = this.state.filterPlayers.indexOf(player);
    if (playerIndex !== -1) {
      this.state.filterPlayers.splice(playerIndex, 1);
    }

    this.setState({ selectedPlayers: newSelectedPlayers });

    sendAnalyticsEvent(ANALYTICS_EDIT_MODAL_TAG_PLAYER, {
      ...this.defaultAnalyticsData,
      playerName: player.fullName,
      videoId: id
    });
  };

  removeSelectedPlayer = player => {
    const newFilterPlayers = this.state.filterPlayers;
    newFilterPlayers.push(player);
    const playerIndex = this.state.selectedPlayers.indexOf(player);
    if (playerIndex !== -1) {
      this.state.selectedPlayers.splice(playerIndex, 1);
    }
    this.setState({ filterPlayers: newFilterPlayers });
  };

  save = keepOpen => {
    const { highlight, onSave } = this.props;
    const { selectedTags, selectedPlayers, textAreaInput } = this.state;
    // const self = this;
    const clip = {
      id: highlight.id,
      tags: selectedTags,
      tagedUsers: selectedPlayers,
      title: textAreaInput !== null ? textAreaInput : highlight.title
    };

    onSave(clip, keepOpen);
  };

  onDismiss = () => {
    this.setState({
      showModal: false,
      textAreaInput: null
    });
    if (this.props.onDismiss) {
      this.props.onDismiss();
    }
  };

  getVideoTime = () => {
    const { highlight } = this.props;
    let s = Math.floor(highlight.duration);
    let m = Math.floor(s / 60);
    s %= 60;
    m %= 60;
    return `${m}:${s < 10 ? `0${s}` : s}`;
  };

  addNewTagToTeam = async tag => {
    teamsModule.createTeamTag(this.props.highlight.team, tag);
    this.setState(prevState => ({
      filterTags: [tag, ...prevState.filterTags]
    }));
  };

  handleNextHighlight = () => {
    if (this.props.isEditMode) {
      this.save(true);
    }
    this.props.changeHighlight({ prev: false });
  };

  handlePrevHighlight = () => {
    if (this.props.isEditMode) {
      this.save(true);
    }
    this.props.changeHighlight({ prev: true });
  };

  handleKeyboardNavigate = e => {
    if (e.key === "ArrowRight") {
      return this.handleNextHighlight();
    }
    if (e.key === "ArrowLeft") {
      return this.handlePrevHighlight();
    }
  };

  handleTagDelete = tagToDelete => {
    teamsModule.removeTeamTagByTitle(this.props.highlight.team, tagToDelete);
    this.setState(prevState => ({
      filterTags: prevState.filterTags.filter(tag => tagToDelete !== tag)
    }));
  };

  getSharedPlatform = platform => {
    sendAnalyticsEvent(ANALYTICS_SHARED_COACH_HIGHLIGHT, {
      ...this.defaultAnalyticsData,
      platform
    });
  };

  downloadAnalyticsEvent = () => {
    const { highlight } = this.props;

    sendAnalyticsEvent(ANALYTICS_EDIT_MODAL_DOWNLOAD, {
      ...this.defaultAnalyticsData,
      videoId: highlight.id
    });
  };

  render() {
    const {
      highlight,
      isEditMode,
      isPanoramic,
      UserStore: {
        data: { accountType }
      }
    } = this.props;
    const clipHighlightsPlayers =
      highlight &&
      highlight.tagedUsers &&
      highlight.tagedUsers.map((player, index) => (
        <SelectPlayerButton
          key={player.id}
          dontChangeColorOnClick
          isSelected
          onDelete={this.removeSelectedPlayer}
          player={player}
        />
      ));

    const clipHighlightsTags =
      highlight &&
      highlight.tags &&
      highlight.tags.map((tag, index) => (
        <SelectTagButton
          key={tag.title}
          dontChangeColorOnClick
          isSelected
          onDelete={this.removeSelectedTag}
          tag={tag}
        />
      ));

    const playersList = this.state.selectedPlayers.map((player, index) => (
      <SelectPlayerButton
        key={player.id}
        dontChangeColorOnClick
        isSelected
        onDelete={this.removeSelectedPlayer}
        player={player}
        showDelete
      />
    ));

    const allPlayersList = this.state.filterPlayers.map((player, index) => (
      <SelectPlayerButton
        key={player.id}
        dontChangeColorOnClick
        onClick={this.addSelectedPlayer}
        player={player}
      />
    ));

    const tagsList = this.state.selectedTags.map((tag, index) => (
      <SelectTagButton
        key={tag.title}
        dontChangeColorOnClick
        isSelected
        onDelete={this.removeSelectedTag}
        showDelete
        tag={tag}
      />
    ));

    const totalClipInfo =
      this.props.highlight.highlights !== undefined ? (
        <span className="editModal__total-info">
          Total Time:{" "}
          <span style={{ color: "#16C4AB" }}>{this.getVideoTime()}</span>
        </span>
      ) : null;

    const saveButtonStyle = {
      fontSize: "14px",
      height: "35px",
      width: "140px"
    };

    const modal = (
      <Rodal
        visible={this.state.showModal}
        animation="fade"
        duration={400}
        onClose={this.onDismiss}
        closeOnEsc
        customMaskStyles={{ background: "#000000d9", zIndex: 0 }}
      >
        <div className="edit-modal-wrapper">
          <div
            className="editModal__container"
            onClick={e => {
              e.stopPropagation();
              this.setState({ isChanged: false });
            }}
            tabIndex={0}
            onMouseOver={() => {
              this.setState({ hover: true });
            }}
            onMouseLeave={() => {
              this.setState({ hover: false });
            }}
            onKeyDown={e => {
              this.handleKeyboardNavigate(e);
            }}
            onKeyPress={e => {
              this.handleKeyboardNavigate(e);
            }}
            ref={this.editModalRef}
          >
            <img
              className="editModal__close-button"
              src={closeIcon}
              onClick={isEditMode ? this.save : this.onDismiss}
              alt="img"
            />
            <img
              src={arrow}
              onClick={this.handlePrevHighlight}
              className="editModal__navigate-arrow editModal__navigate-arrow--left"
              style={{
                visibility:
                  this.state.hover && this.props.currentHighlightIndex > 0
                    ? "visible"
                    : "hidden"
              }}
            />
            <img
              src={arrow}
              onClick={this.handleNextHighlight}
              className="editModal__navigate-arrow editModal__navigate-arrow--right"
              style={{
                visibility:
                  this.state.hover &&
                  this.props.currentHighlightIndex <
                    this.state.numberOfHighlights - 1
                    ? "visible"
                    : "hidden"
              }}
            />
            <div className="editModal__video-container">
              {isPanoramic ? (
                <HighlightPlayer
                  style={{ maxHeight: "500px", maxWidth: "889px" }}
                  height="500px"
                  width="880px"
                  autoPlay
                  loop
                  videoId={highlight.id}
                />
              ) : (
                <video
                  controls
                  controlsList="nodownload"
                  style={{ maxHeight: "500px", maxWidth: "889px" }}
                  autoPlay
                  loop
                  src={highlight.streamUri}
                  poster={highlight.thumbnailUri}
                />
              )}
            </div>
            <div className="editModal__edit-form">
              {!isEditMode && <div className="editModal__edit-blocker" />}
              <EditHighlightHeader
                sender={highlight.sender}
                seekTime={highlight.seekTime}
                duration={highlight.duration}
              />
              <Textarea
                placeholder={I18N.t("ADD_COMMENT")}
                autoFocus={false}
                disabled={!isEditMode}
                style={{
                  borderBottom: !isEditMode ? "none" : "1px solid grey"
                }}
                className="editModal__edit-form__text-area"
                onChange={event =>
                  this.setState({
                    textAreaInput: event.currentTarget.value
                  })
                }
                onBlur={event => {
                  // sendAnalyticsEvent(ANALYTICS_EDIT_MODAL_COMMENT ,{
                  //   ...this.defaultAnalyticsData,
                  //   comment: event.currentTarget.value,
                  //   videoId: highlight.id
                  // })
                }}
                value={this.state.textAreaInput}
              />
              {totalClipInfo}
              {isEditMode && accountType !== USER_PLAYER && (
                <TitleWithIcon
                  iconSrc={tagIcon}
                  iconStyle={{ height: "16px", width: "16px" }}
                  titleStyle={{ fontSize: "16px" }}
                >
                  Tags
                </TitleWithIcon>
              )}
              <div
                className="editModal__items-list"
                style={{ minHeight: "40px" }}
              >
                <VelocityTransitionGroup
                  enter={{
                    animation: "transition.slideUpIn",
                    stagger: "200",
                    duration: "400"
                  }}
                  leave={{
                    animation: "transition.slideDownOut",
                    backwards: "150",
                    duration: "200"
                  }}
                  style={{ display: "flex", flexWrap: "wrap" }}
                >
                  {highlight.clipTags
                    ? [...clipHighlightsTags, ...tagsList]
                    : tagsList}
                </VelocityTransitionGroup>
                {isEditMode && accountType !== USER_PLAYER && (
                  <PlusButton
                    onClick={this.toggleTagPicker}
                    style={{
                      marginRight: "10px",
                      marginLeft: "10px",
                      width: "40px"
                    }}
                  />
                )}
              </div>
              <div className="editModal__tags-picker">
                <VelocityComponent
                  animation={
                    this.state.showTagsPicker
                      ? "transition.flipYIn"
                      : "transition.flipYOut"
                  }
                  duration={200}
                >
                  <TagsContainer
                    tags={this.state.filterTags}
                    onClick={this.addSelectedTag}
                    onAddNewTag={this.addNewTagToTeam}
                    onDelete={this.handleTagDelete}
                  />
                </VelocityComponent>
              </div>
              {isEditMode && accountType !== USER_PLAYER && (
                <TitleWithIcon
                  iconSrc={playerIcon}
                  iconStyle={{ height: "16px", width: "16px" }}
                  titleStyle={{ fontSize: "16px" }}
                >
                  Players
                </TitleWithIcon>
              )}
              <div
                className="editModal__items-list"
                style={{ minHeight: "70px", margin: 0 }}
              >
                <VelocityTransitionGroup
                  enter={{
                    animation: "transition.slideUpIn",
                    duration: "200"
                  }}
                  leave={{
                    animation: "transition.slideDownOut",
                    backwards: "150",
                    duration: "200"
                  }}
                  style={{ display: "flex", flexWrap: "wrap" }}
                >
                  {highlight.clipTaggedPlayers
                    ? [...clipHighlightsPlayers, ...playersList]
                    : playersList}
                </VelocityTransitionGroup>

                {isEditMode && accountType !== USER_PLAYER && (
                  <div className="editModal__round-plus-container">
                    <PlusButton round onClick={this.togglePlayerPicker} />
                  </div>
                )}
              </div>
              <VelocityComponent
                animation={
                  this.state.showPlayerPicker
                    ? "transition.flipYIn"
                    : "transition.flipYOut"
                }
                duration={200}
              >
                <div>
                  <div className="editModal__picker">{allPlayersList}</div>
                </div>
              </VelocityComponent>
              {isEditMode && (
                <div className="editModal__buttons-container">
                  {isEditMode && highlight.sender && (
                    <SocialSharePanel
                      shareLink={getHighlightLink(highlight)}
                      getSharedPlatform={this.getSharedPlatform}
                    />
                  )}
                  <div>
                    {highlight.streamUri && (
                      <a
                        href={
                          highlight.streamUri.includes(
                            "https://1507-globaldots-clips.gcdn.co"
                          )
                            ? `${highlight.streamUri}
                          }?attachment=true&${new Date().getTime()}`
                            : highlight.streamUri
                        }
                        onClick={this.downloadAnalyticsEvent}
                      >
                        <img
                          src={downloadIcon}
                          className="editModal__delete-button"
                        />
                      </a>
                    )}
                    {isEditMode && (
                      <img
                        src={garbageIcon}
                        className="editModal__delete-button"
                        onClick={() => {
                          this.setState({ showConfirmModal: true });
                        }}
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <Rodal
          visible={this.state.showConfirmModal}
          animation="door"
          duration={200}
          showCloseButton={false}
          customStyles={{ background: "none", boxShadow: "unset" }}
        >
          <ConfirmModal
            onDismiss={() => {
              this.setState({ showConfirmModal: false });
            }}
            onApprove={this.props.onDelete}
            title="Are sure you want to delete this highlight?"
          />
        </Rodal>
      </Rodal>
    );

    return this.state.showModal ? modal : null;
  }
}

EditModal.propTypes = {
  /** Show edit modal with total clip info if true */
  editClip: PropTypes.bool,
  /** highlight object to edit  */
  isEditMode: PropTypes.bool,
  highlight: PropTypes.object.isRequired,
  /** function to call when save button click */
  onSave: PropTypes.func.isRequired,
  /** function to call when confirm delete button click */
  onDelete: PropTypes.func.isRequired,
  /** function to call when dismiss button click */
  onDismiss: PropTypes.func,
  teamPlayers: PropTypes.array,
  teamTags: PropTypes.array
};

EditModal.defaultProps = {
  isEditMode: true,
  teamPlayers: [],
  teamTags: []
};

export default inject("UserStore")(EditModal);

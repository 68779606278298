import React, { Component } from "react";
import "./TeamSettings.scss";
import PropTypes from "prop-types";
import { DetailInput } from "@myplay/ui";
import { observer } from "mobx-react";

import { UserTypeGuard } from "../index";
import I18N from "../../I18N";
import refreshTeamCodeIcon from "../../assets/icons/Icon-RefreshTeamCode.svg";

class TeamSettings extends Component {
  shouldComponentUpdate() {
    return true;
  }

  render() {
    const {
      team,
      img,
      onUpdateTeamImage,
      onUpdateTeamName,
      onRefreshTeamClick,
      isAllowToEdit
    } = this.props;

    if (!team) {
      return null;
    }

    return (
      <div className="team-settings">
        <div className="team-settings__image-box">
          <div
            className="team-settings__image-box__image"
            style={{ backgroundImage: `url(${team.teamImage})` }}
          />
          <UserTypeGuard allowed={["coach", "operator"]}>
            <div className="team-settings__image-box__update-button">
              <input
                type="file"
                id="new-logo"
                value=""
                style={{ display: "none" }}
                data-testid="test-team-image"
                onChange={event => onUpdateTeamImage(event, "logo")}
              />
              {/* eslint-disable */}
              <label htmlFor="new-logo" id="new-file">
                {I18N.t("CHANGE").toUpperCase()}
              </label>
              {/* eslint-enable */}
            </div>
          </UserTypeGuard>
        </div>
        <div key={team.name} className="team-settings__details-box">
          <DetailInput
            defaultValue={team.name}
            label={I18N.t("TEAM_NAME")}
            disabled={!isAllowToEdit}
            onChange={() => {}}
            onBlur={e => {
              onUpdateTeamName(e.currentTarget.value);
            }}
            onKeyPress={e => {
              if (e.key === "Enter") {
                e.currentTarget.blur();
              }
            }}
          />
          <UserTypeGuard allowed={["coach", "operator"]}>
            <DetailInput
              value={team.code}
              label={I18N.t("TEAM_CODE")}
              data-testid="test-team-code"
              disabled
            >
              {/* eslint-disable */}
              <img
                src={refreshTeamCodeIcon}
                className="team-settings__details-box__refresh-code-button"
                onClick={onRefreshTeamClick}
                data-testid="test-refresh-team-code-button"
              />
              {/* eslint-enable */}
            </DetailInput>
          </UserTypeGuard>
        </div>
      </div>
    );
  }
}

TeamSettings.propTypes = {
  img: PropTypes.string,
  isAllowToEdit: PropTypes.bool,
  onRefreshTeamClick: PropTypes.func,
  onUpdateTeamImage: PropTypes.func,
  onUpdateTeamName: PropTypes.func,
  team: PropTypes.object
};

TeamSettings.defaultProps = {
  img: "",
  isAllowToEdit: false,
  onRefreshTeamClick: () => {},
  onUpdateTeamImage: () => {},
  onUpdateTeamName: () => {},
  team: {}
};

export default observer(TeamSettings);

import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react/index";
import {
  VideoPlayerWithConnector,
  Loader,
  BreakdownsBottomPanel,
  FiltersPanel
} from "@myplay/ui";
import I18N from "../../I18N";

import "./Breakdowns.scss";
import EventsTable from "../../components/EventsTable/EventsTable";
import { sendAnalyticsEvent } from "../../utils/analytics";
import { mapGameEvents } from "../../utils/helper";
import {
  USER_PLAYER,
  ANALYTICS_BREAKDOWNS_CLIP_CREATED,
  GAME_ROUTE_NAME,
  ANALYTICS_BREAKDOWNS_FILTER_EVENTS,
  ANALYTICS_BREAKDOWNS_VIEW_EVENT
} from "../../utils/constants";

const IFRAME_VIDEO_URL = process.env.REACT_APP_IFRAME_VIDEO_URL;

class Breakdowns extends Component {
  isDesc = false;

  categories = [];

  static propTypes = {
    checkForClipsInProcess: PropTypes.func,
    gameEvents: PropTypes.array,
    height: PropTypes.number,
    updateTabReady: PropTypes.func,
    UserStore: PropTypes.object,
    videoContainer: PropTypes.object,
    videoId: PropTypes.string
  };

  static defaultProps = {
    checkForClipsInProcess: () => {},
    gameEvents: [],
    height: 0,
    updateTabReady: () => {},
    UserStore: {},
    videoContainer: {},
    videoId: ""
  };

  state = {
    gameEvents: [],
    gamePeriods: [],
    filters: {
      positions: [],
      events: [],
      players: [],
      periods: []
    },
    currentFilters: {
      positions: [],
      events: [],
      players: [],
      periods: []
    },
    previousFilters: {
      positions: [],
      events: [],
      players: [],
      periods: []
    },
    isLoading: false,
    isVideoReady: false,
    isFilterPanelVisible: false,
    filteredEvents: [],
    isFiltered: false,
    markedBreakdownsIds: []
  };

  async componentDidMount() {
    const { gameEvents, TeamsStore, videoContainer, UserStore } = this.props;
    const {
      data: { fullName, _id, accountType, email }
    } = UserStore;
    // eslint-disable-next-line
    history.pushState(
      {},
      "",
      `${GAME_ROUTE_NAME}/${videoContainer._id}/breakdown`
    );
    this.addEventListners();
    const videoIds = videoContainer.video.moreCameras
      .filter(video => video.cameraType !== "SCORE")
      .map(video => video.id);
    videoIds.push(videoContainer.video.id);

    const { players } = TeamsStore.currentTeamMembers;
    const gamePeriods = gameEvents
      .filter(gameEvent => gameEvent.commentType === "Period")
      .map(period => ({
        seekTime: period.seekTime,
        endSeekTime: period.endSeekTime
      }))
      .sort((p1, p2) => p1.seekTime - p2.seekTime);
    const filters = this.getFilters(videoContainer, gamePeriods, players);

    this.setState({
      gameEvents: mapGameEvents(gameEvents, gamePeriods),
      gamePeriods,
      filters
    });
    this.defaultAnalyticsData = {
      fullName,
      id: _id,
      accountType,
      email
    };
  }

  componentWillUnmount() {
    if (this.listener) {
      window.removeEventListener("message", this.listener, false);
    }
  }

  getFilters = (videoContainer, gamePeriods, teamPlayers) => {
    const events = videoContainer.team.category.defaultTags.map(
      tag => tag.shortcut
    );
    const positions = [
      ...new Set(
        videoContainer.team.category.courtAreas.map(area => area.title)
      )
    ];
    const periods =
      gamePeriods && gamePeriods.map((gamePeriod, index) => index + 1);
    const players = teamPlayers && [
      ...new Set(
        teamPlayers
          .filter(teamPlayer => teamPlayer.playerNumber !== null)
          .map(teamPlayer => teamPlayer.playerNumber)
          .sort((a, b) => a - b)
      )
    ];

    return {
      events,
      positions,
      periods,
      players
    };
  };

  toggleFilterPanel = () =>
    this.setState(prevState => ({
      isFilterPanelVisible: !prevState.isFilterPanelVisible
    }));

  handleItemClick = (item, categoryName) => {
    const { currentFilters } = this.state;
    const newFiltersToCategory = currentFilters[categoryName];
    const indexOfItem = newFiltersToCategory.indexOf(item);

    if (newFiltersToCategory.indexOf(item) > -1) {
      newFiltersToCategory.splice(indexOfItem, 1);
    } else {
      newFiltersToCategory.push(item);
    }

    this.setState(prevState => ({
      currentFilters: {
        ...prevState.currentFilters,
        [categoryName]: newFiltersToCategory
      }
    }));
  };

  handleClearFilters = () => {
    const { currentFilters } = this.state;
    const clearedFilters = {};
    Object.keys(currentFilters).map(item => {
      clearedFilters[item] = [];
    }); // eslint-disable-line no-return-assign

    this.setState({
      currentFilters: clearedFilters,
      previousFilters: clearedFilters,
      isFiltered: false,
      markedBreakdownsIds: []
    });
  };

  handleSaveFilters = () => {
    const { currentFilters } = this.state;
    const currentFiltersCopy = JSON.parse(JSON.stringify(currentFilters));
    this.setState(
      {
        previousFilters: currentFiltersCopy,
        markedBreakdownsIds: []
      },
      () => this.filterEvents()
    );
  };

  handleClosePanel = () => {
    const { previousFilters } = this.state;
    const currentFiltersCopy = JSON.parse(JSON.stringify(previousFilters));
    this.setState({
      currentFilters: currentFiltersCopy
    });
    this.toggleFilterPanel();
  };

  filterEvents = () => {
    const { gameEvents, currentFilters, gamePeriods } = this.state;
    let filteredEventsCopy = Object.assign(gameEvents);
    const filterByPlayers = currentFilters.players.length > 0;
    const filterByEvents = currentFilters.events.length > 0;
    const filterByPositions = currentFilters.positions.length > 0;
    const filterByPeriods = currentFilters.periods.length > 0;

    gameEvents.filter(() => {
      if (filterByPlayers) {
        filteredEventsCopy = filteredEventsCopy.filter(event =>
          event.tagedUsers.some(user =>
            currentFilters.players.includes(user.playerNumber)
          )
        );
      }
      if (filterByEvents) {
        filteredEventsCopy = filteredEventsCopy.filter(event =>
          currentFilters.events.includes(event.commentType)
        );
      }
      if (filterByPositions) {
        filteredEventsCopy = filteredEventsCopy.filter(event =>
          currentFilters.positions.includes(event.position.area)
        );
      }
      if (filterByPeriods) {
        filteredEventsCopy = filteredEventsCopy.filter(event =>
          gamePeriods.some(
            (periodTime, index) =>
              currentFilters.periods.includes(index + 1) &&
              event.seekTime >= periodTime.seekTime &&
              event.seekTime <= periodTime.endSeekTime
          )
        );
      }
    });

    sendAnalyticsEvent(ANALYTICS_BREAKDOWNS_FILTER_EVENTS, {
      ...this.defaultAnalyticsData,
      players: filterByPlayers,
      events: filterByEvents,
      positions: filterByPositions,
      periods: filterByPeriods
    });

    this.setState({
      filteredEvents: filteredEventsCopy,
      isFiltered: true,
      isFilterPanelVisible: false
    });
  };

  updateMarkedBreakdownIds = ids =>
    this.setState({
      markedBreakdownsIds: ids
    });

  handleMakeClip = async () => {
    const {
      NotificationsStore,
      checkForClipsInProcess,
      GameScreenStore: { createClipFromBreakdown }
    } = this.props;
    const { markedBreakdownsIds } = this.state;

    await createClipFromBreakdown(markedBreakdownsIds);
    NotificationsStore.clearCheckActiveTasksInterval();
    NotificationsStore.getActiveTasksRecursive();
    checkForClipsInProcess();
    sendAnalyticsEvent(ANALYTICS_BREAKDOWNS_CLIP_CREATED, {
      ...this.defaultAnalyticsData,
      createdFrom: "Breakdown",
      numberOfVideosInClip: markedBreakdownsIds.length
    });
    this.setState({
      markedBreakdownsIds: []
    });
  };

  analyticsEventClicked = () => {
    const { videoId } = this.props;
    sendAnalyticsEvent(ANALYTICS_BREAKDOWNS_VIEW_EVENT, {
      ...this.defaultAnalyticsData,
      gameId: videoId
    });
  };

  addEventListners() {
    const { updateTabReady } = this.props;
    const eventMethod = window.addEventListener
      ? "addEventListener"
      : "attachEvent";
    const eventer = window[eventMethod];
    const messageEvent =
      eventMethod === "attachEvent" ? "onmessage" : "message";
    if (!this.listener) {
      this.listener = async e => {
        const iframeEl = document.getElementById("iframe-container")
          .children[1];
        if (iframeEl && iframeEl.contentWindow) {
          // iframeEl.contentWindow.postMessage(e.data, "*");
          if (e.data === "playerReady") {
            this.setState({ isVideoReady: true });
            updateTabReady();
          }
        }
      };
      eventer(messageEvent, this.listener, false);
    }
  }

  /* eslint-disable react/jsx-no-literals */
  /* eslint-disable react/jsx-handler-names */

  render() {
    const {
      height,
      videoId,
      UserStore: {
        data: { accountType }
      }
    } = this.props;


    const {
      gameEvents,
      filters,
      isLoading,
      isVideoReady,
      isFilterPanelVisible,
      currentFilters,
      filteredEvents,
      isFiltered,
      markedBreakdownsIds
    } = this.state;

    const isFiltersActive = !!(
      currentFilters.positions.length ||
      currentFilters.events.length ||
      currentFilters.players.length ||
      currentFilters.periods.length
    );

    return (
      <VideoPlayerWithConnector
        videoContainerId={videoId}
        accountType={accountType}
        iframeId="iframe-container"
        iframeUrl={IFRAME_VIDEO_URL}
        disableFullscreen // eslint-disable-line react/jsx-sort-props
        render={({ connector }) => {
          // this.connector = connector;
          // if (this.connector) {
          //   connector.on("player-clicked", this.clearPlayInterval);
          // }
          return (
            <div className="breakdowns" data-testid="breakdowns">
              <div
                id="iframe-container"
                className="baseVideoPlayer__container"
                style={{ position: "relative" }}
              >
                {!isVideoReady && (
                  <div className="breakdowns__video-loader-container">
                    <Loader
                      type="spinningBubbles"
                      height="125px"
                      width="125px"
                    />
                    <p>
                      <span style={{ color: "white" }}>Loading</span> video
                    </p>
                  </div>
                )}
              </div>
              <div className="breakdowns__table-container">
                {isVideoReady && (
                  <Fragment>
                    <EventsTable
                      categories={this.categories}
                      gameEvents={isFiltered ? filteredEvents : gameEvents}
                      gamePeriods={this.state.gamePeriods}
                      filters={filters}
                      connector={connector}
                      maxHeight={height}
                      isLoading={isLoading}
                      isFiltered={isFiltered}
                      isCheckBoxActive={accountType !== USER_PLAYER}
                      updateMarkedBreakdownIds={this.updateMarkedBreakdownIds}
                      checkedEventsIds={markedBreakdownsIds}
                      sendAnalyticsEvent={this.analyticsEventClicked}
                    />
                    {gameEvents.length > 0 && (
                      <BreakdownsBottomPanel
                        hasMakeClipButton={accountType !== USER_PLAYER}
                        disableMakeClipButton={
                          markedBreakdownsIds.length <= 0 ||
                          markedBreakdownsIds.length >= 49
                        }
                        disabledMessage={
                          // eslint-disable-next-line no-nested-ternary
                          markedBreakdownsIds.length <= 0
                            ? I18N.t("PLEASE_SELECT_EVENT_FIRST")
                            : markedBreakdownsIds.length >= 49
                            ? I18N.t("MAX_HIGHLIGHTS_ERRORS")
                            : ""
                        }
                        hasClearFiltersButtons={isFiltersActive}
                        onFilterClick={this.toggleFilterPanel}
                        onMakeClipClick={this.handleMakeClip}
                        onClearFilters={this.handleClearFilters}
                      />
                    )}
                    <div
                      className={`breakdowns__table-container__filters-container
                    breakdowns__table-container__filters-container--${
                      isFilterPanelVisible ? "show" : "hide"
                    }`}
                      data-testid="filters"
                    >
                      <FiltersPanel
                        filters={filters}
                        currentFilters={currentFilters}
                        toggleFilterPanel={this.toggleFilterPanel}
                        handleItemClick={this.handleItemClick}
                        handleClearFilters={this.handleClearFilters}
                        handleClosePanel={this.handleClosePanel}
                        handleSaveFilters={this.handleSaveFilters}
                      />
                    </div>
                  </Fragment>
                )}
              </div>
            </div>
          );
        }}
      />
    );
  }
}

export default inject(
  "TeamsStore",
  "UserStore",
  "NotificationsStore",
  "GameScreenStore"
)(observer(Breakdowns));

import { types } from "mobx-state-tree";

import optionalAndMaybeNull from "../../utils/mobx-optional-and-maybe-null";

const ModalStore = types
  .model(`Modal`, {
    isOpen: optionalAndMaybeNull(types.boolean),
    modalType: optionalAndMaybeNull(types.string),
    modalProps: optionalAndMaybeNull(types.frozen()),
    containerProps: optionalAndMaybeNull(types.frozen()),
    modalPosition: optionalAndMaybeNull(
      types.enumeration(["center", "top", "top-right"])
    )
  })
  .actions(self => ({
    openModal({ modalType, modalProps, modalPosition, containerProps }) {
      self.isOpen = true;
      self.modalType = modalType;
      self.modalProps = modalProps;
      self.containerProps = containerProps;
      self.modalPosition = modalPosition;
    },
    closeModal() {
      self.isOpen = false;
      self.modalType = null;
      self.modalProps = null;
      self.containerProps = null;
      self.modalPosition = null;
    }
  }));

export default ModalStore;

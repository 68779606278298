import {
  types,
  flow,
  getParent,
  detach,
  destroy,
  setLivelynessChecking
} from "mobx-state-tree";
import {
  stats as statsModule,
  user,
  utils,
  game as gameModule
} from "@myplay/all";
import { toast } from "@myplay/ui";
import I18N from "../../I18N";

import optionalAndMaybeNull from "../../utils/mobx-optional-and-maybe-null";
import { TEAM_CHANNEL_GAMES_BULK_SIZE } from "../../utils/constants";

setLivelynessChecking("error");

const MAIN_VIDEOS_NUMBER = 3;

const Stats = types.model(`GameStats`, {
  donuts: types.frozen(),
  bars: types.frozen(),
  isStats: types.boolean
});

const Game = types
  .model(`Game`, {
    _id: types.identifier,
    thumbnailUri: optionalAndMaybeNull(types.string),
    fullStreamUri: optionalAndMaybeNull(types.string),
    moreCameras: optionalAndMaybeNull(types.frozen()),
    senderId: optionalAndMaybeNull(types.string),
    uniqueId: optionalAndMaybeNull(types.string),
    vid: optionalAndMaybeNull(types.string),
    videoType: optionalAndMaybeNull(types.string),
    title: optionalAndMaybeNull(types.string),
    date: optionalAndMaybeNull(types.string),
    videoDocId: optionalAndMaybeNull(types.string),
    videoAngle: optionalAndMaybeNull(types.string),
    videoCameraType: optionalAndMaybeNull(types.string),
    isShared: optionalAndMaybeNull(types.boolean),
    hasStats: optionalAndMaybeNull(types.boolean),
    info: optionalAndMaybeNull(types.frozen()),
    canEdit: optionalAndMaybeNull(types.frozen())
  })
  .actions(self => ({
    changeTitle: flow(function* changeTitle(title, sendToServer) {
      self.title = title;

      if (sendToServer) {
        yield gameModule.videos.updateVideoContainer(self._id, { title });
      }
    }),
    remove() {
      detach(getParent(self));
    }
  }));

const GamesStore = types
  .model(`GamesStore`, {
    currentTeamGames: optionalAndMaybeNull(types.array(Game)),
    currentGame: optionalAndMaybeNull(types.reference(Game)),
    currentTeamGamesCursor: types.number,
    currentTeamGamesCount: optionalAndMaybeNull(types.number),
    lastGameStats: optionalAndMaybeNull(Stats),
    isLoading: types.optional(types.boolean, false)
  })
  .views(self => ({
    get mainCurrentTeamGames() {
      return self.currentTeamGames.slice(0, MAIN_VIDEOS_NUMBER);
    },
    get restCurrentTeamGames() {
      return self.currentTeamGames.slice(
        MAIN_VIDEOS_NUMBER,
        self.currentTeamGames ? self.currentTeamGames.length : 0
      );
    },
    get hasMoreVideos() {
      return self.currentTeamGamesCount > self.currentTeamGamesCursor;
    }
  }))
  .actions(self => ({
    fetchCurrentTeamGames: flow(function* fetchCurrentTeamGames(
      limit = TEAM_CHANNEL_GAMES_BULK_SIZE,
      skip
    ) {
      if (self.isLoading) {
        return;
      }

      self.isLoading = self.currentTeamGames.length === 0;

      const currentTeamId =
        getParent(self).TeamsStore.currentTeam &&
        getParent(self).TeamsStore.currentTeam._id;

      if (!currentTeamId) {
        return;
      }

      let currentTeamGames = [];

      try {
        currentTeamGames = yield user.teams.getTeamVideos(
          currentTeamId,
          limit,
          skip
        );
        const processedGames = currentTeamGames.map(game =>
          self.processGame(game)
        );

        self.currentTeamGamesCursor += TEAM_CHANNEL_GAMES_BULK_SIZE;

        if (self.currentTeamGames && self.currentTeamGames.length) {
          self.currentTeamGames.push(...processedGames);
          self.isLoading = false;
          return;
        }

        self.currentTeamGames = processedGames;

        if (processedGames[0].hasStats) {
          self.setLastGameStats(processedGames[0]);
        }
        else {
          self.lastGameStats = { donuts: [], bars: [], isStats: false };
        }
      } catch (e) {
        self.currentTeamGame = currentTeamGames;
        return [];
      } finally {
        self.isLoading = false;
        return currentTeamGames;
      }
    }),
    setLastGameStats: flow(function* setLastGameStats(game) {
      try {
        const stats = yield statsModule.stats.getTeamStats(game._id);
        self.lastGameStats =
          typeof stats === "object"
            ? stats
            : { donuts: [], bars: [], isStats: false };
      } catch (error) {
        console.log(error);
      }
    }),
    processGame(game) {
      const { _id, video, title, isShared, hasStats, info, recordDate, canEdit } = game;
      const {
        fullStreamUri,
        moreCameras,
        angle,
        insertionDate,
        senderId,
        thumbnailUri,
        uniqueId,
        vid,
        videoType,
        cameraType
      } = video;

      return {
        fullStreamUri,
        moreCameras,
        senderId,
        thumbnailUri,
        uniqueId,
        vid,
        _id,
        videoType,
        videoCameraType: cameraType,
        title,
        isShared,
        videoDocId: video._id,
        date: recordDate || insertionDate,
        videoAngle: angle,
        hasStats,
        info,
        canEdit
      };
    },

    updateCurrentTeamGames(teamGames) {
      self.currentTeamGames = teamGames;
    },

    deleteGame: flow(function* deleteGame(gameId) {
      try {
        const { ModalStore } = getParent(self);

        const currentTeamGames = self.currentTeamGames.filter(
          ({ _id }) => _id !== gameId
        );
        self.updateCurrentTeamGames(currentTeamGames);

        yield gameModule.videos.deleteVideoContainer(gameId);

        if (ModalStore.isOpen) {
          ModalStore.closeModal();
        }
      } catch (error) {
        console.log(error);
      }
    }),
    shareGame: flow(function* shareGame(videoDocId, thumbnail) {
      try {
        const sharedItem = yield utils.setShareItem("video", videoDocId);
        const link = `${process.env.REACT_APP_SHARE_URL}/team-channel/${
          sharedItem.sharedLinkId
        }`;
        const { ModalStore } = getParent(self);

        ModalStore.openModal({
          modalType: "SHARE_GAME",
          modalPosition: "center",
          modalProps: {
            link,
            thumbnail
          }
        });
      } catch (error) {
        console.log(error);
      }
    }),
    getAndDisplaySharedGame: flow(function* getAndDisplaySharedGame(
      sharedItemId
    ) {
      try {
        const { ModalStore } = getParent(self);

        const item = yield utils.getShareItem(sharedItemId);

        if (item && item.link && item.link === "invalid") {
          toast.error(I18N.t("SHARED_ITEM_USED"));
          return;
        }

        ModalStore.openModal({
          modalType: "INSERT_SHARED_GAME",
          modalPosition: "center",
          modalProps: {
            item: item[0],
            sharedItemId
          }
        });
      } catch (error) {
        console.log("error: ", error);
      }
    }),
    insertSharedItem: flow(function* insertSharedItem(sharedItemId, team) {
      const { ModalStore, TeamsStore } = getParent(self);
      try {
        yield gameModule.videos.insertVideoFromShare(
          sharedItemId,
          team._id
        );
      } catch (e) {
        toast.error(e);
      }

      if (team._id !== TeamsStore.currentTeam._id) {
        TeamsStore.setCurrentTeam(team._id);
      }

      try {
        yield utils.setInvalidStatusForShareItem(sharedItemId);
      } catch (error) {
        console.log(error);
      }
      ModalStore.closeModal();
    }),
    assignAssistant(videoContainerId) {
      const { ModalStore } = getParent(self);

      ModalStore.openModal({
        modalType: "ASSIGN_GAME_ASSISTANT",
        modalPosition: "center",
        modalProps: {
          videoContainerId,
          handleCloseModal: () => ModalStore.closeModal()
        }
      });
    },
    downloadGame: flow(function* downloadGame({ videoContainerId, videoId }) {
      const { UserStore } = getParent(self);

      yield gameModule.videos.downloadVideo(
        videoContainerId,
        videoId,
        UserStore.data._id
      );
    }),
    setCurrentTeamGamesCount(count) {
      self.currentTeamGamesCount = count;
    },
    fetchCurrentTeamGamesCount: flow(function* fetchCurrentTeamGamesCount() {
      try {
        const currentTeamId =
          getParent(self).TeamsStore.currentTeam &&
          getParent(self).TeamsStore.currentTeam._id;

        const request = yield user.teams.getCountOfTeamVideos(currentTeamId);

        if (request && request.count) {
          self.currentTeamGamesCount = request.count;
        }
      } catch (error) {
        console.log(error);
      }
    }),
    clear() {
      if (self.currentTeamGames && self.currentTeamGames.length) {
        self.currentTeamGames.map(game => destroy(game));
      }

      self.currentGame = null;
      self.currentTeamGamesCount = 0;
      self.lastGameStats = null;
      self.currentTeamGamesCursor = 0;
    },
    updateHomeScore: flow(function* updateHomeScore(videoId, score) {
      // eslint-disable-line
      try {
        yield gameModule.videos.setHomeTeamScore(videoId, score);
      } catch (error) {
        console.log(error);
      }
    }),
    updateAwayScore: flow(function* updateAwayScore(videoId, score) {
      // eslint-disable-line
      try {
        yield gameModule.videos.setAwayTeamScore(videoId, score);
      } catch (error) {
        console.log(error);
      }
    })
  }));

export default GamesStore;

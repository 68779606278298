import React, { Component } from "react";
import PropTypes from "prop-types";
import { WithLoader } from "@myplay/ui";
import { inject, observer } from "mobx-react/index";
import bellNotificationsIcon from "../../assets/icons/Icon-Notification-Grey.svg";
import I18N from "../../I18N";
import Notification from "./Notification";

class NotificationsList extends Component {
  static propTypes = {
    notifications: PropTypes.array,
    onNotificationClick: PropTypes.func
  };

  static defaultProps = {
    notifications: [],
    onNotificationClick: () => {}
  };

  loaderTimeout = null;

  state = {
    isLoading: false
  };

  shouldComponentUpdate() {
    return true;
  }

  componentWillUnmount() {
    clearTimeout(this.loaderTimeout);
  }

  handleScroll = async event => {
    try {
      const { getMoreNotifications } = this.props;
      const element = event.target;

      if (
        !this.state.isLoading &&
        element.scrollTop >= element.scrollHeight - element.offsetHeight
      ) {
        this.setState({
          isLoading: true
        });

        await getMoreNotifications();
        this.loaderTimeout = setTimeout(() => {
          this.setState({
            isLoading: false
          });
        }, 1500);
      }
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    const { isLoading } = this.state;
    const {
      notifications,
      onNotificationClick,
      NotificationsStore,
      ModalStore
    } = this.props;
    // console.log(notifications);

    return (
      <div className="notifications__items">
        {notifications && notifications.length ? (
          <ul
            id="notifications-list"
            className="notifications__items__list"
            data-testid="notifications-list"
            onScroll={this.handleScroll}
          >
            {notifications &&
              notifications.map(notification => (
                <li
                  key={notification._id}
                  data-testid={notification._id}
                  className="notifications__items__list__item"
                >
                  <Notification
                    {...notification}
                    disableClick={notification.type === "task"} // eslint-disable-line
                    onClick={
                      notification.type === "task"
                        ? () => null
                        : () => {
                            onNotificationClick(notification);
                          }
                    }
                    handleCancelTask={(id, subType) => {
                      ModalStore.openModal({
                        modalType: "CONFIRM_MODAL",
                        modalPosition: "center",
                        modalProps: {
                          text: `${I18N.t("CANCEL_TASK")}`,
                          handleSuccess: () => {
                            NotificationsStore.cancelTask(id, subType);
                          },
                          onCancel: () => {
                            ModalStore.closeModal();
                          }
                        }
                      });
                    }}
                  />
                </li>
              ))}
            <div className="notifications__items__list__loader">
              <WithLoader isLoading={isLoading} />
            </div>
          </ul>
        ) : (
          <div className="notifications__items__no-items">
            <img
              src={bellNotificationsIcon}
              className="notifications__items__no-items__icon"
            />
            <p className="notifications__items__no-items__text">
              {I18N.t("HI_THERE_YOU_HAVE")}
              <span className="notifications__items__no-items__text__green">
                {I18N.t("NO_NEW_NOTIFICATIONS")}
              </span>
            </p>
          </div>
        )}
      </div>
    );
  }
}

export default inject("NotificationsStore","ModalStore")(observer(NotificationsList));

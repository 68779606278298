import React from "react";
import { Route, Redirect, withRouter } from "react-router-dom";
import { inject, observer } from "mobx-react";

import { TEAM_CHANNEL_ROUTE_NAME } from "../../utils/constants";
import { UnauthLayout } from "../../components/";

const UnauthRoute = ({ Component, UserStore, location, ...rest }) => {
  const isAuth = UserStore.isAuthenticated;

  return (
    // <Route
    //   {...rest}
    //   render={props =>
    //     isAuth && false ? (
    //       <Redirect
    //         to={{
    //           pathname: TEAM_CHANNEL_ROUTE_NAME,
    //           state: { from: location }
    //         }}
    //       />
    //     ) : (
    //       <UnauthLayout>
    //         <Component {...props} />
    //       </UnauthLayout>
    //     )
    //   }
    // />
    <Route 
      render={props => 
        <UnauthLayout>
          <Component {...props} />
        </UnauthLayout>
      }
    />
  );
};

export default withRouter(inject(["UserStore"])(observer(UnauthRoute)));

/* eslint-disable */
import React, { Component } from "react";
import ReactCodeInput from "react-code-input";
import { toast } from "@myplay/ui";
import { user } from "@myplay/all";
import I18N from "../../../I18N";

import "./VerifyPhone.scss";

const authModule = user.auth;

const TIMER_TIME = 5 * 60 * 1000;

class VerifyPhone extends Component {
  state = {
    countDown: TIMER_TIME,
    phone: ''
  };

  async componentDidMount() {
    try {
      await this.handleSendVerificationCode();
    } catch (e) {}
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  startCountDown = () => {
    clearInterval(this.timer);
    this.setState(
      {
        countDown: TIMER_TIME
      },
      () => {
        this.timer = setInterval(() => {
          const { countDown } = this.state;

          if (countDown > 0) {
            this.setState(prevState => ({
              countDown: prevState.countDown - 1000
            }));
          } else {
            clearInterval(this.timer);
          }
        }, 1000);
      }
    );
  };

  millisToMinutesAndSeconds = millis => {
    if (millis) {
      const minutes = Math.floor(millis / 60000);
      const seconds = ((millis % 60000) / 1000).toFixed(0);
      return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
    }
  };

  validateVerificationCode = async value => {
    const isCodeValid = value && value.length === 4;
    if (isCodeValid) {
      await this.handleVerificationCodeValidation(value);
    }
  };

  handleVerificationCodeValidation = async verificationCode => {
    const { requestId } = this;
    const { onSendValidCode } = this.props;

    try {
      const isValid = await authModule.checkVerifyPhone({
        code: verificationCode,
        requestID: requestId
      });
      if (isValid) {
        onSendValidCode();
      }
    } catch (error) {
      console.log(error);
      toast.error("wrong code");
    }
  };

  handleSendVerificationCode = async () => {
    const { phone, country } = this.props;
    const { countDown } = this.state;
    try {
      const request = await authModule.verifyPhone(phone, country);
      this.requestId = request.request_id;
      this.setState({phone: request.fullPhone})
    } catch (error) {
      console.log(error);
      toast.error(error);
    } finally {
      if (countDown === TIMER_TIME) {
        this.startCountDown();
      }
    }
  };

  render() {
    const { countDown ,phone } = this.state;
    return (
      <div className="verify-phone-modal">
        <div className="verify-phone-modal__verify_text">
          <p className="verify-phone-modal__verify_text__title">
            {I18N.t("WE_HAVE_SENT_SMS")}
          </p>
          <p className="verify-phone-modal__verify_text__title verify-phone-modal__verify_text__title--green ">
            {phone}
          </p>
          <p className="verify-phone-modal__verify_text__title verify-phone-modal__verify_text__title--small">
            {I18N.t("PLEASE_ENTER_THE_CODE")}
          </p>
          <p className="verify-phone-modal__verify_text__title verify-phone-modal__verify_text__title--small">
            {I18N.t("TO_COMPLETE_REQUEST")}
          </p>
        </div>
        <div>
          <ReactCodeInput
            type="number"
            inputStyle={{
              background: "White",
              border: "none",
              width: "44px",
              height: "48px",
              margin: "0px 8px",
              color: "grey",
              fontSize: "35px",
              textAlign: "center"
            }}
            onChange={value => {
              this.validateVerificationCode(value);
            }}
          />
          {/* eslint-disable */}
          <div
            className="verify-phone-modal__resend-code"
            onClick={this.handleSendVerificationCode}
          >
            <span>{I18N.t("RESEND_CODE")}</span>
            <span>{this.millisToMinutesAndSeconds(countDown) || "0:00"}</span>
          </div>
          {/* eslint-enable */}
        </div>
      </div>
    );
  }
}

export default VerifyPhone;

import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react/index";
import "./HeaderButtonsPanel.scss";
import ActionOnHoverButton from "./ActionOnHoverButton";
import I18N from "../../I18N/";

import history from "../../utils/history";
import liveIcon from "../../assets/icons/Icon-Live.svg";
import recordIcon from "../../assets/icons/Icon-Record.svg";
import switchTeamIcon from "../../assets/icons/Icon-SwitchTeam.svg";
import uploadIcon from "../../assets/icons/Icon-Upload.svg";
import myProfileIcon from "../../assets/icons/Icon-MyProfile.svg";
import bookingIcon from "../../assets/images/booking.svg";
import { UserTypeGuard } from "../index";
import { PLAYER_PROFILE_ROUTE_NAME } from "../../utils/constants";


const HeaderButtonsPanel = ({
  ModalStore,
  SiteStore,
  UserStore,
  BookingsStore
}) => {
  const handleLiveClick = () => {
    history.push("/app/live");
  };

  const handleRecordClick = () => {
    SiteStore.checkRecordStatus();
    ModalStore.openModal({
      modalType: "RECORD",
      modalPosition: "top"
    });
  };

  const handleUploadVideoClick = () => {
    ModalStore.openModal({
      modalType: "SELECT_UPLOAD_VIDEO_METHOD",
      modalPosition: "top"
    });
  };

  const handleChangeTeam = () => {
    ModalStore.openModal({
      modalType: "CHANGE_TEAM",
      modalPosition: "top"
    });
  };

  const handleUserProfile = () => {
    const {
      data: { _id }
    } = UserStore;
    history.push(`${PLAYER_PROFILE_ROUTE_NAME}/${_id}`);
  };

  const handleBookingClick = () => {
    ModalStore.openModal({
      modalType: "BOOKING",
      modalPosition: "top"
    });
  }

  return (

    <div className="header-buttons-panel" data-testid="header-buttons-panel">
      <UserTypeGuard allowed={["operator", "enabler"]}>
        {SiteStore._id &&
          SiteStore.isRemoteProducer && (
            <Fragment>
              <ActionOnHoverButton
                icon={bookingIcon}
                buttonText={I18N.t("BOOKING")}
                style={{ zIndex: 3 }}
                iconStyle={{width: "25px", marginRight: '10px'}}
                onClick={handleBookingClick}
              />
            </Fragment>
          )}
      </UserTypeGuard>
      <UserTypeGuard allowed={["coach", "operator", "enabler"]}>
        {(SiteStore._id &&
          !SiteStore.isRemoteProducer || UserStore.data.isAdminUser) && (
            <Fragment>
              <ActionOnHoverButton
                icon={recordIcon}
                buttonText={I18N.t("RECORD")}
                style={{ zIndex: 3 }}
                onClick={handleRecordClick}
              />
            </Fragment>
          )}
      </UserTypeGuard>

      <UserTypeGuard allowed={["operator", "enabler"]}>
        <ActionOnHoverButton
          icon={liveIcon}
          buttonText={I18N.t("LIVE")}
          style={{ zIndex: 5 }}
          onClick={handleLiveClick}
        />
      </UserTypeGuard>
      <ActionOnHoverButton
        icon={switchTeamIcon}
        buttonText={I18N.t("SWITCH_TEAM")}
        style={{ zIndex: 4 }}
        onClick={handleChangeTeam}
      />
      <UserTypeGuard allowed={["player"]}>
        <ActionOnHoverButton
          icon={myProfileIcon}
          buttonText={I18N.t("MY_PROFILE")}
          style={{ zIndex: 5 }}
          onClick={handleUserProfile}
        />
      </UserTypeGuard>
      <UserTypeGuard allowed={["coach", "operator", "enabler"]}>
        <ActionOnHoverButton
          icon={uploadIcon}
          buttonText={I18N.t("UPLOAD")}
          style={{ zIndex: 6 }}
          onClick={handleUploadVideoClick}
        />
      </UserTypeGuard>
    </div>
  );
};

HeaderButtonsPanel.propTypes = {
  ModalStore: PropTypes.shape().isRequired,
  SiteStore: PropTypes.shape().isRequired,
  UserStore: PropTypes.shape().isRequired
};

export default inject("ModalStore", "SiteStore", "UserStore", "BookingsStore")(
  observer(HeaderButtonsPanel)
);

import React from "react";
import PropTypes from "prop-types";

import I18N from "../../I18N";
import "./NoInternetConnection.scss";
import noInternet from "../../assets/images/no-internet.png";
import logo from "../../assets/images/logo-name.png";

const NoInternetConnection = ({noInternetLogo}) => {
  return (
    <div className="no-internet-connection">
      <img style={{width:'240px'}} src={logo} className="no-internet-connection__logo" />
      <img style={{width:'140px'}} src={noInternetLogo} />
      <p className="no-internet-connection__secondary-title">
        {I18N.t("CONNECTION_ERROR_2")}
      </p>
    </div>
  );
};

export default NoInternetConnection;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Autocomplete } from '@myplay/ui';
import I18N from '../../I18N';

class Countries extends Component {

  state = {
    countryIndex: null
  }

  shouldComponentUpdate() {
    return true;
  }

  componentDidUpdate(prevProps) {
    const { countries, input, currentCountry, countryFromInvitation } = this.props;
    if (countries.length !== prevProps.countries.length) {
      const countryFromInvitationIndex = countries.map(c => c.value).indexOf(countryFromInvitation);
      this.setState({// eslint-disable-line
        countryIndex: countryFromInvitation ? countryFromInvitationIndex : currentCountry
      },()=> input.onChange(countries[this.state.countryIndex]))

    }
  }

  render() {
    const { input ,countries } = this.props;
    const { countryIndex } = this.state;
    return (countries[countryIndex] ?
      <Autocomplete
        defaultSelectedItem={countries[countryIndex] || {}}
        defaultInputValue={
          (countries[countryIndex] && countries[countryIndex].value) || ""
        }
        placeholder={I18N.t("COUNTRY")}
        items={countries || []}
        input={input}
      /> : null
    );
  }
};

Countries.propTypes = {
  countries: PropTypes.array,
  countryFromInvitation: PropTypes.string,
  currentCountry: PropTypes.number,
  input: PropTypes.object,
}

Countries.defaultProps = {
  countries: [],
  countryFromInvitation: '',
  currentCountry: 0,
  input: {},
}

export default Countries

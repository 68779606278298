import React from "react";
import PropTypes from "prop-types";

import { Loader } from "@myplay/ui";

import I18N from "../../I18N";

const Beacon = ({ count }) => {
  return (
    <div className="notifications__icon__beacon">
      <span className="notifications__icon__beacon__text">{count}</span>
    </div>
  );
};

const NotificationsIcon = ({ id, onClick, unreadCount, activeTasksCount }) =>
  activeTasksCount > 0 ? (
    <div className="notifications__icon__active-tasks">
      <p style={{display:'none'}}>{I18N.t("PROCESSING")}</p>
      <button type="button" className="notifications__icon" onClick={onClick}>
        <Loader type="spin" color="red" height="40px" width="40px" />
        <p className="notifications__icon__task-counter">{activeTasksCount}</p>
      </button>
    </div>
  ) : (
    <button
      id={id}
      type="button"
      data-testid="notifications-icon"
      className="notifications__icon"
      onClick={onClick}
    >
      {unreadCount > 0 ? <Beacon count={unreadCount} /> : null}
      <span className="icon-notification notifications__icon__icon" />
    </button>
  );

NotificationsIcon.propTypes = {
  activeTasksCount: PropTypes.number,
  id: PropTypes.string,
  onClick: PropTypes.func,
  unreadCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

NotificationsIcon.defaultProps = {
  activeTasksCount: 0,
  id: "notifcations-icon",
  onClick: () => {},
  unreadCount: 0
};

Beacon.propTypes = {
  count: PropTypes.number
};

Beacon.defaultProps = {
  count: 0
};

export default NotificationsIcon;

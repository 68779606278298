/* eslint-disable */
import { types, flow, getParent } from "mobx-state-tree";
import {
  cameraControl,
  liveProduction,
  remoteProducer as remoteProducerApi
} from "@myplay/all";
import { toast } from "@myplay/ui";

import optionalAndMaybeNull from "../../utils/mobx-optional-and-maybe-null";
import I18N from "../../I18N";
import {
  ERROR,
  READY_TO_RECORD,
  RECORDING,
  ANALYTICS_GAME_RECORD,
  ANALYTICS_TRAINING_RECORD,
  ANALYTICS_LIVE_VIEW,
  CHECK_IS_LIVE_READY,
  START_LIVE_VIEW
} from "../../utils/constants";
import { sendAnalyticsEvent } from "../../utils/analytics";

const { live, record } = cameraControl;
const siteModule = cameraControl.site;

const DEFAULT_DURATION = 180 * 60;

const Sender = types.model("Sender", {
  _id: optionalAndMaybeNull(types.string),
  firstName: optionalAndMaybeNull(types.string),
  lastName: optionalAndMaybeNull(types.string)
});

const Record = types.model("Task", {
  videoTitle: optionalAndMaybeNull(types.string),
  recordEndsOn: optionalAndMaybeNull(types.string),
  senderId: optionalAndMaybeNull(Sender)
});

const Task = types.model("Task", {
  record: optionalAndMaybeNull(Record)
});

const Camera = types.model("Camera", {
  status: optionalAndMaybeNull(types.string),
  task: optionalAndMaybeNull(Task),
  position: optionalAndMaybeNull(types.string)
});

const RecordState = types.model("Record", {
  isTraining: false,
  isCamerasReady: false,
  isLoading: false,
  isRecording: false,
  fetchComplete: false,
  shouldRenderCounter: false,
  recordName: "",
  timeToEnd: optionalAndMaybeNull(types.number)
});

const LiveState = types.model("LiveState", {
  defaultCameraIndex: types.string,
  isLive: types.boolean,
  sessionId: optionalAndMaybeNull(types.string),
  currentStreamingIndex: optionalAndMaybeNull(types.number),
  youTubeShareLink: optionalAndMaybeNull(types.string)
});

const SiteStore = types
  .model(`Site`, {
    _id: optionalAndMaybeNull(types.string),
    name: optionalAndMaybeNull(types.string),
    liveState: optionalAndMaybeNull(LiveState),
    recordState: optionalAndMaybeNull(RecordState),
    isLiveReady: false,
    isOnline: false,
    liveError: optionalAndMaybeNull(types.string),
    cameras: optionalAndMaybeNull(types.array(Camera)),
    recordError: optionalAndMaybeNull(types.string),
    hasCancelRequest: optionalAndMaybeNull(types.boolean),
    isCheckDone: true,
    hasTrackingCamera: optionalAndMaybeNull(types.boolean),
    checkSitePromise: optionalAndMaybeNull(types.frozen()),
    isCheckLiveCanceled: false,
    isObs: false,
    hasScoreDetection: false,
    pendingRequests: types.array(types.string),
    hasDoubleLiveCameras: optionalAndMaybeNull(types.boolean),
    isRemoteCheckDone: false,
    internalStreams: optionalAndMaybeNull(types.frozen())
  })
  .actions(self => ({
    setSite: flow(function* setSite() {
      if (!self.checkSitePromise) {
        self.checkSitePromise = self.setSitePromise();
      }
      return self.checkSitePromise;
    }),

    setSitePromise: flow(function* setSitePromise() {
      try {
        self.isCheckDone = false;
        self.liveError = null;
        const { TeamsStore } = getParent(self);

        if (TeamsStore.currentTeam) {
          self._id = TeamsStore.currentTeam.connectedSiteId;
        }

        if (self._id) {
          const {
            liveState,
            name,
            isOnline,
            cameras,
            _id,
            obs,
            scoreboard,
            hasDoubleLiveCameras,
          } = yield siteModule.checkSite(TeamsStore.currentTeam._id);

          if (TeamsStore.currentTeam.connectedSiteId === _id) {
            self.name = name;
            self.liveState = liveState;
            self.isOnline = isOnline;
            self.cameras = cameras;
            self.isObs = obs.isAvailable;
            self.hasScoreDetection = scoreboard.hasScoreDetection;
            self.hasDoubleLiveCameras = hasDoubleLiveCameras;
          }
        }
      } catch (error) {
        self.resetSite();
        console.log(error);
      } finally {
        self.setIsCheckDone(true);
        self.checkSitePromise = null;
        if (!self.isOnline) {
          self.setLiveError(I18N.t("LIVE_SITE_ERROR"));
        }
      }
    }),

    resetSite() {
      self.name = null;
      self.liveState = null;
      self.isOnline = false;
      self.cameras = null;
      self.checkSitePromise = null;
      self.isRemoteProducer = false;
    },

    setCameras(cameras) {
      const formatCameras = cameras.map(camera => {
        return {
          status: camera.status,
          task: camera.task && {
            record: {
              videoTitle: camera.task.record && camera.task.record.videoTitle,
              recordEndsOn:
                camera.task.record && camera.task.record.recordEndsOn
            }
          }
        };
      });
      return formatCameras;
    },

    setCancelRequest(val) {
      self.hasCancelRequest = val;
    },

    setLiveReady(isReady) {
      self.isLiveReady = isReady;
    },
    setLiveError(val) {
      self.liveError = val;
    },
    setIsCheckDone(isCheckDone) {
      self.isCheckDone = isCheckDone;
    },
    setIsCheckLiveCanceled(isCanceled) {
      self.isCheckLiveCanceled = isCanceled;
    },
    setIsLive(isLive) {
      self.liveState.isLive = isLive;
    },
    checkLiveStatus: flow(function* checkLiveStatus() {
      try {
        yield self.setSite();
        if (self.liveState && self.liveState.isLive) {
          // try {
          //   if (self.isObs) {
          //     const currentTeamId =
          //       getParent(self).TeamsStore.currentTeam &&
          //       getParent(self).TeamsStore.currentTeam._id;
          //     const location =
          //       (getParent(self).TeamsStore.currentTeam &&
          //         getParent(self).TeamsStore.currentTeam.scoreBoardLocation) ||
          //       (getParent(self).TeamsStore.currentTeam &&
          //         getParent(self).TeamsStore.currentTeam.category.scoreBoardLocation);
          //     // yield liveProduction.updateScoreDisplay(currentTeamId, location);
          //   }
          // } catch (e) {
          //   console.error(e);
          // }
          self.checkLiveIsReady();
        }
      } catch (error) {
        self.setLiveError(I18N.t("LIVE_SITE_ERROR"));
      }
    }),
    handleSwitchLiveCamera(initProgress) {
      const { ModalStore } = getParent(self);

      ModalStore.openModal({
        modalType: "CONFIRM_MODAL",
        modalPosition: "center",
        modalProps: {
          text: I18N.t("SWITCH_LIVE_QUESTION"),
          handleSuccess: () => {
            self.switchLiveCamera();
            initProgress();
          },
          onCancel: () => {}
        }
      });
    },
    switchLiveCamera: flow(function* switchLiveCamera() {
      const switchCameraIndex = self.cameras.findIndex(
        camera =>
          camera.position !== "Score" &&
          camera.position !==
            self.cameras[self.liveState.currentStreamingIndex].position
      );
      const currentTeamId =
        getParent(self).TeamsStore.currentTeam &&
        getParent(self).TeamsStore.currentTeam._id;
      try {
        self.setLiveReady(false);
        const closerId =
          getParent(self).UserStore.data && getParent(self).UserStore.data._id;
        yield live.stopLive(currentTeamId, closerId);
        yield self.startLiveView(switchCameraIndex);
      } catch (error) {
        console.log(error);
      }
    }),
    startLiveView: flow(function* startLiveView(
      cameraIndex,
      thirdPartyProxyUri,
      youTubeShareLink
    ) {
      try {
        self.isCheckLiveCanceled = true;
        self.liveState.isLive = true;
        self.addPendingRequest(START_LIVE_VIEW);
        const currentTeamId =
          getParent(self).TeamsStore.currentTeam &&
          getParent(self).TeamsStore.currentTeam._id;
        const currentTeamName =
          getParent(self).TeamsStore.currentTeam &&
          getParent(self).TeamsStore.currentTeam.name;
        const currentUserEmail =
          getParent(self).UserStore.data &&
          getParent(self).UserStore.data.email;
        const currentUserId =
          getParent(self).UserStore.data && getParent(self).UserStore.data._id;
        self.liveState.sessionId = yield live.startLive(
          currentTeamId,
          DEFAULT_DURATION,
          cameraIndex || self.liveState.currentActiveCameraIndex,
          currentUserId,
          thirdPartyProxyUri,
          youTubeShareLink
        );
        sendAnalyticsEvent(ANALYTICS_LIVE_VIEW, {
          teamName: currentTeamName,
          teamId: currentTeamId,
          email: currentUserEmail
        });
        yield self.checkLiveStatus();
      } catch (error) {
        console.log(error);
        if (self.liveState) {
          self.liveState.isLive = false;
        }
        self.isLiveReady = false;
        self.setLiveError(I18N.t("LIVE_SITE_ERROR"));
      } finally {
        self.removePendingRequest(START_LIVE_VIEW);
      }
    }),
    setHasTrackingCamera(cameras) {
      const trackingCameras = ["PRODUCTION-SERVICE", "TRACKING", "PTZ"];
      const hasTrackingCamera =
        cameras && cameras.length
          ? cameras.filter(({ cameraType }) =>
              trackingCameras.includes(cameraType)
            )
          : [];

      self.hasTrackingCamera = hasTrackingCamera
        ? hasTrackingCamera.length > 0
        : false;
    },
    setIsObs(cameras) {
      console.log(cameras);
      try {
        const isObsCamera =
          cameras && cameras.length
            ? cameras.filter(
                ({ cameraType }) => cameraType === "PRODUCTION-SERVICE"
              )
            : [];

        self.isObs = isObsCamera.length > 0;
      } catch (e) {
        console.log(e);
      }
    },
    checkLiveIsReady: flow(function* checkLiveIsReady() {
      try {
        self.addPendingRequest(CHECK_IS_LIVE_READY);
        const cameras = yield live.liveAuth(self.liveState.sessionId);
        self.setHasTrackingCamera(cameras);

        if (cameras.length > 0) {
          self.liveState.youTubeShareLink = cameras[0].youTubeShareLink;
        }

        // const timeout = setTimeout(()=> {// timeout to handle Site becoming offline after live request was sent
        //   self.setLiveError(I18N.t("LIVE_FAILED"));
        // }, 1500 * 100)// time out should be the same as in Live Component
        const isMainCameraReady = yield live.requestCamerasUntilReadyWithCameras(
          cameras,
          60,
          5000
        );
        // clearTimeout(timeout);

        if (isMainCameraReady) {
          self.setLiveReady(true);
        } else {
          if (!self.isCheckLiveCanceled) {
            self.cancelLive();
            self.setLiveError(I18N.t("LIVE_FAILED"));
          }
        }
      } catch (e) {
        self.cancelLive();
        self.setLiveError(I18N.t("LIVE_FAILED"));
      } finally {
        self.removePendingRequest(CHECK_IS_LIVE_READY);
      }
    }),
    handleCancelLive(handlers) {
      const { ModalStore } = getParent(self);
      const { handleBack, handleCancelOnPage } = handlers;

      ModalStore.openModal({
        modalType: "CONFIRM_MODAL",
        modalPosition: "center",
        modalProps: {
          text: I18N.t("STOP_LIVE_QUESTION"),
          handleSuccess: () => {
            self.cancelLive();
            if (handleBack && typeof handleBack === "function") {
              handleBack();
            }
            if (
              handleCancelOnPage &&
              typeof handleCancelOnPage === "function"
            ) {
              handleCancelOnPage();
            }
          },
          onCancel: () => {
            if (handleBack && typeof handleBack === "function") {
              handleBack();
              self.setIsCheckLiveCanceled(true);
              live.blockRequestCamerasUntilReady();
            }
          }
        }
      });
    },
    cancelLive: flow(function* cancelLive() {
      self.setIsCheckDone(false);
      if (self.liveState) {
        self.liveState.isLive = false;
      }
      self.setLiveReady(false);
      const currentTeamId =
        getParent(self).TeamsStore.currentTeam &&
        getParent(self).TeamsStore.currentTeam._id;
      try {
        const closerId =
          getParent(self).UserStore.data && getParent(self).UserStore.data._id;
        live.blockRequestCamerasUntilReady();
        yield live.stopLive(currentTeamId, closerId);
        yield self.setSite();
      } catch (error) {
        console.log(error);
      }
    }),

    setRecordName(recordName) {
      if (recordName) {
        self.recordState.recordName = recordName;
      } else {
        const recordingCamera =
          self.cameras &&
          self.cameras.find(camera => camera.status === "RECORDING");
        self.recordState.recordName =
          (recordingCamera &&
            recordingCamera.task &&
            recordingCamera.task.record &&
            recordingCamera.task.record.videoTitle) ||
          "";
      }
    },

    setTimeToEnd: flow(function* setTimeToEnd() {
      const recordingCamera =
        self.cameras &&
        self.cameras.find(
          camera => camera.status === "RECORDING" && camera.task !== null
        );
      const endingTime =
        recordingCamera &&
        recordingCamera.task &&
        recordingCamera.task.record &&
        recordingCamera.task.record.recordEndsOn;

      if (!endingTime) {
        return 0;
      }

      const { time } = yield siteModule.getCurrentTime();

      const localEndingTime = new Date(endingTime).getTime();
      const diff = localEndingTime - time;


      self.recordState.timeToEnd = diff > 0 ? diff : 0;
      self.recordState.shouldRenderCounter = true;
    }),

    startRecord: flow(function* startRecord() {
      self.recordState.fetchComplete = false;
      try {
        const currentTeamId =
          getParent(self).TeamsStore.currentTeam &&
          getParent(self).TeamsStore.currentTeam._id;
        const currentTeamName =
          getParent(self).TeamsStore.currentTeam &&
          getParent(self).TeamsStore.currentTeam.name;
        const currentUserId =
          getParent(self).UserStore.data && getParent(self).UserStore.data._id;
        const currentUserEmail =
          getParent(self).UserStore.data &&
          getParent(self).UserStore.data.email;
        yield record.startRecord({
          senderID: currentUserId,
          teamID: currentTeamId,
          videoTitle: self.recordState.recordName,
          recordType: self.recordState.isTraining ? "training" : "game",
          isTesting: true
        });
        sendAnalyticsEvent(
          self.recordState.isTraining
            ? ANALYTICS_TRAINING_RECORD
            : ANALYTICS_GAME_RECORD,
          {
            teamId: currentTeamId,
            teamName: currentTeamName,
            email: currentUserEmail
          }
        );
        yield self.checkRecordStatus();
      } catch (e) {
        console.log(e);
        self.recordState.isLoading = false;
        self.recordState.fetchComplete = true;
        self.recordState.recordError = "Failed to start record";
        toast.error(I18N.t("RECORD_SITE_ERROR"));
      }
    }),
    stopRecord: flow(function* stopRecord() {
      const currentTeamId =
        getParent(self).TeamsStore.currentTeam &&
        getParent(self).TeamsStore.currentTeam._id;
      self.recordState.fetchComplete = false;
      self.recordState.timeToEnd = null;
      self.isCheckDone = false;
      self.resetSite();
      const currentUserId =
        getParent(self).UserStore.data && getParent(self).UserStore.data._id;
      yield record.stopRecord(currentTeamId, currentUserId);
      yield self.checkRecordStatus();
    }),

    toggleRecordMode() {
      self.recordState.isTraining = !self.recordState.isTraining;
    },

    checkRecordStatus: flow(function* checkRecordStatus() {
      self.recordState = {};
      self.recordState.fetchComplete = false;
      try {
        yield self.setSite();
        const { cameras } = self;
        if (cameras) {
          const statuses = cameras.map(camera => camera.status);

          if (statuses.indexOf(RECORDING) > -1) {
            self.recordState.isRecording = true;
            self.recordState.isLoading = false;
            self.setRecordName();
            self.setTimeToEnd();
          } else if (statuses.indexOf(READY_TO_RECORD) > -1) {
            self.recordState.isRecording = false;
            self.recordState.isCamerasReady = true;
          } else if (statuses.every(status => status === ERROR)) {
            self.recordState.Recording = false;
            self.recordState.isCamerasReady = false;
          }
        }
      } catch (e) {
        console.log(e);
      } finally {
        self.recordState.fetchComplete = true;
      }
    }),
    clear() {
      self._id = "";
      self.name = "";
      self.liveState = null;
      self.isLiveReady = false;
      self.isOnline = false;
    },
    getIsLive() {
      if (self.liveState) {
        return self.liveState.isLive;
      }
      return false;
    },
    getCommentatorStatus: flow(function* getCommentatorStatus() {
      try {
        const { liveState } = self;
        if (liveState) {
          const status = yield liveProduction.getCommentatorStatus(
            liveState.sessionId
          );
          return status;
        }
      } catch (error) {
        console.log(error);
        return false;
      }
    }),
    addPendingRequest(requestName) {
      if (!self.pendingRequests.includes(requestName)) {
        self.pendingRequests.push(requestName);
      }
    },
    removePendingRequest(requestName) {
      const index = self.pendingRequests.indexOf(requestName);
      if (index > -1) {
        self.pendingRequests.splice(index, 1);
      }
    },
    checkIsRemoteProducer: flow(function* checkIsRemoteProducer() {
      self.isRemoteCheckDone = false;
      const currentTeamId =
        getParent(self).TeamsStore.currentTeam &&
        getParent(self).TeamsStore.currentTeam._id;
      const { isRemoteProducer } = yield remoteProducerApi.isRemoteProducer(
        currentTeamId
      );
      self.isRemoteProducer = isRemoteProducer;
      self.isRemoteCheckDone = true;
    }),

    getInternalStreams: flow(function* getInternalStreams() {
      const currentTeamId =
        getParent(self).TeamsStore.currentTeam &&
        getParent(self).TeamsStore.currentTeam._id;
      self.internalStreams = yield liveProduction.getInternalStreams(
        currentTeamId
      );
      console.log(self.internalStreams);
    }),
    setIsRemoteCheckDone(isDone) {
      self.isRemoteCheckDone = isDone;
    }
  }));

export default SiteStore;

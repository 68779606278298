import React, { Component } from "react";
import PropTypes from "prop-types";
import { Field } from "react-final-form";
import I18N from "../../../I18N";
import { StepHeader } from "../../../components";

class StepTermsOfService extends Component {
  static propTypes = {
    children: PropTypes.any,
    getTermsOfService: PropTypes.func,
    isActive: PropTypes.bool,
    teamLogo: PropTypes.string,
    termsUrl: PropTypes.string
  };

  static defaultProps = {
    children: {},
    getTermsOfService: () => {},
    isActive: false,
    teamLogo: "",
    termsUrl: ""
  };

  shouldComponentUpdate() {
    return true;
  }

  componentDidUpdate(prevProps) {
    const { isActive, getTermsOfService } = this.props;

    if (isActive && prevProps.isActive !== isActive) {
      getTermsOfService();
    }
  }

  render() {
    const { children, termsUrl, teamLogo } = this.props;
    return (
      <div className="register__form__step">
        <img className="register__form__step__team-logo" src={teamLogo} />
        <StepHeader
          lines={[I18N.t("START_TRAINING"), I18N.t("LIKE_A_REAL_PRO")]}
          style={{ alignItems: "center" }}
          color="white"
        />
        <div
          className="register__form__step__terms-body"
          data-testid="termsOfService"
        >
          <Field
            name="termsAndConditions"
            component="input"
            type="checkbox"
            data-testid="termsAndConditions"
            tabIndex={-1}
            id='termsAndConditions'
          />
          <span>
            {/* eslint-disable */}
            <label htmlFor='termsAndConditions' className="register__form__step__terms-body__text">
              {I18N.t("I_AGREE_TO_THE")}
            </label>
            {/* eslint-enable */}
            <a
              className="register__form__step__terms-body__link"
              target="_blank"
              rel="noopener noreferrer"
              href={termsUrl}
            >
              {I18N.t("TERMS_AND_CONDITIONS")}
            </a>
          </span>
        </div>
        {children}
      </div>
    );
  }
}

export default StepTermsOfService;

import AppLayout from "./Layouts/AppLayout";
import AuthenticationGuard from "./AuthenticationGuard";
import AnimatedScene from "./AnimatedScene";
import AutoSave from "./AutoSave";
import ActionOnHoverButton from "./HeaderButtonsPanel/ActionOnHoverButton";
import ClipPanel from "./ClipPanel";
import CategoryAwareLoader from "./CategoryAwareLoader";
import ChangeTeam from "./ChangeTeam";
import ConfirmBox from "./ConfirmBox";
import EditModal from "./Modals/EditModal";
import EventsTable from "./EventsTable";
import FilterPanel from "./FilterPanel";
import GameTitle from "./GameTitle";
import PlayerHighlightModal from "./Modals/PlayerHighlightModal";
import SecondaryHeader from "./SecondaryHeader";
import TabsContainer from "./TabsContainer";
import Dropdown from "./Dropdown";
import HelloWorld from "./HelloWorld";
import Header from "./Header";
import HighlightPreview from "./HighlightPreview";
import Notifications from "./Notifications";
import RecordPreviewButton from "./RecordPreviewButton";
import KeyValueDetail from "./KeyValueDetail";
import Record from "./Modals/Record";
import UnauthLayout from "./Layouts/UnauthLayout";
import UnauthRoute from "./UnauthRoute";
import UserTypeGuard from "./UserTypeGuard";
import Settings from "./Settings";
import WithCountries from "./WithCountries";
import PhoneInput from "./PhoneInput";
import PlayerInfo from "./PlayerInfo";
import RoundRecordButton from "./RoundRecordButton";
import VideoPreview from "./VideoPreview";
import AssignAssistant from "./Modals/AssignAssistant";
import VideoUpload from "./Modals/VideoUpload";
import UploadCoverPhoto from "./UploadCoverPhoto";
import HeaderModal from "./Modals/HeaderModal";
import VideoGrid from "./VideoGrid";
import ShareGameModal from "./Modals/ShareGame";
import SimpleDropdown from "./SimpleDropdown";
import SideDrawer from './SideDrawer';
import SideDrawerTitle from './SideDrawerTopPanel/SideDrawerTitle';
import SideDrawerTopPanel from './SideDrawerTopPanel';
import StepHeader from './StepHeader';
import RecordingStatusDisplay from "./RecordingStatusDisplay";
import RegisterStandartButtons from "./RegisterStandartButtons";
import RegisterSubmitPanel from "./RegisterSubmitPanel";
import MemberDetails from "./MemberDetails";
import MobileLandingPage from "./MobileLandingPage";
import MobileBlock from "./MobileBlock";
import GridBackground from "./GridBackground";
import TeamMembers from "./TeamMembers";
import ScrollToTop from "./ScrollToTop";
import StatsMap from "./StatsMap";
import UserVideoUpload from "./Modals/UserVideoUpload";
import LiveSideMenu from "./LiveSideMenu";
import PreLiveMenu from "./PreLiveMenu";
import LiveProgress from "./LiveProgress";
import CommentaryInvite from "./CommentaryInvite";
import ScoreboardType from  "./ScoreboardType";

// Modal should be loaded last
import Modal from "./Modal";

export {
  AppLayout,
  AnimatedScene,
  AssignAssistant,
  AuthenticationGuard,
  ActionOnHoverButton,
  AutoSave,
  CategoryAwareLoader,
  ChangeTeam,
  ConfirmBox,
  Dropdown,
  Header,
  HeaderModal,
  HelloWorld,
  HighlightPreview,
  KeyValueDetail,
  MemberDetails,
  Modal,
  Notifications,
  PlayerInfo,
  Record,
  RecordingStatusDisplay,
  RecordPreviewButton,
  RegisterStandartButtons,
  RegisterSubmitPanel,
  RoundRecordButton,
  Settings,
  ScrollToTop,
  ShareGameModal,
  SimpleDropdown,
  TeamMembers,
  StepHeader,
  UnauthLayout,
  UnauthRoute,
  UserTypeGuard,
  VideoGrid,
  VideoPreview,
  VideoUpload,
  GridBackground,
  UploadCoverPhoto,
  WithCountries,
  MobileLandingPage,
  MobileBlock,
  EditModal,
  EventsTable,
  FilterPanel,
  GameTitle,
  PhoneInput,
  PlayerHighlightModal,
  TabsContainer,
  ClipPanel,
  SecondaryHeader,
  SideDrawer,
  SideDrawerTopPanel,
  SideDrawerTitle,
  StatsMap,
  UserVideoUpload,
  LiveSideMenu,
  PreLiveMenu,
  LiveProgress,
  CommentaryInvite,
  ScoreboardType
};

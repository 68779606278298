import React from 'react';
import PropTypes from 'prop-types';
import { SuccessButton, DismissButton } from '@myplay/ui';
import { ORANGE, GREY } from "../../styles/shared/colors";
import I18N from "../../I18N";
import backArrow from "../../assets/images/signup-back-arrow.svg";
import "./RegisterSubmitPanel.scss";

const RegisterSubmitPanel = ({ onSuccess, onDismiss, isSuccessAllowed }) => {
  return(
    <div className="register-submit-panel">
      <SuccessButton
        className="register-submit-panel__success"
        background={isSuccessAllowed ? ORANGE : GREY}
        type="submit"
        data-testid="formSubmit"
        onClick={onSuccess}
      >
        {I18N.t("GO_TO_MYPLAY")}
      </SuccessButton>
      <DismissButton
        showBorder={false}
        style={{ display: 'flex', justifyContent: 'center' }}
        onClick={onDismiss}
      >
        <img src={backArrow} />
      </DismissButton>
  </div>
  )
}

RegisterSubmitPanel.propTypes = {
  isSuccessAllowed: PropTypes.bool,
  onDismiss: PropTypes.func,
  onSuccess: PropTypes.func
}

RegisterSubmitPanel.defaultProps = {
  isSuccessAllowed: false,
  onDismiss: ()=>{},
  onSuccess: ()=>{}
}

export default RegisterSubmitPanel;

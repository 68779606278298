/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import Downshift from "downshift";
import "./TeamsDropDown.scss";
import dropDownIcon from "../../assets/icons/Icon-DropDown.svg";

const TeamsDropDown = ({ teams, onChange }) => {
  return (
    <Downshift items={teams} onChange={team => onChange(team)}>
      {({
        isOpen,
        selectedItem,
        getItemProps,
        getToggleButtonProps,
        getMenuProps
      }) => (
        <div className="teams-drop-down">
          <button
            type="button"
            className="teams-drop-down__selected_item"
            {...getToggleButtonProps()}
          >
            <img
              className="teams-drop-down__button__team-logo"
              src={selectedItem ? selectedItem.teamImage : teams[0].teamImage}
            />
            {selectedItem ? selectedItem.name : teams[0].name}
            <img src={dropDownIcon} style={{width:'45px', marginLeft: 'auto'}}/>
          </button>
          {isOpen ? (
            <ul className="teams-drop-down__list" {...getMenuProps()}>
              {teams &&
                teams.map(team => (
                  <li
                    key={team._id}
                    data-testid="list-item"
                    className="teams-drop-down__list__item"
                  >
                    <button
                      type="button"
                      className="teams-drop-down__list__item__text"
                      {...getItemProps({ item: team })}
                    >
                      <img
                        className="teams-drop-down__button__team-logo"
                        src={team.teamImage}
                      />
                      {team.name}
                    </button>
                  </li>
                ))}
            </ul>
          ) : null}
        </div>
      )}
    </Downshift>
  );
};

TeamsDropDown.propTypes = {};
TeamsDropDown.defaultProps = {};

export default TeamsDropDown;

/* eslint-disable react/jsx-no-literals */
import React, { Component, Fragment } from "react";
import { SocialSharePanel, HighlightPlayer, Loader } from "@myplay/ui";
import { game } from "@myplay/all";
import { DefaultPlayer as Video } from "react-html5video";
import Helmet from "react-helmet";
import isMobile from "is-mobile";

import "./PublicHighlight.scss";
import I18N from "../../I18N";
import { MemberDetails } from "../../components/";

import LiveCourtLeftSide from "../../assets/images/live_court_left.jpg";
import LiveCourtRightSide from "../../assets/images/live_court_right.jpg";
import Logo from "../../assets/images/logo-name.png";
import PublicHighlightMobile from "./PublicHighlightMobile";
import getHighlightLink from "../../utils/getHighlightLink";

const { highlights } = game;

class PublicHighlight extends Component {
  state = {
    highlight: null,
    isLoading: true
  };

  async componentDidMount() {
    const { location } = this.props;
    if (location.pathname) {
      const params = location.pathname.split("public-highlight/")[1];
      const highlightId = params.split("/")[0];

      try {
        const highlight = await highlights.getPublicHighlightById(highlightId);
        this.setState({ highlight });

      } catch (error) {
        console.log(error);
      } finally {
        this.setState({ isLoading: false });
      }
    } else {
      this.setState({ isLoading: false });
    }
  }

  renderHighlight = () => {
    const { highlight } = this.state;
    const { video, streamUri, thumbnailUri } = highlight || {};
    const { cameraType } = video || {};
    return cameraType !== "PANORAMIC" ? (
      <Video
        controls={["PlayPause", "Seek", "Fullscreen"]}
        src={streamUri}
        poster={thumbnailUri}
        autoPlay
        loop
      />
    ) : (
      <HighlightPlayer videoId={highlight.id} autoPlay loop />
    );
  };

  renderNoHighlight = () => {
    return (
      <div className="public-highlight__offline-title">
        <p>{I18N.t("NO_PUBLIC_HIGHLIGHT_PART1")}</p>
        <p className="public-live__secondary-title">
          {I18N.t("NO_PUBLIC_HIGHLIGHT_PART2")}
        </p>
        <div className="public-highlight__logo-container">
          <p>{I18N.t("POWERED_BY")}</p>
          <img src={Logo} />
        </div>
      </div>
    );
  };

  renderLoadingHighlight = () => {
    return (
      <div className="public-highlight__loader">
        <p className="public-highlight__secondary-title">
          {I18N.t("GETTING_HIGHLIGHT")}
        </p>
        <Loader />
      </div>
    );
  };

  render() {
    const { highlight, isLoading } = this.state;
    const { title } = highlight || {};
    const user = highlight && highlight.sender;
    const shareLink = highlight && getHighlightLink(highlight);

    /* eslint-disable no-nested-ternary */
    return isMobile({ tablet: true }) ? (
      <PublicHighlightMobile
        user={user}
        highlight={highlight}
        isLoading={isLoading}
      />
    ) : (
      <div className="public-highlight">
        <Helmet>
          <title>
            {I18N.t("HIGHLIGHT_OF")} {(user && user.fullName) || ""}
          </title>
          <meta property="og:url" content={document.location.href} />
          <meta
            property="og:title"
            content={`${I18N.t("HIGHLIGHT_OF")} ${(user && user.fullName) ||
              ""}`}
          />
          <meta
            property="og:description"
            content={I18N.t("OG_DESCRIPTION_PLAYER")}
          />
        </Helmet>
        <div className="public-highlight__header">
          {user ? (
            <div className="public-highlight__highlight-details">
              <MemberDetails member={user} />
              <p className="public-highlight__secondary-title">
                {title && `#${title}`}
              </p>
            </div>
          ) : null}
        </div>
        <div className="public-highlight__wrapper">
          <img src={LiveCourtLeftSide} />
          <div className="public-highlight__player-container">
            {isLoading
              ? this.renderLoadingHighlight()
              : highlight
              ? this.renderHighlight()
              : this.renderNoHighlight()}
          </div>
          <img src={LiveCourtRightSide} />
        </div>
        <div className="public-highlight__footer">
          {highlight ? (
            <Fragment>
              <SocialSharePanel
                shareLink={shareLink}
              />
            </Fragment>
          ) : null}
        </div>
      </div>
    );
  }
}

export default PublicHighlight;

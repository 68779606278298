import React, { Component } from "react";
import Downshift from "downshift";
import { GREEN_LIGHT } from "../../utils/constants";

class Dropdown extends Component {
  redner() {
    const { items, onChange, itemsToString, buttonText } = this.props;

    return (
      <Downshift
        items={items}
        itemsToString={itemsToString}
        onChange={onChange}
      >
        {({
          getItemProps,
          getToggleButtonProps,
          highlightedIndex,
          getMenuProps,
          isOpen
        }) => (
          <div className="autocomplete header__user__settings__dropdown">
            <button
              type="button"
              {...getToggleButtonProps()}
              className="header__user__settings__dropdown__button"
            >
              {buttonText}
              <span className="header__user__settings__dropdown__button__arrow">
                <span className="icon-arrow-down" />
              </span>
            </button>
            {isOpen ? (
              <ul
                className="header__user__settings__dropdown__menu autocomplete__menu"
                {...getMenuProps()}
              >
                {items.map((item, index) => (
                  <li
                    key={item.key}
                    {...getItemProps({
                      item,
                      key: item.key,
                      className: item.className,
                      onClick: item.onClick,
                      style: {
                        backgroundColor:
                          highlightedIndex === index ? GREEN_LIGHT : "black"
                      }
                    })}
                  >
                    {item.content}
                  </li>
                ))}
              </ul>
            ) : null}
          </div>
        )}
      </Downshift>
    );
  }
}

export default Dropdown;

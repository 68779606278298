/* eslint-disable */

import dayjs from "dayjs";

const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
const GOOGLE_CLIEND_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

const authenticate = async () => {
  try {
    const res = await gapi.auth2
      .getAuthInstance()
      .signIn({ scope: "https://www.googleapis.com/auth/youtube.force-ssl" });
  } catch (e) {
    console.error("Error signing in", e);
  }
};

const loadClient = async () => {
  gapi.client.setApiKey(GOOGLE_API_KEY);
  try {
    const res = await gapi.client.load(
      "https://www.googleapis.com/discovery/v1/apis/youtube/v3/rest"
    );
  } catch (e) {
    console.error("Error loading GAPI client for API", e);
  }
};

const createLiveBroadcast = async ({ title }) => {
  try {
    const response = await gapi.client.youtube.liveBroadcasts.insert({
      part: ["snippet,contentDetails,status"],
      resource: {
        snippet: {
          title: title,
          scheduledStartTime: dayjs(Date.now()).toISOString(),
          scheduledEndTime: dayjs(Date.now())
            .add(3, "hours")
            .toISOString()
        },
        contentDetails: {
          enableAutoStart: true
        },
        status: {
          lifeCycleStatus: "live",
          privacyStatus: "public",
          selfDeclaredMadeForKids: false
        }
      }
    });
    const liveBroadcast = response.result;
    const { liveStreamId, streamName } = await getLiveStreamId(title);
    await gapi.client.youtube.liveBroadcasts.bind({
      id: liveBroadcast.id,
      part: ["snippet"],
      streamId: liveStreamId
    });
    try {
      const res = gapi.client.youtube.liveBroadcasts.transition({
        id: liveBroadcast.id,
        broadcastStatus: "live",
        part: ["snippet,status"]
      });
    } catch (e) {
      throw new Error(e.message);
    }

    return { streamName: streamName, liveBroadcastId: liveBroadcast.id };
  } catch (e) {
    throw new Error(e.result.error.message);
  }
};

const getLiveStreamId = async title => {
  try {
    const response = await gapi.client.youtube.liveStreams.insert({
      part: ["snippet,cdn,contentDetails, status"],
      resource: {
        snippet: {
          title: title
        },
        cdn: {
          frameRate: "30fps",
          ingestionType: "rtmp",
          resolution: "1080p"
        },
        contentDetails: {
          isReusable: false
        }
      }
    });
    const liveStream = response.result;
    const { id, cdn } = liveStream;
    const { ingestionInfo } = cdn;
    const { streamName } = ingestionInfo;
    return { liveStreamId: id, streamName };
  } catch (e) {
    throw new Error(e.message);
  }
};

const initClientAndGetStream = async ({ title }) => {
  return new Promise((resolve, reject) => {
    gapi.load("client:auth2", async () => {
      try {
        await gapi.auth2.init({
          client_id: GOOGLE_CLIEND_ID,
          scope: "https://www.googleapis.com/auth/youtube",
          fetch_basic_profile: false,
          cookie_policy: "single_host_origin"
        });
        await authenticate();
        await loadClient();
        const streamName = await createLiveBroadcast({ title });
        resolve(streamName);
      } catch (e) {
        reject(e);
      }
    });
  });
};

const deleteOldKeys = async () => {
  const { result } = await gapi.client.youtube.liveStreams.list({
    part: ["snippet"],
    maxResults: 50,
    mine: true
  });
  await Promise.all(
    result.items.map(async stream => {
      if (stream.snippet.title.includes("Myplay")) {
        return gapi.client.youtube.liveStreams.delete({
          id: stream.id
        });
      }
    })
  );
};

export { initClientAndGetStream };

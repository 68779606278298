import React from 'react';
import isMobile from 'is-mobile';
import { MobileLandingPage } from '../index';

const MobileBlock = ({ children }) => {
  if (isMobile({tablet: true})) {
    return <MobileLandingPage />
  }

  return children
};

export default MobileBlock;

import React from "react";
import { PlayerBadge } from "@myplay/ui";
import "./MemberDetails.scss";
import PropTypes from "prop-types";

const MemberDetails = ({ member, style, imageStyle }) => {
  const {
    firstName,
    accountType,
    lastName,
    fullName,
    playerNumber,
    avatar,
    avatarBgColor
  } = member;
  return (
    <div className="member-details" style={style}>
      <div className="member-details__image" style={imageStyle}>
        {avatar ? (
          <img src={avatar} />
        ) : (
          <PlayerBadge
            firstName={firstName}
            lastName={lastName}
            color={avatarBgColor}
          />
        )}
      </div>
      <div>
        <span className="team-members__member__detail">
          {accountType === "player" ? playerNumber : accountType}
        </span>
        <span>{fullName}</span>
      </div>
    </div>
  );
};

MemberDetails.propTypes = {
  imageStyle: PropTypes.shape(),
  member: PropTypes.shape().isRequired,
  style: PropTypes.shape()
};

MemberDetails.defaultProps = {
  imageStyle: {},
  style: {}
};

export default MemberDetails;

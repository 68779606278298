import React, { Component } from "react";
import PropTypes from "prop-types";
import { liveProduction } from "@myplay/all";
import {
  DropdownWrapper,
  LinkWithButton,
  SponsorUpload,
  toast,
  ScoreboardSettings
} from "@myplay/ui";
import { CommentaryInvite } from "..";
import { inject, observer } from "mobx-react";

import resourceUpload from "../../utils/resourceUpload";
import { ScoreboardType } from "..";
import { PUBLIC_LIVE_SHARE_LINK } from "../../utils/constants";
import I18N from "../../I18N";
import "./LiveSideMenu.scss";

class LiveSideMenu extends Component {
  static propTypes = {
    hasDarkOverlay: PropTypes.bool,
    SiteStore: PropTypes.object,
    TeamsStore: PropTypes.object,
    youTubeShareLink: PropTypes.string
  };

  static defaultProps = {
    hasDarkOverlay: false,
    SiteStore: null,
    TeamsStore: null,
    youTubeShareLink: ""
  };

  state = {
    teamResources: null,
    isDigitalScore: true,
    liveId: "",
    openDropdownIndex: 0,
    scoreType: "Video-Stream"
  };

  async componentDidMount() {
    const { isLiveActive } = this.props;
    if (isLiveActive) {
      await this.getObsResources();
    }
  }

  componentDidUpdate(prevProps) {
    const { isLiveActive, liveId } = this.props;
    if (
      isLiveActive &&
      prevProps.isLiveActive !== isLiveActive &&
      this.state.liveState !== liveId
    ) {
      this.getObsResources();
    }

    if (!isLiveActive && isLiveActive !== prevProps.isLiveActive) {
      this.setState({
        openDropdownIndex: 0
      });
    }
  }

  getLink = () => {
    const { SiteStore } = this.props;
    if (SiteStore && SiteStore.liveState && SiteStore.liveState.sessionId) {
      return `${PUBLIC_LIVE_SHARE_LINK}/${SiteStore.liveState.sessionId}`;
    }

    return null;
  };

  handleBottomSponsorSave = async image => {
    const {
      TeamsStore: {
        currentTeam: { _id }
      }
    } = this.props;
    // const { teamResources } = this.state;
    try {
      await resourceUpload({
        files: {
          bottomSponsor: image
        },
        teamId: _id
      });
      toast.success(I18N.t("SCOREBOARD_UPDATE_TIME"));
    } catch (error) {
      console.log(error);
    }
  };

  getObsResources = async () => {
    const {
      TeamsStore: {
        currentTeam: { _id, scoreBoardLocation }
      },
      SiteStore: { isObs },
      liveId
    } = this.props;
    if (isObs) {
      try {
        const {
          teamResources,
          isDigitalScore
        } = await liveProduction.getScoreDisplayResources(_id);
        teamResources.location = scoreBoardLocation || "Left";
        const { scoreboardType } = await liveProduction.getScoreboardType(_id);
        this.setState({
          teamResources,
          isDigitalScore,
          liveId,
          scoreType: scoreboardType
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  handleScoreboardApplyChanges = async resources => {
    const {
      TeamsStore: {
        currentTeam: { _id },
        refreshTeamData
      }
    } = this.props;
    try {
      await resourceUpload({
        files: {
          ...resources
        },
        teamId: _id
      });
      await refreshTeamData();
      toast.success(I18N.t("SCOREBOARD_UPDATE_TIME"));
    } catch (error) {
      console.log(error);
    }
  };

  swapScoreboardToVideoView = async () => {
    const {
      TeamsStore: {
        currentTeam: { _id }
      }
    } = this.props;
    try {
      await liveProduction.swapDigitalToStream(_id);
      this.setState({
        isDigitalScore: false
      });
      toast.success(I18N.t("SCOREBOARD_UPDATE_TIME"));
    } catch (error) {
      console.log(error);
    }
  };

  handleRemoveBottomSponsor = async () => {
    const {
      TeamsStore: {
        currentTeam: { _id }
      }
    } = this.props;
    try {
      await liveProduction.removeBottomSponsor(_id);
      this.setState(prevState => ({
        teamResources: {
          ...prevState.teamResources,
          bottomSponsor: null
        }
      }));
      toast.success(I18N.t("SCOREBOARD_UPDATE_TIME"));
    } catch (error) {
      console.log(error);
    }
  };

  handleRemoveTeamColorAndImage = async isHomeTeam => {
    const {
      TeamsStore: {
        currentTeam: { _id }
      }
    } = this.props;
    try {
      let filesToDelete = [];
      if (isHomeTeam) {
        filesToDelete = ["homeLogo", "homeColor"];
      } else {
        filesToDelete = ["awayLogo", "awayColor"];
      }

      await liveProduction.deleteSiteResources(_id, filesToDelete);
      this.getObsResources();
      toast.success(I18N.t("SCOREBOARD_UPDATE_TIME"));
    } catch (error) {
      console.log(error);
    }
  };

  handleRemoveScoreboardSponsor = async () => {
    const {
      TeamsStore: {
        currentTeam: { _id }
      }
    } = this.props;
    try {
      await liveProduction.deleteSiteResources(_id, ["sponsorLogo"]);
      this.getObsResources();
      toast.success(I18N.t("SCOREBOARD_UPDATE_TIME"));
    } catch (error) {
      console.log(error);
    }
  };

  handleDropdownOpen = index => {
    const { openDropdownIndex } = this.state;
    this.setState({
      openDropdownIndex: index === openDropdownIndex ? 0 : index
    });
  };

  handleScoreTypeSelect = type => {
    this.setState({ scoreType: type });
  };

  handleScoreTypeApply = async () => {
    const {
      TeamsStore: {
        currentTeam: { _id }
      }
    } = this.props;
    const { scoreType } = this.state;
    await liveProduction.changeScoreboardType(_id, scoreType);
    setTimeout(async () => {
      const { scoreboardType } = await liveProduction.getScoreboardType(_id);
      this.setState({ scoreType: scoreboardType });
    }, 5000);

    toast.success(I18N.t("SCOREBOARD_UPDATE_TIME"));
  };

  render() {
    const {
      SiteStore: { name, liveState, isObs, hasScoreDetection },
      youTubeShareLink,
      hasDarkOverlay,
      liveId
    } = this.props;
    const {
      teamResources,
      openDropdownIndex,
      scoreType
    } = this.state;

    const link = this.getLink();
    return (
      <div className="live-side-menu">
        <div className="live-side-menu__header">
          <span className="live-side-menu__header__title live-side-menu__header__title--green">
            {I18N.t("LIVE_VIEW_FROM")}
          </span>
          <span className="live-side-menu__header__title">{name}</span>
        </div>
        <div className="live-side-menu__link-container">
          {link && <LinkWithButton link={link} />}
          {youTubeShareLink && (
            <LinkWithButton link={youTubeShareLink} isYoutube />
          )}
        </div>
        {isObs && (
          <div className="live-side-menu__dropdowns-container">
            {teamResources && (
              <DropdownWrapper
                title="Scoreboard Settings"
                isOpen={openDropdownIndex === 1}
                onClick={() => this.handleDropdownOpen(1)}
              >
                <ScoreboardSettings
                  teamResources={teamResources}
                  onApplyChanges={this.handleScoreboardApplyChanges}
                  swapScoreboardToVideoView={this.swapScoreboardToVideoView}
                  onRemoveTeamColorAndImage={this.handleRemoveTeamColorAndImage}
                  liveId={liveId}
                  onRemoveScoreboardSponsor={this.handleRemoveScoreboardSponsor}
                  isDigitalScore={scoreType !== "Video-Stream"}
                />
              </DropdownWrapper>
            )}
            {hasScoreDetection && (
              <DropdownWrapper
                title="Scoreboard type"
                isOpen={openDropdownIndex === 2}
                onClick={() => this.handleDropdownOpen(2)}
              >
                <ScoreboardType
                  selected={scoreType}
                  onSelect={this.handleScoreTypeSelect}
                  onApply={this.handleScoreTypeApply}
                />
              </DropdownWrapper>
            )}
            <DropdownWrapper
              title="Advertisers Banners"
              isOpen={openDropdownIndex === 3}
              onClick={() => this.handleDropdownOpen(3)}
            >
              <SponsorUpload
                title={I18N.t("ADD_ADVERTISER_BANNER")}
                id="bottom-banner"
                onSave={this.handleBottomSponsorSave}
                image={teamResources ? teamResources.bottomSponsor : null}
                aspectRatio={1920 / 110}
                cropperStyle={{ height: 100, width: 800 }}
                className="live-side-menu__dropdowns-container__bottom-sponsor"
                onRemoveLogo={this.handleRemoveBottomSponsor}
              />
            </DropdownWrapper>
          </div>
        )}
        <div
          className={`live-side-menu__dark-mask live-side-menu__dark-mask--${
            hasDarkOverlay ? "overlay" : "no-overlay"
          }`}
        />
      </div>
    );
  }
}

export default inject("SiteStore", "TeamsStore")(observer(LiveSideMenu));

import React from 'react';
import PropTypes from 'prop-types';
import './SideDrawerTopPanel.scss';
import generate from 'shortid';
import { SideDrawerTitle } from '../';

const SideDrawerTopPanel = ({ buttonText, onButtonClick, elements, shouldDisplayFirstTitle, shouldDisplaySecondTitle, shouldDisplayButton })=> {
  return(
    <div className='top-panel'>
    {elements.length > 0 && elements.map((element, i) =>
      <SideDrawerTitle key={generate()} element={element} shouldDisplayTitle={shouldDisplaySecondTitle || (i === 0 && shouldDisplayFirstTitle)}/>
    )}
      {shouldDisplayButton &&
        <button className='top-panel__button' type='button' onClick={onButtonClick} >{buttonText}</button>}
    </div>
  );
};

/* eslint-disable react/boolean-prop-naming */
SideDrawerTopPanel.propTypes = {
  buttonText: PropTypes.string,
  elements: PropTypes.array,
  onButtonClick: PropTypes.func,
  shouldDisplayButton: PropTypes.bool,
  shouldDisplayFirstTitle: PropTypes.bool,
  shouldDisplaySecondTitle: PropTypes.bool
};
/* eslint-enable react/boolean-prop-naming */

SideDrawerTopPanel.defaultProps = {
  buttonText: '',
  elements: [],
  onButtonClick: ()=> {},
  shouldDisplayButton: false,
  shouldDisplayFirstTitle: false,
  shouldDisplaySecondTitle: false
}

export default SideDrawerTopPanel;

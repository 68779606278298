import React, { Component } from "react";
import { Field } from 'react-final-form';
import { Input } from "@myplay/ui";
import I18N from '../../../I18N';
import { StepHeader } from '../../../components';

class StepPassword extends Component {

  state = {
    isPasswordVisible: false
  }

  toggleShowPassword = ()=> {
    this.setState(prevState => ({
      isPasswordVisible: !prevState.isPasswordVisible
    }));
  };

  validatePassword = value => {
    const { isNextStepAllowed, isActive } = this.props;
    const isValid = value && value.length >= 6;
    if (isActive) {
      isNextStepAllowed(isValid)
      return value && value.length >= 6 ? undefined : I18N.t("ERROR_PASSWORD_SHORT");
    };
  };


  render() {
    const { isPasswordVisible } = this.state;
    return (
      <div className="register__form__step">
        <StepHeader lines={[I18N.t("CREATE"),I18N.t("PASSWORD")]}/>
        <div className="register__form__step__fields">
          <Field
            className="register__form__step__fields__field"
            name="password"
            validate={this.validatePassword}
          >
            {({ input, meta }) => (
              <Input
                {...input}
                type={isPasswordVisible ? "input" : "password"}
                label={I18N.t("PASSWORD")}
                data-testid="password"
                error={
                  (meta.touched && meta.error) || meta.submitFailed
                    ? meta.error
                    : ""
                }
                tabIndex={-1}
                required
              />
            )}
          </Field>
          {/* eslint-disable */}
          <span className="register__form__step__fields__show-password" onClick={this.toggleShowPassword}>{I18N.t("SHOW_PASSWORD")}</span>
          {/* eslint-enable */}
        </div>
      </div>
    );
  }
};

export default StepPassword;

import React from "react";
import PropTypes from 'prop-types';
import './SideDrawerTopPanel.scss';

const SideDrawerTitle = ({element, shouldDisplayTitle}) =>
  <div
    key={Object.keys(element)}
    className="top-panel__title"
    style={{ opacity: shouldDisplayTitle ? "1" : "0" }}
  >
    <span>{Object.values(element)}</span>
    <span>{Object.keys(element)}</span>
  </div>;

SideDrawerTitle.propTypes = {
  element: PropTypes.object,
  shouldDisplayTitle: PropTypes.bool // eslint-disable-line react/boolean-prop-naming
};

SideDrawerTitle.defaultProps = {
  element: {},
  shouldDisplayTitle: false
}

export default SideDrawerTitle;

/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { SuccessButton, LineProgressBar } from "@myplay/ui";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { VelocityComponent, VelocityTransitionGroup } from "velocity-react";

import closeIcon from "../../assets/images/closeWithCircle.svg";

require("velocity-animate");
require("velocity-animate/velocity.ui");

class ClipPanel extends Component {
  state = {
    videos: this.props.videos,
    showTextModal: false,
    hoverOnVideo: false,
    showProgressBar: true
  };

  velRef = React.createRef();

  sumTotalClipTime = () => {
    const self = this;
    const totalTime = self.state.videos.reduce(
      (accumulator, currentValue) =>
        accumulator +
        (currentValue.duration
          ? currentValue.duration
          : currentValue.endSeekTime - currentValue.seekTime),
      0
    );
    let s = Math.floor(totalTime);
    let m = Math.floor(s / 60);
    s = s % 60;
    m = m % 60;
    return `${m}:${s < 10 ? "0" + s : s}`;
  };

  onDragEnd = result => {
    this.props.onDragEnd(result);
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.videos !== prevState.videos) {
      return {
        videos: nextProps.videos
      };
    }
    return null;
  }

  discardAll = () => {
    if (this.props.onDiscardAll) {
      this.props.onDiscardAll();
    }
  };

  onDeleteVideo = video => {
    this.velRef.current.runAnimation();
    if (this.props.onDelete) {
      this.props.onDelete(video);
    }
  };

  render() {
    const { showThumbnails, showProgressBar } = this.props;
    return showProgressBar ? (
      <div className="clipPanel__progress-bar">
        <LineProgressBar
          progressDone={this.props.isProgressBarDone}
          title={"Creating clip"}
        />
      </div>
    ) : (
      <div className="clipPanel__container">
        <div className="clipPanel__number-of-videos">
          <VelocityComponent
            animation="callout.pulse"
            duration={500}
            ref={this.velRef}
          >
            <span style={{ color: "#15c4ab", fontSize: "30px" }}>
              {this.state.videos.length}
            </span>
          </VelocityComponent>
          <span>Videos</span>
        </div>
        {showThumbnails ?
          <DragDropContext onDragEnd={this.onDragEnd}>
            <Droppable droppableId="droppable" direction="horizontal">
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className="clipPanel__videos-editor"
                >
                  <VelocityTransitionGroup
                    enter={{
                      animation: "transition.slideDownIn",
                      duration: "200"
                    }}
                    leave={{
                      animation: "transition.slideDownOut",
                      duration: "200"
                    }}
                    style={{ display: "flex" }}
                  >
                    {this.state.videos.map((video, index) => (
                      <Draggable
                        key={video.id}
                        draggableId={video.id}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className="clipPanel__video-box"
                            onMouseOver={() => {
                              this.setState({ hoverOnVideo: true });
                            }}
                            onMouseLeave={() => {
                              this.setState({ hoverOnVideo: false });
                            }}
                          >
                            {video.textVideo ? (
                              <div className="clipPanel__video-text-box">
                                <span>{video.title}</span>
                              </div>
                            ) : (
                              <img src={video.thumbnailUri} alt="alt" />
                            )}
                            {this.state.hoverOnVideo ? (
                              <img
                                src={closeIcon}
                                className="clipPanel__delete-icon"
                                onClick={() => this.onDeleteVideo(video)}
                                alt="img"
                              />
                            ) : null}
                          </div>
                        )}
                      </Draggable>
                    ))}
                  </VelocityTransitionGroup>

                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext> : <div style={{ width: '10px' }} />
        }
        <div className="clipPanel__submit-box">
          <SuccessButton
            style={{ width: "160px" }}
            disabled={this.state.videos.length === 0}
            onClick={() => {
              this.props.onMakeClip();
              this.setState({ showProgressBar: true });
            }}
          >
            MAKE CLIP{" "}
            {this.state.videos.length > 0
              ? `(${this.sumTotalClipTime()})`
              : null}{" "}
          </SuccessButton>
          <span className="clipPanel__discard-button" onClick={this.discardAll}>
            Discard all videos
          </span>
        </div>
      </div>
    );
  }
}

ClipPanel.propTypes = {
  /** array of videos*/
  videos: PropTypes.array.isRequired,
  /** function to call when discard button is clicked */
  onDiscardAll: PropTypes.func,
  /** function to call when delete icon is clicked */
  onDelete: PropTypes.func,
  /** function to call when dragging object on the panel*/
  onDragEnd: PropTypes.func,
  /** function to call when click make clip*/
  onMakeClip: PropTypes.func,
  /** show progress bar  */
  showProgressBar: PropTypes.bool,
  /** is progress bar done  */
  isProgressBarDone: PropTypes.bool
};

export default ClipPanel;

import React, { Component } from "react";
import PropTypes from "prop-types";
import { SeparatorWithTitle } from "@myplay/ui";
import { VelocityTransitionGroup } from "velocity-react";

import { SideDrawerTopPanel } from "../index";
import "./SideDrawer.scss";
import arrow from "../../assets/images/left_arrow-06.svg";

class SideDrawer extends Component {
  static propTypes = {
    bottomPanel: PropTypes.any,
    buttonText: PropTypes.string,
    children: PropTypes.any,
    elements: PropTypes.object,
    emptyDrawer: PropTypes.any,
    isEmpty: PropTypes.bool,
    onClose: PropTypes.func,
    onOpen: PropTypes.func,
    selectAllHighlights: PropTypes.func,
    shouldDisplayButton: PropTypes.bool, // eslint-disable-line react/boolean-prop-naming
    shouldDisplaySecondTitle: PropTypes.bool, // eslint-disable-line react/boolean-prop-naming
    withBottomPanel: PropTypes.bool // eslint-disable-line react/boolean-prop-naming
  };

  static defaultProps = {
    bottomPanel: {},
    buttonText: "",
    children: {},
    elements: {},
    emptyDrawer: null,
    isEmpty: false,
    onClose: () => {},
    onOpen: () => {},
    selectAllHighlights: () => {},
    shouldDisplayButton: false,
    shouldDisplaySecondTitle: false,
    withBottomPanel: false
  };

  state = {
    isFullScreen: false,
    className: "side-drawer",
    buttonClassName: "side-drawer__button__arrow"
  };

  handleToggleDrawer = () => {
    const { onOpen, onClose } = this.props;
    const { isFullScreen } = this.state;

    this.setState(
      prevState => ({
        isFullScreen: !prevState.isFullScreen,
        className: `side-drawer side-drawer--${
          prevState.isFullScreen ? "closed" : "open"
        }`,
        buttonClassName: `side-drawer__button__arrow side-drawer__button__arrow--${
          prevState.isFullScreen ? "flip-back" : "flip"
        }`
      }),
      () => (isFullScreen ? onClose() : onOpen())
    );
  };

  /* eslint-disable react/jsx-no-literals */

  render() {
    const { className, isFullScreen, buttonClassName } = this.state;
    const {
      children,
      buttonText,
      selectAllHighlights,
      bottomPanel,
      withBottomPanel,
      elements,
      isEmpty,
      emptyDrawer,
      shouldDisplaySecondTitle,
      shouldDisplayButton
    } = this.props;

    return (
      <div className={className} data-testid="side-drawer">
        <button
          type="button"
          className="side-drawer__button"
          data-testid="side-drawer-button"
          onClick={this.handleToggleDrawer}
        >
          <img className={buttonClassName} src={arrow} />
        </button>
        {isEmpty ?
          emptyDrawer
         : (
          <div className="side-drawer__content" data-testid="content">

                <SideDrawerTopPanel
                  shouldDisplaySecondTitle={
                    !isFullScreen && shouldDisplaySecondTitle
                  }
                  shouldDisplayFirstTitle={!isFullScreen}
                  elements={elements}
                  buttonText={buttonText}
                  shouldDisplayButton={shouldDisplayButton}
                  onButtonClick={selectAllHighlights}
                />
            {/* eslint-disable react/no-array-index-key */}
            {children.length > 0 &&
              children.map((child, i) => (
              <div key={`childNo.${i}`} style={{ position: "relative" }}>
              {/* eslint-enable react/no-array-index-key */}
                {child.length > 0 && isFullScreen && (
                  <SeparatorWithTitle title={Object.keys(elements[i])} number={Object.values(elements[i])}/>
                )}
                <VelocityTransitionGroup
                  enter={{
                    animation: isFullScreen
                      ? "transition.slideDownBigIn"
                      : "transition.slideDownBigIn",
                    duration: "500"
                  }}
                  leave={{
                    animation: isFullScreen
                      ? "transition.slideDownBigOut"
                      : "transition.slideRightBigOut",
                    duration: "500"
                  }}
                  runOnMount={false}
                  className={`side-drawer__content__child side-drawer__content__child--${i}`}
                >
                  {child.length > 0 &&
                    child.map((childElement, index) => (
                    <div key={childElement.key}>
                      <div
                        className={`side-drawer__content__child__element side-drawer__content__child__element--${
                          !isFullScreen ? "animate" : "animate-again"
                        } `}
                        style={{ animationDelay: `${index * 0.3}s` }}
                      >
                        {childElement}
                      </div>
                    </div>
                  ))}
                </VelocityTransitionGroup>
              </div>
            ))}
            <div className="side-drawer__content__bottom-gradient" />
            {withBottomPanel && (
              <div
                className={`side-drawer__content__bottom-panel side-drawer__content__bottom-panel--${
                  withBottomPanel ? "slide-in" : "slide-out"
                }`}
              >
                {bottomPanel()}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }

  /* eslint-enable react/jsx-no-literals */
}

export default SideDrawer;

/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import cameraArrow from "../../assets/images/camera-arrow.svg";
import camera from "../../assets/images/camera.svg";

const CameraSwitcherButton = ({ cameraSide, onSwitch }) => {
  return (
    <button
      type="button"
      className={`camera-switcher-button ${
        cameraSide === "Left" ? "" : "camera-switcher-button--flip"
      }`} // eslint-disable-line
      onClick={onSwitch}
    >
      <img className="camera-switcher-button__arrow" src={cameraArrow} />
      <img className="camera-switcher-button__camera" src={camera} />
      <img
        className="camera-switcher-button__arrow camera-switcher-button__arrow--down"
        src={cameraArrow}
      />
    </button>
  );
};

CameraSwitcherButton.propTypes = {
  cameraSide: PropTypes.string,
  onSwitch: PropTypes.func
};

CameraSwitcherButton.defaultProps = {
  cameraSide: "Left",
  onSwitch: () => {}
};

export default CameraSwitcherButton;

import React from 'react';
import PropTypes from "prop-types";
import './RoundRecordButton.scss';
import { Loader } from "@myplay/ui";
import {ORANGE} from "../../styles/shared/colors";
import I18N from "../../I18N/";


const RoundRecordButton = ({text,background,isLoading,...rest}) => {
  return (
    isLoading ?
      <Loader color={ORANGE} type='spin'/> :
      <button type="button" className="round-record-button" style={{background}} {...rest}>{text}</button>

  );
}

RoundRecordButton.propTypes = {
  background: PropTypes.string,
  isLoading: PropTypes.bool,
  onClick: PropTypes.func,
  text: PropTypes.string,
};
RoundRecordButton.defaultProps = {
  background: ORANGE,
  isLoading: true,
  onClick: () => {},
  text: I18N.t('RECORD_BUTTON_TEXT')
};

export default RoundRecordButton;

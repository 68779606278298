import { user as userModule, liveProduction } from "@myplay/all";

import Upload from "./videoUpload/Upload";
const teamsModule = userModule.teams;

const base64ImgToFile = (dataUrl, filename) => {
  try {
    const arr = dataUrl.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  } catch (e) {
    throw new Error(e.message);
  }
};

const textToFile = (content, filename, contentType) => {
  const a = document.createElement("a");
  const file = new Blob([content], { type: contentType });
};

const singleUpload = async ({ file, teamId }) => {
  try {
    const { uploadUrl } = await teamsModule.uploadSiteResource(
      teamId,
      file.name
    );
    const upload = new Upload({
      id: file.name,
      url: uploadUrl,
      file: file
    });
    await upload.start();
  } catch (e) {
    throw new Error(e.message);
  }
};

const resourceUpload = async ({ files, teamId, siteId, location }) => {
  const filesToDelete = [];
  for (const fileName of Object.keys(files)) {
    const value = files[fileName];
    if (value) {
      try {
        let file;
        // case of base64 img data
        if (value.includes("http")) {
          continue;
        }
        if (value.includes("base64")) {
          file = base64ImgToFile(value, `${fileName}.png`);
        }
        // case of txt data color or team name
        else {
          file = new File([value], `${fileName}.txt`, {
            type: "text/plain"
          });
        }
        if (file) {
          await singleUpload({ file, teamId });
        }
      } catch (e) {
        console.error(`Failed to upload ${fileName} e.message`);
      }
    } else {
      filesToDelete.push(fileName);
    }
  }
  await liveProduction.deleteSiteResources(teamId, filesToDelete);
  await liveProduction.updateScoreDisplay(teamId, location);
};

export default resourceUpload;

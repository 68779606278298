import React, { Component } from "react";
import PropTypes from "prop-types";
import "./LiveScheduler.scss";
import { Input, SuccessButton } from "@myplay/ui";
import ReactTooltip from "react-tooltip";

import clockIcon from "../../../assets/images/clock-green.svg";
import calendarIcon from "../../../assets/images/calendar-green.svg";
import facebookLiveIcon from "../../../assets/images/f-live-button.png";
import youtubeLiveIcon from "../../../assets/images/youtube-live.png";
import CloseIcon from "../../../assets/icons/Icon-Close.svg";

import { DatePicker, TimePicker } from "@material-ui/pickers";
import I18N from "../../../I18N";
import { SimpleDropdown } from "../../index";
import convertMinutesToHoursAndMinutes from "../../../utils/convertMinutesToHoursAndMinutes";
import dayjs from "dayjs";
import { inject, observer } from "mobx-react";

const maxRecordDuration = 180;
const minRecordDuration = 15;

const getRecordDurations = () => {
  const durationDivision = maxRecordDuration / minRecordDuration;
  const durations = [];

  for (let i = 1; i <= durationDivision; i++) {
    // eslint-disable-line
    durations.push({
      label: minRecordDuration * i,
      value: convertMinutesToHoursAndMinutes(minRecordDuration * i)
    });
  }

  return durations;
};

const DEFAULT_DURATIONS = getRecordDurations();

const LiveScheduler = ({ BookingsStore }) => {
  const handleOpenClose = () => {
    BookingsStore.openCloseScheduler();
  };

  const handleSave = async () => {
    await BookingsStore.saveBooking();
  };

  const handleHomeTeamChange = event => {
    BookingsStore.setCurrentBookingProp("homeTeamTitle", event.target.value);
  };

  const handleAwayTeamChange = event => {
    BookingsStore.setCurrentBookingProp("awayTeamTitle", event.target.value);
  };

  const handleThirdPartyKeySet = event => {
    BookingsStore.setCurrentBookingProp("thirdPartyKey", event.target.value);
  };

  const handleThirdPartySelect = event => {
    BookingsStore.setCurrentBookingProp("thirdPartyType", event.target.value);
  };

  const handleDateChange = date => {
    BookingsStore.setCurrentBookingProp("bookingDate", date.toISOString());
  };

  const handleDurationChange = duration => {
    BookingsStore.setCurrentBookingProp("duration", duration);
  };

  const { currentBooking } = BookingsStore;
  const {
    homeTeamTitle,
    awayTeamTitle,
    thirdPartyType,
    thirdPartyKey,
    bookingDate,
    duration
  } = currentBooking;
  return (
    <div className="live-scheduler">
      <div className="live-scheduler__header">
        <p className="live-scheduler__header__title">
          {I18N.t("SCHEDULE_NEW_LIVE_STREAM")}
        </p>
        <img
          className={
            BookingsStore.isSchedulerOpen
              ? "live-scheduler__header__close-icon"
              : "live-scheduler__header__close-icon live-scheduler__header__close-icon--rotate"
          }
          src={CloseIcon}
          onClick={handleOpenClose}
        />
      </div>
      {BookingsStore.isSchedulerOpen && (
        <div className="live-scheduler__form">
          {(BookingsStore.hasNoCredits() && !BookingsStore.isOnEdit)  && <div className="live-scheduler__form__no-credits">
            You have No Credits!
          </div>}
          <Input
            className={`live-scheduler__form__input ${
              BookingsStore.error.homeTeamTitle
                ? "live-scheduler__form__input--error"
                : ""
            }`}
            placeholder="Home Team"
            autoFocus
            value={homeTeamTitle}
            onChange={handleHomeTeamChange}
          />
          <div className="live-scheduler__form__away-input-container">
            <span className="live-scheduler__form__away-label">Vs.</span>
            <Input
              className={`live-scheduler__form__input live-scheduler__form__input--away ${
                BookingsStore.error.awayTeamTitle
                  ? "live-scheduler__form__input--error"
                  : ""
              }`}
              placeholder="Away Team"
              autoFocus
              value={awayTeamTitle}
              onChange={handleAwayTeamChange}
            />
          </div>
          <div className="live-scheduler__form__time-panel">
            <div className="live-scheduler__form__box">
              <img src={calendarIcon} />
              <div className="live-scheduler__form__box__picker">
                <DatePicker
                  placeholder={"DD/MM/YY"}
                  format="DD/MM/YY"
                  value={bookingDate}
                  autoOk
                  disablePast
                  onChange={handleDateChange}
                  error={BookingsStore.error.bookingDate}
                />
              </div>
            </div>
            <div className="live-scheduler__form__box">
              <img src={clockIcon} />
              <div className="live-scheduler__form__box__picker">
                <TimePicker
                  placeholder={"00:00"}
                  value={bookingDate}
                  minDate={new Date()}
                  ampm={false}
                  onChange={handleDateChange}
                  error={BookingsStore.error.bookingDate}
                />
              </div>
            </div>
            <SimpleDropdown
              items={DEFAULT_DURATIONS}
              defaultSelectedItem={DEFAULT_DURATIONS.find(
                item => item.label === duration
              )}
              placeholder="Duration"
              className="live-scheduler__form__dropdown"
              onChange={value => handleDurationChange(value)}
            />
          </div>
          <div className="live-scheduler__form__third-party-picker">
            <input
              value="YOUTUBE"
              type="radio"
              onChange={handleThirdPartySelect}
              checked={thirdPartyType === "YOUTUBE"}
            />
            <img src={youtubeLiveIcon} />
            <input
              value="FACEBOOK"
              type="radio"
              onChange={handleThirdPartySelect}
              checked={thirdPartyType === "FACEBOOK"}
            />
            <img src={facebookLiveIcon} />
            <input
              value="OTHER"
              type="radio"
              onChange={handleThirdPartySelect}
              checked={thirdPartyType === "OTHER"}
            />
            <label>Other</label>
          </div>

          <div className="live-scheduler__form__save">
            <span
              data-tip={"Third party stream key"}
              className="live-scheduler__form__save__tip"
            >
              ?
            </span>
            <Input
              placeholder={"Stream key"}
              className={`live-scheduler__form__input ${
                BookingsStore.error.thirdPartyKey
                  ? "live-scheduler__form__input--error"
                  : ""
              }`}
              value={thirdPartyKey}
              onChange={handleThirdPartyKeySet}
            />
            <SuccessButton onClick={handleSave}>
              {" "}
              {I18N.t("SAVE")}
            </SuccessButton>
            <ReactTooltip />
          </div>
          <span className="live-scheduler__form__error-message">
            {BookingsStore.error.message}
          </span>
          {BookingsStore.saveError && (
            <div className="live-scheduler__save-error">
              <span className="live-scheduler__save-error__title">{I18N.t("BOOKING_ERROR")}</span>
              <span>{BookingsStore.saveError}</span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

LiveScheduler.propTypes = {};
LiveScheduler.defaultProps = {};

export default inject("TeamsStore", "BookingsStore")(observer(LiveScheduler));

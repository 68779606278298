/* eslint-disable */
import postRobot from "post-robot";

postRobot.CONFIG.LOG_LEVEL = "error";

const PLAYER_ID = "59242fc001a93b2c0dcbdab7";
const COACH_ID = "5aca1a67010d3401309e5917";

export const getUserData = async () => {
  if (process.env.NODE_ENV === "production") {
    const event = await postRobot.sendToParent("getUserData");
    return event.data;
  }

  return { userId: COACH_ID, accessToken: "1234" };
};

export const getNewAccessToken = async () => {
  const event = await postRobot.sendToParent("getNewAccessToken");
  return event.data;
};

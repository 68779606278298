/* eslint-disable */
import React, { Component } from "react";
import "./GridBackground.scss";
import isMobile from "is-mobile";

const row = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
const desktopRow = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
  31,
  32,
  33,
  34,
  35,
  36,
  37,
  38,
  39,
  40
];
const grid = new Array(isMobile() ? 8 : 16).fill(isMobile() ? row : desktopRow);

/* eslint-disable no-shadow */
class GridBackground extends Component {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    return (
      <div className="grid-background">
        {grid.map((row, index) =>
          row.map((col, colIndex) => (
            <div key={colIndex + index} className="grid__item" />
          ))
        )}
      </div>
    );
  }
}

export default GridBackground;

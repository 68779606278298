import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "mobx-react";
import dayjsUtils from "@date-io/dayjs";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core";

import { MuiPickersUtilsProvider } from "@material-ui/pickers";

import "react-toastify/dist/ReactToastify.css"; // eslint-disable-line

import "./index.scss";
import { App } from "./scenes";
import { RootStore } from "./stores";
import registerServiceWorker from "./registerServiceWorker";

if (process.env.NODE_ENV === "production") {
  require("../node_modules/@myplay/ui/build/static/css/myplay-ui.css"); // eslint-disable-line
}

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#ffffff"
    },
    secondary: {
      main: "#16c4ab"
    }
  },
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#16c4ab"
      }
    },
    MuiPickersModal: {
      dialogActions: {
        color: "#16c4ab"
      }
    }
  }
});

const setCssInnerHeight = () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
};

setCssInnerHeight();
window.addEventListener("resize", () => {
  setCssInnerHeight();
});

const Root = RootStore.create({});

ReactDOM.render(
  <Provider
    UserStore={Root.UserStore}
    NotificationsStore={Root.NotificationsStore}
    TeamsStore={Root.TeamsStore}
    GamesStore={Root.GamesStore}
    ModalStore={Root.ModalStore}
    SiteStore={Root.SiteStore}
    MetaStore={Root.MetaStore}
    RegisterStore={Root.RegisterStore}
    StatsStore={Root.StatsStore}
    SchedulerStore={Root.SchedulerStore}
    GameScreenStore={Root.GameScreenStore}
    BookingsStore={Root.BookingsStore}
  >
    <MuiThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={dayjsUtils}>
        <App />
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  </Provider>,
  document.getElementById("root")
);
registerServiceWorker();

import React from 'react';
import PropTypes from 'prop-types';
import './StepHeader.scss';


const StepHeader = ({ children ,lines, style, color }) =>
  <div className="title" style={style}>
    {
      lines.map(line => <span key={line} className="title__line" style={{ color }}>{line}</span>)
    }
    {children}
  </div>

StepHeader.propTypes = {
  children: PropTypes.node,
  color: PropTypes.string,
  lines: PropTypes.array,
  style: PropTypes.object
}

StepHeader.defaultProps = {
  children: null,
  color: "",
  lines: [],
  style: {}
}

export default StepHeader;

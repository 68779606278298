import React from "react";
import PropTypes from "prop-types";
import "./UnauthLayout.scss";
import Logo from "../../../logo.png";

const UnauthLayout = ({ children }) => (
  <div className="unauth-layout">
    <div className="unauth-layout__logo">
      <img src={Logo} className="unauth-layout__logo__image" />
    </div>
    {children}
  </div>
);

UnauthLayout.propTypes = {
  children: PropTypes.node
};

UnauthLayout.defaultProps = {
  children: null
};

export default UnauthLayout;

/* eslint-disable react/jsx-handler-names */
/* eslint-disable */
import React, { Component } from "react";
import { SuccessButton, DismissButton } from "@myplay/ui";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";

import "./InsertSharedItem.scss";
import I18N from "../../../I18N/";
import TeamsDropDown from "../../TeamsDropDown";

class InsertSharedItem extends Component {
  static propTypes = {
    GamesStore: PropTypes.shape().isRequired,
    item: PropTypes.shape(),
    ModalStore: PropTypes.shape().isRequired,
    sharedItemId: PropTypes.string,
    TeamsStore: PropTypes.shape().isRequired
  };

  static defaultProps = {
    item: {},
    sharedItemId: ""
  };

  state = {
    team: {}
  };

  componentDidMount() {
    const { TeamsStore } = this.props;
    const { teams } = TeamsStore;

    this.setState({ team: teams[0] });
    
    this.handleSetTeam(teams[0]);
  }

  handleSetTeam = team => {
    this.setState({
      team
    });
  };

  handleSave = () => {
    const { team } = this.state;
    const { GamesStore, sharedItemId } = this.props;

    GamesStore.insertSharedItem(sharedItemId, team);
  };

  handleDiscard = () => {
    const { ModalStore } = this.props;

    ModalStore.closeModal();
  };

  teamsToItems = teams => {
    return (teams && teams.map(({ name }) => ({ value: name }))) || [];
  };

  render() {
    const { TeamsStore, item } = this.props;
    const { teams } = TeamsStore;

    return (
      <div className="insert-shared-game">
        <h2>{I18N.t("NEW_SHARED_VIDEO_TITLE")}</h2>
        <TeamsDropDown teams={teams} onChange={this.handleSetTeam} />
        <img
          src={item.thumbnailUri}
          className="insert-shared-game__thumbnail"
        />
        <div />
        <div>
          <DismissButton onClick={this.handleDiscard}>
            {I18N.t("DISCARD")}
          </DismissButton>
          <SuccessButton onClick={this.handleSave}>
            {I18N.t("SAVE")}
          </SuccessButton>
        </div>
      </div>
    );
  }
}

export default inject("GamesStore", "TeamsStore", "ModalStore")(
  observer(InsertSharedItem)
);

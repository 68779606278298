import React from "react";
import "./ScoreboardType.scss";
import { RoundCheckbox, SuccessButton } from "@myplay/ui";
import I18N from "../../I18N";

const types = ["Digital-Images", "Video-Stream"];

const ScoreboardType = ({ onSelect, selected ,onApply}) => {
  return (
    <div className="scoreboard-type">
      <div className="scoreboard-type__checkbox-container">
        {types.map(type => (
          <div className="scoreboard-type__checkbox-wrapper">
            <RoundCheckbox
              isSelected={selected === type}
              onClick={() => onSelect(type)}
            />
            <span className="scoreboard-type__checkbox-wrapper__text">
              {type}
            </span>
          </div>
        ))}
      </div>
      <SuccessButton onClick={onApply}> {I18N.t("APPLY")}</SuccessButton>
    </div>
  );
};

export default ScoreboardType;

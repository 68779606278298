import React from 'react';
import { SuccessButton } from '@myplay/ui';
import I18N from '../../../I18N';
import { GREEN_LIGHT } from '../../../styles/shared/colors';
import { LOGIN_ROUTE_NAME, MYPLAY_LOGIN_URL } from '../../../utils/constants';
import history from '../../../utils/history';




const ResetPasswordSuccess = ()=> {
  return(
    <div className="reset-password-success">
      <span>{I18N.t("YOUR_NEW_PASSWORD")}</span>
      <span>{I18N.t("WAS_SAVED_SUCCESSFULLY")}</span>
    </div>
  )
}

export default ResetPasswordSuccess;

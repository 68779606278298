import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react/index";

import "./TabsContainer.scss";
import { Highlights, Breakdowns, Stats } from "../../scenes";

class TabsContainer extends Component {
  static propTypes = {
    checkForClipsInProcess: PropTypes.func,
    GameScreenStore: PropTypes.object, 
    onTabChange: PropTypes.func,
    NotificationsStore: PropTypes.object,
    notify: PropTypes.any,
    updateTabReady: PropTypes.func,
    selectedIndex: PropTypes.number
  };

  static defaultProps = {
    selectedIndex: 0,
    onTabChange: () => {},
    checkForClipsInProcess: () => {},
    NotificationsStore: {},
    notify: null,
    updateTabReady: () => {},
    GameScreenStore: {}
  };

  state = {
    height: window.innerHeight
  };

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
  }

  updateDimensions = () => {
    this.setState({
      height: window.innerHeight
    });
  };

  handleSelectTab = index => {
    const { NotificationsStore } = this.props;
    if (NotificationsStore.selectedClipId) {
      NotificationsStore.setCurrentClipId("");
    }
    this.props.onTabChange(index);
  };

  render() {
    const { height } = this.state;
    const {
      notify,
      selectedIndex,
      updateTabReady,
      checkForClipsInProcess,
      GameScreenStore: { currentVideoContainer, currentVideoId, breakdowns }
    } = this.props;

    const currentTabs = ["HIGHLIGHTS", "BREAKDOWN", "STATS"];

    const tabList = (
      <TabList>
        {currentTabs.map(title => (
          <Tab key={title}>{title}</Tab> // eslint-disable-line
        ))}
      </TabList>
    );
    return (
      <Tabs
        selectedIndex={selectedIndex}
        onSelect={index => this.handleSelectTab(index)}
      >
        {tabList}
        <TabPanel>
          <div style={{ height: "100%" }}>
            <Highlights
              notify={notify}
              updateTabReady={updateTabReady}
              checkForClipsInProcess={checkForClipsInProcess}
              isActive={selectedIndex === 0}
            />
          </div>
        </TabPanel>
        <TabPanel>
          <Breakdowns
            videoId={currentVideoId}
            height={height}
            videoContainer={currentVideoContainer}
            gameEvents={breakdowns}
            updateTabReady={updateTabReady}
            checkForClipsInProcess={checkForClipsInProcess}
          />
        </TabPanel>
        <TabPanel>
            <div style={{ height: "100%" }}>
              <Stats
                videoId={currentVideoId}
                videoContainer={currentVideoContainer}
                updateTabReady={updateTabReady}
              />
            </div>
        </TabPanel>
      </Tabs>
    );
  }
}

export default inject("UserStore", "NotificationsStore", "GameScreenStore")(
  observer(TabsContainer)
);

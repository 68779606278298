import React from "react";
import PropTypes from "prop-types";
import { CategoryLoader } from "@myplay/ui";
import { inject, observer } from "mobx-react";

const CategoryAwareLoader = ({ TeamsStore, children, isLoading }) => {
  const { currentTeam } = TeamsStore
  const name  = currentTeam && currentTeam.category ? currentTeam.category.name : 'basketball'
  return (
    <CategoryLoader category={name} isLoading={isLoading}>
      {children}
    </CategoryLoader>
  );
};

CategoryAwareLoader.propTypes = {
  children: PropTypes.object,
  isLoading: PropTypes.bool,
  TeamsStore: PropTypes.object
};

CategoryAwareLoader.defaultProps = {
  children: {},
  isLoading: true,
  TeamsStore: {
    currentTeam: {
      category: {
        name: "basketball"
      }
    }
  }
};

export default inject("TeamsStore")(observer(CategoryAwareLoader));

import React from "react";
import PropTypes from "prop-types";
import { SuccessButton, DismissButton } from "@myplay/ui";
import arrow from "../../assets/images/signup-forward-arrow.svg";

import "./RegisterStandartButtons.scss";

import I18N from "../../I18N";
import { ORANGE, GREY } from "../../styles/shared/colors";

const RegisterStandartButtons = ({
  onSuccess,
  onDismiss,
  isDismissVisible,
  isSuccessVisible,
  isSuccessAllowed
}) => {
  return (
    <div className="register-standart-panel">
      <DismissButton
        className="register-standart-panel__dismiss"
        style={{ visibility: isDismissVisible ? "visible" : "hidden" }}
        showBorder={false}
        color="white"
        onClick={onDismiss}
      >
        {I18N.t("BACK")}
      </DismissButton>
      <SuccessButton
        background={isSuccessAllowed ? ORANGE : GREY}
        style={{
          visibility: isSuccessVisible ? "visible" : "hidden",
          filter: isSuccessAllowed ? "" : "unset",
          cursor: isSuccessAllowed ? "pointer" : "unset"
        }}
        className="register-standart-panel__success"
        onClick={isSuccessAllowed ? onSuccess : null}
      >
        <img src={arrow} style={{ margin: "4px 0px 0px 4px" }} />
      </SuccessButton>
    </div>
  );
};

RegisterStandartButtons.propTypes = {
  isDismissVisible: PropTypes.bool,
  isSuccessAllowed: PropTypes.bool,
  isSuccessVisible: PropTypes.bool,
  onDismiss: PropTypes.func,
  onSuccess: PropTypes.func
};

RegisterStandartButtons.defaultProps = {
  isDismissVisible: true,
  isSuccessAllowed: false,
  isSuccessVisible: true,
  onDismiss: () => {},
  onSuccess: () => {}
};

export default RegisterStandartButtons;

import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import "./HeaderModal.scss";
import CloseIcon from "../../../assets/images/close_icon.svg";

Modal.setAppElement("body");

const HeaderModal = ({
  children,
  isOpen,
  onRequestClose,
  modalWidth,
  modalHeight,
  position
}) => {
  return (
    <Modal
      className={
        position !== "right"
          ? "header-modal__modal"
          : "header-modal__modal header-modal__modal--right"
      }
      overlayClassName="header-modal__overlay"
      bodyOpenClassName="header-modal__body--open"
      isOpen={isOpen}
      style={{ content: { width: modalWidth, height: modalHeight } }}
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
      onRequestClose={onRequestClose}
    >
      <div className="header-modal__close-wrapper">
        {position !== "right" && (
          <button
            type="button"
            className="header-modal__close"
            onClick={onRequestClose}
          >
            <img src={CloseIcon} alt="close" />
          </button>
        )}
      </div>
      <div className="header-modal__content">{children}</div>
    </Modal>
  );
};

HeaderModal.propTypes = {
  children: PropTypes.node,
  isOpen: PropTypes.bool.isRequired,
  modalHeight: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
  modalWidth: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
  onRequestClose: PropTypes.func,
  position: PropTypes.string
};

HeaderModal.defaultProps = {
  children: null,
  modalHeight: null,
  modalWidth: null,
  onRequestClose: () => {},
  position: null
};

export default HeaderModal;

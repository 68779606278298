import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { PrimaryButton, Loader } from "@myplay/ui";

import {
  WHITE,
  GREEN_LIGHT,
  FACEBOOK_BLUE,
  RED
} from "../../styles/shared/colors";
import I18N from "../../I18N";
import myplayIcon from "../../assets/images/logo_small.svg";
import facebookLiveIcon from "../../assets/icons/facebook-rounded-icon.png";
import youtubeLiveIcon from "../../assets/icons/youtube-rounded-icon.png";
import "./PreLiveMenu.scss";

const PreLiveMenu = ({
  onFacebook,
  onYoutube,
  onMyplay,
  isCheckDone
}) => {
  return (
    <div className="pre-live-menu">
      {isCheckDone ? (
        <Fragment>
          <p className="pre-live-menu__title">
            {I18N.t("PRE_LIVE_MENU_TITLE")}
          </p>
          <div className="pre-live-menu__buttons-container">
            <PrimaryButton
              color={WHITE}
              background={GREEN_LIGHT}
              className="pre-live-menu__buttons-container__button"
              onClick={onMyplay}
            >
              <img
                src={myplayIcon}
                style={{
                  width: "30px",
                  marginLeft: "0px",
                  marginRight: "15px"
                }}
              />
              {I18N.t("MYPLAY_LIVE")}
            </PrimaryButton>
            <PrimaryButton
              color={WHITE}
              background={FACEBOOK_BLUE}
              className="pre-live-menu__buttons-container__button"
              onClick={onFacebook}
            >
              <img
                src={facebookLiveIcon}
                style={{
                  width: "30px",
                  marginLeft: "0px",
                  marginRight: "15px"
                }}
              />
              {I18N.t("FACEBOOK_LIVE")}
            </PrimaryButton>
            <PrimaryButton
              color={WHITE}
              background={RED}
              className="pre-live-menu__buttons-container__button"
              onClick={onYoutube}
            >
              <img
                src={youtubeLiveIcon}
                style={{ width: "60px", marginLeft: "-12px" }}
              />
              {I18N.t("YOUTUBE_LIVE")}
            </PrimaryButton>
          </div>
        </Fragment>
      ) : (
        <div className="pre-live-menu__loader">
          <p>{I18N.t("GETTING_READY")}</p>
          <Loader />
        </div>
      )}
    </div>
  );
};

PreLiveMenu.propTypes = {
  isCheckDone: PropTypes.bool,
  onFacebook: PropTypes.func,
  onYoutube: PropTypes.func,
  onMyplay: PropTypes.func
};

PreLiveMenu.defaultProps = {
  isCheckDone: false,
  onFacebook: () => {},
  onYoutube: () => {},
  onMyplay: () => {}
};

export default PreLiveMenu;

/* eslint-disable react/jsx-no-literals */
import React from "react";
import Downshift from "downshift";

import "./SimpleDropdown.scss";

function getButtonText(selectedItem, defaultSelectedItem, placeholder) {
  if (selectedItem && selectedItem.value) {
    return selectedItem.value;
  }

  if (placeholder) {
    return placeholder;
  }
  if (defaultSelectedItem && defaultSelectedItem.value) {
    return defaultSelectedItem.value;
  }

  return "";
}

const SimpleDropdown = ({
  items,
  itemToString,
  defaultSelectedItem,
  onChange,
  menuClassName,
  placeholder,
  className
}) => {
  return (
    <Downshift
      items={items}
      defaultSelectedItem={defaultSelectedItem}
      itemToString={itemToString}
      onChange={value => onChange(value)}
    >
      {({
        isOpen,
        selectedItem,
        getItemProps,
        getToggleButtonProps,
        getMenuProps
      }) => {
        return (
          <div
            style={{ position: "relative", width: "100%" }}
            className={className}
          >
            <button
              type="button"
              {...getToggleButtonProps()}
              className="simple-dropdown__button"
            >
              {getButtonText(selectedItem, defaultSelectedItem, placeholder)}
            </button>
            {isOpen ? (
              <ul
                {...getMenuProps()}
                className={`simple-dropdown__list ${menuClassName || ""}`}
              >
                {items &&
                  items.map(item => (
                    <li
                      key={item.value}
                      index={item.value}
                      className="simple-dropdown__list__item"
                      {...getItemProps({ item })}
                    >
                      {item.value}
                    </li>
                  ))}
              </ul>
            ) : null}
          </div>
        );
      }}
    </Downshift>
  );
};

export default SimpleDropdown;

import footballBg from "../../assets/images/Football_BG.jpg";
import vollyballBg from "../../assets/images/Vollyball_BG.jpg";
import basketBall1 from "../../assets/images/backgrounds/Basketball.jpg";
import basketBall2 from "../../assets/images/backgrounds/Basketball_2.jpg";
import football from '../../assets/images/backgrounds/Football.jpg';
import hockey from '../../assets/images/backgrounds/Hockey.jpg';
import lacross from '../../assets/images/backgrounds/Lacross.jpg';
import swim from '../../assets/images/backgrounds/swim.jpg';
import tennis from '../../assets/images/backgrounds/Tennis.jpg';
import mobileFootball from '../../assets/images/backgrounds/Football-mobile.jpg';
import mobileVollyball from '../../assets/images/backgrounds/Vollyball-mobile.jpg';
import mobileBasketball1 from '../../assets/images/backgrounds/Basketball_1-mobile.jpg';
import mobileSoccer from '../../assets/images/backgrounds/Soccer-mobile.jpg';
import mobileHockey from '../../assets/images/backgrounds/Hockey-mobile.jpg';
import mobileLacross from '../../assets/images/backgrounds/Lacross-mobile.jpg';
import mobileSwim from '../../assets/images/backgrounds/Swim-mobile.jpg';
import mobileTennis from '../../assets/images/backgrounds/Tennis-mobile.jpg';
import emptyBasketball from '../../assets/images/BasketballBackground.jpg';

import { NAME, COUNTRY, BIRTHDATE, REGISTRATION_METHOD, PHONE, EMAIL, VERIFICATION_CODE, TEAM_CODE, PASSWORD, TERMS_OF_SERVICE } from '../../utils/constants';

const backgrounds = {
  [NAME]: basketBall2,
  [COUNTRY] : vollyballBg,
  [BIRTHDATE]: lacross,
  [REGISTRATION_METHOD]: tennis,
  [PHONE]: footballBg,
  [EMAIL]: footballBg,
  [VERIFICATION_CODE]: swim,
  [TEAM_CODE]: football,
  [PASSWORD]: hockey,
  [TERMS_OF_SERVICE]:basketBall1
}

const mobileBackgrounds = {
  [NAME]: mobileBasketball1,
  [COUNTRY] : mobileVollyball,
  [BIRTHDATE]: mobileLacross,
  [REGISTRATION_METHOD]: mobileTennis,
  [PHONE]: mobileSoccer,
  [EMAIL]: mobileSoccer,
  [VERIFICATION_CODE]: mobileSwim,
  [TEAM_CODE]: mobileFootball,
  [PASSWORD]: mobileHockey,
  [TERMS_OF_SERVICE]: emptyBasketball
}

export {
  backgrounds,
  mobileBackgrounds
};

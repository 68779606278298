import { types } from "mobx-state-tree";
import optionalAndMaybeNull from "../../utils/mobx-optional-and-maybe-null";
import {
  NAME,
  COUNTRY,
  BIRTHDATE,
  REGISTRATION_METHOD,
  PHONE,
  EMAIL,
  VERIFICATION_CODE,
  TEAM_CODE,
  PASSWORD,
  TERMS_OF_SERVICE
} from "../../utils/constants";

const Step = types.model("Step", {
  stepName: optionalAndMaybeNull(types.string),
  value: optionalAndMaybeNull(types.frozen()),
  isVisible: optionalAndMaybeNull(types.boolean)
});

const Steps = types.model("Steps", {
  name: optionalAndMaybeNull(Step),
  country: optionalAndMaybeNull(Step),
  birthdate: optionalAndMaybeNull(Step),
  registrationMethod: optionalAndMaybeNull(Step),
  phone: optionalAndMaybeNull(Step),
  email: optionalAndMaybeNull(Step),
  verificationCode: optionalAndMaybeNull(Step),
  teamCode: optionalAndMaybeNull(Step),
  password: optionalAndMaybeNull(Step),
  termsOfService: optionalAndMaybeNull(Step)
});

/**
 * Login parameters to pass to ReactNative App via WebView to auto login after register finish
 *
 * We can delete it after we are done developing new register flow on native app
 */
const LoginParams = types.model("LoginParams", {
  identity: optionalAndMaybeNull(types.string),
  password: optionalAndMaybeNull(types.string)
});

const RegisterStore = types
  .model("RegisterStore", {
    fields: optionalAndMaybeNull(Steps),
    registerWithFacebook: optionalAndMaybeNull(types.boolean),
    registerWithAppleId: optionalAndMaybeNull(types.boolean),
    loginParams: optionalAndMaybeNull(LoginParams)
  })
  .views(self => ({
    get stepsArray() {
      const stepsNames = [];

      if (!self.fields) {
        return [];
      }

      Object.values(self.fields).map(field => {
        if (field && field.isVisible) {
          stepsNames.push(field.stepName);
        }
      });

      return stepsNames;
    }
  }))
  .actions(self => ({
    setField(name, value, isVisible) {
      self.fields[name] = {
        stepName: name,
        value,
        isVisible
      };
    },
    setFacebookConnetValues(userData) {
      const { firstName, lastName, email, country, birthdate } = userData;

      self.setField(NAME, { firstName, lastName }, false);
      self.setField(
        COUNTRY,
        {
          country
        },
        false
      );
      self.setField(BIRTHDATE, birthdate, !birthdate);
      self.setField(REGISTRATION_METHOD, null, false);
      self.setField(PHONE, null, false);
      self.setField(EMAIL, email, !email);
      self.setField(VERIFICATION_CODE, null, false);
      self.setField(TEAM_CODE, null, true);
      self.setField(PASSWORD, null, false);
      self.setField(TERMS_OF_SERVICE, null, true);

      self.registerWithFacebook = true;
    },
    setAppleIdConnectValues(userData) {
      const { email, country } = userData;

      self.setField(NAME, null, true);
      self.setField(COUNTRY,  country , false);
      self.setField(BIRTHDATE, null, true);
      self.setField(REGISTRATION_METHOD, null, false);
      self.setField(PHONE, null, false);
      self.setField(EMAIL, email, !email);
      self.setField(VERIFICATION_CODE, null, false);
      self.setField(TEAM_CODE, null, true);
      self.setField(PASSWORD, null, false);
      self.setField(TERMS_OF_SERVICE, null, true);

      self.registerWithAppleId = true;
    },

    setFullRegistrationFlow() {
      const { fields } = self;
      Object.keys(fields).map(field => {
        self.setField(field, null, true);
      });
    },
    setInvitationFlow(data) {
      Object.values(data).map(step => {
        self.setField(step.stepName, step.value, step.isVisible);
      });
    },
    setLoginParams(data) {
      const params = {};
      params.identity = data.email || data.phone;
      params.password = data.password;
      self.loginParams = { ...params };
    }
  }));

export default RegisterStore;

import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import "./SecondaryHeader.scss";
import { TEAM_CHANNEL_ROUTE_NAME } from "../../utils/constants";
import backIcon from "../../assets/icons/Icon-Back-02.svg";



const ConditionalWrapping = ({ condition, wrap, children }) =>
  condition ? wrap(children) : children;

const SecondaryHeader = ({ to, handleBack, children }) => (
  <header className='secondary-header'>
    <ConditionalWrapping
      condition={!handleBack}
      wrap={child => <Link to={to}>{child}</Link>}
    >
      <button
        type="button"
        className="secondary-header__back-button"
        onClick={handleBack || null}
      >
        <img src={backIcon} className="secondary-header__back-button__icon" />
      </button>
      {children}
    </ConditionalWrapping>
  </header>
);

SecondaryHeader.propTypes = {
  children: PropTypes.node,
  handleBack: PropTypes.func,
  to: PropTypes.string
};

SecondaryHeader.defaultProps = {
  children: null,
  handleBack: null,
  to: TEAM_CHANNEL_ROUTE_NAME
};

export default SecondaryHeader;

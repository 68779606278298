import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import "./Notifications.scss";
import NotificationsList from "./NotificationsList";

class Notifications extends Component {
  static propTypes = {
    closeModal: PropTypes.func,
    currentVideoId: PropTypes.string,
    getMoreNotifications: PropTypes.func,
    history: PropTypes.object,
    notifications: PropTypes.array,
    updateSelectedClipId: PropTypes.func,
    updateSelectedVideoId: PropTypes.func
  };

  static defaultProps = {
    closeModal: () => {},
    currentVideoId: "",
    getMoreNotifications: () => {},
    history: {},
    notifications: [],
    updateSelectedClipId: () => {},
    updateSelectedVideoId: () => {}
  };

  shouldComponentUpdate() {
    return true;
  }

  getVideoIdFromUrl = () => {
    if (window.location.pathname.includes("game/")) {
      const urlVideoId = window.location.pathname
        .split("game/")[1]
        .split("/")[0];
      return urlVideoId;
    }

    return null;
  };

  handleNotificationClick = ({ action }) => {
    const {
      currentVideoId,
      updateSelectedVideoId,
      updateSelectedClipId,
      history,
      closeModal
    } = this.props;
    switch (action.type) {
      case "new_video":
      case "upload": {
        const urlVideoId = this.getVideoIdFromUrl();
        const videoId = action.path.split("game/")[1];
        if (videoId !== currentVideoId && videoId !== urlVideoId) {
          updateSelectedVideoId(videoId, action.team);
          history.push(`/app/${action.path}`);
        }
        break;
      }
      case "clip": {
        const urlVideoId = this.getVideoIdFromUrl();
        const videoId = action.path.split("game/")[1].split("?clip")[0];
        if (videoId !== currentVideoId && videoId !== urlVideoId) {
          updateSelectedVideoId(videoId, action.team);
          history.push(action.path);
        }
        const clipId = action.path.split("clip=")[1];
        updateSelectedClipId(clipId, action.team);
        break;
      }
      case "download":
      case "assign":
        window.location.assign(action.link);
        break;
    }
    closeModal();
  };

  render() {
    const { notifications, getMoreNotifications } = this.props;

    return (
      <NotificationsList
        notifications={notifications}
        getMoreNotifications={getMoreNotifications}
        onNotificationClick={this.handleNotificationClick}
      />
    );
  }
}
export default withRouter(Notifications);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { generate } from "shortid";

class CountriesCode extends Component {
  static propTypes = {
    countries: PropTypes.array,
    currentCountry: PropTypes.number,
    input: PropTypes.object
  };

  static defaultProps = {
    countries: [],
    currentCountry: 0,
    input: {}
  };

  static getDerivedStateFromProps(props, state) {
    const { countries, currentCountry } = props;
    if (countries === state.countries) {
      return {}
    };
    return {
      countries,
      currentCountry,
    };
  }


  state = {
    countries: [],
    currentCountry: {},
    currentDialCode: ''
  }

  componentDidUpdate(prevProps) {
    const { input, countries, currentCountry } = this.props;

    if (countries.length !== prevProps.countries.length) {
      input.onChange(countries[currentCountry])
    }

    if (currentCountry !== prevProps.currentCountry) {
      this.setState({// eslint-disable-line
        currentDialCode: countries[currentCountry] && countries[currentCountry].dialCode || ""
      }, ()=> input.onChange(this.state.currentDialCode));
    }
  }

  handleChange = event => {
    const { input } = this.props;
    this.setState({
      currentDialCode: event.target.value
    })
    event.target.size=0;
    input.onChange(event.target.value);
  }

  render() {
    const { currentDialCode ,currentCountry, countries } = this.state;
    const dialCodes = countries.map(country => country.dialCode).sort((code1,code2) => parseInt(code1.substring(1),10) - parseInt(code2.substring(1),10));
    dialCodes.unshift("+972"); // put israel code on the top 
    return (countries[currentCountry] ?
        <select className="countries-code-select"
          tabIndex={-1}
          onMouseDown={e => {
            if(e.target.options && e.target.options.length>8){
              e.target.size=8;
            }
          }}
          onChange={event => this.handleChange(event)}
          onBlur={e=> e.target.size=0}>
        {
          dialCodes.map(
            code => currentDialCode === code ?
              <option key={generate()} value={code} selected >
                {code}
              </option> :
              <option key={generate()} value={code} >
                {code}
              </option>)
        }
      </select> : null
    );
  }
};

export default CountriesCode

import React, { Component } from "react";
import PropTypes from "prop-types";
import "./HeaderButtonsPanel.scss";

class ActionOnHoverButton extends Component {
  static propTypes = {
    buttonText: PropTypes.string.isRequired,
    icon: PropTypes.any.isRequired,
    onClick: PropTypes.func.isRequired,
    style: PropTypes.object,
    iconStyle: PropTypes.object
  };

  static defaultProps = {
    style: {},
    iconStyle: {}
  }

  state = {
    isHidden: true
  };

  handleMouseOver = () => {
    this.setState({ isHidden: false });
  };

  handleMouseLeave = () => {
    this.setState({ isHidden: true });
  };

  handleClick = () => {
    const { onClick } = this.props;
    onClick();
  };

  render() {
    const { isHidden } = this.state;
    const { icon, buttonText, style ,iconStyle} = this.props;
    return (
      /* eslint-disable */
      <div
        className="action-on-hover-button"

        onClick={this.handleClick}
        data-testid={`${buttonText}-btn`.toLowerCase()}
        style={style} //add z-index to prevent buggy behavior for long words
      >
        {typeof icon === "string" ? (
          <img src={icon} style={iconStyle} onMouseLeave={this.handleMouseLeave}
               onMouseEnter={this.handleMouseOver} />
        ) : (
          icon
        )}
        <button
          type="button"
          className={`action-on-hover-button__text  ${
            isHidden ? "action-on-hover-button__text--hide" : ""
          }`}
        >
          {buttonText || null}
        </button>
      </div>
    );
  }
}

export default ActionOnHoverButton;

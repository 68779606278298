import React from "react";
import { inject, observer } from "mobx-react";
import { ImagePicker, ScoreboardSettings } from "@myplay/ui";

import { HeaderModal } from "../";
import DefaultModal from "./DefaultModal";
import ShareGameModal from "../Modals/ShareGame/";
import AssignAssistant from "../Modals/AssignAssistant";
import PreviewHighlight from "../Modals/PreviewHighlight";
import DownloadVideo from "../Modals/DownloadVideo";
import InsertSharedItemModal from "../Modals/InsertSharedItem";
import Record from "../Modals/Record";
import VideoUpload from "../Modals/VideoUpload";
import NotificationsContainer from "../Notifications/NotificationsContainer";
import ConfirmModal from "../Modals/ConfirmModal";
import ChangeTeam from "../ChangeTeam";
import VerifyPhone from "../Modals/VerifyPhone";
import RecordScheduler from "../Modals/RecordScheduler";
import UserVideoUpload from "../Modals/UserVideoUpload";
import SelectUploadVideoMethod from "../Modals/SelectUploadVideoMethod"
import BookingScheduler from "../Modals/BookingScheduler";

/* eslint-disable react/display-name */
const modalToComponent = {
  SHARE_GAME: ShareGameModal,
  ASSIGN_GAME_ASSISTANT: AssignAssistant,
  DOWNLOAD_GAME: DownloadVideo,
  INSERT_SHARED_GAME: InsertSharedItemModal,
  RECORD: Record,
  VIDEO_UPLOAD: VideoUpload,
  NOTIFICATIONS: NotificationsContainer,
  CONFIRM_MODAL: ConfirmModal,
  PREVIEW_HIGHLIGHT: PreviewHighlight,
  PICKER_MODAL: ImagePicker,
  CHANGE_TEAM: ChangeTeam,
  VERIFY_PHONE: VerifyPhone,
  RECORD_SCHEDULER: RecordScheduler,
  USER_VIDEO_UPLOAD: UserVideoUpload,
  SELECT_UPLOAD_VIDEO_METHOD: SelectUploadVideoMethod,
  SCOREBOARD_SETTINGS: ScoreboardSettings,
  BOOKING: BookingScheduler
};
/* eslint-enable */

/* eslint-disable react/jsx-handler-names */
const RootModal = ({ ModalStore }) => {
  const {
    modalType,
    modalPosition,
    modalProps,
    containerProps,
    isOpen
  } = ModalStore;

  const SpecificModal = modalToComponent[modalType];

  if (!isOpen || !SpecificModal) {
    return null;
  }

  switch (modalPosition) {
    case "center":
      return (
        <DefaultModal isOpen={isOpen} {...containerProps}>
          <SpecificModal {...modalProps} />
        </DefaultModal>
      );
    case "top":
      return (
        <HeaderModal
          isOpen={isOpen}
          onRequestClose={ModalStore.closeModal}
          {...containerProps}
        >
          <SpecificModal {...modalProps} />
        </HeaderModal>
      );

    case "top-right":
      return (
        <HeaderModal
          isOpen={isOpen}
          onRequestClose={ModalStore.closeModal}
          {...containerProps}
          position="right"
        >
          <SpecificModal {...modalProps} />
        </HeaderModal>
      );
    default:
      return <div />;
  }
};

export default inject(["ModalStore"])(observer(RootModal));

import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { game as gameModule } from "@myplay/all";
import "./AssignAssistant.scss";
import { inject, observer } from "mobx-react";
import {
  PlayerBadge,
  PrimaryButton,
  SuccessButton,
  DismissButton,
  toast
} from "@myplay/ui";
import I18N from "../../../I18N/";
import { WHITE, GREEN_LIGHT } from "../../../styles/shared/colors";
import { MemberDetails } from "../../";

const tagTasksModule = gameModule.tagTasks;

class AssignAssistant extends Component {
  static propTypes = {
    TeamsStore: PropTypes.shape(),
    videoContainerId: PropTypes.string
  };

  static defaultProps = {
    TeamsStore: {},
    videoContainerId: ""
  };

  state = {
    assistant: null,
    isDone: false
  };

  renderTeamList = () => {
    const { TeamsStore } = this.props;
    const { currentTeamMembers } = TeamsStore;
    const assignButton = {
      color: WHITE,
      background: GREEN_LIGHT,
      width: "57px",
      height: "23px",
      fontSize: "12px"
    };
    return (
      <Fragment>
        <p className="assign-assistant__list-title">
          {I18N.t("ASSIGN_ASSISTANT")}
        </p>
        <ul className="assign-assistant__list">
          {currentTeamMembers &&
            Object.keys(currentTeamMembers)
              .sort()
              .map(memberType => (
                <Fragment key={memberType}>
                  {currentTeamMembers[memberType].map(member => (
                    <li key={member._id} className="assign-assistant__row">
                      <MemberDetails member={member} />
                      <PrimaryButton
                        style={assignButton}
                        onClick={() => {
                          this.setState({ assistant: member });
                        }}
                      >
                        {I18N.t("ASSIGN")}
                      </PrimaryButton>
                    </li>
                  ))}
                </Fragment>
              ))}
        </ul>
      </Fragment>
    );
  };

  renderApproveAssign = () => {
    const {
      firstName,
      lastName,
      fullName,
      avatar,
      avatarBgColor
    } = this.state.assistant;

    const { isDone } = this.state;
    return (
      <div className="assign-assistant__approve-container">
        <div className="assign-assistant__approve-container__image">
          {avatar ? (
            <img src={avatar} />
          ) : (
            <PlayerBadge
              firstName={firstName}
              lastName={lastName}
              color={avatarBgColor}
              fontSize="48px"
            />
          )}
        </div>
        {!isDone ? (
          <Fragment>
            <p className="assign-assistant__approve-container__assign-title">
              {I18N.t("ASSIGN_APPROVE_PART1")}
              <b>{fullName}</b>
              {I18N.t("ASSIGN_APPROVE_PART2")}
            </p>
            <div>
              <DismissButton
                onClick={() => {
                  this.setState({ assistant: null });
                }}
              >
                {I18N.t("CANCEL")}
              </DismissButton>
              <SuccessButton onClick={this.handleAssignApprove}>
                {I18N.t("YES")}
              </SuccessButton>
            </div>
          </Fragment>
        ) : (
          <div>
            <p className="assign-assistant__approve-container__assign-title">
              <b>{fullName}</b>
              {I18N.t("AFTER_ASSIGN_APPROVE")}
            </p>
          </div>
        )}
      </div>
    );
  };

  handleAssignApprove = async () => {
    const { assistant } = this.state;
    const { UserStore, handleCloseModal, videoContainerId, TeamsStore } = this.props;
    const currentUser = UserStore.data;
    try {
      const assignerId = currentUser._id;
      const taggerId = assistant._id;
      await tagTasksModule.createGameTagTask(
        assignerId,
        taggerId,
        videoContainerId,
        TeamsStore.currentTeam._id
      );
      this.setState({ isDone: true });
    } catch (e) {
      handleCloseModal();
      toast.error(I18N.t("ASSIGN_ERROR"));
    }

  };

  render() {
    const { assistant } = this.state;
    return assistant ? this.renderApproveAssign() : this.renderTeamList();
  }
}

export default inject("TeamsStore", "UserStore")(observer(AssignAssistant));

import React, { Component } from "react";
import PropTypes from "prop-types";
import { PrimaryButton } from "@myplay/ui";

import I18N from "../../I18N";
import { WHITE, FACEBOOK_BLUE } from "../../styles/shared/colors";
import facebookLiveIcon from "../../assets/icons/facebook-rounded-icon.png";

class SetFacebookLive extends Component {
  state = {
    streamKey: null
  };

  render() {
    const { onClick } = this.props;
    const { streamKey } = this.state;
    return (
      <div className="set-facebook-live">
        <input
          className="set-facebook-live__key-input"
          placeholder={I18N.t("ENTER_STREAM_KEY")}
          value={streamKey}
          onChange={event => this.setState({streamKey: event.target.value})}
        />
        <PrimaryButton
          color={WHITE}
          background={FACEBOOK_BLUE}
          className="set-facebook-live__button"
          disabled={!streamKey || streamKey.length < 10}
          onClick={() => {
            onClick(streamKey);
          }}
        >
          <img
            src={facebookLiveIcon}
            className="set-facebook-live__button__image"
          />
          {I18N.t("SET_FACEBOOK_LIVE")}
        </PrimaryButton>
      </div>
    );
  }
}

SetFacebookLive.propTypes = {
  onClick: PropTypes.func
};

SetFacebookLive.defaultProps = {
  onClick: () => {}
};

export default SetFacebookLive;

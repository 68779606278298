import React, { Component } from "react";
import PropTypes from 'prop-types';
import { ImageMarker } from '@myplay/ui';

import { BASKETBALL, SOCCER, HANDBALL, TENNIS, VOLLYBALL } from '../../utils/constants';
import basketball from '../../assets/images/courts/Basketball.png';
import handball from '../../assets/images/courts/Handball.png';
import tennis from '../../assets/images/courts/Tennis.png';
import soccer from '../../assets/images/courts/Soccer.png';
import vollyball from '../../assets/images/courts/Vollyball.png';
import "./StatsMap.scss";

const HEIGHT = 375;
const WIDTH = 647;
const MARKER_SIZE = 8;

const SMALL_HEIGHT = 282;
const SMALL_WIDTH = 500;

class StatsMap extends Component {
  static propTypes = {
    category: PropTypes.string,
    courtSketch: PropTypes.string,
    hasTooltip: PropTypes.bool,
    mappedEvents: PropTypes.array,
    onEventClick: PropTypes.func,
  };

  static defaultProps = {
    category: '',
    courtSketch: '',
    hasTooltip: false,
    mappedEvents: [],
    onEventClick: () => {},
  }

  static getDerivedStateFromProps(props, state) {
    if (!state.courtSketch && !props.courtSketch) {
      let courtSketch = '';
      switch(props.category) {
        case BASKETBALL:
          courtSketch = basketball;
          break;
        case SOCCER:
          courtSketch = soccer;
          break;
        case HANDBALL:
          courtSketch = handball;
          break;
        case VOLLYBALL:
          courtSketch = vollyball;
          break;
        case TENNIS:
          courtSketch = tennis;
          break;
        default:
          courtSketch = basketball;
      };
      return {
        courtSketch
      };
    };
    return {
      courtSketch: props.courtSketch
    };
  };

  state = {
    height: window.innerWidth > 1660 ? HEIGHT : SMALL_HEIGHT,
    width: window.innerWidth > 1660 ? WIDTH : SMALL_WIDTH,
    courtSketch: ''
  }

  componentDidMount() {
    window.addEventListener('resize', ()=> this.resizeHeatmap());
  }

  componentWillUnmount() {
    window.removeEventListener('resize', ()=> this.resizeHeatmap());
  }

  setDefaultImage = ()=> {

  }

  resizeHeatmap = ()=> {
    const { height } = this.state;
    if (window.innerWidth < 1660 && height === HEIGHT) {
      this.setState({
        height: SMALL_HEIGHT,
        width: SMALL_WIDTH
      });
    };

    if (window.innerWidth > 1660 && height === SMALL_HEIGHT) {
      this.setState({
        height: HEIGHT,
        width: WIDTH
      });
    };
  }

  render() {
    const { mappedEvents, onEventClick, hasTooltip } = this.props;
    const { height, width, courtSketch } = this.state;
    // const { height, width } = this.state;

    return (
      <div className="stats-map">
        <ImageMarker
          imageHeight={height}
          imageWidth={width}
          markerSize={MARKER_SIZE}
          markerColor={mappedEvents}
          courtSketch={courtSketch}
          // courtSketch='https://myplay-assets.gcdn.co/default-assets/court-sketch/full/dev-volleyball.png'
          markers={mappedEvents}
          isClickable={false}
          hasTooltip={hasTooltip}
          isOneSide={false}
          onEventClick={onEventClick}
        />
      </div>
    )
  }
}

export default StatsMap;

/* eslint-disable react/jsx-no-literals */
/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { Link, withRouter, Redirect } from "react-router-dom";
import PropTypes from "prop-types";

import "./PageNotFound.scss";
import airballImage from "../../assets/images/404.jpg";
import { TEAM_CHANNEL_ROUTE_NAME } from "../../utils/constants";

const PageNotFound = ({ location }) => {
  if (location.pathname === "/") {
    return <Redirect to={TEAM_CHANNEL_ROUTE_NAME} />;
  }

  return (
    <div className="page-not-found">
      <img className="page-not-found__image" src={airballImage} />
      <div className="page-not-found__text">
        <p>404</p>
        <p>IT'S AN AIR BALL...</p>
        <p>SORRY, PAGE NOT FOUND</p>
        <div className="page-not-found__link">
          <Link
            to={TEAM_CHANNEL_ROUTE_NAME}
            className="page-not-found__link__anchor"
          >
            Go to Team Channel
          </Link>
        </div>
      </div>
    </div>
  );
};

PageNotFound.propTypes = {
  location: PropTypes.shape()
};

PageNotFound.defaultProps = {
  location: {}
};

export default withRouter(PageNotFound);

/* eslint-disable no-nested-ternary */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import {
  TeamBrand,
  EmptyTeamChannel,
  WithLoader,
  TeamMembersSwiper
} from "@myplay/ui";
import { withRouter } from "react-router-dom";
import Helmet from "react-helmet";

import "./TeamChannel.scss";
import I18N from "../../I18N";
import CoverImage from "./CoverImage";
import {
  KeyValueDetail,
  VideoGrid,
  UploadCoverPhoto,
  CategoryAwareLoader,
  TeamMembers,
  UserTypeGuard
} from "../../components";
import LiveSchedulerPanel from "../../components/Modals/LiveScheduler/LiveSchedulerPanel";
import { PLAYER_PROFILE_ROUTE_NAME } from "../../utils/constants";
import history from "../../utils/history";
import { getSnapshot } from "mobx-state-tree";

class TeamChannel extends Component {
  static propTypes = {
    GamesStore: PropTypes.shape().isRequired,
    location: PropTypes.shape().isRequired,
    MetaStore: PropTypes.shape().isRequired,
    TeamsStore: PropTypes.shape().isRequired,
    UserStore: PropTypes.shape().isRequired
  };

  state = {
    hasShared: false,
    currentPlayerId: null
  };

  async componentDidMount() {
    const {
      location,
      GamesStore,
      MetaStore,
      UserStore,
      BookingsStore,
      SiteStore
    } = this.props;

    UserStore.refreshUserData();


    await BookingsStore.getBookings();

    if (location.search) {
      const params = new URLSearchParams(location.search);
      const shared = params.get("shared");

      if (shared) {
        GamesStore.getAndDisplaySharedGame(shared);
        this.setState({
          hasShared: true
        });
      }
    }

    if (!MetaStore.showHeader) {
      MetaStore.toggleHeaderOn();
    }
  }
  getCurrentTeamMember = index => {
    const { TeamsStore } = this.props;
    if (index > 0) {
      const id = TeamsStore.currentTeamMembers.players[index - 1]._id;
      this.setState({
        currentPlayerId: id
      });
    }
  };
  getCurrentTeamMembersLength = () => {
    /* eslint-disable consistent-return */
    const { TeamsStore } = this.props;
    const { currentTeamMembers } = TeamsStore;

    if (!currentTeamMembers) {
      return 0;
    }

    const flatMembers = Object.keys(currentTeamMembers).reduce((acc, curr) => {
      acc.push(...currentTeamMembers[curr]);
      return acc;
    }, []);

    if (flatMembers && flatMembers.length) {
      return flatMembers.length;
    }

    return 0;
  };

  render() {
    const {
      TeamsStore,
      GamesStore,
      UserStore,
      SiteStore,
      BookingsStore
    } = this.props;
    const { hasShared, currentPlayerId } = this.state;
    const { currentTeam, currentTeamMembers } = TeamsStore;
    const { currentTeamGames, currentTeamGamesCount, isLoading } = GamesStore;


    return (
      <CategoryAwareLoader
        isLoading={isLoading}
      >
        <div className="team-channel">
          {hasShared ? (
            <Helmet>
              <meta
                name="og:title" // eslint-disable-next-line react/jsx-no-literals
                content={`${I18N.t("TEAM_CHANNEL_SHARE_TITLE")} ${currentTeam.name
                  }`}
              />

              <meta
                name="og:description" // eslint-disable-next-line react/jsx-no-literals
                content={I18N.t("OG_DESCRIPTION_OPEARTOR")}
              />
            </Helmet>
          ) : null}
          <header className="team-channel__header">
            <CoverImage
              src={(currentTeam && currentTeam.teamCoverImage) || ""}
            />
            <div className="team-channel__header__team">
              <TeamBrand
                name={currentTeam ? currentTeam.name : ""}
                defaultImage={currentTeam ? currentTeam.teamImage : ""}
                imagePosition="left"
              />
              <UserTypeGuard allowed={["coach", "operator"]}>
                <div className="team-channel__header__team__details__upload-cover">
                  <UploadCoverPhoto />
                </div>
              </UserTypeGuard>
            </div>
          </header>
          <WithLoader
            isLoading={false}
            className="team-channel__content__loader"
          >
            <section className="team-channel__content">
              <div className="team-channel__content__players">
                <TeamMembers
                  members={currentTeamMembers || []}
                  handleDeleteMember={(id, accountType) => {
                    TeamsStore.removeUserFromTeamWithConfirm(id, accountType);
                  }}
                />
              </div>

              {currentTeamGames && currentTeamGames.length ? (
                <VideoGrid
                  UserStore={UserStore}
                  GamesStore={GamesStore}
                  teamId={currentTeam._id}
                  withStats
                />
              ) : !GamesStore.isLoading ? (
                <EmptyTeamChannel
                  isCenter={currentTeamMembers && currentTeamMembers.length > 0}
                />
              ) : null}
            </section>
          </WithLoader>
        </div>
      </CategoryAwareLoader>
    );
  }
}

TeamChannel.propTypes = {};

export default withRouter(
  inject(
    "TeamsStore",
    "GamesStore",
    "UserStore",
    "MetaStore",
    "SiteStore",
    "BookingsStore"
  )(observer(TeamChannel))
);

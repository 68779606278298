import React, { Component } from "react";
import PropTypes from "prop-types";
import { Field } from "react-final-form";
import ClickOutside from "react-click-outside";
import dayjs from "dayjs";
import { DatePicker } from "@myplay/ui";
import I18N from '../../../I18N';
import { StepHeader } from '../../../components';

class StepBirthdate extends Component {
  static propTypes = {
    isActive: PropTypes.bool,
    isNextStepAllowed: PropTypes.func,
  };

  static defaultProps = {
    isActive: false,
    isNextStepAllowed: () => {},
  };

  state = {
    isDatePickerOpen: false,
    isValue: false
  };

  componentDidUpdate(prevProps) {
    const { isActive, isNextStepAllowed } = this.props;
    const { isValue } = this.state;

    if (isActive && prevProps.isActive !== isActive ) {
      isNextStepAllowed(isValue)
    }
  }

  handleToggleDatePicker = () =>
    this.setState(prevState => ({
      isDatePickerOpen: !prevState.isDatePickerOpen
    }));

  handleDatePickerSelect = (value, input) => {
    input.onChange(value);
    this.setState({
      isValue: !!value
    })
    this.handleToggleDatePicker();
  };

  render() {
    const { isNextStepAllowed } = this.props;
    const { isDatePickerOpen } = this.state;
    return (
      <div className="register__form__step">
        <StepHeader lines={[I18N.t("WHEN_IS_YOUR"),I18N.t("BIRTHDAY")]}/>
        <div className="register__form__step__fields" data-testid="birthdate">
          <Field
            className="register__form__step__fields__field"
            name="birthdate"
          >
            {({ input, meta }) => (
              <div className="register__form__step__fields__field__datepicker">
                <button
                  type="button"
                  className="register__form__step__fields__field__datepicker__button"
                  onClick={this.handleToggleDatePicker}
                >
                  {input.value
                    ? dayjs(input.value).format("DD/MM/YYYY")
                    : "Birthdate"}
                </button>
                {isDatePickerOpen ? (
                  <ClickOutside onClickOutside={this.handleToggleDatePicker}>
                    <div className="register__form__step__fields__field__datepicker__picker">
                      <DatePicker
                        tabIndex={-1}
                        onChange={value => {
                          this.handleDatePickerSelect(value, input);
                          isNextStepAllowed(true);
                        }}
                      />
                    </div>
                  </ClickOutside>
                ) : null}
                <span className="register__form__step__fields__field__datepicker__error">
                  {(meta.touched && meta.error) || meta.submitFailed
                    ? meta.error
                    : ""}
                </span>
              </div>
            )}
          </Field>
        </div>
      </div>
    );
  }
}

export default StepBirthdate;

/* eslint-disable */

import {
  LS_USER_DATA
} from "./constants";


let isVisibleCheck = false;
let isInvisible = false;


const sendAnalyticsEvent = (name, data) => {
  if (process.env.NODE_ENV === "test") {
    return;
  }

  if (!FB) {// eslint-disable-line
    return;
  }

  if (!isVisibleCheck) {
    const userData = localStorage.getItem(LS_USER_DATA);
    if(userData){
      isInvisible = userData.isInvisibleUser;
      isVisibleCheck = true;
    }
  }


  if (!isInvisible) {
    const params = { ...data, date: Date.parse(new Date()) }; // eslint-disable-line
    FB.AppEvents.logEvent(`${name}`, null, params);// eslint-disable-line
  }

  // Use to test analytics
  if (isInvisible) {
    const params = {
      ...data,
      date: Date.parse(new Date()) //eslint-disable-line
    };

    FB.AppEvents.logEvent(`TEST-WEB ${name}`, null, params);// eslint-disable-line
  }
};

export { sendAnalyticsEvent }; // eslint-disable-line

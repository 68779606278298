import React, { Fragment, Component } from "react";
import { SocialSharePanel, LivePlayer, Loader } from "@myplay/ui";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import Div100vh from "react-div-100vh";

import I18N from "../../I18N";
import Logo from "../../assets/images/logo-name.png";
import "./PublicLiveMobile.scss";

class PublicLiveMobile extends Component {
  renderLiveOnline = () => {
    const { liveId } = this.props;
    return <LivePlayer liveId={liveId} />;
  };

  renderLiveOffline = () => {
    return (
      <div className="public-live-mobile__offline-title">
        <p>{I18N.t("PUBLIC_LIVE_OFFLINE_PART1")}</p>
        <p className="public-live-mobile__secondary-title">
          {I18N.t("PUBLIC_LIVE_OFFLINE_PART2")}
        </p>
        <div className="public-live-mobile__logo-container">
          <p>{I18N.t("POWERED_BY")}</p>
          <img src={Logo} />
        </div>
      </div>
    );
  };

  renderReadySiteStatus = () => {
    const { isLive } = this.props;
    return isLive ? this.renderLiveOnline() : this.renderLiveOffline();
  };

  renderPendingSiteStatus = () => {
    return (
      <div className="public-live__loader">
        <p className="public-live__secondary-title">
          {I18N.t("GETTING_READY")}
        </p>
        <Loader />
      </div>
    );
  };

  renderLiveView = () => {
    const { isCheckLiveDone } = this.props;
    return isCheckLiveDone
      ? this.renderReadySiteStatus()
      : this.renderPendingSiteStatus();
  };

  render() {
    const { siteName, isLive, shareLink } = this.props;
    return (
      /* eslint-disable no-nested-ternary */
      <Div100vh>
        <div className="public-live-mobile">
          <Helmet>
            <title>{I18N.t("SHARE_LIVE_TITLE")}</title>
          </Helmet>
          <div className="public-live-mobile__header">
            {siteName ? (
              <Fragment>
                <p style={{ marginRight: "10px" }}>{I18N.t("LIVE_VIEW_FROM")}</p>
                <p className="live__secondary-title"> {siteName} </p>
              </Fragment>
            ) : null}
          </div>
          {this.renderLiveView()}
          <div className="public-live-mobile__footer">
            {isLive ? (
              <Fragment>
                <SocialSharePanel shareLink={shareLink} />
                <div className="public-live-mobile__logo-container">
                  <p>{I18N.t("POWERED_BY")}</p>
                  <img src={Logo} />
                </div>
              </Fragment>
            ) : null}
          </div>
        </div>
      </Div100vh>
    );
  }
}

PublicLiveMobile.propTypes = {
  isCheckLiveDone: PropTypes.bool,
  isLive: PropTypes.bool,
  liveId: PropTypes.string,
  shareLink: PropTypes.string,
  siteName: PropTypes.string
};

PublicLiveMobile.defaultProps = {
  isCheckLiveDone: false,
  isLive: false,
  liveId: "",
  shareLink: "",
  siteName: ""
};

export default PublicLiveMobile;

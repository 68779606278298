import React from 'react';
import PropTypes from 'prop-types';

import I18N from '../../../I18N';
import email from '../../../assets/images/email-icon.svg';
import phone from '../../../assets/images/phone-icon.svg';
import { PHONE, EMAIL } from '../../../utils/constants';

const ResetMethodStep = ({ updateResetMethod })=> {
  const handleClick = (value)=> {
    updateResetMethod(value)
  }
  return(
    <div className='reset-method'>
      <div className='reset-method__title'>
        <span>{I18N.t("HOW_DO_YOU_WANT")}</span>
        <span>{I18N.t("TO_RESET_YOUR_PASSWORD")}</span>
      </div>
      <div className='reset-method__buttons'>
        <button
          type='button'
          data-testid='resetMethod-phone'
          className='reset-method__buttons__button'
          onClick={()=> handleClick(PHONE)}
        >
          <img src={phone} alt=''/>
        </button>
        <button
          type='button'
          data-testid='resetMethod-email'
          className='reset-method__buttons__button'
          onClick={()=> handleClick(EMAIL)}
        >
          <img src={email} alt=''/>
        </button>
      </div>
    </div>
  )
};

ResetMethodStep.propTypes = {
  updateResetMethod: PropTypes.func,
};

ResetMethodStep.defaultProps = {
  updateResetMethod: ()=>{}
};

export default ResetMethodStep;

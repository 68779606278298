/* eslint-disable react/jsx-no-literals */
import React from "react";
import Modal from "react-modal";
import { inject, observer } from "mobx-react";
import "react-html5video/dist/styles.css";
import CloseIcon from "../../assets/images/close_icon.svg";

import "./DefaultModal.scss";

const DefaultModal = ({
  children,
  onRequestClose,
  ModalStore,
  isOpen,
  hideCloseIcon,
  style,
  ...props
}) => {
  const close = onRequestClose || ModalStore.closeModal;

  return (
    <Modal
      isOpen={isOpen}
      className="default-modal"
      overlayClassName="default-modal__overlay"
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
      onRequestClose={() => close()}
      style={{ content: { ...style } }}
      {...props}
    >
      <div className="default-modal__content" style={style}>
        {!hideCloseIcon &&
          < button
            type="button"
            className="default-modal__close"
            onClick={() => close()}
          >
            <img src={CloseIcon} alt="close" />
          </button>
        }
      {children}
      </div>
    </Modal >
  );
};

export default inject(["ModalStore"])(observer(DefaultModal));

import React from "react";
import PropTypes from "prop-types";
import "./LiveSchedulerPanel.scss";
import LiveScheduler from "./LiveScheduler";
import BookingCard from "./BookingCard";
import {inject, observer} from "mobx-react";
import {Loader} from "@myplay/ui";
import I18N from "../../../I18N";

const LiveSchedulerPanel = ({bookings,BookingsStore}) => {
  return (
    <div className="live-scheduler-panel">
      {BookingsStore.isLoading && <div className="live-scheduler-panel__saving-mask">
        <p>{I18N.t("LOADING")}</p>
        <Loader  type="spinningBubbles"/>
      </div>}
      {bookings.map(booking => <BookingCard bookingData={booking} />)}
    </div>
  );
};

LiveSchedulerPanel.propTypes = {};
LiveSchedulerPanel.defaultProps = {};

export default inject("BookingsStore")(observer(LiveSchedulerPanel));

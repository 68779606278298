import { types } from 'mobx-state-tree';

import optionalAndMaybeNull from '../../utils/mobx-optional-and-maybe-null';

const MetaStore = types
  .model('Meta', {
    showHeader: optionalAndMaybeNull(types.boolean)
  })
  .actions(self => ({
    toggleHeaderOn() {
      self.showHeader = true;
    },
    toggleHeaderOff() {
      self.showHeader = false;
    }
  }))

export default MetaStore;


import React from "react";
import { inject, observer } from "mobx-react";

import ConfirmBox from "../../ConfirmBox";

/* eslint-disable react/jsx-sort-props */
const ConfirmModal = ({
  handleSuccess,
  text,
  ModalStore,
  onCancel,
  hasButtons,
  successText,
  cancelText
}) => {
  const handleSuccessAndCloseModal = () => {
    handleSuccess();
    ModalStore.closeModal();
  };

  const handleCancelAndCloseModal = () => {
    if (onCancel) {
      onCancel();
      ModalStore.closeModal();
    } else {
      ModalStore.closeModal();
    }
  };

  return (
    <ConfirmBox
      onCancel={handleCancelAndCloseModal}
      onSuccess={handleSuccessAndCloseModal}
      hasButtons={hasButtons}
      successText={successText}
      cancelText={cancelText}
    >
      <h4 style={{ textAlign: "center" }}>{text}</h4>
    </ConfirmBox>
  );
};

ConfirmModal.propTypes = ConfirmBox.propTypes;
ConfirmModal.defaultProps = ConfirmBox.defaultProps;

export default inject(["ModalStore"])(observer(ConfirmModal));

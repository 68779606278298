import React, { Component } from "react";
import PropTypes from 'prop-types';
import { VideoPlayerWithConnector, VideoInfoBox, EmptyHighlights } from "@myplay/ui";
import postRobot from "post-robot";
import { inject, observer } from "mobx-react";
import { ClipPanel, EditModal, SideDrawer } from "../../components";
import { GREY_LIGHT } from "../../styles/shared/colors";
import { reorder } from "../../utils/helper";
import { sendAnalyticsEvent } from "../../utils/analytics";
import {
  ANALYTICS_PLAYER_HIGHLIGHT_RECORD,
  ANALYTICS_COACH_HIGHLIGHT_RECORD,
  ANALYTICS_CLIP_CREATED,
  ANALYTICS_DISCARD_HIGHLIGHT,
  USER_PLAYER,
  GAME_ROUTE_NAME,
  ANALYTICS_HIGHLIGHTS_HIGHLIGHT_VIEW,
  ANALYTICS_HIGHLIGHTS_CLIP_VIEW,
  ANALYTICS_EDIT_MODAL_DELETE_HIGHLIGHT,
  ANALYTICS_EDIT_MODAL_DELETE_CLIP,
  ANALYTICS_NEW_HIGHLIGHT_ADD_COMMENT,
  ANALYTICS_SAVED_HIGHLIGHT,
  ANALYTICS_DISCARD_RECORDED_HIGHLIGHT
} from "../../utils/constants";
import "./Highlights.scss";
import I18N from "../../I18N";

postRobot.CONFIG.LOG_LEVEL = "error";

const IFRAME_VIDEO_URL = process.env.REACT_APP_IFRAME_VIDEO_URL;

class Highlights extends Component {
  static propTypes = {
    GameScreenStore: PropTypes.object,
    NotificationsStore: PropTypes.object,
    notify: PropTypes.func,
    TeamsStore: PropTypes.object,
    UserStore: PropTypes.object
  };

  static defaultProps = {
    GameScreenStore: {},
    NotificationsStore: {},
    notify: ()=> {},
    TeamsStore: {},
    UserStore: {}
  };

  connector = null;

  scrollBottomRef = React.createRef();

  state = {
    highlightToEdit: null,
    isMakeClipDone: false,
    showProgressBar: false,
    shouldDisplayHighlightModal: false,
    currentDisplayVideos: [],
    currentHighlightIndex: 0,
    withClipPanelThumbnails: false,
    currentClipId: "",
  };

  static getDerivedStateFromProps(props) {
    const {
      NotificationsStore: { selectedClipId }
    } = props;
    if (selectedClipId) {
      return {
        currentClipId: selectedClipId
      };
    }
    return {};
  }

  componentDidMount = async () => {
    const { NotificationsStore, UserStore, updateTabReady, GameScreenStore } = this.props;
    const { data: { fullName, _id, email, accountType } } = UserStore;

    this.playerReady = false;
    this.addEventListners();
    this.setState({ currentClipId: NotificationsStore.currentClipId });

    this.defaultAnalyticsData = {
      fullName,
      id: _id,
      email,
      accountType
    };

    history.pushState(// eslint-disable-line
      {},
      "",
      `${GAME_ROUTE_NAME}/${GameScreenStore.currentVideoId}/highlights`
    );

    try {
      postRobot.on("getUserData", () => {
        return {
        userId: UserStore.data._id,
        accountType : UserStore.data.accountType
      }});
    } catch (error) {
     // console.log(error)
    }

    setTimeout(() => {
      if (!this.playerReady) {
        updateTabReady();
      }
    }, 10000);
  };

  async componentDidUpdate(prevProps, prevState) {
    const { NotificationsStore, GameScreenStore } = this.props;

    if (GameScreenStore.currentVideoId !== prevProps.GameScreenStore.currentVideoId) {
      history.pushState(// eslint-disable-line
        {},
        "",
        `${GAME_ROUTE_NAME}/${GameScreenStore.currentVideoId}/highlights`
      );
    }

    if (NotificationsStore.selectedClipId !== prevState.currentClipId) {
      this.startPlayingClip();
    }
  }

  componentWillUnmount() {
    if (this.listener) {
      window.removeEventListener("message", this.listener, false);
    }
    clearInterval(this.checkReadyInterval);
  }

  startPlayingClip = async () => {
    const { currentClipId } = this.state;
    const { GameScreenStore: { gameClips } } = this.props;

    if (currentClipId) {
      const clipToView = gameClips.find(clip => clip.uniqueId === currentClipId);
      if (clipToView) {
        await this.onVideoClick(clipToView);
      }
    }
  };

  async addEventListners() {// eslint-disable-line
    const { notify, updateTabReady, GameScreenStore: { getHighlights } } = this.props;
    const eventMethod = window.addEventListener
      ? "addEventListener"
      : "attachEvent";
    const eventer = window[eventMethod];
    const messageEvent =
      eventMethod === "attachEvent" ? "onmessage" : "message";
    if (!this.listener) {
      this.listener = async e => {
        const iframeEl = document.getElementById("iframe-container")
          .children[0];
        if (iframeEl) {
          if (e.data === "highlight-saved") {
            iframeEl.contentWindow.postMessage(e.data, "*");
            sendAnalyticsEvent(ANALYTICS_SAVED_HIGHLIGHT, {
              ...this.defaultAnalyticsData
            });
            await getHighlights();
          } else if (e.data === "record-failed") {
            notify(
              "error",
              "Failed to create your highlight, please try again"
            );
          } else if (e.data === "playerReady") {
            this.playerReady = true;
            updateTabReady();
            this.startPlayingClip();
          } else if (e.data === "highlight-discard") {
            iframeEl.contentWindow.postMessage(e.data, "*");
            sendAnalyticsEvent(ANALYTICS_DISCARD_RECORDED_HIGHLIGHT, {
              ...this.defaultAnalyticsData
            });
          } else if (e.data === "highlight-add-comment") {
            const title = e.data.split(",")[1];
            sendAnalyticsEvent(ANALYTICS_NEW_HIGHLIGHT_ADD_COMMENT, {
              ...this.defaultAnalyticsData,
              commentText: title
            });
          }
        }
      };
      eventer(messageEvent, this.listener, false);
    }
  }

  onDragEnd = async result => {
    const { GameScreenStore: { selectedVideos, updateSelectedVideos, updateClipOnWork } } = this.props;
    if (!result.destination) {
      return;
    }

    const newSelectedVideos = reorder(
      selectedVideos,
      result.source.index,
      result.destination.index
    );
    updateSelectedVideos(newSelectedVideos);
    await updateClipOnWork(newSelectedVideos);
  };

  onVideoClick = async highlight => {
    const { GameScreenStore: { highlights, gameClips, currentVideoId } } = this.props;
    const currentDisplayVideos = highlight.clipTags ? gameClips : highlights;
    const currentHighlightIndex = currentDisplayVideos.findIndex(
      h => h.id === highlight.id
    );

    this.setState({
      highlightToEdit: highlight,
      shouldDisplayHighlightModal: true,
      currentDisplayVideos,
      currentHighlightIndex
    });

    sendAnalyticsEvent(
      highlight.isHighlight
        ? ANALYTICS_HIGHLIGHTS_HIGHLIGHT_VIEW
        : ANALYTICS_HIGHLIGHTS_CLIP_VIEW,
      {
        ...this.defaultAnalyticsData,
        videoId: highlight._id,
        gameId: currentVideoId
      }
    );
  };

  addRemoveVideoToClip = async video => {
    const { GameScreenStore: { selectedVideos, updateSelectedVideos } } = this.props;
    const newVideos = [...selectedVideos];
    const videoIndex = selectedVideos.findIndex(
      videoItem => videoItem.id === video.id
    );

    if (videoIndex !== -1) {
      newVideos.splice(videoIndex, 1);
    } else {
      newVideos.push(video);
    }
    updateSelectedVideos(newVideos);
    await this.createUpdateClip(newVideos);
  };

  createUpdateClip = async highlights => {
    const {
      GameScreenStore: { clipOnWork, startClipOnWork, updateClipOnWork },
    } = this.props;
    if (Object.keys(clipOnWork).length > 0) {
      await updateClipOnWork(highlights)
    } else {
      await startClipOnWork(highlights)
    }
  };

  onDismissEditModal = () => {
    const {
      NotificationsStore
    } = this.props;
    NotificationsStore.setCurrentClipId("");
    this.setState({
      highlightToEdit: null,
      shouldDisplayHighlightModal: false,
      currentClipId: ""
    });
    sendAnalyticsEvent(ANALYTICS_DISCARD_HIGHLIGHT, {
      ...this.defaultAnalyticsData
    });
  };

  onSaveEditModal = async (highlight,keepOpen) => {
    const { NotificationsStore } = this.props;
    const { highlightToEdit: { clipTags } } = this.state;
    if (clipTags) {
      this.saveClip(highlight)
    } else {
      this.saveHighlight(highlight);
    };
    if(keepOpen !== true) {
      this.setState({
        shouldDisplayHighlightModal: false,
        currentClipId: ""
      });
      NotificationsStore.setCurrentClipId("");
    }

  };

  onSavePlayerHighlightModal = async highlight => {
    this.saveHighlight(highlight);
  };

  saveHighlight = async highlight => {
    const {
      GameScreenStore: { highlights, updateHighlight },
      UserStore: {
        data: { accountType }
      }
    } = this.props;
    const filterVideos = highlights.filter(video => video.id === highlight.id);
    const highlightToUpdate = { ...filterVideos[0] };
    highlightToUpdate.tags = highlight.tags;
    highlightToUpdate.tagedUsers = highlight.tagedUsers;
    highlightToUpdate.title = highlight.title;
    await updateHighlight(highlightToUpdate);
    sendAnalyticsEvent(
      accountType === "player"
        ? ANALYTICS_PLAYER_HIGHLIGHT_RECORD
        : ANALYTICS_COACH_HIGHLIGHT_RECORD,
      {
        ...this.defaultAnalyticsData,
        tagedPlayers:
          highlight.tagedUsers.length > 0 ? highlight.tagedUsers.length : "",
        title: highlight.title || "",
        tag: highlight.tags.length > 0 ? highlight.tags.length : "",
        duration: highlight.duration || ""
      }
    );
  };

  saveClip = async clip => {
    const { GameScreenStore: { updateClipInfo, gameClips } } = this.props;
    const { tags, tagedUsers, title } = clip;
    const filterVideos = gameClips.filter(video => video.id === clip.id);
    const clipToUpdate = { ...filterVideos[0] };
    clipToUpdate.clipTags = tags;
    clipToUpdate.clipTaggedPlayers = tagedUsers;
    clipToUpdate.tagedUsers = tagedUsers;
    clipToUpdate.title = title;
    await updateClipInfo(clipToUpdate);
  };

  onDeleteEditModal = () => {
    const { highlightToEdit: { clipTags } } = this.state;
    if (clipTags) {
      this.deleteClip()
    } else {
      this.deleteHighlight();
    };
    this.setState({
      shouldDisplayHighlightModal: false
    });

    sendAnalyticsEvent(
      this.state.highlightToEdit.clipTags
        ? ANALYTICS_EDIT_MODAL_DELETE_CLIP
        : ANALYTICS_EDIT_MODAL_DELETE_HIGHLIGHT,
      { ...this.defaultAnalyticsData }
    );
  };

  onDeletePlayerHighlightModal = highlightId => {
    const { GameScreenStore: { deleteHighlight } } = this.props;
    this.setState({ highlightToEdit: null });
    deleteHighlight(highlightId);
  };

  onSelectAll = async () => {
    const { GameScreenStore: {highlights, selectedVideos, updateSelectedVideos } } = this.props;
    if(highlights.length !== selectedVideos.length) {
      updateSelectedVideos([...highlights]);
      await this.createUpdateClip([...highlights]);
    }
    else {
      updateSelectedVideos([]);
    }

  };

  deleteHighlight = async () => {
    const { GameScreenStore: { deleteHighlight } } = this.props;
    const { highlightToEdit } = this.state;
    deleteHighlight(highlightToEdit._id);
  };

  deleteClip = async () => {
    const { GameScreenStore: { deleteClip } } = this.props;
    const { highlightToEdit } = this.state;
    this.setState({ highlightToEdit: null });
    deleteClip(highlightToEdit.id)
  };

  makeVideoClip = async () => {
    const {
      checkForClipsInProcess,
      NotificationsStore,
      GameScreenStore: { createClip, selectedVideos, updateSelectedVideos }
    } = this.props;

    try {
      await createClip();
      checkForClipsInProcess();
      NotificationsStore.clearCheckActiveTasksInterval();
      NotificationsStore.getActiveTasksRecursive();
      this.setState({ isMakeClipDone: true });
      if (this.scrollBottomRef.current) {
        this.scrollBottomRef.current.scrollIntoView();
      }

      sendAnalyticsEvent(ANALYTICS_CLIP_CREATED, {
        ...this.defaultAnalyticsData,
        createdFrom: "Highlights",
        numberOfVideosInClip: selectedVideos.length
      });
      updateSelectedVideos([]);
      this.setState({ showProgressBar: false });
    } catch (e) {
      this.props.notify("error", "Failed to create clip, please try again");
      this.setState({ showProgressBar: false });
    }
  };

  setConnector = connector => {
    this.connector = connector;
  };

  changeHighlight = props => {
    const { GameScreenStore: { highlights, gameClips } } = this.props;
    const { highlightToEdit } = this.state;
    const currentDisplayVideos = highlightToEdit.clipTags ? gameClips : highlights;
    const index = currentDisplayVideos.findIndex(
      highlight => highlight.id === highlightToEdit.id
    );

    if (currentDisplayVideos.length > 0) {
      if (props.prev) {
        if (index > 0) {
          this.setState({
            highlightToEdit: currentDisplayVideos[index - 1],
            currentHighlightIndex: index - 1
          });
        }
      } else if (index < currentDisplayVideos.length - 1) {
        this.setState({
          highlightToEdit: currentDisplayVideos[index + 1],
          currentHighlightIndex: index + 1
        });
      }
    }
  };

  discardAll = async () => {
    const { GameScreenStore: { deleteClip, clipOnWork, updateSelectedVideos } } = this.props;
    await deleteClip(clipOnWork._id);
    updateSelectedVideos([])
  };

  isVideoSelected = video => {
    const { GameScreenStore: { selectedVideos } } = this.props;
    if (selectedVideos) {
      const filterArray = selectedVideos.filter(
        selectedVideo => selectedVideo.id === video.id
      );
      return filterArray.length > 0;
    }
    return false;
  };
  /* eslint-disable react/jsx-handler-names */

  sideDrawerBottomPanel = () => {
    const { GameScreenStore: { selectedVideos } } = this.props;
    return <ClipPanel
      onDragEnd={this.onDragEnd}
      onDiscardAll={this.discardAll}
      onDelete={this.addRemoveVideoToClip}
      onMakeClip={this.makeVideoClip}
      videos={selectedVideos}
      isProgressBarDone={this.state.isMakeClipDone}
      showProgressBar={this.state.showProgressBar}
      showThumbnails={this.state.withClipPanelThumbnails}
    />;
  }


  toggleClipPanelThumbnails = () => {
    const { withClipPanelThumbnails } = this.state;

    if (this.connector) {
      this.connector.call(withClipPanelThumbnails ? "play" : "pause");
    }

    this.setState(prevState => ({
      withClipPanelThumbnails: !prevState.withClipPanelThumbnails
    }));
  };

  isEditMode = highlight => {
    const {
      UserStore: { data }
    } = this.props;
    if (data.accountType === USER_PLAYER && data._id === highlight.sender._id) {
      return true;
    }

    if (data.accountType !== USER_PLAYER) {
      return true;
    }

    return false;
  };

  render() {
    const { TeamsStore, NotificationsStore, GameScreenStore, UserStore: { data: { accountType } } } = this.props;
    const { highlights, gameClips, currentVideoContainer, selectedVideos } = GameScreenStore;
    const {
      shouldDisplayHighlightModal,
      currentDisplayVideos,
      currentHighlightIndex,
      highlightToEdit,
      withClipPanelThumbnails,
    } = this.state;
    const { players } = TeamsStore.currentTeamMembers || {};

    const isAllhighlightsSelected = highlights && highlights.length === selectedVideos.length;
    /* hack to solve mobx-state-tree not updating the component after store change */
    const test = NotificationsStore.selectedClipId; // eslint-disable-line
    return (
      <div className="highlights" data-testid="highlights">
        <div className="highlights__video-player-wrapper">
          {currentVideoContainer && currentVideoContainer._id && (
            <VideoPlayerWithConnector
              videoContainerId={currentVideoContainer._id}
              accountType="coach"
              iframeId="iframe-container"
              iframeUrl={IFRAME_VIDEO_URL}
              onConnector={this.setConnector}
              teamId={TeamsStore.currentTeam._id}
              render={() => (
                <div
                  id="iframe-container"
                  className="baseVideoPlayer__container"
                  style={{ position: "relative" }}
                />
              )}
            />
          )}
        </div>
        <SideDrawer
          elements={[
            { [I18N.t("HIGHLIGHTS_CAPITAL")]: highlights.length },
            { [I18N.t("VIDEO_CLIPS_CAPITAL")]: gameClips.length }
          ]}
          buttonText={
            isAllhighlightsSelected
              ? I18N.t("DESELECT_ALL")
              : I18N.t("SELECT_ALL")
          }
          shouldDisplayButton={
            accountType !== USER_PLAYER && highlights.length > 0
          }
          selectAllHighlights={this.onSelectAll}
          bottomPanel={this.sideDrawerBottomPanel}
          withBottomPanel={
            !!selectedVideos.length && accountType !== USER_PLAYER
          }
          onOpen={this.toggleClipPanelThumbnails}
          onClose={this.toggleClipPanelThumbnails}
          emptyDrawer={<EmptyHighlights />}
          isEmpty={!(highlights.length || gameClips.length)}
          shouldDisplaySecondTitle={gameClips.length > 0}
        >
          {highlights.map(highlight => (
            <VideoInfoBox
              key={highlight.id}
              isSelected={this.isVideoSelected(highlight)}
              onAdd={this.addRemoveVideoToClip}
              onClick={this.onVideoClick}
              style={{
                width: "325px",
                backgroundColor: GREY_LIGHT,
                border: "none"
              }}
              video={highlight}
              hasAddButton={accountType !== USER_PLAYER}
              hasClipBedge={accountType !== USER_PLAYER}
            />
          ))}
          {gameClips.map(clip => {
            return (
              <VideoInfoBox
                key={clip.id}
                isSelected={false}
                onAdd={this.addRemoveVideoToClip}
                onClick={this.onVideoClick}
                style={{
                  height: withClipPanelThumbnails && "356px",
                  width: withClipPanelThumbnails ? "405px" : "325px",
                  backgroundColor: GREY_LIGHT,
                  border: "none"
                }}
                video={clip}
                hasAddButton={false}
                hasClipBedge={!withClipPanelThumbnails}
              />
            );
          })}
          <div />
        </SideDrawer>
        {shouldDisplayHighlightModal && (
          <EditModal
            onDismiss={this.onDismissEditModal}
            highlight={highlightToEdit}
            changeHighlight={this.changeHighlight}
            onSave={this.onSaveEditModal}
            onDelete={this.onDeleteEditModal}
            teamPlayers={players}
            numberOfHighlights={currentDisplayVideos.length}
            currentHighlightIndex={currentHighlightIndex}
            isPanoramic={this.isPanoramic}
            isEditMode={this.isEditMode(highlightToEdit)}
          />
        )}
      </div>
    );
  }
}

export default inject("TeamsStore", "UserStore", "NotificationsStore", "GameScreenStore")(
  observer(Highlights)
);

import { types, flow, getParent, destroy } from "mobx-state-tree";
import { user, config } from "@myplay/all";

import { UserData } from "../user/user";
import optionalAndMaybeNull from "../../utils/mobx-optional-and-maybe-null";
import { setItem } from "../../utils/storage";
import {
  TEAM_CHANNEL_GAMES_BULK_SIZE,
  LS_CURRENT_TEAM,
  MYPLAY_LOGIN_URL,
  MYPLAY_APP_URL
} from "../../utils/constants";
import I18N from "../../I18N";
// import history from "../../utils/history";

const TeamCategory = types.model(`TeamCategory`, {
  playerPositions: types.array(types.string),
  name: optionalAndMaybeNull(types.string),
  scoreBoardLocation: optionalAndMaybeNull(types.string)
});

const Team = types
  .model(`Team`, {
    _id: types.identifier,
    name: types.string,
    code: types.string,
    teamImage: optionalAndMaybeNull(types.string),
    category: optionalAndMaybeNull(TeamCategory),
    members: types.array(UserData),
    connectedSiteId: optionalAndMaybeNull(types.string),
    teamCoverImage: optionalAndMaybeNull(types.string),
    scoreBoardLocation: optionalAndMaybeNull(types.string),
    club: optionalAndMaybeNull(types.frozen()),
  })
  .actions(self => ({
    setTeamCoverImage(image) {
      self.teamCoverImage = image;
    }
  }));

const TeamMembers = types.model(`TeamMembers`, {
  players: types.array(UserData),
  operators: types.array(UserData),
  coaches: types.array(UserData)
});

const TeamsStore = types
  .model(`Teams`, {
    teams: optionalAndMaybeNull(types.array(Team)),
    currentTeam: optionalAndMaybeNull(types.reference(Team)),
    currentTeamMembers: optionalAndMaybeNull(TeamMembers)
  })
  .actions(self => ({
    setUserTeams(teams, currentTeam = null) {
      if (!teams) {
        return;
      }

      self.teams = teams;
      if (teams && teams[0] && teams[0]._id) {
        self.setCurrentTeam(currentTeam || teams[0]._id);
        getParent(self).BookingsStore.setCurrentBookingProp('homeTeamTitle',self.currentTeam.name)
      }
    },
    setCurrentTeam(teamId) {
      const { GamesStore, SiteStore, UserStore, BookingsStore } = getParent(self);
      const { data } = UserStore;
      SiteStore.clear();
      const isTeamExists = self.teams && self.teams.find(team => team._id === teamId) !== undefined;
      if(isTeamExists) {
        self.currentTeam = teamId;
        config.addHeader("currentTeam", teamId);
        self.refreshTeamData();
        if (data && data.accountType !== "player") {
          SiteStore.resetSite();
          SiteStore.checkIsRemoteProducer();
          SiteStore.setSite();
          BookingsStore.clearRecentBooking();
          BookingsStore.getBookings();
          BookingsStore.getCredits();
        }

        if (data && data.accountType === "player") {
          SiteStore.setIsRemoteCheckDone(true);
        }

        window.scrollTo(0, 0);

        self.setCurrentTeamMembers(teamId);
        UserStore.setPlayerNumberByTeam(teamId);

        setItem(LS_CURRENT_TEAM, teamId);
        setTimeout(() => {
          GamesStore.clear();
          GamesStore.fetchCurrentTeamGames(TEAM_CHANNEL_GAMES_BULK_SIZE, 0);
          GamesStore.fetchCurrentTeamGamesCount();
        });
      }
      else {
        UserStore.clearLocalStorage();
        window.location.href = `${MYPLAY_LOGIN_URL}/?redirect=${MYPLAY_APP_URL}`;
        // history.push('/clear');
      }
    },
    setCurrentTeamMembers: flow(function* setCurrentTeamMembers(teamId) {
      try {
        const members = yield user.teams.getTeamMembers(teamId);
        self.currentTeamMembers = members;
      } catch (error) {
        console.log(error);
      }
    }),
    updateTeamImage: flow(function* updateTeamImage(newImg) {
      const { UserStore } = getParent(self);
      try {
        yield user.teams.updateTeamImage(self.currentTeam._id, newImg);
        UserStore.refreshUserData();
        yield self.refreshTeamData();
      } catch (error) {
        console.log(error);
      }
    }),
    updateTeamName: flow(function* updateTeamImage(name) {
      const { UserStore } = getParent(self);
      try {
        yield user.teams.updateTeamName(self.currentTeam._id, name);
        UserStore.refreshUserData();
        yield self.refreshTeamData();
      } catch (error) {
        console.log(error);
      }
    }),
    refreshTeamData: flow(function* refreshTeamData() {
      try {
        const updatedTeam = yield user.teams.getTeam(self.currentTeam._id);
        const index = self.teams.findIndex(
          ({ _id }) => _id === updatedTeam._id
        );

        self.teams[index] = updatedTeam;
      } catch (error) {
        console.log(error);
      }
    }),
    refreshTeamCode: flow(function* refreshTeamCode() {
      try {
        // eslint-disable-next-line no-shadow
        const { UserStore } = getParent(self);

        const { code } = yield user.teams.refreshTeamCode(self.currentTeam._id);

        self.currentTeam.code = code;
        UserStore.refreshUserData();
      } catch (error) {
        console.log(error);
      }
    }),
    uploadTeamCoverImage: flow(function* uploadTeamCoverImage(image) {
      try {
        const { UserStore } = getParent(self);
        yield user.teams.updateTeamCoverImage(self.currentTeam._id, image);
        self.refreshTeamData();
        UserStore.refreshUserData();
      } catch (error) {
        console.log(error);
      }
    }),
    addNewTeam(team) {
      const { UserStore } = getParent(self);

      const teams = [team, ...self.teams];
      self.setUserTeams(teams);
      UserStore.refreshUserData();
    },
    removeTeamWithConfirm(teamId) {
      const { ModalStore } = getParent(self);

      ModalStore.openModal({
        modalType: "CONFIRM_MODAL",
        modalPosition: "center",
        modalProps: {
          text: I18N.t("PRE_DELETE_TEAM_MESSAGE"),
          handleSuccess: () => {
            self.removeTeam(teamId);
          },
          onCancel: () => {}
        }
      });
    },
    removeTeam: flow(function* removeTeam(teamId) {
      const { UserStore } = getParent(self);
      console.log(UserStore.data._id);
      const teams = self.teams.filter(team => team._id !== teamId);
      self.setUserTeams(teams);
      yield user.teams.removeUserFromTeam(UserStore.data._id, teamId);
      UserStore.refreshUserData();
    }),

    /* eslint-disable */
    removeUserFromTeamWithConfirm(userId, accountType = "player") {
      const { ModalStore } = getParent(self);

      ModalStore.openModal({
        modalType: "CONFIRM_MODAL",
        modalPosition: "center",
        modalProps: {
          text: `${I18N.t("PRE_DELETE_MESSAGE")} ${accountType}`,
          handleSuccess: () => {
            self.removeUserFromTeam(userId);
          },
          onCancel: () => {}
        }
      });
    },

    removeUserFromTeam: flow(function* removeUserFromTeam(userId) {
      yield user.teams.removeUserFromTeam(userId, self.currentTeam._id);
      yield self.setCurrentTeamMembers(self.currentTeam._id);
    }),
    clear() {
      self.teams.map(team => destroy(team));
      self.currentTeam = null;
      self.currentTeamMembers = null;
    }
  }));

export default TeamsStore;

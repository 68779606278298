import React, { Component } from "react";
import PropTypes from "prop-types";
import { PlayerBadge } from "@myplay/ui";
import "./TeamMembers.scss";

class SingleTeamMember extends Component {
  static propTypes = {
    avatar: PropTypes.string,
    avatarBgColor: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string
  };

  static defaultProps = {
    avatar: '',
    avatarBgColor: '',
    firstName: '',
    lastName: ''
  }

  state = {
    isError: false
  };

  handleImgError = () => {
    this.setState({
      isError: true
    });
  };

  render() {
    const { firstName, lastName, avatarBgColor, avatar } = this.props;
    const { isError } = this.state;

    return avatar && !isError ? (
      <img src={avatar} alt="" onError={this.handleImgError} />
    ) : (
      <PlayerBadge
        firstName={firstName}
        lastName={lastName}
        color={avatarBgColor}
      />
    );
  }
}

export default SingleTeamMember;


import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { inject } from "mobx-react";
import { utils, liveProduction } from "@myplay/all";
import { SuccessButton } from "@myplay/ui";

import I18N from "../../I18N";
import headsetIcon from "../../assets/icons/headset.png";

import "./CommentaryInvite.scss";

const INTERVAL = 1000 * 60 // check commentator every minute

class CommentaryInvite extends Component {
  checkInterval = null;

  static propTypes = {
    liveId: PropTypes.string
  };

  static defaultProps = {
    liveId: null
  };

  state = {
    name: null,
    email: null,
    isInvitationSent: false,
    isSentError: false
  };

  componentDidMount() {
    const { liveId } = this.props;
    if(liveId) {
      this.checkCommentatorRecursive();
    }
  }

  componentDidUpdate(prevProps) {
    const { liveId } = this.props;

    if (liveId && liveId !== prevProps.liveId) {
      this.checkCommentatorRecursive();
    }

    if (!liveId && liveId !== prevProps.liveId) {
      clearInterval(this.checkInterval);
    }
  };

  componentWillUnmount() {
    clearInterval(this.checkInterval);
  }

  handleEnterName = e => {
    this.setState({ name: e.target.value });
  };

  handleEnterEmail = e => {
    this.setState({ email: e.target.value });
  };

  handleInvite = async () => {
    const { name, email } = this.state;
    const { liveId, TeamsStore  } = this.props;

    try {
      await liveProduction.inviteCommentator(name, email, liveId, TeamsStore.currentTeam._id);
      this.setState({
        isInvitationSent: true
      });
      this.checkCommentatorRecursive();
    } catch (e) {
      this.setState({ isSentError: true });
      setTimeout(()=> {
        this.setState({ isSentError: false });
      }, 3000)
    }
  };

  handleResend = ()=> {
    clearInterval(this.checkInterval);
    this.setState({
      name: null,
      email: null,
      isInvitationSent: false,
      isSentError: false
    });
  }

  checkCommentatorRecursive = async ()=> {
    clearInterval(this.checkInterval);
    await this.checkCommentator();
    this.checkInterval = setInterval( async () => {
      await this.checkCommentator();
    }, INTERVAL)
  }

  checkCommentator = async ()=> {
    const { SiteStore } = this.props;
    try {
      const commentaryStatus = await SiteStore.getCommentatorStatus();

      if(commentaryStatus.status === "NO_COMMENTATOR") {
        this.handleResend();
        return;
      }

      if (commentaryStatus) {
        this.setState({
          status: commentaryStatus.status,
          email: commentaryStatus.commentator.email,
          name: commentaryStatus.commentator.name,
          isInvitationSent: commentaryStatus.status === "INIT" || commentaryStatus.status === "BROADCASTING"
        });
      }
    } catch(error) {
      console.log(error);
    }
  }

  render() {
    const { name, email, isSentError, isInvitationSent, status } = this.state;

    return (
      <div className="commentary-invite">
        {!isInvitationSent ?
          <Fragment>
            <div className="commentary-invite__main-wrapper">
              <div className="commentary-invite__main-wrapper__inputs-container">
                <input
                  className="commentary-invite__main-wrapper__inputs-container__input"
                  placeholder={I18N.t("COMMENTATOR_NAME")}
                  onChange={this.handleEnterName}
                />
                <input
                  className="commentary-invite__main-wrapper__inputs-container__input"
                  placeholder={I18N.t("COMMENTATOR_EMAIL")}
                  onChange={this.handleEnterEmail}
                />
              </div>
              <div className="commentary-invite__main-wrapper__button-container">
                <img
                  src={headsetIcon}
                  className="commentary-invite__main-wrapper__button-container__icon"
                />
                <SuccessButton
                  className="commentary-invite__main-wrapper__button-container__button"
                  disabled={!(name && email && utils.isValidEmail(email))}
                  onClick={this.handleInvite}
                >
                  {I18N.t("INVITE")}
                </SuccessButton>
              </div>
            </div>
            <span
              className={`commentary-invite__error commentary-invite__error--${isSentError ? "visible" : "hidden"}`}
            >
              {I18N.t("INVITE_FAILED")}
            </span>
          </Fragment> :
          <div className="commentary-invite__success">
            <div className="commentary-invite__success__circle" style={{ backgroundColor: status === "INIT" ? "#E29517" : "#7ED321" }}/>
            <div className="commentary-invite__success__text" style={{ flexDirection: status === "INIT" ? "row-reverse" : "row" }}>
              <span
                className="commentary-invite__success__text__name"
              >
                {name}
              </span>
              <span>
                {status === "INIT" ? I18N.t("WAITING_FOR") : I18N.t("IS_NOW_ACTIVE")}
              </span>
            </div>
            <button
              className="commentary-invite__success__button"
              onClick={this.handleResend}
            >
              {I18N.t("REPLACE")}
            </button>
          </div>
        }
      </div>

    )
  }
}

export default inject("SiteStore","TeamsStore")(CommentaryInvite);

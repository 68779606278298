import React from "react";
import PropTypes from "prop-types";
import "./BookingCard.scss";
import youtubeIcon from "../../../assets/images/youtube-icon.png";
import facebookIcon from "../../../assets/images/facebook-icon.png";
import myplayLogo from "../../../assets/images/logo_round.png"
import deleteIcon from "../../../assets/icons/Icon-Delete.svg";
import editIcon from "../../../assets/icons/Icon-Edit.svg";
import BookingsStore from "../../../stores/bookings/bookings";
import {inject, observer} from "mobx-react";


const logos = {
    "YOUTUBE":  youtubeIcon,
    "FACEBOOK": facebookIcon,
    "OTHER": myplayLogo
}

const BookingCard = ({bookingData, BookingsStore, handleEditBooking, isEditable = true}) => {
  const {thirdPartyProxyUri,day,month, year, kickoff, thirdPartyType, gameTime,id, bookingDate} = bookingData;
  return (
    <div className="booking-card">
      <div className="booking-card__head">
        <div className="booking-card__head__date">
          <p className="booking-card__head__date__day">{day} {month}, {year}</p>
          <p className="booking-card__head__date"></p>
        </div>
        <div className="booking-card__head__game-time">
          <p>{kickoff}</p>
        </div>
        <div className="booking-card__head__actions">
          <button
            type="button"
            className="booking-card__delete"
            onClick={()=>{BookingsStore.cancelRequest(id)}}
          >
            <img
              src={deleteIcon}
              className="booking-card__delete__image"
            />

          </button>
          <button
            type="button"
            className="booking-card__edit"
            onClick={()=>{handleEditBooking(bookingData)}}
          >
            <img
              src={editIcon}
              className="booking-card__edit__image"
            />
          </button>
        </div>


      </div>
    </div>
  );
};

BookingCard.propTypes = {};
BookingCard.defaultProps = {};

export default inject("BookingsStore")(observer(BookingCard));

/* eslint-disable */
import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Progress } from "react-sweet-progress";
import "react-sweet-progress/lib/style.css";

import { Loader } from "@myplay/ui";
import { GREEN_LIGHT, WHITE } from "../../styles/shared/colors";
import deleteIcon from "../../assets/icons/Icon-Close.svg";

const Notification = ({
  _id,
  title,
  body,
  icon,
  sinceDate,
  type,
  onClick,
  action,
  disableClick,
  process,
  handleCancelTask,
  subType
}) => {
  return (
    <button
      type="button"
      className="notifications__notification"
      style={{
        cursor: disableClick && "default",
        backgroundColor: disableClick && "transparent"
        // transition: disableClick && 'none'
      }}
      onClick={onClick}
    >
      <div className="notifications__notification__content">
        <p className="notifications__notification__content__title">
          {title}{" "}
          <b className="notifications__notification__content__title--green">
            {action && action.title}
          </b>{" "}
        </p>
        <p className="notifications__notification__content__body">{body}</p>
        {type === "clientUpload" && (
          <Progress
            theme={{
              success: {
                symbol: process + "%",
                trailColor: WHITE,
                color: GREEN_LIGHT
              }
            }}
            percent={process}
            status="success"
            symbolClassName="user-video-upload__progress-bar"
          />
        )}
        <p className="notifications__notification__content__date">
          {sinceDate}
        </p>
      </div>
      {type === "task" ? (
        <Fragment>
          <Loader type="spin" color="red" height="30px" width="30px" />
          <button
            onClick={() => {
              handleCancelTask(_id,subType);
            }}
            className="notifications__notification__cancel-icon"
          >
            <img src={deleteIcon}/>
          </button>
        </Fragment>
      ) : (
        type !== "clientUpload" && (
          <img className="notifications__notification__icon" src={icon} />
        )
      )}
    </button>
  );
};

Notification.propTypes = {
  action: PropTypes.object,
  body: PropTypes.string,
  disableClick: PropTypes.bool, // eslint-disable-line
  icon: PropTypes.string,
  onClick: PropTypes.func,
  sinceDate: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
  process: PropTypes.number
};

Notification.defaultProps = {
  action: null,
  body: null,
  disableClick: false,
  icon: "",
  onClick: () => {},
  sinceDate: "",
  title: "",
  type: null,
  process: 0
};

export default Notification;

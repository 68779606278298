import React from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { Loader } from '@myplay/ui';

import "./MobileLandingPage.scss";
import I18N from "../../I18N";
import history from "../../utils/history";
import getMobileOperatingSystem from "../../utils/detectOS";
import { LOGIN_ROUTE_NAME } from "../../utils/constants";

const MobileLandingPage = ({ UserStore }) => {
  const OS = getMobileOperatingSystem();
  const shouldRenderButton = OS === "IOS" || "Android";

  const handleLogoutAndGoToLogin = () => {
    UserStore.logout();
    history.push(LOGIN_ROUTE_NAME);
  };

  /* eslint-disable jsx-a11y/anchor-has-content */
  /* eslint-disable react/jsx-handler-names */
  return (
    !window.isRNWebView ?
      <div className="mobile-landing-page">
        <span className="mobile-landing-page__title">
          {I18N.t("FOR_BETTER_EXPERIENCE")}
        </span>
        <span className="mobile-landing-page__title mobile-landing-page__title--green">
          {I18N.t("DOWNLOAD_THE_APP")}
        </span>
        {shouldRenderButton && OS === "IOS" ? (
          <a
            href="https://itunes.apple.com/il/app/myplay/id1417829699?mt=8"
            className="mobile-landing-page__button mobile-landing-page__button--ios"
          />
        ) : (
          <a
            href="https://play.google.com/store/apps/details?id=com.myplayapp"
            className="mobile-landing-page__button mobile-landing-page__button--android"
          />
        )}
        <span>{I18N.t("OR")}</span>
        <br />
        {UserStore.isLoggedIn ? (
          <button
            style={{ color: "white" }}
            type="button"
            onClick={handleLogoutAndGoToLogin}
          >
            {I18N.t("BACK_TO_LOGIN")}
          </button>
        ) : (
          <a href="/login">{I18N.t("BACK_TO_LOGIN")}</a>
        )}
      </div> :
      <div className='loader-wrapper'>
        <Loader height='100px' width='100px' />
      </div>
  );
};

MobileLandingPage.propTypes = {
  UserStore: PropTypes.shape().isRequired
};

export default inject("UserStore")(observer(MobileLandingPage));

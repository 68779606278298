import React from "react";
import { PrimaryButton } from "@myplay/ui";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";

import "./ConfirmBox.scss";
import { WHITE, RED } from "../../styles/shared/colors";

function ConfirmBox({
  className,
  children,
  successText,
  cancelText,
  onSuccess,
  onCancel,
  hasButtons
}) {
  return (
    <div className={className}>
      {children}
      {hasButtons && (
        <div className="confirm-box__buttons">
          <PrimaryButton background={RED} color={WHITE} onClick={onCancel}>
            {cancelText}
          </PrimaryButton>
          <PrimaryButton background="#6a943c" color={WHITE} onClick={onSuccess}>
            {successText}
          </PrimaryButton>
        </div>
      )}
    </div>
  );
}

ConfirmBox.propTypes = {
  cancelText: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  hasButtons: PropTypes.bool,
  onCancel: PropTypes.func,
  onSuccess: PropTypes.func,
  successText: PropTypes.string
};

ConfirmBox.defaultProps = {
  cancelText: "NO",
  children: null,
  className: "",
  hasButtons: true,
  onCancel: () => {},
  onSuccess: () => {},
  successText: "YES"
};

export default inject(["ModalStore"])(observer(ConfirmBox));

import React, { Component } from "react";
import PropTypes from "prop-types";
import { EventsList, NoEventsList } from "@myplay/ui";
import { sortEvents } from "../../utils/helper";

import "./EventsTable.scss";

class EventsTable extends Component {
  isDesc = true;

  eventListRef = React.createRef();

  static propTypes = {
    checkedEventsIds: PropTypes.array,
    connector: PropTypes.object,
    gameEvents: PropTypes.array.isRequired,
    isCheckBoxActive: PropTypes.bool,
    isFiltered: PropTypes.bool,
    isLoading: PropTypes.bool,
    sendAnalyticsEvent: PropTypes.func,
    updateMarkedBreakdownIds: PropTypes.func
  };

  static defaultProps = {
    checkedEventsIds: [],
    connector: {},
    isCheckBoxActive: false,
    isFiltered: false,
    isLoading: false,
    sendAnalyticsEvent: () => {},
    updateMarkedBreakdownIds: () => {}
  };

  static getDerivedStateFromProps(props, state) {
    if (
      props.gameEvents &&
      state.sortedEvents &&
      props.gameEvents.length !== state.sortedEvents.length
    ) {
      const sortedEvents = sortEvents("time", props.gameEvents);
      return { sortedEvents, sortBy: "time" };
    }
    return null;
  }

  state = {
    playOnProgress: false,
    isPaused: false,
    currentFilter: null,
    filteredEvents: null,
    sortedEvents: [],
    sortBy: ""
  };

  componentDidMount() {
    this.timeOut = {}; // timeOut id holder to make sure timeout is clear before starting new one
    this.isDesc = false;
    window.addEventListener("keydown", e => this.handleSpacePress(e));
  }

  componentDidUpdate() {
    if (this.props.connector) {
      this.connector = this.props.connector;
      this.setVideoPlayerEventsListeners();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", e => this.handleSpacePress(e));
  }

  setVideoPlayerEventsListeners() {
    const { isPaused, playOnProgress } = this.state;
    // this.connector.on("player-play", ()=> {
    //   console.log('played');
    // })
    this.connector.on("player-pause", () => {
      if (!isPaused && playOnProgress) {
        this.pausePlaylistMode();
      }
    });
  }

  handleSpacePress = e => {
    const { playOnProgress } = this.state;
    if (e.code === "Space") {
      e.preventDefault();
      if (playOnProgress) {
        this.pausePlaylistMode();
      }
    }
  };

  setCurrentFilter = filterIndex => {
    this.setState({
      currentFilter: filterIndex
    });
  };

  handleSelectedFilterItemsChange = filterItems => {
    const { filters, currentFilter } = this.state;
    const cloneFilters = [...filters];

    cloneFilters[currentFilter].selectedItems = filterItems;

    this.setState(
      {
        filters: cloneFilters
      },
      async () => {
        this.handleFilter();
      }
    );
  };

  handleSort = category => {
    const { gameEvents } = this.props;
    const sortBy = category.toLowerCase();
    this.isDesc = !this.isDesc;
    const sortedEvents = sortEvents(sortBy, gameEvents, this.isDesc);
    this.setState({ sortedEvents, sortBy });
  };

  getVisibleEvents = () => {
    return this.state.filteredEvents && this.state.filteredEvents.length > 0
      ? this.state.filteredEvents
      : this.state.gameEvents;
  };

  handleEventClick = selectedEvent => {
    const { sendAnalyticsEvent } = this.props;
    const { sortedEvents } = this.state;
    this.playlistMode = true;
    const currentPlayingIndex = sortedEvents.findIndex(
      event => event.id === selectedEvent.id
    );

    if (currentPlayingIndex === this.index) {
      this.pausePlaylistMode();
      return; // eslint-disable-line
    }
    this.index = currentPlayingIndex;
    if (this.timeOut) {
      // check if timeOut is running and clear it if necessary before running new timer from runPlayListMode
      clearTimeout(this.timeOut);
    }
    sendAnalyticsEvent();
    this.runPlaylistMode();
  };

  handlePlayEvent = selectedEvent => {
    if (selectedEvent !== undefined) {
      const { seekTime, video } = selectedEvent;
      if (this.connector) {
        this.connector.call("setCurrentTime", {
          seekTime,
          videoId: video
        });
        this.connector.call("play");
      }
      // if (this.eventListRef.current) {
      //   this.eventListRef.current.scroll({
      //     top: 65 * this.index,
      //     left: 0,
      //     behavior: "smooth"
      //   });
      // }
      this.setState(prevState => ({
        sortedEvents: prevState.sortedEvents.map(event =>
          this.markSelectedEvent(event, selectedEvent)
        )
      }));
    }
  };

  handleChangeTime = async time => {
    if (!time) {
      this.connector.call("pause");
      if (this.timeOut) {
        clearTimeout(this.timeOut);
      }
      this.setState({
        isPaused: true
      });
    } else {
      this.runPlaylistMode(time);
    }
  };

  markSelectedEvent = (event, selectedEvent) => {
    // eslint-disable-next-line no-param-reassign
    event = { ...event };
    event.isSelected = event.id === selectedEvent.id;
    return event;
  };

  runPlaylistMode = async time => {
    const { sortedEvents } = this.state;
    let playOnProgress = false;
    let timeToPlay = 6000;
    const currentEvent = sortedEvents[this.index];
    if (!time) {
      this.handlePlayEvent(currentEvent);
    } else {
      timeToPlay = currentEvent
        ? parseInt(currentEvent.seekTime + 6 - time) * 1000 //eslint-disable-line
        : await this.connector.get("getCurrentTime");
      if (this.connector) {
        await this.connector.call("setCurrentTime", {
          seekTime: time,
          videoId: currentEvent ? currentEvent.video : ""
        });
        await this.connector.call("play");
      }
      playOnProgress = true;
    }
    this.setState({
      isPaused: false
    });

    this.setState({ playOnProgress }, () => {
      this.connector.on("ready-to-play", () => {
        if (!playOnProgress || time) {
          playOnProgress = true;
          this.setState({ playOnProgress }, () => {
            this.timeOut = setTimeout(() => {
              this.index = this.index + 1;
              this.runPlaylistMode();
            }, timeToPlay);
          });
        }
      });
    });
  };

  stopPlaylistMode = () => {
    this.index = -1;
    this.playlistMode = false;
    if (this.timeOut) {
      clearTimeout(this.timeOut);
    }

    this.setState(() => {
      // eslint-disable-line
      const events = this.getVisibleEvents();
      events.map(event => {
        event.isSelected = false;
        return event;
      });
      return { filteredEvents: events };
    });
  };

  pausePlaylistMode = async () => {
    const { isPaused } = this.state;

    if (isPaused) {
      const time = await this.connector.get("getCurrentTime");
      this.runPlaylistMode(time);
    } else {
      this.connector.call("pause");
      if (this.timeOut) {
        clearTimeout(this.timeOut);
      }
      this.setState({
        isPaused: true
      });
    }
  };

  handleCheckboxClick = id => {
    const { updateMarkedBreakdownIds, checkedEventsIds } = this.props;
    const checkedEventsCopy = [...checkedEventsIds];
    const idIndex = checkedEventsCopy.indexOf(id);

    if (idIndex > -1) {
      checkedEventsCopy.splice(idIndex, 1);
    } else {
      checkedEventsCopy.push(id);
    }

    updateMarkedBreakdownIds(checkedEventsCopy);
  };

  handleMarkAllEvents = () => {
    const { checkedEventsIds, updateMarkedBreakdownIds } = this.props;
    const { sortedEvents } = this.state;
    const allEvents = [];
    if (checkedEventsIds.length !== sortedEvents.length) {
      sortedEvents.map(event => allEvents.push(event.id));
    }

    updateMarkedBreakdownIds(allEvents);
  };
  /* eslint-disable react/jsx-handler-names */

  render() {
    const {
      isLoading,
      gameEvents,
      isFiltered,
      isCheckBoxActive,
      checkedEventsIds
    } = this.props;
    const { sortedEvents, sortBy } = this.state;
    return (
      <div className="events-table">
        {gameEvents.length > 0 || isLoading ? (
          <EventsList
            ref={this.eventListRef}
            className="events-table__list"
            events={sortedEvents}
            isDesc={this.isDesc}
            handleSort={this.handleSort}
            handleEventClick={this.handleEventClick}
            playOnProgress={this.state.playOnProgress}
            isPaused={this.state.isPaused}
            isLoading={isLoading}
            handleChangeTime={this.handleChangeTime}
            sortBy={sortBy}
            checkedEventsIds={checkedEventsIds}
            shouldDisplayHeaderCheckbox={isFiltered}
            isCheckBoxActive={isCheckBoxActive}
            onEventCheckboxClick={this.handleCheckboxClick}
            onMarkAllEvents={this.handleMarkAllEvents}
          />
        ) : (
          <NoEventsList />
        )}
      </div>
    );
  }

  /* eslint-enable react/jsx-handler-names */
}

export default EventsTable;

import React, { Component, Fragment } from "react";
import { game as gameModule } from "@myplay/all";
import { Loader, Input, SuccessButton, DismissButton } from "@myplay/ui";
import { inject, observer } from "mobx-react";

import "./VideoUpload.scss";
import I18N from "../../../I18N/";
import { WHITE, BLACK } from "../../../styles/shared/colors";

const videoModule = gameModule.videos;

class VideoUpload extends Component {
  state = {
    videoInfo: null,
    videoUrl: null,
    isLoading: false,
    errorMessage: null,
    uploadFinishMessage: null,
    uploadError: false
  };

  renderUploadStep1 = () => {
    const { videoUrl, isLoading, errorMessage } = this.state;
    const inputStyle = {
      background: WHITE,
      color: BLACK,
      border: "none",
      width: "386px",
      height: "40px"
    };

    /* eslint-disable react/jsx-handler-names */
    return isLoading ? (
      <div className="video-upload video-upload__loader">
        {I18N.t("GETTING_VIDEO_INFO")}
        <Loader />
      </div>
    ) : (
      <div className="video-upload">
        <p>{I18N.t("UPLOAD_VIDEO")}</p>
        <Input
          style={inputStyle}
          placeholder={I18N.t("UPLOAD_VIDEO_PLACE_HOLDER")}
          data-testid="url-input"
          onChange={this.isVideoUrlValid}
        />
        {errorMessage && (
          <p className="video-upload__error-message">{errorMessage}</p>
        )}
        <SuccessButton disabled={!videoUrl} onClick={this.getVideoInfo}>
          {I18N.t("UPLOAD")}
        </SuccessButton>
      </div>
    );
  };

  renderUploadStep2 = () => {
    const { title, thumbnail } = this.state.videoInfo;
    return (
      <div className="video-upload">
        <p>{title}</p>
        <img src={thumbnail} />
        <p>{I18N.t("CHECK_VIDEO_IS_CORRECT")}</p>
        <div>
          <DismissButton
            onClick={() => {
              this.setState({ videoInfo: null });
            }}
          >
            {I18N.t("CANCEL")}
          </DismissButton>
          <SuccessButton onClick={this.uploadVideo}>
            {I18N.t("YES")}
          </SuccessButton>
        </div>
      </div>
    );
  };

  renderFinishUpload = () => {
    const { uploadFinishMessage, uploadError } = this.state;
    return (
      <div className="video-upload video-upload__finish-upload">
        {uploadError ? (
          <p className="video-upload__error-message"> {uploadFinishMessage} </p>
        ) : (
          <p className="video-upload__success-message">
            {" "}
            {uploadFinishMessage}{" "}
          </p>
        )}
      </div>
    );
  };

  uploadVideo = async () => {
    const { TeamsStore, UserStore, NotificationsStore } = this.props;
    const { videoUrl } = this.state;
    const team = TeamsStore.currentTeam;
    const user = UserStore.data;
    try {
      const upload = await videoModule.uploadExternalVideo(
        team._id,
        user._id,
        videoUrl
      );
      NotificationsStore.clearCheckActiveTasksInterval();
      NotificationsStore.getActiveTasksRecursive();
      this.setState({ uploadFinishMessage: upload.responseText });
    } catch (e) {
      this.setState({ uploadFinishMessage: e.message, uploadError: true });
    }
  };

  getVideoInfo = async () => {
    this.setState({ isLoading: true });
    const { videoUrl } = this.state;
    try {
      const videoInfo = await videoModule.getVideoToUploadInfo(videoUrl);
      this.setState({ videoInfo, isLoading: false });
    } catch (e) {
      this.setState({
        errorMessage: e.message,
        isLoading: false,
        videoUrl: null
      });
      setTimeout(() => {
        this.setState({ errorMessage: null });
      }, 2000);
    }
  };

  isVideoUrlValid = e => {
    const inputElement = e.target;

    if (inputElement && inputElement.value.length > 20) {
      const url = inputElement.value;

      if (url !== null && url !== undefined && url !== "") {
        // eslint-disable-next-line
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=|\?vi=)([^#\&\?]*).*/;
        const match = url.match(regExp);

        if (match && match[2].length === 11) {
          return this.setState({ videoUrl: url });
        }

        return this.setState({ videoUrl: null });
      }

      return this.setState({ videoUrl: null });
    }
  };

  render() {
    const { videoInfo, uploadFinishMessage } = this.state;

    /* eslint-disable no-nested-ternary */
    return (
      <Fragment>
        {!uploadFinishMessage
          ? !videoInfo
            ? this.renderUploadStep1()
            : this.renderUploadStep2()
          : this.renderFinishUpload()}
      </Fragment>
    );
  }
}

export default inject("TeamsStore", "UserStore", "NotificationsStore")(
  observer(VideoUpload)
);

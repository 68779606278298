/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { toast } from "@myplay/ui";
import ReactTooltip from "react-tooltip";

import "./UploadCoverPhoto.scss";
import I18N from "../../I18N";
import Icon from "../../assets/icons/Icon-ChangeCover.svg";
import ImageTools from "../../utils/imageTools";

const isValidResolution = file => {
  return new Promise(resolve => {
    const img = new Image();

    img.src = URL.createObjectURL(file);

    img.onload = () => {
      const width = img.naturalWidth;
      const height = img.naturalHeight;

      URL.revokeObjectURL(img.src);

      const isValid = width >= 1200 && height > 200;

      resolve(isValid);
    };
  });
};

const isValidSize = file => {
  return new Promise(resolve => {
    const sizeToMegaBytes = file.size / 1024 / 1024;
    resolve(sizeToMegaBytes < 2);
  });
};

const UploadCoverPhoto = ({ TeamsStore }) => {
  const handleUpload = async event => {
    if (!event || !event.target || !event.target.files) {
      return;
    }
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = onloadEvent => {
      TeamsStore.currentTeam.setTeamCoverImage(onloadEvent.target.result);
    };

    event.target.value = "";

    const isFileResolutionValid = await isValidResolution(file);
    const isFileSizeValid = await isValidSize(file);

    if (isFileResolutionValid && isFileSizeValid) {
      const resized = await resize(file);
      reader.readAsDataURL(resized);
      return;
    }

    toast.error(
      "Please Upload An image with at least 1200 width and less than 2MB.",
      {
        className: "one-line-toast"
      }
    );
  };

  const resize = file => {
    return new Promise(resolve => {
      ImageTools.resize(
        file,
        {
          height: Number.MAX_VALUE,
          width: Number.MAX_VALUE
        },
        image => {
          resolve(image);
        }
      );
    });
  };

  return (
      <div data-tip={I18N.t("UPDATE_COVER_PHOTO")} className="upload-cover-photo__button">
        <label
          htmlFor="upload-cover-photo__input"
          type="file"
          accept="image/*"
          id="upload-cover-photo__label"
          className="upload-cover-photo__button__label"
        >
          <img src={Icon} className="upload-cover-photo__button__img" />
          <span className="upload-cover-photo__button__img__text">
            {I18N.t("CHANGE_COVER_PHOTO")}
          </span>
        </label>
        <input
          type="file"
          accept="image/*"
          id="upload-cover-photo__input"
          className="upload-cover-photo__button__input"
          onChange={handleUpload}
        />
        <ReactTooltip />
      </div>
  );
};

UploadCoverPhoto.propTypes = {
  TeamsStore: PropTypes.shape().isRequired
};

export default inject("TeamsStore")(observer(UploadCoverPhoto));

import React, { Fragment } from "react";
import { DefaultPlayer as Video } from "react-html5video";
import { SocialSharePanel, HighlightPlayer, Loader } from "@myplay/ui";
import PropTypes from "prop-types";

import I18N from "../../I18N";
import { MemberDetails } from "../../components/";
import "./PublicHighlightMobile.scss";
import Logo from "../../assets/images/logo-name.png";
import {WHITE} from "../../styles/shared/colors";
import getHighlightLink from "../../utils/getHighlightLink";

const PublicHighlightMobile = ({ user, highlight, isLoading }) => {
  const { video, streamUri, title, viewCount, thumbnailUri } = highlight || {};
  const renderHighlight = () => {
    const { cameraType } = video || {};
    return cameraType !== "PANORAMIC" ? (
      <Video
        controls={["PlayPause", "Seek", "Fullscreen"]}
        src={streamUri}
        poster={thumbnailUri}
        autoPlay
        loop
      />
    ) : (
      <HighlightPlayer videoId={highlight.id} autoPlay loop />
    );
  };

  const renderNoHighlight = () => {
    return (
      <div className="public-highlight-mobile__offline-title">
        <p>{I18N.t("NO_PUBLIC_HIGHLIGHT_PART1")}</p>
        <p className="public-highlight-mobile__secondary-title">
          {I18N.t("NO_PUBLIC_HIGHLIGHT_PART2")}
        </p>
        <div className="public-highlight-mobile__logo-container">
          <p>{I18N.t("POWERED_BY")}</p>
          <img src={Logo} />
        </div>
      </div>
    );
  };

  const renderLoadingHighlight = () => {
    return (
      <div className="public-highlight-mobile__loader">
        <p className="public-highlight-mobile__secondary-title">
          {I18N.t("GETTING_HIGHLIGHT")}
        </p>
        <Loader />
      </div>
    );
  };

  const shareLink = highlight && getHighlightLink(highlight);

  /* eslint-disable no-nested-ternary */
  return (
    <div className="public-highlight-mobile">
      <div className="public-highlight-mobile__header">
        {user ? (
          <div className="public-highlight-mobile__highlight-details">
            <MemberDetails
              member={user}
              style={{ flexDirection: "column", fontSize: "22px" }}
              imageStyle={{
                height: "100px",
                width: "100px",
                marginBottom: "20px"
              }}
            />
          </div>
        ) : null}
      </div>
      <div className="public-highlight-mobile__wrapper">
        <div className="public-highlight-mobile__player-container">
          {isLoading
            ? renderLoadingHighlight()
            : highlight
              ? renderHighlight()
              : renderNoHighlight()}
        </div>
        <p className="public-highlight-mobile__secondary-title">
          {title && `#${title}`}
        </p>
        <p className="public-highlight-mobile__view-count">
          {viewCount && `${viewCount}`} <span style={{color:WHITE}}>{I18N.t('VIEWS')}</span>
        </p>
      </div>
      <div className="public-highlight-mobile__footer">
        {highlight ? (
          <Fragment>
            <SocialSharePanel shareLink={shareLink} />
            <div className="public-highlight-mobile__logo-container">
              <p>{I18N.t("POWERED_BY")}</p>
              <img src={Logo} />
            </div>
          </Fragment>
        ) : null}
      </div>
    </div>
  );
};

PublicHighlightMobile.propTypes = {
  highlight: PropTypes.shape(),
  isLoading: PropTypes.bool,
  user: PropTypes.shape()
};

PublicHighlightMobile.defaultProps = {
  highlight: null,
  isLoading: true,
  user: null
};

export default PublicHighlightMobile;

/* eslint-disable */
import { types, getParent, flow } from "mobx-state-tree";
import { user, game as gameModule } from "@myplay/all";

// import { flow } from "mobx";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";

import optionalAndMaybeNull from "../../utils/mobx-optional-and-maybe-null";
import { TEAM_CHANNEL_GAMES_BULK_SIZE } from "../../utils/constants";

dayjs.extend(isBetween);

const notificationsModule = user.notifications;

const CHECK_ACTIVE_TASK_WHEN_ACTIVE = 10000; // 10 sec
const CHECK_ACTIVE_TASK_WHEN_NOT_ACTIVE = 180000; // 3 minutes

const videoModule = gameModule.videos;


// const Action = types.model({
//   path: types.string
// });

const clientUploadTask = types
  .model({
    type: "clientUpload",
    title: optionalAndMaybeNull(types.string),
    requestId: optionalAndMaybeNull(types.string),
    process: optionalAndMaybeNull(types.number)
  })
  .actions(self => ({
    setTaskProcess(process) {
      self.process = process;
    }
  }));

const ActiveTask = types.model({
  type: optionalAndMaybeNull(types.string),
  subType: optionalAndMaybeNull(types.string),
  title: optionalAndMaybeNull(types.string),
  body: optionalAndMaybeNull(types.string),
  icon: optionalAndMaybeNull(types.string),
  status: optionalAndMaybeNull(types.string),
  requestId: optionalAndMaybeNull(types.string),
  _id: optionalAndMaybeNull(types.string)
});

const Notification = types.model({
  _id: types.identifier,
  isRead: types.boolean,
  title: types.string,
  stringType: types.string,
  action: types.frozen(),
  params: types.frozen(),
  icon: types.string,
  isSystem: types.boolean,
  sinceDate: types.string,
  body: optionalAndMaybeNull(types.string)
});

const NotificationsStore = types
  .model(`Notifications`, {
    unreadCount: optionalAndMaybeNull(types.number),
    notifications: optionalAndMaybeNull(types.array(Notification)),
    activeTasks: optionalAndMaybeNull(types.array(ActiveTask)),
    clientUploadTasks: optionalAndMaybeNull(types.array(clientUploadTask)),
    selectedVideoId: optionalAndMaybeNull(types.string),
    selectedClipId: optionalAndMaybeNull(types.string),
    checkActiveTasksInterval:  optionalAndMaybeNull(types.number)

  })
  .actions(self => ({
    getNotifications: flow(function* getNotifications(userId = null, skip = 0) {
      try {
        /* eslint-disable-next-line no-param-reassign */
        userId =
          userId ||
          (getParent(self).UserStore.data &&
            getParent(self).UserStore.data._id);

        if (!userId) {
          return;
        }

        const notifications = yield notificationsModule.getNotifications(
          userId,
          skip
        );

        if (skip > 0) {
          self.notifications.push(...notifications);
          return;
        }

        self.notifications = notifications || [];
        if (
          notifications.some(
            not =>
              not.action.type === "new_video" &&
              dayjs(not.creationDate).isBetween(
                dayjs(new Date()),
                dayjs(new Date()).subtract(20, "seconds")
              )
          )
        ) {
          getParent(self).GamesStore.clear();
          getParent(self).GamesStore.fetchCurrentTeamGames(
            TEAM_CHANNEL_GAMES_BULK_SIZE,
            0
          );
        }
      } catch (error) {
        console.log(error);
      }
    }),
    getActiveTasks: flow(function* getActiveTasks(userId = null) {
      try {
        /* eslint-disable-next-line no-param-reassign */
        userId =
          userId ||
          (getParent(self).UserStore.data &&
            getParent(self).UserStore.data._id);

        if (!userId) {
          return;
        }
        const activeTasks = yield notificationsModule.getActiveTasks(userId);

        const gcsUploadingTasks = activeTasks.filter(
          task => task.status === "GCS_UPLOADING"
        );
        if (self.clientUploadTasks && self.clientUploadTasks.length > 0) {
          gcsUploadingTasks.map(async task => {
            if (
              self.clientUploadTasks.filter(
                item => item.requestId === task.requestId
              ).length === 0
            ) {
              await videoModule.updateUploadVideoRequest(
                task.requestId,
                "STOPPED_BY_USER"
              );
            }
          });
        } else {
          gcsUploadingTasks.map(task =>
            videoModule.updateUploadVideoRequest(
              task.requestId,
              "STOPPED_BY_USER"
            )
          );
        }
        self.activeTasks =
          activeTasks.filter(task => task.status !== "GCS_UPLOADING") || [];
      } catch (error) {
        console.log(error);
      }
    }),

    getActiveTasksRecursive: flow(function* getActiveTasks(userId = null) {

      try {
        yield self.getActiveTasks(userId);
        const checkTaskInterval =
          self.activeTasks && self.activeTasks.length > 0
            ? CHECK_ACTIVE_TASK_WHEN_ACTIVE
            : CHECK_ACTIVE_TASK_WHEN_NOT_ACTIVE;


        self.checkActiveTasksInterval = setTimeout(() => {
          self.getActiveTasksRecursive();
          self.getNotifications();
          self.getUnreadCount();
        }, checkTaskInterval);
      } catch (error) {
        console.log(error);
      }
    }),

    clearCheckActiveTasksInterval () {
      clearTimeout(self.checkActiveTasksInterval);
    },

    getInUploadProcessTask() {
      return self.clientUploadTasks;
    },

    getUnreadCount: flow(function* getUnreadCount() {
      try {
        const userId =
          getParent(self).UserStore.data && getParent(self).UserStore.data._id;

        if (!userId) {
          return;
        }

        const unreadCount = yield notificationsModule.getUnreadCount(userId);
        self.unreadCount = unreadCount;
      } catch (error) {
        console.log(error);
      }
    }),
    markAllAsRead: flow(function* markAllAsRead() {
      const userId =
        getParent(self).UserStore.data && getParent(self).UserStore.data._id;

      if (!userId) {
        return;
      }

      yield notificationsModule.markAllAsRead(userId);
      self.getUnreadCount();
    }),
    setCurrentClipId(id) {
      self.selectedClipId = id;
    },
    setCurrentVideoId(id) {
      self.selectedVideoId = id;
    },
    setActiveTasks(tasks) {
      self.activeTasks = tasks;
    },
    addClientUploadTasks(requestId, name) {
      if (!self.clientUploadTasks) {
        self.clientUploadTasks = [];
      }

      const title = `Uploading ${name}...`;

      const task = {
        requestId,
        title,
        process: 0
      };
      self.clientUploadTasks.push(task);
    },
    removeClientUploadTasks(requestId) {
      self.clientUploadTasks = self.clientUploadTasks.filter(
        task => task.requestId !== requestId
      );
    },
    cancelTask: flow(function* cancelTask(id,subType) {
      yield notificationsModule.cancelTask(id,subType);
      self.getActiveTasksRecursive();
    })
  }));

export default NotificationsStore;

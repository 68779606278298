import React from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react/index";
import I18N from "../../../I18N";
import "./SelectUploadVideoMethod.scss";
import Youtube from "../../../assets/images/youtube-upload.svg"
import Direct from "../../../assets/images/direct-upload.svg";
import {GREEN_LIGHT} from "../../../styles/shared/colors";

const SelectUploadVideoMethod = ({ ModalStore }) => {
  const handleDirectUploadSelect = () => {
    ModalStore.openModal({
      modalType: "USER_VIDEO_UPLOAD",
      modalPosition: "top"
    });
  };

  const handleYoutubeUploadSelect = () => {
    ModalStore.openModal({
      modalType: "VIDEO_UPLOAD",
      modalPosition: "top"
    });
  };
/* eslint-disable */
  return (
    <div className="select-upload-video-method">
      <p className="select-upload-video-method__title">{I18N.t("SELECT_UPLOAD_METHOD")}</p>
      <div className="select-upload-video-method__buttons-wrapper">
        <div>
          <img src={Direct} className="select-upload-video-method__icon" onClick={handleDirectUploadSelect} style={{background:GREEN_LIGHT}}/>
          <p>{I18N.t("DIRECT")}</p>
        </div>
        {/*<div>*/}
        {/*  <img src={Youtube} className="select-upload-video-method__icon" onClick={handleYoutubeUploadSelect} />*/}
        {/*  <p>{I18N.t("YOUTUBE")}</p>*/}
        {/*</div>*/}
      </div>
    </div>
  );
};

SelectUploadVideoMethod.propTypes = {
  ModalStore: PropTypes.object.isRequired
};

export default inject("ModalStore")(observer(SelectUploadVideoMethod));

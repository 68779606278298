import React, { Component } from "react";
import PropTypes from "prop-types";
import { NotifyContext, MyPlayLoader } from "@myplay/ui";
import { inject, observer } from "mobx-react";
import { GameTitle, TabsContainer } from "../../components";
import I18N from "../../I18N";
import "./Game.scss";

const getInitialTabIndex = () => {
  /* eslint-disable no-restricted-globals */
  if (location.pathname.includes("stats")) {
    return 2;
  }

  if (
    location.pathname.includes("breakdowns") ||
    location.pathname.includes("breakdown")
  ) {
    return 1;
  }

  return 0;
};

const TABS_TITLES = ["HIGHLIGHTS", "BREAKDOWNS", "STATS"];

class Game extends Component {
  static propTypes = {
    GameScreenStore: PropTypes.object,
    NotificationsStore: PropTypes.object,
    videoId: PropTypes.string.isRequired
  };

  static defaultProps = {
    GameScreenStore: {},
    NotificationsStore: {}
  };

  state = {
    tabIndex: getInitialTabIndex()
  };

  async componentDidMount() {
    const { videoId, GameScreenStore } = this.props;
    await GameScreenStore.initializeGameScreen(videoId);
    this.checkForClipsInProcess();
  }

  componentDidUpdate() {
    const {
      NotificationsStore: { selectedClipId }
    } = this.props;
    const { tabIndex } = this.state;
    this.node.scrollIntoView();
    if (tabIndex !== 0 && selectedClipId) {
      this.setState({ tabIndex: 0 }); // eslint-disable-line
    }
  }

  componentWillUnmount() {
    const { NotificationsStore } = this.props;
    NotificationsStore.setCurrentVideoId("");
    NotificationsStore.setCurrentClipId("");
    clearInterval(this.checkInterval);
  }

  // eslint-disable-next-line react/sort-comp
  handleTabChange = async tabIndex => {
    const {
      GameScreenStore: { getHighlights }
    } = this.props;
    this.setState({
      tabIndex
    });

    if (tabIndex === 0) {
      try {
        await getHighlights();
      } catch (error) {
        console.log(error);
      }
    }
  };

  checkForClipsInProcess = async () => {
    const {
      GameScreenStore: { checkClipsInProcess, getGameClips }
    } = this.props;
    try {
      this.checkInterval = setInterval(async () => {
        const hasClipsInProcess = await checkClipsInProcess();
        if (hasClipsInProcess) {
          clearInterval(this.checkInterval);
          this.checkForClipsInProcess();
        } else {
          clearInterval(this.checkInterval);
          await getGameClips();
        }
      }, 15000);
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    const { NotificationsStore, GameScreenStore } = this.props;
    const { isFetchingData, currentVideoContainer } = GameScreenStore;
    const videoContainer = { ...currentVideoContainer };
    const { tabIndex } = this.state;

    const { title, recordDate, video } = videoContainer || "";
    const gameDate = recordDate || video && video.insertionDate || "";
    /* hack to make mobx rerender and start life cycle methods */
    const dummySelectedClipId = NotificationsStore.selectedClipId; // eslint-disable-line
    return (
      <div ref={node => (this.node = node)} className="game">
        <div className="game__title">
          <GameTitle date={gameDate} title={title} />
        </div>
        {isFetchingData ? (
          <div className="game__loader">
            <MyPlayLoader title={I18N.t("LOADING_GAME_CAPITAL")} />
          </div>
        ) : (
          <NotifyContext.Consumer>
            {notify => (
              <TabsContainer
                className="game__tabs"
                tabsTitles={TABS_TITLES}
                notify={notify}
                selectedIndex={tabIndex}
                updateTabReady={this.updateTabReady}
                checkForClipsInProcess={this.checkForClipsInProcess}
                onTabChange={this.handleTabChange}
              />
            )}
          </NotifyContext.Consumer>
        )}
      </div>
    );
  }
}

export default inject(
  "UserStore",
  "MetaStore",
  "StatsStore",
  "TeamsStore",
  "NotificationsStore",
  "GameScreenStore"
)(observer(Game));

import React from "react";
import PropTypes from "prop-types";
//import "./BookingCard.scss";
import youtubeIcon from "../../../assets/images/youtube-icon.png";
import facebookIcon from "../../../assets/images/facebook-icon.png";
import iconDelete from "../../../assets/icons/Icon-Delete.svg";
import iconEdit from "../../../assets/icons/Icon-Edit.svg";
import BookingsStore from "../../../stores/bookings/bookings";
import {inject, observer} from "mobx-react";

const logos = {
    "YOUTUBE":  youtubeIcon,
    "FACEBOOK": facebookIcon
}

const BookingCard = ({bookingData, BookingsStore, isEditable = true}) => {
  const {creator,day,month,thirdPartyType,homeTeamTitle,awayTeamTitle, gameTime,id} = bookingData;
  return (
    <div className="booking-card">
      <div className="booking-card__head">
        <div className="booking-card__head__date">
          <p className="booking-card__head__date__day">{day}</p>
          <p className="booking-card__head__date">{month}</p>
        </div>
        <div className="booking-card__head__vertical-line"></div>
        <div className="booking-card__head__game-title">
          <p>{homeTeamTitle}</p>
          <p><span className="booking-card__title-green">vs </span>{awayTeamTitle}</p>
        </div>
      </div>
      <div className="booking-card__bottom">
        <div className="booking-card__bottom__third-party-logo">
          <img src={logos[thirdPartyType]} />
        </div>
        <div className="booking-card__bottom__game-time">
          <p>{gameTime}</p>
          <p className="booking-card__bottom__game-time__owner"><span className="booking-card__title-green">By:</span>{creator}</p>
        </div>
      </div>
    </div>
  );
};

BookingCard.propTypes = {};
BookingCard.defaultProps = {};

export default inject("BookingsStore")(observer(BookingCard));

import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { SuccessButton, DismissButton, toast } from "@myplay/ui";
import { inject, observer } from "mobx-react";

import "./DownloadVideo.scss";
import { SimpleDropdown } from "../../";
import I18N from "../../../I18N";

class DownloadVideoModal extends Component {
  state = {
    angle: null,
    isDone: false
  };

  componentDidMount() {
    const { cameras } = this.props;

    this.setAngle({ value: cameras[0].angle });
  }

  setAngle = ({ value }) => {
    this.setState({
      angle: value
    });
  };

  handleSendDownloadRequest = async () => {
    const {
      GamesStore,
      cameras,
      videoContainerId,
      ModalStore,
      NotificationsStore
    } = this.props;
    const { angle } = this.state;

    const { _id } = cameras.find(camera => camera.angle === angle);

    try {
      await GamesStore.downloadGame({
        videoId: _id,
        videoContainerId
      });
      NotificationsStore.clearCheckActiveTasksInterval();
      NotificationsStore.getActiveTasksRecursive();
      this.setState({ isDone: true });
    } catch (e) {
      ModalStore.closeModal();
      toast.error(I18N.t("DOWNLOAD_ERROR"));
    }
  };

  getThumbnailUri = cameras => {
    if (this.state.angle) {
      const camera = cameras.find(item => item.angle === this.state.angle);

      if (camera) {
        return camera.thumbnailUri;
      }
    }
    return cameras[0].thumbnailUri;
  };

  render() {
    const { cameras, ModalStore } = this.props;
    const { isDone } = this.state;

    const filteredCameras = cameras;

    const thumbnail = this.getThumbnailUri(filteredCameras);

    return !isDone ? (
      <div className="download-video-modal">
        <Fragment>
          <h2>{I18N.t("DOWNLOAD_VIDEO")}</h2>
          {filteredCameras.length > 1 && (
            <Fragment>
              <p>{I18N.t("CHOOSE_CAMERA")}</p>
              <SimpleDropdown
                items={filteredCameras.map(({ angle }) => ({ value: angle }))}
                defaultSelectedItem={{ value: filteredCameras[0].angle }}
                onChange={camera => this.setAngle(camera)}
                placeholder={I18N.t("CHOOSE_CAMERA")}
              />
            </Fragment>
          )}
          <img src={thumbnail} className="download-video-modal__thumbnail" />
          <SuccessButton onClick={this.handleSendDownloadRequest}>
            {I18N.t("SEND_REQUEST")}
          </SuccessButton>
        </Fragment>
      </div>
    ) : (
      <div className="download-video-modal__done-step">
        <p className="download-video-modal__title">
          {I18N.t("DOWNLOAD_EXPLAIN_1")}
        </p>
        <p>{I18N.t("DOWNLOAD_EXPLAIN_2")}</p>
      </div>
    );
  }
}

DownloadVideoModal.propTypes = {
  cameras: PropTypes.array,
  GamesStore: PropTypes.shape(),
  ModalStore: PropTypes.shape(),
  videoContainerId: PropTypes.string
};

DownloadVideoModal.defaultProps = {
  cameras: [],
  GamesStore: {},
  ModalStore: {},
  videoContainerId: ""
};

export default inject("ModalStore", "GamesStore", "NotificationsStore")(
  observer(DownloadVideoModal)
);


import React from "react";
import PropTypes from "prop-types";
import { Form, Field } from "react-final-form";
import { DetailInput } from "@myplay/ui";
import setFieldData from "final-form-set-field-data";
import { observer } from "mobx-react";

import "./PlayerInfo.scss";
import { AutoSave, SimpleDropdown } from "../../components";

/* eslint-disable react/jsx-sort-props */
const PlayerInfo = ({ inputs, onChange }) => {
  const initialValues = inputs.reduce((current, { value, name }) => {
    current[name] = value;
    return current;
  }, {});
  return inputs ? (
    <Form
      initialValues={initialValues}
      onSubmit={values => {
        delete values.team;
        delete values.age;
        onChange(values);
      }}
      mutators={{ setFieldData }}
      subscription={{} /* No need to subscribe to anything */}
      render={({ mutators, handleSubmit }) => {
        return (
          <form className="player-info__inputs">
            <AutoSave
              debounce={200}
              setFieldData={mutators ? mutators.setFieldData : () => {}}
              save={handleSubmit}
            />
            {inputs.map(
              ({
                name,
                placeholder,
                type,
                disabled,
                suffix,
                options,
                format
              }) => {
                return (
                  <Field
                    key={name}
                    name={name}
                    placeholder={placeholder}
                    format={format ? value => format(value) : null}
                  >
                    {({ input, meta }) => (
                      <div className="player-info__inputs__input">
                        {type === "select" ? (
                          <div className="player-info__inputs__input__select">
                            <span className="player-info__inputs__input__select__label">
                              {placeholder}
                            </span>
                            <SimpleDropdown
                              className="player-info__inputs__input__select__input"
                              menuClassName="player-info__inputs__input__select__menu"
                              items={options.map(option => ({
                                value: option
                              }))}
                              defaultSelectedItem={{
                                value: initialValues[name]
                              }}
                              onChange={value => {
                                input.onChange(value.value);
                                handleSubmit({ [name]: value.value });
                              }}
                            />
                          </div>
                        ) : (
                          <DetailInput
                            {...input}
                            type={type}
                            label={placeholder}
                            readOnly={disabled ? "readonly" : ""}
                            suffix={suffix}
                            options={
                              options &&
                              options.map(option => ({ value: option }))
                            }
                            error={
                              (!meta.pristine && meta.error) ||
                              meta.submitFailed
                                ? meta.error
                                : ""
                            }
                            disabled={disabled}
                          />
                        )}
                      </div>
                    )}
                  </Field>
                );
              }
            )}
          </form>
        );
      }}
    />
  ) : null;
};

PlayerInfo.propTypes = {
  inputs: PropTypes.shape(),
  onChange: PropTypes.func
};

PlayerInfo.defaultProps = {
  inputs: {},
  onChange: () => {}
};

export default observer(PlayerInfo);

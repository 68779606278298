import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Input } from '@myplay/ui';
import { Field } from 'react-final-form';
import { StepHeader } from '../../../components';
import I18N from '../../../I18N';
import { NEW_PASSWORD } from '../../../utils/constants';

class NewPasswordStep extends Component {
  static propTypes = {
    isActive: PropTypes.bool,
    isNextStepAllowed: PropTypes.func,
    onHandleCurrentStep: PropTypes.func,
    setNewError: PropTypes.func,
    // toggleTab: PropTypes.func,
  };

  static defaultProps = {
    isActive: false,
    isNextStepAllowed: ()=>{},
    onHandleCurrentStep: ()=>{},
    setNewError: ()=>{},
    // toggleTab: ()=>{},
  };

  state = {
    newPassword: '',
    confirmPassword: '',
  }

  componentDidMount() {
    const { isActive, onHandleCurrentStep } = this.props;

    if (isActive) {
      onHandleCurrentStep(NEW_PASSWORD)
    }
  }

  shouldComponentUpdate() {
    return true;
  }

  componentDidUpdate(prevProps) {
    const { isActive, onHandleCurrentStep } = this.props;

    if (prevProps.isActive !== isActive && isActive) {
      onHandleCurrentStep(NEW_PASSWORD)
    }
  }


  validateNewPassword = value => {
    const isValid = value && value.length >= 6;

    if (isValid) {
      this.setState({
        newPassword: value
      })
    }
    return isValid ? undefined : I18N.t("ERROR_PASSWORD_SHORT");
  }

  validateConfirmPassword = value => {
    const isValid = value && value.length >= 6;

    if (isValid) {
      this.setState({
        confirmPassword: value
      }, ()=> this.isPasswordsEqual())
    }


    return isValid ? undefined : I18N.t("ERROR_PASSWORD_SHORT");
  }

  isPasswordsEqual = () => {
    const { newPassword, confirmPassword } = this.state;
    const { isNextStepAllowed, setNewError } = this.props;
    const isEqual = newPassword === confirmPassword

    isNextStepAllowed(isEqual);
    setNewError(isEqual ? '' : I18N.t("ERROR_PASSWORDS_UMATCH"))
  }

  render() {
    // const { toggleTab } = this.props;
    const { isActive } = this.props
    return(
      <div className="new-password-step">
        <StepHeader lines={[I18N.t("CREATE_NEW"),I18N.t("PASSWORD")]} />
        <div className="new-password-step__fields">
          <Field
            className="new-password-step__fields__field"
            name="password"
            validate={this.validateNewPassword}
          >
            {({ input, meta }) => (
              <Input
                {...input}
                type="password"
                placeholder="NEW PASSWORD"
                data-testid="password"
                error={
                  (!meta.pristine && meta.error) || meta.submitFailed
                    ? meta.error
                    : ""
                }
                tabIndex={isActive ? 0 : -1}
              />
            )}
          </Field>
          <Field
            className="new-password-step__fields__field"
            name="confirmPassword"
            validate={this.validateConfirmPassword}
          >
            {({ input, meta }) => (
              <Input
                {...input}
                type="password"
                placeholder="CONFIRM PASSWORD"
                error={
                  (!meta.pristine && meta.error) || meta.submitFailed
                    ? meta.error
                    : ""
                }
                data-testid="confirmPassword"
                tabIndex={isActive ? 0 : -1}
                required
              />
            )}
          </Field>
        </div>
      </div>
    )
  }
}

export default NewPasswordStep;

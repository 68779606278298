/* eslint-disable */
import { types, getParent, flow } from "mobx-state-tree";
import { bookings as bookingsApi, utils, liveStreamDashboard } from "@myplay/all";

// import { flow } from "mobx";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import optionalAndMaybeNull from "../../utils/mobx-optional-and-maybe-null";

dayjs.extend(isBetween);


const formatBooking = booking => {
  const startTime = new Date(booking.bookingDate);
  const endTime = new Date(
    dayjs(booking.bookingDate).add(booking.duration, "minutes")
  );

  const gameTime = `${startTime.getHours()}:${startTime
    .getMinutes()
    .toString()
    .padStart(2, "0")} - ${endTime.getHours()}:${endTime
    .getMinutes()
    .toString()
    .padStart(2, "0")}`;

  const bookingCard = {
    homeTeamTitle: booking.homeTeamTitle,
    awayTeamTitle: booking.awayTeamTitle,
    thirdPartyType: booking.thirdPartyType,
    thirdPartyKey: booking.thirdPartyKey,
    thirdPartyProxyUri: booking.thirdPartyProxyUri,
    bookingDate: booking.bookingDate,
    duration: booking.duration,
    scoreboardData: booking.scoreboardData,
    day:  dayjs(new Date(booking.bookingDate)).format("DD"),
    month:  dayjs(new Date(booking.bookingDate)).format("MMM"),
    year:  dayjs(new Date(booking.bookingDate)).format("YY"),
    kickoff: dayjs(new Date(booking.bookingDate)).format("HH:mm"),
    creator: booking.creator?.fullName,
    gameTime: gameTime,
    id: booking._id
  };
  return bookingCard;
};

const BookingsStore = types
  .model(`Bookings`, {
    credits: 0,
    bookings: optionalAndMaybeNull(types.array(types.frozen())),
    teamId: optionalAndMaybeNull(types.array(types.frozen())),
    currentBooking: optionalAndMaybeNull(types.frozen()),
    recentBooking: optionalAndMaybeNull(types.frozen()),
    error: optionalAndMaybeNull(types.frozen()),
    saveError: optionalAndMaybeNull(types.string),
    isSchedulerOpen: false,
    isLoading: false,
    isByClub: false,
    isOnEdit: false,
    hasConfirmModal: false,
    bookingToCancelId: optionalAndMaybeNull(types.string),
    hasThirdParty: false,
    isListMode: false
  })
  .views(self => ({
    get daysForNextLive() {
      const liveDate = dayjs(self.recentBooking?.bookingDate);
      const today = dayjs();
      return liveDate.diff(today, "day");
    },
    get hoursForNextLive() {
      const liveDate = dayjs(self.recentBooking?.bookingDate);
      const today = dayjs();
      const days = liveDate.diff(today, "day");
      const hours = liveDate.diff(today, "hour");
      const totalHours = hours - 24 * days;
      return totalHours;
    }
  }))
  .actions(self => ({
    afterCreate: flow(function* afterCreate() {
      self.error =  {};
    }),
    getCredits: flow(function* getCredits() {
      const { TeamsStore } = getParent(self);
      const { currentTeam } = TeamsStore;
      try {
        const res = yield bookingsApi.getTeamCredits(currentTeam?._id);
        self.credits = res.credits;
        self.error = self.hasNoCredits() ? {message: "No Credits"} : {};
      } catch (e) {
        console.error(e);
      }
    }),
    getCurrentTeam(){
      const { TeamsStore } = getParent(self);
      const { currentTeam } = TeamsStore;
      return currentTeam;
    },

    setLoadingState(isLoading) {
      self.isLoading = isLoading;
    },
    getBookings: flow(function* getBookings() {
      self.isLoading = true;
      let bookings;
      const { TeamsStore } = getParent(self);
      const { currentTeam } = TeamsStore;
      try {
        if (self.isByClub) {
          bookings = yield bookingsApi.getBookingsByClub(currentTeam?.club);
        } else {
          bookings = yield bookingsApi.getBookingsByTeam(currentTeam?._id);
        }
      } catch (e) {
        bookings = [];
      }

      const formatBookings = bookings.filter(booking => booking.paymentStatus === 'PAID').map(booking => formatBooking(booking));
      self.bookings = formatBookings;
      self.isLoading = false;
    }),
    saveBooking: flow(function* createEditBooking() {
      const isFormValid = self.validateFields();
      let savingResponse;
      if (isFormValid) {
        self.isLoading = true;
        const { UserStore, TeamsStore } = getParent(self);
        const bookingToSave = { ...self.currentBooking };
        bookingToSave.team = TeamsStore?.currentTeam?._id;
        bookingToSave.creator = UserStore?.data?._id;

        bookingToSave.paymentStatus = 'PAID';
        bookingToSave.thirdPartyType = null;
        if(!self.hasThirdParty){
          bookingToSave.thirdPartyProxyUri = null;
        }
        let savingResponse;
        if (self.isOnEdit && bookingToSave.id) {
          savingResponse = yield bookingsApi.editBooking(bookingToSave);
        }else{
          savingResponse = yield bookingsApi.scheduledNewBooking(bookingToSave);
        }

        if (savingResponse.isSuccess || savingResponse.status === 200) {
          self.isLoading = false;
          yield self.getBookings();
          self.resetCurrentBooking();

        } else {
          self.isLoading = false;
          self.saveError = savingResponse.error;
          setTimeout(() => {
            self.clearSaveError();
          }, 2000);
        }
      }
    }),
    cancelRequest(id) {
      self.bookingToCancelId = id;
      self.hasConfirmModal = true;
    },
    dismissCancelRequest() {
      self.bookingToCancelId = null;
      self.hasConfirmModal = false;
    },
    validateFields() {
      const requiredFields = [ "bookingDate"];
      const error = {};
      for (const field of requiredFields) {
        if (
          self.currentBooking[field] === null ||
          self.currentBooking[field].length === 0
        ) {
          error[field] = true;
        }
      }
      if (Object.keys(error).length > 0) {
        error.message = "Please fill missing fields";
        self.error = error;
        setTimeout(() => {
          self.clearError();
        }, 2000);
        return false;
      }
      return true;
    },
    setError(error) {
      self.error = {message : error };
    },
    clearError() {
      self.error = {};
    },
    clearSaveError() {
      self.saveError = null;
    },
    toggleClubFilter: flow(function* toggleClubFilter() {
      self.isByClub = !self.isByClub;
      yield self.getBookings();
    }),
    resetCurrentBooking() {
      const { TeamsStore } = getParent(self);
      const emptyBooking = {
        homeTeamTitle: TeamsStore?.currentTeam?.name,
        awayTeamTitle: "",
        thirdPartyType: null,
        thirdPartyKey: "",
        scoreboardData: {},
        bookingDate: "",
        bookingDateTemp: "",
        bookingTime: "",
        day: null,
        month: null,
        creator: null,
        gameTime: null,
        id: null,
        duration: 120
      };
      self.currentBooking = { ...emptyBooking };
      self.isOnEdit = false;
      self.hasThirdParty = false;
    },
    cancelBooking: flow(function* cancelBooking() {
      try {
        if(self.bookingToCancelId){
          yield bookingsApi.cancelBooking(self.bookingToCancelId);
        }

      }
      catch (e) {
      }
      self.hasConfirmModal = false;
      yield self.getBookings();
    }),

    setHasThirdParty(value) {
      if(typeof value == "boolean"){
        self.hasThirdParty = value;
      }else{
        self.hasThirdParty = !self.hasThirdParty;
      }
    },

    setCurrentBooking(booking) {
      self.currentBooking = { ...booking };
      console.log("new Date(self.currentBooking.bookingDate).toLocaleTimeString()",new Date(self.currentBooking.bookingDate).toLocaleString())
      console.log("self.currentBooking.bookingDate",self.currentBooking.bookingDate)
      self.setCurrentBookingProp('bookingDateTemp',dayjs(new Date(self.currentBooking.bookingDate).toLocaleDateString()).format('YYYY-MM-DD'));
      self.setCurrentBookingProp('bookingTime', dayjs(new Date(self.currentBooking.bookingDate).toLocaleString()).format('HH:mm'));
      if(self.currentBooking.thirdPartyProxyUri && self.currentBooking.thirdPartyProxyUri.length > 0){
        self.setHasThirdParty(true);
      }else{
        self.setHasThirdParty(false);
      }
    },

    setCurrentBookingProp(key, value) {
      const tempBooking = { ...self.currentBooking };
      tempBooking[key] = value;
      self.currentBooking = { ...tempBooking };
    },
    setEditBookingMode(value) {
      self.isOnEdit = value;
    },
    openCloseScheduler() {
      self.isSchedulerOpen = !self.isSchedulerOpen;
    },
    getRecentBooking: flow(function* getRecentBooking() {
      yield self.getBookings();
      if (self.bookings.length > 0) {
        if (new Date(self.bookings[0].bookingDate) > Date.now()) {
          self.recentBooking = self.bookings[0];
        } else {
          self.recentBooking = {};
        }
      } else {
        self.recentBooking = {};
      }
    }),
    hasNoCredits() {
      return self.credits === 0;
    },
    onTeamNameChange(value, isHome){
      const tempBooking = { ...self.currentBooking };
      let obj = {awayName: value}
      if(isHome){
        obj = {homeName: value};
      }
      tempBooking.scoreboardData = {...tempBooking.scoreboardData, ...obj};
      self.currentBooking = { ...tempBooking };
    },
    onTeamLogoChange(value, isHome){
      const tempBooking = { ...self.currentBooking };
      let obj = {awayLogo: value}
      if(isHome){
        obj = {homeLogo: value};
      }
      tempBooking.scoreboardData = {...tempBooking.scoreboardData, ...obj};
      self.currentBooking = { ...tempBooking };
    },
    onTeamColorChange(value, isHome){
      const tempBooking = { ...self.currentBooking };
      let obj = {awayColor: value}
      if(isHome){
        obj = {homeColor: value};
      }
      tempBooking.scoreboardData = {...tempBooking.scoreboardData, ...obj};
      self.currentBooking = { ...tempBooking };
    },
    clearRecentBooking() {
      self.recentBooking = null;
    },

    toggleListMode() {
      self.isListMode = !self.isListMode;
      self.hasThirdParty = false;
    }
  }));

export default BookingsStore;

import React, { Component } from "react";
import { inject } from "mobx-react";
import { Link } from "react-router-dom";
import { user, utils } from "@myplay/all";
import { SwipeableViews, toast } from "@myplay/ui";
import { Form } from "react-final-form";
import createDecorator from "final-form-calculate";
import isMobile from "is-mobile";

import logo from "../../assets/images/logo-name.png";
import {
  ResetMethodStep,
  NewPasswordStep,
  ResetPasswordSuccess,
  EmailSentStep
} from "./steps";
import {
  PHONE,
  EMAIL,
  NEW_PASSWORD,
  VERIFICATION_CODE,
  NAME,
  ANALYTICS_FORGOT_PASSWORD,
  MYPLAY_APP_URL,
  FORGOT_PASSWORD_ROUTE_NAME, MYPLAY_LOGIN_URL
} from "../../utils/constants";
import { RegisterStandartButtons } from "../../components";
import StepEmail from "../Register/steps/StepEmail";
import StepPhone from "../Register/steps/StepPhone";
import StepVerificationCode from "../Register/steps/StepVerificationCode";
import I18N from "../../I18N";
import {
  backgrounds,
  mobileBackgrounds
} from "../../styles/shared/backgrounds";
import "./ForgotPassword.scss";
import { sendAnalyticsEvent } from "../../utils/analytics";

const authModule = user.auth;

class ForgotPassword extends Component {
  state = {
    currentPage: 0,
    currentStepName: "",
    resetMethod: "",
    isNextStepAllowed: false,
    token: "",
    shouldDisplayButton: true,
    shouldDisplayBack: true,
    error: "",
    requestId: "",
    ismobile: false
  };

  phoneNumber = createDecorator({
    field: [/regularPhone/, /countryCode/],
    updates: {
      phone: (ignoredValue, allValues) => {
        if (allValues.regularPhone) {
          return (allValues.countryCode.dialCode || "").concat(
            Number(allValues.regularPhone)
          );
        }
      }
    }
  });

  async componentDidMount() {
    const { UserStore } = this.props;
    const params = new URLSearchParams(location.search); // eslint-disable-line
    const token = params.get("token");

    await UserStore.clearUserData();
    if (token) {
      const res = await authModule.checkResetTokenIsValid(token);
      const { isValidToken } = res;
      if (isValidToken) {
        this.setState({
          currentPage: 3,
          token,
          shouldDisplayBack: false
        });
      } else {
        toast.error(I18N.t("EXPIRE_LINK"));
        // eslint-disable-next-line
        history.pushState(
          {},
          null,
          `${MYPLAY_APP_URL}${FORGOT_PASSWORD_ROUTE_NAME}`
        );
      }
    }
    this.setState({
      ismobile: isMobile()
    });
  }

  shouldComponentUpdate() {
    return true;
  }

  handleNext = () => {
    this.setState(prevState => ({
      currentPage: prevState.currentPage + 1,
      error: ""
    }));
  };

  handlePrevious = () => {
    this.setState(prevState => ({
      currentPage: prevState.currentPage - 1,
      error: ""
    }));
  };

  handleCurrentStepName = step => {
    this.setState({
      currentStepName: step
    });
  };

  isNextStepAllowed = value => {
    this.setState({
      isNextStepAllowed: value
    });
  };

  handleEmailReset = async values => {
    console.log("enter email");
    const { UserStore } = this.props;
    const { currentStepName } = this.state;

    const isValid = await UserStore.resetPasswordByEmail(values.email);
    console.log(isValid);
    if (isValid && currentStepName == EMAIL ) {
      this.handleNext();
    }

    if (!isValid) {
      this.setState({
        error: I18N.t("EMAIL_NOT_EXIST")
      });
    }
  };

  handlePhoneValidation = async values => {
    const { list } = await utils.getCountriesList();
    const country = list.filter(c => c.dial_code === values.countryCode)[0]
      .name;
    const { isPhoneExist } = await authModule.isPhoneOrEmailExists(
      values.phone,
      country,
      values.email || ""
    );

    if (isPhoneExist) {
      await this.handlePhoneReset(values);
      this.handleNext();
      return;
    }

    this.setState({
      error: I18N.t("PHONE_NOT_EXIST")
    });
  };

  handlePhoneReset = async values => {
    console.log("enter");
    const { UserStore } = this.props;
    const fullPhone = `${values.countryCode}${values.phone}`;
    const response = await UserStore.resetPasswordByPhone(fullPhone);
    if (response) {
      this.setState({
        requestId: response.request_id
      });
    } else {
      this.setState({
        error: I18N.t("PHONE_NOT_EXIST")
      });
    }
  };

  handleVerificactionCode = async values => {
    const { requestId } = this.state;
    try {
      const { resetToken } = values.phone
        ? await authModule.checkVerifyPhone({
            code: values.verificationCode,
            requestID: requestId,
            resetPassword: true
          })
        : await authModule.verifyUserCodeFromEmail({
            email: values.email,
            code: values.verificationCode
          });

      this.setState(
        {
          token: resetToken
        },
        this.handleNext()
      );
    } catch (error) {
      console.log(error);
      this.setState({
        error: I18N.t("WRONG_VERIFICATION_CODE")
      });
    }
  };

  handleResetPassword = async password => {
    const { UserStore } = this.props;
    const { token } = this.state;

    const res = await UserStore.setNewPassword(token, password);
    if (res) {
      const {accessToken , refreshToken, userData} = res;
      window.location.href =`${MYPLAY_APP_URL}/?accessToken=${accessToken}&refreshToken=${refreshToken}&userId=${userData.id}`;
      this.handleNext();
    }
  };

  handleNextClick = values => {
    const { currentStepName, isNextStepAllowed } = this.state;
    if (isNextStepAllowed) {
      switch (currentStepName) {
        case PHONE:
          this.handlePhoneValidation(values);
          break;
        case EMAIL:
          this.handleEmailReset(values);
          break;
        case NEW_PASSWORD:
          this.handleResetPassword(values.password);
          break;
        case VERIFICATION_CODE:
          this.handleVerificactionCode(values);
          break;
      }
      sendAnalyticsEvent(`TEST ${ANALYTICS_FORGOT_PASSWORD}`, {
        stepName: currentStepName,
        value: values[currentStepName]
      });
    }
  };

  setNewError = error => {
    this.setState({
      error
    });
  };

  updateResetMethod = method => {
    sendAnalyticsEvent(`TEST ${ANALYTICS_FORGOT_PASSWORD}`, {
      stepName: "resetMethod",
      value: method
    });
    this.setState(
      {
        resetMethod: method,
        shouldDisplayButton: true
      },
      () => this.handleNext()
    );
  };

  render() {
    const {
      resetMethod,
      currentPage,
      shouldDisplayButton,
      isNextStepAllowed,
      error,
      shouldDisplayBack,
      currentStepName,
      ismobile
    } = this.state;
    return (
      <div
        className="forgot-password"
        style={{
          backgroundImage: `url(${
            ismobile
              ? mobileBackgrounds[currentStepName] || backgrounds[NAME]
              : backgrounds[currentStepName] || backgrounds[NAME]
          })`
        }}
        data-testid="forgotPassword"
      >
        <img className="forgot-password__logo" src={logo} />
        <Form decorators={[this.phoneNumber]} onSubmit={() => null}>
          {({ handleSubmit, values }) => (
            <form style={{ width: "100%" }} onSubmit={handleSubmit}>
              <SwipeableViews
                index={currentPage}
                isSwipeable={false}
                hasArrows={false}
                hasDots={false}
                arrows={false}
                handleSwipe={() => null}
              >
                <ResetMethodStep updateResetMethod={this.updateResetMethod} />
                <div>
                  {resetMethod === EMAIL ? (
                    <StepEmail
                      isActive={currentPage === 1}
                      isNextStepAllowed={this.isNextStepAllowed}
                      onHandleCurrentStep={this.handleCurrentStepName}
                    />
                  ) : (
                    <StepPhone
                      isActive={currentPage === 1}
                      isNextStepAllowed={this.isNextStepAllowed}
                      onHandleCurrentStep={this.handleCurrentStepName}
                    />
                  )}
                </div>
                <div>
                  <StepVerificationCode
                    isActive={currentPage === 2}
                    phone={values.regularPhone}
                    email={values.email}
                    isNextStepAllowed={this.isNextStepAllowed}
                    handleSendVerificationCode={() => {
                      currentStepName === PHONE ?
                       this.handlePhoneReset(values) : this.handleEmailReset(values);
                    }}
                    onHandleCurrentStep={this.handleCurrentStepName}
                  />
                </div>
                <NewPasswordStep
                  isActive={currentPage === 3}
                  isNextStepAllowed={this.isNextStepAllowed}
                  setNewError={this.setNewError}
                  onHandleCurrentStep={this.handleCurrentStepName}
                />
                <ResetPasswordSuccess />
              </SwipeableViews>
              <div className="forgot-password__error">
                <span>{error}</span>
              </div>
              <div
                className="forgot-password__button-container"
                style={{
                  opacity:
                    shouldDisplayButton && currentPage && currentPage !== 4
                      ? "1"
                      : "0"
                }}
              >
                <RegisterStandartButtons
                  isSuccessAllowed={isNextStepAllowed}
                  isDismissVisible={currentPage && shouldDisplayBack}
                  isSuccessVisible
                  onDismiss={this.handlePrevious}
                  onSuccess={() => this.handleNextClick(values)}
                />
              </div>
            </form>
          )}
        </Form>
        <a
          style={{ position: "relative", zIndex: 1 }}
          href="https://id.myplay.com/logout"
          className="forgot-password__back"
        >
          {I18N.t("BACK_TO_LOGIN")}
        </a>
      </div>
    );
  }
}

export default inject("UserStore")(ForgotPassword);

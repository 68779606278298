export default {
  AGREE_TO_GET_UPDATES: "I aggree to get updates from MyPlay",
  LOGIN: "Login",
  EMAIL_OR_PHONE: "E-mail / Phone",
  PASSWORD: "Password",
  ADD_TEAM: "Add Team",
  ADD_NEW_TEAM: "Add New Team",
  ADD_GAME_TITLE: "Add game title",
  ADD_TRAINING_TITLE: "Add training title",
  ASSIGN: "Assign",
  ASSIGN_ASSISTANT: "Assign Assistant",
  ASSIGNED_USER_SUCCESSFULLY: "Assigned User Successfully",
  ASSIGN_APPROVE_PART1: "Do you want to assign ",
  ASSIGN_APPROVE_PART2: "\n as game assistant?",
  AFTER_ASSIGN_APPROVE: "\n Was assigned as game assistant",
  ASSIGN_ERROR: "Assign failed, please try again!",
  ASSISTS: "Assists",
  CREATE_PASSWORD: "Create Password",
  BY_EMAIL: "By E-mail",
  BY_PHONE: "By Phone",
  CANCEL: "Cancel",
  CANCEL_CAPITAL: "CANCEL",
  CANT_FIND_TEAM: "Cant find team with code",
  CLOSE: "Close",
  DATE_OF_BIRTH: "Date of birth",
  MYPLAY: "My Play",
  START_TRAINING: "Start Training",
  LIKE_A_REAL_PRO: "Like a Pro!",
  SIGN_UP: "Sign Up",
  LOG_IN: "Log In",
  OR: "OR",
  DONE: "Done",
  EMAIL: "Email",
  ERROR_PASSWORD_SHORT: "At least 6 characters",
  ERROR_SHORT_NAME: "At least 2 characters",
  ERROR_SHORT_TEAM_CODE: "Team code should be at least 6 charecters",
  ERROR_SHORT_PASSWORD: "At least 6 characters",
  ERROR_INVALID_PHONE: "Invalid Phone",
  ERROR_INVALID_EMAIL: "Invalid E-mail",
  ERROR_INVALID_EMAIL_OR_PHONE: "Invalid E-mail / Phone",
  ERROR_PASSWORD_REQUIRED: "Please Enter Password",
  ERROR_TEAMCODE_REQUIRED: "Please Enter Team Code",
  ERROR_TERMS_REQUIRED: "Please aggree to the Terms of Service",
  ERROR_INVALID_TEAMCODE: "Invalid Team Code",
  ERROR_BIRTHDATE_REQUIRED: "Please Enter Birthdate",
  FORGOT_PASSWORD: "Forgot Password?",
  SEASONAL: "Seasonal",
  BACK_TO_LOGIN: "Back To Login",
  CONNECT_WITH_FACEBOOK: "Connect With Facebook",
  PROFILE_UPDATED_SUCCESSFULLY: "Profile Updated Successfully",
  DELETE: "Delete",
  SOMETHING_WENT_WRONG: "Something Went Wrong",
  TEAM_CODE_MODAL: "Team Code is the code you get from your coach",
  I_AGREE_TO_THE: "I Agree To ",
  TERMS_OF_SERVICE: "Terms Of Service",
  TEAM_CHANNEL: "Team Channel",
  TEAM_ALREADY_EXIST_USER: "Team Already Exist",
  RECORD_BUTTON_TEXT: "REC",
  COPY_LINK_AND_SHARE: "Copy The Link And Share This Game",
  COPY_LINK: "Copy Link",
  COPY_SUCCESS: "Link was successfully copied!",
  GAME: "Game",
  TRAINING: "Training",
  LOGIN_SUCCESS: "Welcome",
  TRAINING_NAME: "Training Name",
  VS: "VS",
  STOP: "STOP",
  BACK: "Back",
  FIRST_NAME: "First Name",
  SEND_AGAIN: "Send Again",
  CANT_RECORD_HIGHLIGHT_FROM_VIDEO: "Cannot record a highlight from this video",
  LAST_NAME: "Last Name",
  PLAYER_NUMBER: "Player Number",
  PHONE_NUMBER: "Phone Number",
  COUNTRY: "Country",
  WE_HAVE_SENT_SMS: "We have sent a SMS with a code to",
  SIGNUP_PHONE_SUBTITLE: "Please Enter the code to complete registartion",
  EDIT_NUMBER: "Edit Number",
  RESEND_CODE: "Resend Code",
  TEAM_CODE: "Team Code",
  RESET_PASSWORD: "Reset Password",
  HOW_DO_YOU_WANT: "How do you want",
  TO_RESET_YOUR_PASSWORD: "to reset your password?",
  I_ALREADY_HAVE_AN_ACCOUNT: "I already have an account",
  SELECT_IMAGE: "Select Image",
  CHOOSE_TEAM_TO_CONNECT: "Choose Team To Connect",
  NEXT: "Next",
  FORGOT_PASSWORD_EMAIL_TITLE: "We have sent an E-mail to",
  FORGOT_PASSWORD_EMAIL_SUBTITLE:
    "Click the link in the email to reset your password",
  RESEND_EMAIL: "Resend Email",
  CHOOSE_NEW_PASSWORD: "Choose a new password",
  NEW_PASSWORD: "New Password",
  CONFIRM_NEW_PASSWORD: "Confirm New Password",
  ERROR_PASSWORDS_UMATCH: "Passwords must match",
  PASSWORD_CHANGED_TITLE: "Your password has changed successfully!",
  GOING_TO_LOGIN: "Going to login...",
  MY_PROFILE: "My Profile",
  RECORD: "Record",
  LIVE: "Live",
  FACEBOOK_LIVE: "Facebook Live",
  SET_FACEBOOK_LIVE: "Stream To Facebook Live",
  ENTER_STREAM_KEY: "Paste your stream key here",
  YOUTUBE_LIVE: "Youtube Live",
  MYPLAY_LIVE: "Myplay Live",
  LIVE_OPTIONS: "Live Options",
  NOTIFICATIONS: "Notifications",
  NO: "No",
  GENERATING: "Generating",
  LIVE_VIEW_FROM: "Live From",
  STOP_LIVE_WARNING: "(Stopping the live view will make the link unavailable)",
  DO_YOU_WANT_TO_STOP_LIVE: "Do you want to stop the live view ?",
  START_LIVE_VIEW: "Start Generating",
  GENERATING_LIVE_VIEW_FROM: "Generating Live from",
  ERROR_LIVE_VIEW_FROM: "Live View is not available  from",
  GETTING_READY: "Getting Ready...",
  PUBLIC_LIVE_OFFLINE_PART1: "THE STREAM IS CURRENTLY OFFLINE,",
  PUBLIC_LIVE_OFFLINE_PART2: "PLEASE TUNE IN LATER...",
  NO_PUBLIC_HIGHLIGHT_PART1: "No Highlight found",
  NO_PUBLIC_HIGHLIGHT_PART2: "Check url is correct !",
  POWERED_BY: "Powered by",
  SHARE_LIVE_VIEW: "Share Live View",
  CANCEL_LIVE_VIEW: "Cancel Live View",
  TEAM_LIST: "Team List",
  PHONE_ALREADY_EXIST: "Phone Already Exist",
  PLAYER_ASSIGNED: "Was assigned as game assistant",
  PRE_DELETE_MESSAGE: "Are you sure you want to delete this",
  PRE_DELETE_TEAM_MESSAGE: "Are you sure you want to delete this team?",
  PRE_REFRESH_TEAM_CODE_MESSAGE:
    "Are you sure you want to refresh this team code?",
  PRE_DELETE_GAME_MESSAGE: "Are you sure you want to remove this game?",
  PRE_DELETE_HIGHLIGHT: "Are you sure you want to delete this highlight?",
  PHONE: "Phone",
  TOTAL_SAVED: "Total Saved",
  POINTS_PER_GAME: "Points Per Game",
  REMOVE: "Remove",
  REMOVE_USER_SUCCESS: "Member Removed Successfully",
  TEAM_CODE_REFRESH_SUCCESS: "Team Code Refreshed Successfully",
  OPEN_SETTINGS: "Open Settings",
  SETTINGS: "Settings",
  COMMENTS_CAPITAL: "COMMENTS",
  LOGOUT: "Logout",
  LOGOUT_CONFIRMATION: "Are you sure you would like to logout?",
  STATS_CAPITAL: "STATS",
  HIGHLIGHTS_CAPITAL: "HIGHLIGHT",
  WRONG_CODE: "Wrong Code",
  VERIFIED: "Verified",
  MY_HIGHLIGHTS_CAPITAL: "MY HIGHLIGHTS",
  COACH_FEEDBACK_CAPITAL: "COACH FEEDBACK",
  GAME_STATS_CAPITAL: "GAME STATS",
  GAME_BREAKDOWN_CAPITAL: "BREAKDOWN",
  SAVE: "SAVE",
  DISCARD: "DISCARD",
  CANT_SAVE_HIGHLIGHT: "Cannot save this highlight",
  HIGHLIGHT_SUCCESSFULLY_CREATED: "Highlight Successfully Created",
  SHARE_HIGHLIGHT_MESSAGE: "Checkout this highlight via Myplay",
  SHARE_THIS_HIGHLIGHT: "Share This Highlight",
  CANT_START_RECORD: "Cannot start record",
  CANT_STOP_RECORD: "Cannot stop record",
  PICTURE_UPDATED_SUCCCESSFULLY: "Picture Updated Successfully",
  TAKE_YOUR_TEAM: "TAKE YOUR TEAM",
  MOBILE_PHONE: "Mobile phone",
  NEXT_LEVEL: "TO THE NEXT LEVEL!",
  CONTACT_US_NOW: "Contact Us Now!",
  NEW_SHARED_VIDEO_TITLE: "Save this game to your team channel",
  CHANGE: "Change",
  SELECTED_TEAM: "Selected team",
  UPDATED_SUCCESSFULLY: "Settings Updated Successfully",
  UPDATED_FAILED: "Settings Update Failed",
  UPDATE: "Update",
  BREAKDOWNS_CAPITAL: "BREAKDOWNS",
  TIME_CAPITAL: "TIME",
  EVENTS_CAPITAL: "EVENTS",
  POSITION_CAPITAL: "POSITION",
  CHANGE_COVER_PHOTO: "Change Cover Photo",
  HIGHLIGHT_OF: "Highlight Of",
  PLAYER_CAPITAL: "PLAYER",
  PICK_N_ROLLS: "Pick and Roll",
  OFFENSIVE_REBOUDS: "Offensive Rebound",
  DEFENSIVE_REBOUDS: "Oefensive Rebound",
  UPLOAD_SETTINGS: "Upload settings",
  UPLOAD_VIDEO: "Upload video to your Team channel",
  UPLOAD: "Upload",
  UPLOAD_VIDEO_PLACE_HOLDER: "Paste link here...",
  CHECK_VIDEO_IS_CORRECT: "Is this your video?",
  GETTING_VIDEO_INFO: "Getting video info",
  RECORDING_PRESET: "Recording preset",
  YES: "Yes",
  NAME: "Name",
  TEAM_NAME: "Team Name",
  SKIP: "Skip",
  SHARED_ITEM_USED: "This shared item has already used",
  NEW: "New",
  CHANGE_TEAM: "Change Team",
  WERE_SORRY: "We're sorry",
  QUIT: "Quit",
  TRY_AGAIN: "Try again",
  LIVE_FAILED: "live stream has failed to generate",
  LAST_GAME: "Last Game",
  STATS: "Stats",
  SWITCH_TEAM: "Switch Team",
  LIVE_SITE_ERROR:
    "The live stream is currently unavailable",
  RECORD_SITE_ERROR:
    "There is a problem to record at the moment. System connection is not responding",
  GETTING_HIGHLIGHT: "Highlight is loading...",
  NOW_RECORDING: "Now Recording",
  SEND_VIDEO_DOWNLOAD_REQUEST: "Send Video Download Request",
  SEND_REQUEST: "Send Request",
  SUBMIT: "SUBMIT",
  LOADING: "Loading...",
  LIVE_FROM: "Live From",
  OG_DESCRIPTION_PLAYER:
    "MyPlay - Smart video solution. Create your own highlight reel, watch games, statistics and more.",
  OG_DESCRIPTION_OPEARTOR:
    "MyPlay - Smart video solution.Live stream games, record training sessions, and analyse your team performance.",
  SHARE_LIVE_TITLE: "Watch live stream game of",
  TEAM_CHANNEL_SHARE_TITLE: "Watch a recorded game of",
  CLIP_OF: " Highlight clip of",
  DRAG_TO_REPOSITION: "Drag To Reposition",
  DOWNLOAD_VIDEO: "Download video",
  CHOOSE_CAMERA: "Choose camera:",
  DOWNLOAD_EXPLAIN_1: "Your request is in process",
  DOWNLOAD_EXPLAIN_2:
    "Email with a link to download the recording will be sent to you in the next couple of minutes.",
  SAVE_CHANGES: "Save changes",
  STOP_RECORD: "Are you sure you would like to stop this recording?",
  STARTED_BY: "It was started by ",
  SIGNUP: "signup",
  SIGNUP_BOTH_IDENTITIES_EXIST: `There's already a MyPlay account associated with your phone number and email address.`,
  SIGNUP_EMAIL_EXIST: `There's already a MyPlay account associated with your email address.`,
  SIGNUP_PHONE_EXIST: `There's already a MyPlay account associated with your phone number.`,
  NOT_ALLOWED: "You don't have permission to access this resource",
  THERE_ARE: "There are",
  NO_STATS_YET: "no stats yet",
  VIEWS: "Views",
  WHAT_IS: "What is",
  YOUR_NAME: "your name?",
  INSERT_TEAM_CODE: "Insert your team code",
  INVALID_TEAM_CODE: "Invalid team code",
  TERMS_AND_CONDITIONS: "Terms & Conditions",
  WHERE_ARE: "Where are",
  YOU_FROM: "you from?",
  WHEN_IS_YOUR: "When is your",
  BIRTHDAY: "birthday?",
  REGISTER_BY: "Register by",
  PHONE_OR_EMAIL: "phone or email?",
  WHATS_YOUR: "What's your",
  NUMBER: "number?",
  INSERT_YOUR: "insert your",
  CREATE: "create",
  SHOW_PASSWORD: "Show password",
  GO_TO_MYPLAY: "Go to MyPlay",
  INSERT_CODE: "Insert code",
  VERIFICATION_CODE_TEXT_FIRST_PART: "It is the 4 digit code",
  VERIFICATION_CODE_TEXT_SECOND_PART: "we sent to ",
  STOP_LIVE_QUESTION: "Do you want to stop the live broadcast?",
  SWITCH_LIVE_QUESTION: "Do you want to switch live view camera?",
  HI_THERE_YOU_HAVE: "Hi there, you have",
  NO_NEW_NOTIFICATIONS: "no new notifications",
  WRONG_VERIFICATION_CODE: "Wrong verification code",
  UPDATE_COVER_PHOTO: "Update cover photo",
  PLEASE_ENTER_THE_CODE: "Please enter the code",
  TO_COMPLETE_REQUEST: "to complete request",
  PLEASE_CONTACT_SUPPORT: "Please contact support",
  RESET_PASSWORD_FIRST_PART: "Reset password was sent to your email",
  RESET_PASSWORD_SECOND_PART: "check your inbox to proceed",
  CREATE_NEW: "Create new",
  YOUR_NEW_PASSWORD: "Your new password",
  WAS_SAVED_SUCCESSFULLY: "was saved successfully",
  PHONE_NOT_EXIST: `There's no MyPlay account associated with your phone number.`,
  EMAIL_NOT_EXIST: `There's no MyPlay account associated with your email.`,
  CLICK_HERE_TO_RECOVER_PASSWORD: "Click here to recover your password",
  FOR_BETTER_EXPERIENCE: "For a better experience",
  DOWNLOAD_THE_APP: "Download MyPlay App",
  INVITATION_EXPIRED: "Invitation link expired",
  HIGHLIGHTS: "Highlights",
  SELECT_ALL: "Select all",
  DESELECT_ALL: "Deselect all",
  VIDEO_CLIPS_CAPITAL: "VIDEO CLIPS",
  DOWNLOAD_ERROR: "Download request failed, please try again!",
  PROCESSING: "Processing...",
  NO_STATS_AVAILABLE_CAPITAL: "NO STATS AVAILABLE",
  FOR_THIS_GAME_CAPITAL: "FOR THIS GAME",
  LOADING_GAME_CAPITAL: "LOADING GAME",
  ADD_COMMENT: "Add Comment",
  SCHEDULE_RECORDING: "Schedule Recording",
  SCHEDULE_NEW_RECORDING: "Schedule New Recording",
  PICK_DATE: "Pick Date",
  SET_TIME: "Set Time",
  DURATION: "Duration",
  RECORD_CREATED_SUCCESSFULLY: "Record Created Successfully",
  RECORD_DELETED_SUCCESSFULLY: "Record Deleted Successfully",
  ERROR_DELETE_RECORD: "Error while deleting scheduled record",
  NO_SCHEDULED_RECORD_TITLE: "Please insert record title first",
  TIME_OUT: "Time out for maintenance.",
  WILL_BE_BACK_ON_COURT_SHORTLY: "Will be back on court shortly.",
  SELECT_VIDEO: "Select Video...",
  UPLOADING: "Uploading...",
  YOUTUBE: "From Youtube",
  DIRECT: "From Computer",
  SELECT_UPLOAD_METHOD: "Upload Video",
  UPLOAD_DIRECT: "Upload File Direct From Your Device",
  FINISH_UPLOAD: "Uploading video,will be notify once it will be ready",
  PLEASE_SELECT_EVENT_FIRST: "Please select events first",
  MAX_HIGHLIGHTS_ERRORS: "You can only select 50 breakdowns per clip",
  PROCESSING_REQUEST: "Processing, please wait...",
  SHARE_GAME_ERROR: "Unable to share a game that was shared with you",
  DOWNLOAD_SHARED_GAME_ERROR: "Unable to download a game that was shared with you",
  GO_TO_AD_PANEL: "Go to ad-panel",
  INVITE: "Invite",
  COMMENTATOR_NAME: "Commentator Name",
  COMMENTATOR_EMAIL: "Commentator Email",
  INVITE_SUCCESS: "Invite successfully sent",
  INVITE_FAILED: "Invite failed, please try again",
  LIVE_COMMENTARY: "Live Commentary",
  CONNECTION_ERROR_1: "Something went wrong",
  CONNECTION_ERROR_2: "Check your internet connection",
  SCOREBOARD: "Scoreboard",
  SCOREBOARD_SETTINGS: "Scoreboard Settings",
  SCOREBOARD_UPDATE_TIME: "The changes will take effect in 1 minute",
  CANCEL_TASK: "Are you sure you want to cancel this request?",
  CHANGE_SCORE_VIEW: `Are you sure you want change to video score view?\n` +
                      `this operation cannot be undone`,
  PRE_LIVE_MENU_TITLE: "Where do you want to broadcast?",
  OTHER: "Other",
  ADD_ADVERTISER_BANNER: "Add advertiser banner (1920 X 110)",
  REPLACE: "replace",
  IS_NOW_ACTIVE: "is now active",
  WAITING_FOR: "Waiting for",
  EXPIRE_LINK: "Reset link is expire",
  SCHEDULE_NEW_LIVE_STREAM : "Schedule New Live Stream",
  SAVING: "Saving",
  CREDITS : "credits",
  BUY_CREDITS : "Buy Credits",
  BY_TEAM: "Team",
  BY_CLUB: "Club",
  BOOKING_ERROR: "Live stream order failed",
  APPLY: "APPLY",
  BOOKING: "Scheduler",
};

import React, { Component } from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import {
  TeamMembersSwiper,
  PieChart,
  StatsGameScore,
  Bars,
  HeatMapFilters,
  VideoPlayerWithConnector,
  StatsTable,
  PlayerTotalPoints
} from "@myplay/ui";
import { entries } from "mobx";
import generate from "shortid";
import { StatsMap } from "../../components";
import I18N from "../../I18N";
import "./Stats.scss";
import { sendAnalyticsEvent } from "../../utils/analytics";
import {
  GAME_ROUTE_NAME,
  ANALYTICS_STATS_SWITCH_PLAYER,
  ANALYTICS_STATS_TABLE_ROW_CLICKED,
  ANALYTICS_STATS_MAP_EVENT_CLICKED
} from "../../utils/constants";

const IFRAME_VIDEO_URL = process.env.REACT_APP_IFRAME_VIDEO_URL;

class Stats extends Component {
  static propTypes = {
    StatsStore: PropTypes.object,
    TeamsStore: PropTypes.object,
    updateTabReady: PropTypes.func,
    UserStore: PropTypes.object,
    videoContainer: PropTypes.object
  };

  static defaultProps = {
    StatsStore: {},
    TeamsStore: {},
    updateTabReady: () => { },
    UserStore: {},
    videoContainer: {}
  };

  state = {
    currentStats: {},
    playerTotalPoints: 0,
    mapFilterItems: [],
    activeMapFilters: [],
    currentPlayerId: "",
    mappedEvents: [],
    selectedEvent: {},
    mappedEventsInitialized: false // eslint-disable-line
  };

  static getDerivedStateFromProps(props, state) {
    const { StatsStore } = props;
    if (state.mappedEvents.length === 0 && !state.mappedEventsInitialized) {
      return {
        mappedEvents: StatsStore.mappedEvents,
        mappedEventsInitialized: true
      };
    }
    return {};
  }

  componentDidMount() {
    const { videoContainer, updateTabReady, UserStore } = this.props;
    const {
      data: { fullName, _id, email, accountType }
    } = UserStore;
    this.getCurrentTeamMember(0);
    history.pushState( // eslint-disable-line
      {},
      "",
      `${GAME_ROUTE_NAME}/${videoContainer._id}/stats`
    );
    updateTabReady();
    this.defaultAnalyticsData = {
      fullName,
      id: _id,
      accountType,
      email
    };
  }

  componentDidUpdate(_, prevState) {
    const { videoContainer } = this.props;
    const { mapFilterItems, currentPlayerId } = this.state;

    if (Object.keys(videoContainer).length > 0 && mapFilterItems.length === 0) {
      this.setMapFilterItems();
    }
    if (prevState.currentPlayerId !== currentPlayerId) {
      this.handleHeatMapFilterClick();
    }
  }

  getCurrentTeamMember = index => {
    const { StatsStore, TeamsStore } = this.props;

    if (index > 0) {
      const id = TeamsStore.currentTeamMembers.players[index - 1]._id;
      const playersStats = entries(StatsStore.playersStats);
      let currentPlayerStats = playersStats.filter(entry => entry[0] === id);

      const singlePlayerStats = JSON.parse(
        JSON.stringify(StatsStore.tableStats)
      )[id];

      if (singlePlayerStats) {
        const playerTotalPoints = singlePlayerStats.stats.PTS;
        currentPlayerStats = currentPlayerStats.length > 0  ? currentPlayerStats[0][1] : []
        this.setState({
          currentStats: { ...currentPlayerStats },
          currentPlayerId: id,
          playerTotalPoints
        });
      }
    } else {
      this.setState({
        currentStats: { ...StatsStore.teamStats },
        currentPlayerId: "",
        playerTotalPoints: 0
      });
    }
    sendAnalyticsEvent(ANALYTICS_STATS_SWITCH_PLAYER, {
      ...this.defaultAnalyticsData
    });
  };

  setBarsData = bars => {
    const {
      StatsStore: { isStats }
    } = this.props;
    const newBars = [];
    Object.values(bars).map(bar =>
      newBars.push({
        data: isStats ? [bar.current, bar.avg] : [0, 0],
        tag: bar.tag
      })
    );
    return newBars;
  };

  setMapFilterItems = () => {
    const { videoContainer } = this.props;
    const defaultTags = [...videoContainer.team.category.defaultTags];
    const filterItems = [];
    const filterTags = [];

    defaultTags.map(tag => {
      if (tag.showMap && !filterItems.includes(tag.shortcut)) {
        const filterItem = {
          shortcut: tag.shortcut,
          markerColor: tag.markerColor
        };
        filterTags.push(tag.shortcut);
        filterItems.push(filterItem);
      }
    });

    if (filterItems.length > 0) {
      this.setState({
        mapFilterItems: filterItems,
        activeMapFilters: filterTags
      });
    }
  };

  handleHeatMapFilterClick = item => {
    const { StatsStore } = this.props;
    const { activeMapFilters } = this.state;
    const filtersCopy = [...activeMapFilters];
    const index = filtersCopy.indexOf(item);
    if (index > -1) {
      filtersCopy.splice(index, 1);
    } else {
      filtersCopy.push(item);
    }

    const mappedEvents = [...StatsStore.mappedEvents];
    const mappedEventsCopy = mappedEvents.filter(event =>
      filtersCopy.includes(event.commentType)
    );

    this.setState(
      {
        activeMapFilters: [...filtersCopy],
        mappedEvents: mappedEventsCopy
      },
      () => this.filterEventsByPlayer()
    );
  };

  handleHeatMapEventClick = event => {
    this.setState(
      {
        selectedEvent: event
      },
      () => this.jumpToSeekTime()
    );
    sendAnalyticsEvent(ANALYTICS_STATS_MAP_EVENT_CLICKED, {
      ...this.defaultAnalyticsData,
      eventArea: event.position.area
    });
  };

  jumpToSeekTime = () => {
    const { selectedEvent } = this.state;
    if (this.connector) {
      this.connector.call("setCurrentTime", {
        seekTime: selectedEvent.seekTime
      });
    }
  };

  handleTablePlayerClick = playerId => {
    this.setState(prevState => ({
      currentPlayerId: prevState.currentPlayerId !== playerId ? playerId : ""
    }));

    const selectedPlayerName = this.getPlayerNameById(playerId);
    sendAnalyticsEvent(
      ANALYTICS_STATS_TABLE_ROW_CLICKED,
      { ...this.defaultAnalyticsData },
      selectedPlayerName
    );
  };

  getPlayerNameById = id => {
    const {
      TeamsStore: {
        currentTeamMembers: { players }
      }
    } = this.props;
    const currentPlayer = players.filter(player => player._id === id);
    return currentPlayer[0].fullName;
  };

  filterEventsByPlayer = () => {
    const { currentPlayerId, mappedEvents } = this.state;
    if (currentPlayerId) {
      const newMappedEvents = mappedEvents.filter(event => {
        if (event.taggedUsers.length && currentPlayerId) {
          return event.taggedUsers[0]._id === currentPlayerId;
        }
      });

      this.setState({
        mappedEvents: [...newMappedEvents]
      });
    }
  };

  render() {
    const {
      TeamsStore,
      StatsStore: { courtSketch, tableStats, isStats, tableHeaders },
      videoContainer
    } = this.props;
    const {
      currentStats: { donuts, bars },
      mapFilterItems,
      activeMapFilters,
      mappedEvents,
      currentPlayerId,
      playerTotalPoints
    } = this.state;
    const barsData = bars && this.setBarsData(bars);

    return videoContainer && videoContainer._id ? (
      <VideoPlayerWithConnector
        videoContainerId={videoContainer._id}
        accountType="player"
        iframeId="iframe-container"
        iframeUrl={IFRAME_VIDEO_URL}
        isNakedVideoPlayer // eslint-disable-line
        render={({ connector }) => {
          this.connector = connector;
          return (
            <div className="stats" data-testid="stats">
              <div className="stats__main-stats-container">
                <div className="stats__main-stats-container__heatmap-container">
                  <div
                    className="stats__main-stats-container__heatmap-container__video-player"
                    id="iframe-container"
                  />
                  <div className="stats__main-stats-container__heatmap-container__map">
                    {mappedEvents && (
                      <StatsMap
                        mappedEvents={mappedEvents}
                        courtSketch={courtSketch}
                        category={TeamsStore.currentTeam.category.name}
                        hasTooltip
                        onEventClick={this.handleHeatMapEventClick}
                      />
                    )}
                  </div>
                  <div className="stats__main-stats-container__heatmap-container__filter">
                    <HeatMapFilters
                      filters={mapFilterItems}
                      activeFilter={activeMapFilters}
                      onItemClick={this.handleHeatMapFilterClick}
                    />
                  </div>
                </div>
                <div className="stats__main-stats-container__pies-and-bars-container">
                  <div className="stats__main-stats-container__pies-and-bars-container__top">
                    <div className="stats__main-stats-container__pies-and-bars-container__top__pies">
                      <div className="stats__main-stats-container__pies-and-bars-container__top__pies__score">
                        {currentPlayerId ? (
                          <PlayerTotalPoints points={playerTotalPoints} />
                        ) : (
                            <StatsGameScore
                              home={videoContainer.info.score.home !== null ? videoContainer.info.score.home : ""}
                              away={videoContainer.info.score.away !== null ?  videoContainer.info.score.away : ""}
                            />
                          )}
                      </div>
                      {donuts &&
                        <div className="stats__main-stats-container__pies-and-bars-container__top__pies__pies-wrapper">
                          {
                            Object.values(donuts).map((donut, i) => (
                              <PieChart
                                key={donut.success.tag}
                                pieIndex={i}
                                failure={isStats ? donut.failure.count : 0}
                                success={isStats ? donut.success.count : 0}
                                avgFailure={isStats ? donut.failure.avg : 0}
                                avgSuccess={isStats ? donut.success.avg : 0}
                                parameter={donut.success.tag}
                              />
                            ))

                          }
                        </div>
                      }
                    </div>
                    <TeamMembersSwiper
                      teamMembers={
                        TeamsStore.currentTeamMembers &&
                        TeamsStore.currentTeamMembers.players
                      }
                      teamImage={TeamsStore.currentTeam.teamImage}
                      teamName={TeamsStore.currentTeam.name}
                      getCurrentTeamMember={this.getCurrentTeamMember}
                      currentTeamMemberId={currentPlayerId}
                    />
                  </div>
                  <div className="stats__main-stats-container__pies-and-bars-container__bottom">
                    {bars && (
                      <Bars
                        data={barsData}
                        legends={["This Game", "Season Avg"]}
                        uniqueId={generate()}
                        hasBackgroundLines
                      />
                    )}
                    {!isStats && (
                      <div className="stats__main-stats-container__pies-and-bars-container__bottom__no-stats">
                        <span className="stats__main-stats-container__pies-and-bars-container__bottom__no-stats__text">
                          {I18N.t("NO_STATS_AVAILABLE_CAPITAL")}
                        </span>
                        <span className="stats__main-stats-container__pies-and-bars-container__bottom__no-stats__text">
                          {I18N.t("FOR_THIS_GAME_CAPITAL")}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="stats__table-container">
                <StatsTable
                  data={tableStats}
                  titles={tableHeaders || []}
                  selectedPlayerId={currentPlayerId}
                  onPlayerClick={this.handleTablePlayerClick}
                />
              </div>
              {!isStats && <div className="stats__disable-clicks-cover" />}
            </div>
          );
        }}
      />
    ) : null;
  }
}

export default inject("TeamsStore", "StatsStore", "UserStore")(observer(Stats));

export const WHITE                    = '#FFF';
export const BLACK                    = '#000';
export const GREEN_LIGHT              = '#16C4AB';
export const GREEN                    = '#73CC5A';
export const GREEN_SUCCESS            = '#68C153';
export const TURQUOISE                = '#ACE5DB';
export const ORANGE                   = '#F45530';
export const ORANGE_LIGHT             = '#EDA698';
export const RED                      = '#D61F07';
export const RED_FAILURE              = '#B72E2E';
export const YELLOW                   = '#EFD90A';
export const GREY                     = '#B3B3B3';
export const GREY_LIGHT               = '#2D2D2D';
export const GREY_DARK                = '#1F1F1F';
export const GREY_GAME_EVENT_DIVIDER  = '#434343';
export const GREY_GAME_EVENT_TAG      = '#1E1E1E';
export const GREY_TEAM_LIST           = '#121212';
export const GREY_GAME_EVENT          = '#191919';
export const GREY_DIVIDER             = '#1A1A1A';
export const GREY_PLAYER_BORDER       = '#292929';
export const GREEN_LIGHT_WITH_OPACITY = 'rgba(22, 196, 171, 0.65)';
export const FACEBOOK_BLUE            = '#3659A4';

export default {
  WHITE,
  BLACK,
  GREEN_LIGHT,
  GREEN,
  GREEN_SUCCESS,
  TURQUOISE,
  ORANGE,
  ORANGE_LIGHT,
  RED,
  RED_FAILURE,
  YELLOW,
  GREY,
  GREY_DARK,
  GREY_GAME_EVENT,
  GREY_GAME_EVENT_TAG,
  GREY_GAME_EVENT_DIVIDER,
  GREY_TEAM_LIST,
  GREY_DIVIDER,
  GREY_PLAYER_BORDER,
  GREEN_LIGHT_WITH_OPACITY,
  FACEBOOK_BLUE
}

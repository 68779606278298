import React from "react";

import "./StreamingDetailsTable.scss";

const StreamingDetailsTable = ({ streamingData }) => {
  return (
    <div >
      <ul className="streaming-details-table" >
        {Object.keys(streamingData).map(key => (
          <li className="streaming-details-table__item">
            <span>{key}</span>
            <span>{streamingData[key]}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default StreamingDetailsTable;

import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { SuccessButton, Loader, DismissButton } from "@myplay/ui";
import Draggable from "react-draggable";
import { inject, observer } from "mobx-react";

import I18N from "../../I18N";
import isValidUrl from "../../utils/isValidUrl";

class CoverImage extends Component {
  state = {
    maxTop: 1000,
    isEditing: false,
    isLoading: false,
    isUploading: false,
    y: 0,
    originalImage: ""
  };

  containerRef = null;

  imageRef = null;

  componentDidMount() {
    this.setMaxTop();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.src &&
      prevProps.src !== this.props.src &&
      isValidUrl(this.props.src)
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        isLoading: false,
        isUploading: false
      });
    }

    if (
      prevProps.src &&
      prevProps.src !== this.props.src &&
      !isValidUrl(this.props.src)
    ) {
      this.setMaxTop();
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        isEditing: true,
        originalImage: prevProps.src
      });
    }
  }

  setMaxTop = () => {
    // wait for the next tick for get the re-rendered heights
    setTimeout(() => {
      if (!this.imageRef) {
        console.log("[CoverImage] no image ref");
        return;
      }

      this.setState({
        maxTop: this.containerRef.clientHeight - this.imageRef.clientHeight
      });
    });
  };

  handleContainerRef = ref => {
    this.containerRef = ref;
  };

  handleImageRef = ref => {
    this.imageRef = ref;
  };

  handleSave = () => {
    this.setState({
      maxTop: 0
    });
    this.saveImage();
  };

  handleDiscard = () => {
    const { TeamsStore } = this.props;
    const { originalImage } = this.state;

    TeamsStore.currentTeam.setTeamCoverImage(originalImage);

    this.setState({
      isEditing: false,
      maxTop: 0,
      originalImage: ""
    });
  };

  handleDrag = (event, data) => {
    this.setState({
      y: data.y + data.deltaY
    });
  };

  saveImage = () => {
    const { src, TeamsStore } = this.props;
    const { y } = this.state;
    const img = new Image();
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    img.src = src;
    canvas.height = this.containerRef.clientHeight;
    canvas.width = document.body.clientWidth;

    img.onload = () => {
      ctx.drawImage(
        img,
        0,
        y,
        document.body.clientWidth,
        this.imageRef.clientHeight
      );
      canvas.style.visibility = "hidden";
      document.body.appendChild(canvas);
      TeamsStore.uploadTeamCoverImage(canvas.toDataURL());
      document.body.removeChild(canvas);
      this.setState({
        isUploading: true,
        isEditing: false,
        isLoading: true
      });
    };
  };

  handleLoad = () => {
    setTimeout(() => {
      this.setState({
        isLoading: false,
        y: 0
      });
    });
  };

  render() {
    const { src } = this.props;
    const { maxTop, isEditing, isLoading ,isUploading} = this.state;

    return (
      <Fragment>
        {isLoading && (
          <Loader
            type="spinningBubbles"
            className="cover-image__loader"
            width="100px"
            height="100px"
          />
        )}
        <div
          ref={this.handleContainerRef}
          className="cover-image"
          style={{ cursor: isEditing ? "move" : "" }}
        >
          {isEditing ? (
            <div className="cover-image__instructions">
              {I18N.t("DRAG_TO_REPOSITION")}
            </div>
          ) : null}
          {isEditing || isUploading ? (
            <Draggable
              axis="y"
              bounds={{ bottom: 0, top: maxTop }}
              disabled={!isEditing}
              onDrag={this.handleDrag}
            >
              <div className="cover-image__image">
                <img
                  ref={this.handleImageRef}
                  src={src}
                  draggable={false}
                  className="cover-image"
                />
              </div>
            </Draggable>
          ) : (
            <img
              ref={this.handleImageRef}
              src={src}
              draggable={false}
              className="cover-image"
            />
          )}
          <div
            className="cover-image__border"
            style={{ zIndex: isEditing ? -1 : 1 }}
          />
          {isEditing ? (
            <Fragment>
              <SuccessButton
                type="button"
                className="cover-image__save"
                onClick={this.handleSave}
              >
                {I18N.t("SAVE")}
              </SuccessButton>
              <DismissButton
                type="button"
                className="cover-image__discard"
                onClick={this.handleDiscard}
              >
                {I18N.t("DISCARD")}
              </DismissButton>
            </Fragment>
          ) : null}
        </div>
      </Fragment>
    );
  }
}

CoverImage.propTypes = {
  src: PropTypes.string,
  TeamsStore: PropTypes.shape().isRequired
};

CoverImage.defaultProps = {
  src: ""
};

export default inject(["TeamsStore"])(observer(CoverImage));

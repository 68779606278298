import { types, flow, getParent } from "mobx-state-tree";
import { game as gameModule } from "@myplay/all";
import optionalAndMaybeNull from "../../utils/mobx-optional-and-maybe-null";
import { USER_PLAYER, SCORE, ON_EDIT } from "../../utils/constants";

const videoModule = gameModule.videos;
const highlightsModule = gameModule.highlights;
const clipsModule = gameModule.clips;
const eventsModule = gameModule.events;

const GameScreenStore = types
  .model("GameScreenStore", {
    breakdowns: types.array(types.frozen()),
    clipOnWork: optionalAndMaybeNull(types.frozen()),
    currentVideoId: optionalAndMaybeNull(types.string),
    currentVideoContainer: optionalAndMaybeNull(types.frozen()),
    gameClips: types.array(types.frozen()),
    highlights: types.array(types.frozen()),
    isFetchingData: true,
    selectedVideos: types.array(types.frozen())
  })
  .actions(self => ({
    initializeGameScreen: flow(function* initializeGameScreen(videoId) {
      try {
        self.isFetchingData = true;
        yield self.getVideoContainer(videoId);
        self.getAllGameStats();
        yield self.getHighlights();
        yield self.getGameClips();
        yield self.getGameBreakdown();
        yield self.getClipOnWork();
        self.setIsFetchingData(false);
      } catch (error) {
        console.log(error);
      }
    }),
    getVideoContainer: flow(function* getVideoContainer(videoId) {
      try {
        const {
          TeamsStore: { currentTeam, setCurrentTeam },
          NotificationsStore: { setCurrentVideoId, selectedVideoId }
        } = getParent(self);
        const videoContainer = yield videoModule.getVideoContainerByID(videoId);
        self.currentVideoContainer = { ...videoContainer };
        self.currentVideoId = videoId;
        if (videoContainer.team._id !== currentTeam._id && (!videoContainer.awayTeam || videoContainer.awayTeam !== currentTeam._id)) {
          const newTeamId = self.getRelatedTeamByVideoContainer();
          setCurrentTeam(newTeamId);
        }
        if (videoContainer._id !== selectedVideoId) {
          setCurrentVideoId(videoContainer._id);
        }
      } catch (error) {
        console.log(error);
      }
    }),
    getHighlights: flow(function* getHighlights() {
      try {
        const { UserStore } = getParent(self);
        const { data } = UserStore;
        const { _id, accountType } = data;
        const videoIds = self.getVideoIds();
        const gameHighlights = yield highlightsModule.getGameHighlights(
          self.currentVideoId
        );
        if (accountType === USER_PLAYER) {
          const playerHighLights = yield highlightsModule.getPlayerGameHighlights(
            _id,
            videoIds
          );
          self.highlights = [...playerHighLights, ...gameHighlights];
        } else {
          self.highlights = [...gameHighlights];
        }
      } catch (error) {
        console.log(error);
      }
    }),
    getVideoIds() {
      const videoIds = self.currentVideoContainer.video.moreCameras
        .filter(video => video.cameraType !== SCORE)
        .map(video => video.id);
      videoIds.unshift(self.currentVideoContainer.video.id);
      return videoIds;
    },
    getGameClips: flow(function* getGameClips() {
      try {
        const gameClips = yield clipsModule.getGameClips(self.currentVideoId);
        self.gameClips = gameClips.filter(clip => clip.status !== ON_EDIT);
      } catch (error) {
        console.log(error);
      }
    }),
    getGameBreakdown: flow(function* getGameBreakdown() {
      try {
        const {
          UserStore: { data },
          TeamsStore: { currentTeam }
        } = getParent(self);
        const videoIds = self.getVideoIds();
        const breakdowns = yield eventsModule.getGameEvents(
          videoIds,
          currentTeam._id,
          data._id
        );
        self.breakdowns = [...breakdowns];
      } catch (error) {
        console.log(error);
      }
    }),
    checkClipsInProcess: flow(function* getClipsInProcess() {
      try {
        const { clipsInProcess } = yield clipsModule.checkForClipsInProcess(
          self.currentVideoContainer._id
        );
        return !!clipsInProcess.length;
      } catch (error) {
        console.log(error);
      }
    }),
    getClipOnWork: flow(function* getClipOnWork() {
      try {
        const clipOnWork = yield clipsModule.getClipOnWork(
          self.currentVideoContainer._id
        );
        self.clipOnWork = clipOnWork;
        if (
          Object.keys(clipOnWork).length > 0 &&
          clipOnWork.highlights.length > 0
        ) {
          self.updateSelectedVideos([...clipOnWork.highlights]);
        }
      } catch (error) {
        console.log(error);
      }
    }),
    getAllGameStats() {
      const { StatsStore } = getParent(self);
      StatsStore.getTeamStats(self.currentVideoId);
      StatsStore.getAllGameMappedEvents(self.currentVideoId);
      StatsStore.getCourtSketch(self.currentVideoId);
      StatsStore.getTableStats(self.currentVideoId);
      if (self.currentVideoContainer.hasStats) {
        StatsStore.getPlayersStats(self.currentVideoId);
      }
    },
    setIsFetchingData(value) {
      self.isFetchingData = value;
    },
    deleteHighlight: flow(function* deleteHighlight(highlightId) {
      // eslint-disable-line
      try {
        yield highlightsModule.deleteHighlight(highlightId);
        const deletedHighlightIndex = self.highlights.findIndex(
          highlight => highlight._id === highlightId
        );
        const newHighlights = self.highlights;
        newHighlights.splice(deletedHighlightIndex, 1);
        self.highlights = [...newHighlights];
      } catch (error) {
        console.log(error);
      }
    }),
    updateHighlight: flow(function* updateHighlight(highlightToUpdate) {
      try {
        yield highlightsModule.updateHighlight(
          highlightToUpdate.id,
          highlightToUpdate
        );
        const indexOfHighlight = self.highlights.findIndex(
          highlight => highlight.id === highlightToUpdate._id
        );
        self.highlights[indexOfHighlight] = highlightToUpdate;
      } catch (error) {
        console.log(error);
      }
    }),
    deleteClip: flow(function* deleteClip(clipId) {
      try {
        yield clipsModule.removeClip(clipId);
        const deletedClipIndex = self.gameClips.findIndex(
          clip => clip._id === clipId
        );
        const newClips = self.gameClips;
        newClips.splice(deletedClipIndex, 1);
        if (self.clipOnWork._id && self.clipOnWork._id === clipId) {
          self.clipOnWork = {};
        }
        self.gameClips = [...newClips];
      } catch (error) {
        console.log(error);
      }
    }),
    startClipOnWork: flow(function* startClipOnWork(highlights) {
      const {
        TeamsStore: { currentTeam },
        UserStore: { data }
      } = getParent(self);
      try {
        const clip = yield clipsModule.createClip({
          title: `Game Clip - ${new Intl.DateTimeFormat("en-GB", {
            year: "numeric",
            month: "short",
            day: "2-digit"
          }).format(new Date())}`,
          team: currentTeam._id,
          highlights: highlights.map(videos => videos.id),
          videoContainer: self.currentVideoContainer._id,
          sender: data._id
        });
        self.clipOnWork = clip;
      } catch (error) {
        console.log(error);
      }
    }),
    updateClipOnWork: flow(function* updateClipOnWork(highlights) {
      try {
        yield clipsModule.updateClip(self.clipOnWork.id, {
          highlights: highlights.map(videos => videos._id)
        });
      } catch (error) {
        console.log(error);
      }
    }),
    updateSelectedVideos(selectedVideos) {
      self.selectedVideos = selectedVideos;
    },
    createClip: flow(function* createClip() {
      try {
        yield clipsModule.makeClipVideo(self.clipOnWork.id);
        self.getGameClips();
        self.clipOnWork = {};
      } catch (error) {
        console.log(error);
      }
    }),
    updateClipInfo: flow(function* updateClipInfo(clipToUpdate) {
      try {
        yield clipsModule.updateClip(clipToUpdate._id, clipToUpdate);
        const indexOfClip = self.gameClips.findIndex(
          clip => clip.id === clipToUpdate._id
        );
        self.gameClips[indexOfClip] = clipToUpdate;
      } catch (error) {
        console.log(error);
      }
    }),
    createClipFromBreakdown: flow(function* createClipFromBreakdown(
      markedBreakdownsIds
    ) {
      try {
        const {
          TeamsStore: { currentTeam },
          UserStore: { data }
        } = getParent(self);
        yield clipsModule.makeClipVideoFromBreakdown(
          self.currentVideoId,
          currentTeam._id,
          data._id,
          markedBreakdownsIds
        );
        yield self.getGameClips();
      } catch (error) {
        console.log(error);
      }
    }),
    getRelatedTeamByVideoContainer() {
      const { TeamsStore: { teams, currentTeam } } = getParent(self);
      const isUserInHomeTeam = teams.some(team => team._id === self.currentVideoContainer.team._id);
      const isUserInAwayTeam = teams.some(team => self.currentVideoContainer.awayTeam && self.currentVideoContainer.awayTeam._id === team._id);

      if (isUserInHomeTeam && isUserInAwayTeam) {
        return currentTeam._id
      }

      if (isUserInHomeTeam || !self.currentVideoContainer.awayTeam) {
        return self.currentVideoContainer.team._id;
      }
      
      return self.currentVideoContainer.awayTeam._id;
    }
  }));

export default GameScreenStore;

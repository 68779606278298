/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FilterBar, DesktopFilterModal } from '@myplay/ui';
import './FilterPanel.scss';

class FilterPanel extends Component {
    static propTypes = {
        /** objects for categories, example {periods: [1,2,3],events:['3p',FT']} */
        categoriesToFilterBy: PropTypes.object.isRequired
    };

    static defaultProps = {};

    state = {
        fullScreenModalShow: false,
        filterTitle: '',
        filterCategories: [],
        currentFilter: {
            period: [],
            event: [],
            player: [],
            position: []
        },
        currentFilterCopy: {
            period: [],
            event: [],
            player: [],
            position: []
        }
    };

    componentDidMount() {
      this.initFilters();
    }


    initFilters() {
      const { categories } = this.props;
      const { event, period, player, positions } = categories;

      const filters = [
        {
          name: "periods",
          title: "Period",
          id: 1,
          items: videoPeriods,
          selectedItems: [],
          itemsPrefix: "Period"
        },
        {
          name: "events",
          title: "Events",
          id: 2,
          items: commentsTypes,
          selectedItems: []
        },
        {
          name: "positions",
          title: "Position",
          id: 3,
          items: courtAreas,
          selectedItems: []
        },
        {
          name: "players",
          title: "Players",
          id: 4,
          items: [],
          selectedItems: []
        }
      ];

    }
    getItemsToFilterBy = items => {
        const filterTitle = this.state.filterTitle.toLowerCase();

        this.setState(prevState => ({
            currentFilterCopy: {
                ...prevState.currentFilterCopy,
                [filterTitle]: items
            }
        }));
    };

    setFilterItemsByCategory = async (e, category) => {
        this.setState(prevState => ({
            currentFilterCopy: {
                ...prevState.currentFilterCopy,
                [category]: prevState.currentFilter[category]
            }
        }));

        const filterCategories = this.props.categoriesToFilterBy[category];

        this.setState(prevState => ({
            fullScreenModalShow: !prevState.fullScreenModalShow,
            filterCategories,
            filterTitle: category
        }));
    };

    handleClose = () => {
        this.setState(prevState => ({
            currentFilterCopy: {
                ...prevState.currentFilter
            }
        }));
    };

    handleSaveClick = () => {
        this.setState(
            prevState => ({
                currentFilter: {
                    ...prevState.currentFilterCopy
                }
            }),
            () => {
                this.props.onHandleFilter(this.state.currentFilter);
                this.hideFullScreenModal();
            }
        );
    };

    handleClearFiltersClick = () => {
        this.setState({
            currentFilter: {
                period: [],
                event: [],
                position: [],
                player: []
            },
            currentFilterCopy: {
                period: [],
                event: [],
                position: [],
                player: []
            }
        });
    };

    hideFullScreenModal = () => {
        this.setState(prevState => ({
            fullScreenModalShow: !prevState.fullScreenModalShow
        }));
        this.handleClose();
    };

    render() {
        const { categoriesToFilterBy } = this.props;
        const categoriesTitles = Object.keys(categoriesToFilterBy);
        const { filterTitle, filterCategories, currentFilter, currentFilterCopy, fullScreenModalShow } = this.state;
        return (
            <div className="filter-panel">
                <DesktopFilterModal
                    isVisible={fullScreenModalShow}
                    hideFullScreenModal={this.hideFullScreenModal}
                    onHandleClose={this.handleClose}
                    filterTitle={filterTitle}
                    filterCategories={filterCategories}
                    selectedTags={currentFilterCopy[filterTitle]}
                    getItemsToFilterBy={this.getItemsToFilterBy}
                    currentFilter={currentFilter[filterTitle]}
                    currentFiltersCopy={currentFilterCopy}
                    onSaveClick={this.handleSaveClick}
                    onHandleClearFilters={this.handleClearFiltersClick}
                />
                <FilterBar
                    categoriesToFilterBy={categoriesTitles}
                    handleClick={this.setFilterItemsByCategory}
                    indicator={currentFilter}
                />
            </div>
        );
    }
}

export default FilterPanel;

import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { PlayerBadge, DetailInput, DatePicker } from "@myplay/ui";
import { utils } from "@myplay/all";
import { Form, Field } from "react-final-form";
import dayjs from "dayjs";
import setFieldData from "final-form-set-field-data";
import ClickOutside from "react-click-outside";
import { observer } from "mobx-react";

import { WithCountries } from "../";
import CountriesContext from "../WithCountries/CountriesContext";

import "./UserSettings.scss";
import I18N from "../../I18N";

class UserSettings extends Component {
  static propTypes = {
    img: PropTypes.string,
    onUpdatePress: PropTypes.func,
    updateUser: PropTypes.func,
    user: PropTypes.object
  };

  static defaultProps = {
    img: "",
    onUpdatePress: () => {},
    updateUser: () => {},
    user: {}
  };

  state = {
    isDatePickerOpen: false, 
    hasImageError: false
  };

  setInitialValues = () => {
    const {
      user: { data }
    } = this.props;

    const values = {
      firstName: data.firstName,
      lastName: data.lastName,
      birthdate: data.birthdate
        ? dayjs(data.birthdate).format("MMM DD,YYYY")
        : data.birthdate,
      phone: data.phone,
      country: data.country
    };
    return values;
  };

  toggleDatePicker = () => {
    this.setState(prevState => ({
      isDatePickerOpen: !prevState.isDatePickerOpen
    }));
  };

  validate = details => {
    const errors = {};
    const { phone, firstName, lastName } = details;

    if (phone && !utils.isValidPhone(phone)) {
      errors.phone = I18N.t("ERROR_INVALID_PHONE");
    }

    if (!firstName || firstName.length < 2) {
      errors.firstName = I18N.t("ERROR_SHORT_NAME");
    }

    if (!lastName || lastName.length < 2) {
      errors.lastName = I18N.t("ERROR_SHORT_NAME");
    }

    return errors;
  };

  handleSubmit = (values, form) => {
    const { updateUser } = this.props;
    const valuesCopy = { ...values };
    valuesCopy.birthdate = valuesCopy.birthdate
      ? dayjs(valuesCopy.birthdate).add(1,'day').toISOString()
      : valuesCopy.birthdate;
    updateUser(valuesCopy, form);
  };

  handleImageError = ()=> {
    this.setState({
      hasImageError: true
    });
  };

  render() {
    const { user, img, onUpdatePress } = this.props;
    const { isDatePickerOpen, hasImageError } = this.state;
    return user.data._id ? (
      <div className="user-settings">
        <div className="user-settings__avatar-box">
          {img && !hasImageError ? (
            <img
              className="user-settings__avatar-box__avatar"
              src={img}
              data-testid="test-avatar"
              onError={this.handleImageError}
            />
          ) : (
            <PlayerBadge
              {...user.data}
              color={user.data ? user.data.avatarBgColor : ""}
            />
          )}
          <div className="user-settings__avatar-box__update-button">
            <input
              type="file"
              id="new-image"
              style={{ display: "none" }}
              value=""
              data-testid="test-avatar-input"
              onChange={event => {
                onUpdatePress(event, "user");
              }}
            />
            {/* eslint-disable */}
            <label htmlFor="new-image" id="new-file">
              {I18N.t("CHANGE").toUpperCase()}
            </label>
            {/* eslint-enable */}
          </div>
        </div>
        <Form
          onSubmit={this.handleSubmit} // eslint-disable-line
          validate={this.validate}
          mutators={{ setFieldData }}
          initialValues={this.setInitialValues()}
          render={({ handleSubmit, pristine }) => (
            <form className="user-settings__form" onSubmit={handleSubmit}>
              <Field name="firstName">
                {({ meta, input }) => (
                  <DetailInput
                    {...input}
                    data-testid="first-name"
                    label={I18N.t("NAME")}
                    error={!meta.pristine && meta.error ? meta.error : ""}
                  />
                )}
              </Field>
              <Field name="lastName">
                {({ meta, input }) => (
                  <DetailInput
                    {...input}
                    label={I18N.t("LAST_NAME")}
                    error={!meta.pristine && meta.error ? meta.error : ""}
                  />
                )}
              </Field>
              <Field className="user-settings__form__date" name="birthdate">
                {({ input }) => (
                  <Fragment>
                    <DetailInput
                      {...input}
                      label={I18N.t("DATE_OF_BIRTH")}
                      onClick={this.toggleDatePicker} // eslint-disable-line
                    />
                    {isDatePickerOpen ? (
                      <ClickOutside
                        onClickOutside={this.toggleDatePicker} // eslint-disable-line
                      >
                        <div className="user-settings__form__datepicker__picker">
                          <DatePicker
                            onChange={value => {
                              const valueToDisplay = dayjs(value).format(
                                "MMM DD,YYYY"
                              );
                              input.onChange(valueToDisplay);
                              this.toggleDatePicker();
                            }}
                          />
                        </div>
                      </ClickOutside>
                    ) : null}
                  </Fragment>
                )}
              </Field>
              <Field name="country">
                {({ input }) => (
                  <WithCountries>
                    <CountriesContext.Consumer>
                      {({ countries }) => (
                        <DetailInput
                          {...input}
                          label={I18N.t("COUNTRY")}
                          type="select"
                          defaultSelectedItem={{ value: user.data.country }}
                          defaultInputValue={{ value: user.data.country }}
                          items={countries}
                        />
                      )}
                    </CountriesContext.Consumer>
                  </WithCountries>
                )}
              </Field>

              <Field name="phone">
                {({ meta, input }) => (
                  <DetailInput
                    {...input}
                    label={I18N.t("PHONE")}
                    error={!meta.pristine && meta.error ? meta.error : ""}
                  />
                )}
              </Field>
              {!pristine ? (
                <button
                  type="submit"
                  className="user-settings__form__submit-button"
                >
                  {/* eslint-disable */}
                  {I18N.t("SAVE_CHANGES")}
                  {/* eslint-enable */}
                </button>
              ) : null}
            </form>
          )}
        />
      </div>
    ) : null;
  }
}

export default observer(UserSettings);

/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { VideosGrid, Loader } from "@myplay/ui";
import { user as userModule, game as gameModule } from "@myplay/all";
import { EditModal } from "../../components";
import { GAME_ROUTE_NAME } from "../../utils/constants";

const highlightsModule = gameModule.highlights;
const videoModule = gameModule.videos;
const clipsModule = gameModule.clips;

class CoachComments extends Component {
  static propTypes = {};

  static defaultProps = {};

  state = {
    commentToDisplay: null,
    commentsAndClips: [],
    isLoading: true
  };

  async componentDidMount() {
    const { videoId, userId } = this.props;
    history.pushState(
      {}, // eslint-disable-line
      "",
      `${GAME_ROUTE_NAME}/${videoId}/coach-comments`
    );
    this.userId = userId;
    this.videoContainerId = videoId;
    this.videoContainer = await videoModule.getVideoContainerByID(
      this.videoContainerId
    );
    const [comments, clips] = await Promise.all([
      this.getGameHighlights(),
      this.getGameClips()
    ]);
    const commentsAndClips = [...comments, ...clips].sort(
      (video1, video2) => video2.insertionDate - video1.insertionDate
    );
    this.setState({
      commentsAndClips,
      isLoading: false
    });
  }

  getGameHighlights = async () => {
    const highlights = await highlightsModule.getTeamGameHighlights(
      this.videoContainerId
    );
    return highlights;
  };

  getGameClips = async () => {
    const clips = await clipsModule.getGameClips(this.videoContainerId);
    return clips;
  };

  handleVideoClick = async highlight => {
    this.setState({ commentToDisplay: highlight });
  };

  changeHighlight = props => {
    const { commentsAndClips, commentToDisplay } = this.state;
    const index = commentsAndClips.findIndex(
      comment => comment.id === commentToDisplay.id
    );
    if (commentsAndClips.length > 0) {
      if (props.prev) {
        if (index > 0) {
          this.setState({ commentToDisplay: commentsAndClips[index - 1] });
        }
      } else if (index < commentsAndClips.length - 1) {
        this.setState({ commentToDisplay: commentsAndClips[index + 1] });
      }
    }
  };

  handleDismissEditModal = () => {
    this.setState({ commentToDisplay: null });
  };

  render() {
    const { commentToDisplay, commentsAndClips } = this.state;
    let currentHighlightIndex = 0;
    if (commentToDisplay) {
      currentHighlightIndex = commentsAndClips.findIndex(
        comment => comment.id === commentToDisplay.id
      );
    }

    const fullGrid = (
      <div className="coach-comments">
        {this.state.isLoading ? (
          <div className="coach-comments__loader-container">
            <Loader type="spinningBubbles" height="150px" width="150px" />
          </div>
        ) : (
          <VideosGrid
            onClick={this.handleVideoClick}
            videos={commentsAndClips}
            hasSelectAll={false}
            hasAddButton={false}
            hasHeader={false}
            hasClipBedge
          />
        )}
        {this.state.commentToDisplay && (
          <EditModal
            onDismiss={this.handleDismissEditModal}
            highlight={commentToDisplay}
            changeHighlight={this.changeHighlight}
            isEditMode={false}
            numberOfHighlights={commentsAndClips.length}
            currentHighlightIndex={currentHighlightIndex}
          />
        )}
      </div>
    );
    return fullGrid;
  }
}

export default CoachComments;

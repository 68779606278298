import React from "react";
import PropTypes from "prop-types";
import { Counter } from "@myplay/ui";

import "./RecordingStatusDisplay.scss";
import I18N from "../../I18N";

const RecordingStatusDisplay = ({ timeToEnd }) => {
  return timeToEnd ? (
    <div className="recording-status-display">
      <div className="recording-status-display__red-circle recording-status-display__red-circle--flashit" />
      <span>
        {I18N.t("NOW_RECORDING")} <Counter time={timeToEnd} hasHours />
      </span>
    </div>
  ) : null;
};

RecordingStatusDisplay.propTypes = {
  timeToEnd: PropTypes.number
};

RecordingStatusDisplay.defaultProps = {
  timeToEnd: 0
};

export default RecordingStatusDisplay;

/* eslint-disable react/jsx-no-literals */
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { Transition } from "react-transition-group";
import { inject, observer } from "mobx-react";
import { GameScoreInputs, Dots } from "@myplay/ui";
import ReactTooltip from "react-tooltip";

import "./VideoPreview.scss";
import I18N from "../../I18N";
import VideoPreviewMenu from "./VideoPreviewMenu";
import VideoPreviewMenuItem from "./VideoPreviewMenuItem";
import { UserTypeGuard } from "../";
import { GAME_ROUTE_NAME } from "../../utils/constants";
import downloadIcon from "../../assets/icons/Icon-Download.svg";
import shareIcon from "../../assets/icons/Icon-ShareVideo.svg";
import deleteIcon from "../../assets/icons/Icon-Delete.svg";
import assignIcon from "../../assets/icons/Icon-Assign.svg";
import statsIcon from "../../assets/images/inline-svgs/stats-indicator.svg";
import defaultThumbnail from "../../assets/images/logo-transparent.png";

const iconSize = "50px";

class VideoPreview extends Component {
  state = {
    withMenu: false,
    isImageLoaded: false,
    isShown: true,
    thumbnailSrc: ""
  };

  EXIT_ANIMATION_DURATION = 300;

  transitionsStyle = {
    exited: {
      transform: "translateX(-1000px)",
      opacity: 1
    }
  };

  ref = null;

  componentDidMount() {
    const { item } = this.props;

    this.setState({
      thumbnailSrc: item.thumbnailUri
    });
  }

  shouldComponentUpdate() {
    return true;
  }

  handleRef = ref => {
    if (!this.ref) {
      this.ref = ref;
    }
  };

  toggleWithMenu = event => {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }

    this.setState(state => ({
      withMenu: !state.withMenu
    }));
  };

  handleDelete = () => {
    this.toggleWithMenu();
    const {
      ModalStore,
      GamesStore,
      item: { _id }
    } = this.props;

    ModalStore.openModal({
      modalType: "CONFIRM_MODAL",
      modalPosition: "center",
      modalProps: {
        gameId: _id,
        text: "Are you sure you want to delete this game?",
        handleSuccess: () => {
          ModalStore.closeModal();

          this.setState(
            {
              isShown: false
            },
            () => {
              setTimeout(() => {
                GamesStore.deleteGame(_id);
              }, this.EXIT_ANIMATION_DURATION + 150);
            }
          );
        }
      }
    });
  };

  handleShare = () => {
    this.toggleWithMenu();
    const {
      item: { videoDocId, thumbnailUri },
      GamesStore
    } = this.props;

    GamesStore.shareGame(videoDocId, thumbnailUri);
  };

  handleAssignAssistant = () => {
    this.toggleWithMenu();
    const {
      item: { _id },
      GamesStore
    } = this.props;

    GamesStore.assignAssistant(_id);
  };

  handleDownload = () => {
    this.toggleWithMenu();
    const {
      item: {
        _id,
        thumbnailUri,
        videoAngle,
        videoDocId,
        moreCameras,
        videoCameraType
      },
      ModalStore
    } = this.props;

    ModalStore.openModal({
      modalType: "DOWNLOAD_GAME",
      modalPosition: "center",
      modalProps: {
        cameras: [
          {
            angle: videoAngle,
            _id: videoDocId,
            thumbnailUri,
            cameraType: videoCameraType
          },
          ...moreCameras
        ],
        thumbnail: thumbnailUri,
        videoId: videoDocId,
        videoContainerId: _id
      }
    });
  };

  handleKeyDown = (keyCode, value) => {
    const {
      item: { changeTitle }
    } = this.props;

    if (keyCode === 13) {
      this.ref.blur();
      changeTitle(value);
    }
  };

  setIsImageLoaded = isLoaded => {
    this.setState({
      isImageLoaded: isLoaded
    });
  };

  setImageError = () => {
    this.setState({
      thumbnailSrc: defaultThumbnail
    });
  };

  updateHomeScore = score => {
    const {
      GamesStore,
      item: { _id }
    } = this.props;
    GamesStore.updateHomeScore(_id, score);
  };

  updateAwayScore = score => {
    const {
      GamesStore,
      item: { _id }
    } = this.props;
    GamesStore.updateAwayScore(_id, score);
  };

  /* eslint-disable react/jsx-handler-names */
  render() {
    const {
      item: { _id, title, date, changeTitle, isShared, hasStats, info, canEdit },
      index,
      allowEditTitle,
      withMarker,
      allowEditScore
    } = this.props;
    const { isShown, withMenu, isImageLoaded, thumbnailSrc } = this.state;

    const isNew = dayjs(date).add(5, "day") >= dayjs();
    const shouldDisplayScore =
      allowEditTitle || info.score.home !== null || info.score.away !== null;
    const shouldDisplayTitle = title || allowEditTitle;
    const animationDelay = (index % 12) * 50 + 1;
    return (
      <Transition in={isShown} timeout={this.EXIT_ANIMATION_DURATION}>
        {state => (
          <div
            key={_id}
            style={{
              animationDelay: `${animationDelay}ms`,
              visibility: isImageLoaded ? "visible" : "hidden",
              transition: `all ${this.EXIT_ANIMATION_DURATION}ms`,
              ...this.transitionsStyle[state]
            }}
            className={`video-preview ${
              isImageLoaded ? "video-preview--animation" : ""
            }`}
          >
            <VideoPreviewMenu isOpen={withMenu}>
                <Fragment>
                  <VideoPreviewMenuItem onClick={!isShared ? this.handleShare : null}>
                    <img
                      data-tip={I18N.t("SHARE_GAME_ERROR")}
                      data-for={`${_id}-share`}
                      src={shareIcon}
                      style={{ height: iconSize, width: iconSize, filter: `grayscale(${isShared ? 1 : 0})` }}
                    />
                    Share
                    {isShared && <ReactTooltip id={`${_id}-share`}/>}
                  </VideoPreviewMenuItem>
                  <VideoPreviewMenuItem onClick={!isShared ? this.handleDownload : null}>
                    <img
                      data-tip={I18N.t("DOWNLOAD_SHARED_GAME_ERROR")}
                      data-for={`${_id}-share`}
                      src={downloadIcon}
                      style={{ height: iconSize, width: iconSize, filter: `grayscale(${isShared ? 1 : 0})` }}
                    />
                    Download
                    {isShared && <ReactTooltip id={`${_id}-download`}/>}
                  </VideoPreviewMenuItem>
                </Fragment>
              <VideoPreviewMenuItem onClick={this.handleAssignAssistant}>
                <img
                  src={assignIcon}
                  style={{ height: iconSize, width: iconSize }}
                />
                <span style={{ color: "white" }}>Assign</span>
              </VideoPreviewMenuItem>
              {canEdit.delete &&
                <VideoPreviewMenuItem onClick={this.handleDelete}>
                  <img
                    src={deleteIcon}
                    style={{ height: iconSize, width: iconSize }}
                  />
                  {I18N.t("DELETE")}
                </VideoPreviewMenuItem>
              }
            </VideoPreviewMenu>
            <Link to={`${GAME_ROUTE_NAME}/${_id}`}>
              <img
                src={thumbnailSrc}
                className="video-preview__thumbnail"
                onLoad={() => this.setIsImageLoaded(true)}
                onError={() => this.setImageError(true)}
              />
            </Link>
            <footer
              className={`video-preview__footer ${
                index === 0 && withMarker ? "video-preview__footer--marker" : ""
              }`}
            >
              <div>
                {shouldDisplayTitle && (
                  <input
                    ref={ref => this.handleRef(ref)}
                    value={title || ""}
                    placeholder={title ? "" : "Add Game Title"}
                    className="video-preview__footer__title"
                    contentEditable={allowEditTitle}
                    disabled={!allowEditTitle}
                    onChange={({ target: { value } }) => changeTitle(value)}
                    onBlur={({ target: { value } }) => changeTitle(value, true)}
                    onKeyDown={({ keyCode, target: { value } }) =>
                      this.handleKeyDown(keyCode, value)
                    }
                  />
                )}
                <div style={{ display: "flex" }}>
                  <Link to={`${GAME_ROUTE_NAME}/${_id}`}>
                    <div>
                      <div className="video-preview__footer__bottom">
                        <span className="video-preview__footer__date">
                          {dayjs(date).format("MMM DD, YYYY")}
                        </span>
                        {isNew ? (
                          <div className="video-preview__footer__new-game">
                            <span>{I18N.t("NEW")}</span>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </Link>
                  {shouldDisplayScore && (
                    <GameScoreInputs
                      score={info.score}
                      updateHomeScore={this.updateHomeScore}
                      updateAwayScore={this.updateAwayScore}
                      isEditable={allowEditScore}
                    />
                  )}
                </div>
              </div>
              <UserTypeGuard allowed={["coach", "operator"]}>
                <button
                  type="button"
                  className="video-preview__footer__menu-button"
                  onClick={this.toggleWithMenu}
                >
                  <Dots length={3} isHorizontal={withMenu} />
                </button>
              </UserTypeGuard>
            </footer>
            {hasStats ? (
              <div className="video-preview__stats-status video-preview__stats-status--without-badge">
                <Link to={`${GAME_ROUTE_NAME}/${_id}/stats`}>
                  {" "}
                  <img src={statsIcon} className="video-preview__stats-icon video-preview__stats-icon--without-badge"/>
                </Link>
              </div>
            ) : null}
          </div>
        )}
      </Transition>
    );
  }
}

/* eslint-disable react/boolean-prop-naming */
VideoPreview.propTypes = {
  allowEditTitle: PropTypes.bool,
  GamesStore: PropTypes.shape().isRequired,
  index: PropTypes.number,
  item: PropTypes.shape(),
  ModalStore: PropTypes.shape().isRequired,
  withMarker: PropTypes.bool,
  allowEditScore: PropTypes.bool
};

VideoPreview.defaultProps = {
  allowEditTitle: false,
  index: 0,
  item: {},
  withMarker: false,
  allowEditScore: false
};

export default inject("GamesStore", "ModalStore")(observer(VideoPreview));

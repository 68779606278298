import React, { Component } from "react";
import { game as gameModule } from "@myplay/all";
import { inject, observer } from "mobx-react/index";
import { SuccessButton } from "@myplay/ui";
import Upload from "../../../utils/videoUpload/Upload";
import I18N from "../../../I18N";
import { liveProduction } from "@myplay/all";

import "./UserVideoUpload.scss";

const videoModule = gameModule.videos;

const INPUT_INIT_VALUE = I18N.t("SELECT_VIDEO");

class UserVideoUpload extends Component {
  inputRef = React.createRef();

  state = {
    fileName: INPUT_INIT_VALUE,
    isUploadStarted: false
  };




  handleSelectFile = () => {
    const fileName = this.inputRef.current.value.split("\\")[2];
    this.setState({ fileName });
  };

  handleUploadFile = async () => {
    const { UserStore, TeamsStore, NotificationsStore } = this.props;
    const { fileName } = this.state;

    const { data } = UserStore;
    const userId = data._id;
    const { currentTeam } = TeamsStore;

    try {
      const res = await videoModule.uploadDirectExternalVideo(
        currentTeam._id,
        userId,
        fileName,
        "checksum"
      );

      const { uploadUrl, requestId } = res;

      NotificationsStore.addClientUploadTasks(requestId, fileName);

      this.upload = new Upload({
        id: fileName,
        url: uploadUrl,
        file: this.inputRef.current.files[0],
        onChunkUpload: info => {
          const percent = Math.ceil(
            (info.uploadedBytes / info.totalBytes) * 100
          );
          const uploadTask = NotificationsStore.clientUploadTasks.find(
            task => task.requestId === requestId
          );
          if (uploadTask) {
            uploadTask.setTaskProcess(percent);
          }
        }
      });

      try {
        this.setState({ isUploadStarted: true });
        await this.upload.start();
        await NotificationsStore.removeClientUploadTasks(requestId);
        await videoModule.updateUploadVideoRequest(requestId, "GCS_UPLOADED");
        NotificationsStore.clearCheckActiveTasksInterval();
        await NotificationsStore.getActiveTasksRecursive();
      } catch (e) {
        console.log(e.message);
      } finally {
        this.upload = null;
      }
    } catch (e) {
      console.log(e);
    }
  };

  hasFile = () => {
    return this.state.fileName !== INPUT_INIT_VALUE;
  };

  render() {
    const { fileName, isUploadStarted } = this.state;
    return isUploadStarted ? (
      <div className="user-video-upload__finish">
        <p>{I18N.t("FINISH_UPLOAD")}</p>
      </div>
    ) : (
      <div className="user-video-upload">
        <p>
          <p>{I18N.t("UPLOAD_DIRECT")}</p>
        </p>
        <div
          className="user-video-upload__file-upload-wrapper"
          data-text={fileName}
        >
          <input
            type="file"
            accept="video/mp4,.mov,.m4p,.m4v,.mkv,video/avi"
            onChange={this.handleSelectFile}
            ref={this.inputRef}
          />
        </div>
        <SuccessButton
          disabled={!this.hasFile()}
          onClick={this.handleUploadFile}
        >
          {I18N.t("UPLOAD")}
        </SuccessButton>
      </div>
    );
  }
}

export default inject("UserStore", "TeamsStore", "NotificationsStore")(
  observer(UserVideoUpload)
);

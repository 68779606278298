import React from 'react';
import './RecentBooking.scss'
import youtubeIcon from "../../assets/images/youtube-icon.png";
import facebookIcon from "../../assets/images/facebook-icon.png";


const logos = {
  "YOUTUBE":  youtubeIcon,
  "FACEBOOK": facebookIcon
}

const RecentBooking = ({bookingData}) => {
  const {day,month ,gameTime} = bookingData;
  return (
    <div className="recent-booking">
      <div className="recent-booking__head">
        <div className="recent-booking__head__game-title">
          <p>{gameTime ? "THE NEXT LIVE EVENT IS ON" : "NO UPCOMING EVENTS"}</p>
        </div>
      </div>
      {gameTime &&
        <div className="recent-booking__head__date">
          <p className="recent-booking__head__date">{month}</p>
          <p className="recent-booking__head__date">{day},</p>
          <div className="recent-booking__head__date__game-time">
            <p>{gameTime}</p>
          </div>
        </div>}
    </div>
  );
};



export default RecentBooking;

import React from "react";
import PropTypes from "prop-types";
import { CircleProgressBar } from "@myplay/ui";

import I18N from "../../I18N";
import "./LiveProgress.scss";

const LiveProgress = ({ progress, name })=> {
  return (
    <div className="live-progress">
      <div className="live-progress__pie-container" data-testid="circle-progress">
        <CircleProgressBar strokeWidth="8" progress={progress} />
      </div>
      <p className="live-progress__text">{I18N.t("GENERATING_LIVE_VIEW_FROM")}</p>
      <p className="live-progress__text">{name}</p>
    </div>
  );
};

LiveProgress.propTypes = {
  progress: PropTypes.number,
  name: PropTypes.string
};

LiveProgress.defaultProps = {
  progress: 0,
  name: ""
}

export default LiveProgress;